import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class StorePageProductsCore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			current_page: 1,
			products: [],
			loading_data: true,
			total_entries: 0,
			queryParams: {
				order: null,
				query: "",
			},
			opening_time: {
				day: 0,
				hour: 0,
				minute: 0,
				second: 0
			}
		};
		this.ChangePage = this.ChangePage.bind(this);
		this.GetData = this.GetData.bind(this);

		this.ChangeOrder = this.ChangeOrder.bind(this);
		this.Filter = this.Filter.bind(this);
	}

	componentDidMount() {
		this.GetData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.kind !== this.props.kind) {
			this.setState({ products: [] });
			this.GetData();
		}
	}

	ChangePage(current_page) {
		this.setState({ current_page }, this.GetData);
	}

	GetData() {
		this.setState({ loading_data: true });
		PostData("/get_store_details", {
			page: this.state.current_page,
			kind: this.props.kind,
			slug: this.props.storeSlug,
			...this.state.queryParams,
		})
		.then((response) => {
			this.setState({ ...response });
			this.setState({ loading_data: false });
		})
		.catch((err) => { });
	}

	ChangeOrder(order) {
		const queryParams = this.state.queryParams;
		queryParams.order = order;
		this.setState(
			{
				queryParams,
			},
			this.GetData
		);
	}

	Filter(e) {
		e.preventDefault();
		const queryParams = this.state.queryParams;
		queryParams.query = e.currentTarget.query.value;
		this.setState(
			{
				queryParams,
			},
			this.GetData
		);
	}
}

export default StorePageProductsCore;

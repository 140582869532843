import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import GlobalVar from "../../GlobalVar"

class MyStorePanelCore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			store_detail: {},
			own_packages: {
				end_datetime: new Date(),
				info: {
					name: "Yükleniyor...",
					package_content: []
				},
			},
			external_packages: {},
			package_usages: {},
			SetData: {},
			activeBox: null,
			uploadedFiles: [],
			article: "Yükleniyor..."
		}
		this.GetData = this.GetData.bind(this);
		this.UpdateData = this.UpdateData.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
		this.UpdateImage = this.UpdateImage.bind(this);
		this.RemoveImage = this.RemoveImage.bind(this);
		this.GetArticle = this.GetArticle.bind(this);
		this.RemainingHandler = this.RemainingHandler.bind(this);
	}

	componentDidMount() {
		this.GetData();
		this.UpdateData();
	}


	GetArticle() {
		return {__html: this.state.article}
	}

	GetData() {
		let exist_package = {
			end_datetime: new Date(),
			info: {
				name: "Aktif Paket Yok",
				package_content: []
			},
		};
		let all_content = [];
		PostData("/magaza-paketlerim", {get_info: "true"})
			.then((response) => {
				if (response.exist_store_packages.length > 0){
					if (response.exist_store_packages.length > 1) {
						// TODO: exist_package = tüm elemanların toplamı tek obje.
						exist_package = response.exist_store_packages[0];
						all_content = response.exist_store_packages[0].info.package_content;
					} else if (response.exist_store_packages.length === 1) {
						exist_package.end_datetime = response.exist_store_packages[0].end_datetime;
						exist_package.order = response.exist_store_packages[0].info.order;
						exist_package.info.name = response.exist_store_packages[0].info.name;
						exist_package.info.package_content = response.exist_store_packages[0].info.package_content.sort(function(a,b){
							return a.order - b.order;
						})
						all_content = response.exist_store_packages[0].info.package_content;
					}
					for (let key in all_content) {
						for (let product_key in response.products) {
							if (all_content[key].product_id === response.products[product_key].id) {
								all_content[key].product_name = response.products[product_key].name;
								if(GlobalVar.boolean_sub_products.includes(all_content[key].product_id)){
									all_content[key].product_type = "boolean";
								}else if(GlobalVar.currency_sub_products.includes(all_content[key].product_id)){
									all_content[key].product_type = "currency";
								}else if(GlobalVar.percentage_sub_products.includes(all_content[key].product_id)) {
									all_content[key].product_type = "percentage";
								}else if(GlobalVar.constant_sub_products.includes(all_content[key].product_id)){
									all_content[key].product_type = "constant";
								}else{
									all_content[key].product_type = "default";
								}
								delete all_content[key].product_id;
							}
						}
					}
					exist_package.info.package_content = all_content;
				}
				this.setState({
					own_packages: exist_package,
					external_packages: response.add_store_packages,
					article: response.article
				});
			})
			.catch((err) => {

			});
	}

	UpdateData = () => {
		PostData("/profilim", {})
			.then((data) => {
				this.setState({SetData: data});
			})
			.catch((err) => {

			});
	};

	BoxOpen(activeBox){
		this.setState({
			activeBox
		});
	}

	UpdateImage(e) {
		const uploadedFiles = [];
		if (uploadedFiles.length > 1) {
			ShowPopup({
				message: "En fazla 1 avatar yükleyebilirsiniz!",
				title: "Hata",
			});
			e.target.value = '';
			return false;
		}
		if(typeof e === "Integer"){
			return false;
		}

		const files = e.currentTarget.files;
		if (files.length) {
			const types = ['image/jpeg', 'image/jpg', 'image/png'];
			if (types.indexOf(files[0].type) === -1) {
				ShowPopup({
					message: "Sadece JPG ve PNG fotoğraflarını yükleyebilirsiniz!",
					title: "Hata",
				});
				e.target.value = '';
				return false;
			}
			uploadedFiles.push(files[0]);
			this.setState({ uploadedFiles });
		}
		this.setState({ uploadedFiles },
			() => { e.target.value = ''; }
		);
	}

	RemoveImage(fileIndex) {
		this.setState({ uploadedFiles: [] });
	}

	RemainingHandler(product_id, remaining, amount){
		if(GlobalVar.boolean_sub_products.includes(product_id) || product_id == "boolean"){
			if(amount == 0){
				return(
					<div className="property-benefit">
						<label className="non-exist">Yok</label>
					</div>
				);
			}else{
				return(
					<div className="property-benefit">
						<label className="exist">Var</label>
					</div>
				);
			}
		}else if(GlobalVar.currency_sub_products.includes(product_id) || product_id == "currency"){
			return(
				<div className="property-benefit">
					<strong>{amount} ₺</strong>
				</div>
			);
		}else if(GlobalVar.percentage_sub_products.includes(product_id) || product_id == "percentage"){
			return(
				<div className="property-benefit">
					<label className="exist">%{amount}</label>
				</div>
			);
		}else if(GlobalVar.constant_sub_products.includes(product_id) || product_id == "constant"){
			if(amount > 1) {
				return (
					<div className="property-benefit">
						<label className="exist">{amount} Adet</label>
					</div>
				);
			}else{
				return (
					<div className="property-benefit">
						<label className="non-exist">Yok</label>
					</div>
				);
			}

		}else{
			return(
				<div className="property-benefit">
					<strong>{remaining}</strong>
					<label> / {amount}</label>
				</div>
			);
		}
		return true;
	}
}

export default MyStorePanelCore;
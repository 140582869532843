import React from 'react';
import ImageWithFallback from "../../../Common/ImageWithFallback";
import currency from "../../../../packs_helper/scripts/Currency";
import DefaultCategoryImage from "../../../../assets_v3/images/default-category.jpeg"

class ProgressBar extends React.Component {
    render() {
        let barLength = ((100 / (this.props.ItemWizard.length - 1)) * (this.props.currentStep)) + 1;
        return (
            <div className="wizard-progress">
                <div className="wizard-progress-btn">
                    <picture
                        className="wizard-category-selection-image"
                        style={{ borderRadius: '.25rem' }}
                    >
                        {this.props.SelectedGame ? (
                            <ImageWithFallback
                                src={this.props.SelectedGame.image.category_image}
                            />
                        ):(
                            <ImageWithFallback
                                src={DefaultCategoryImage}
                            />
                        )}
                    </picture>
                </div>
                <div className="wizard-progress-top">
                    <div className="wizard-progress-info">
                        <div className="wizard-progress-info-right-border">
                            <div className={this.props.SelectedGame ? 'wizard-progress-info-green': undefined}>
                                {
                                    this.props.SelectedGame ?
                                        this.props.SelectedGame.name: 'Kategori Henüz Seçilmedi'
                                }
                            </div>
                            <div className={this.props.AdSell !== null ? 'wizard-progress-info-green': undefined}>
                                {
                                    this.props.AdSell ?
                                        'Satış İlanı': this.props.AdSell === null ?
                                            'İlan Tipi Henüz Seçilmedi':'Alım İlanı'
                                }
                            </div>
                        </div>
                        <div>
                            <div className={this.props.ItemName ? 'wizard-progress-info-green': undefined}>
                                {
                                    this.props.ItemName ?
                                        this.props.ItemName: 'İlan Başlığı Henüz Belirlenmedi'
                                }
                            </div>
                            <div className={this.props.Price > 0 ? 'wizard-progress-info-green': undefined}>
                                {
                                    this.props.Price > 0 ?
                                        currency(this.props.Price) + ' ₺':'Fiyat Henüz Belirlenmedi'
                                }
                            </div>
                        </div>
                    </div>
                    <hr className="wizard-progress-top-hr" />
                    <div className="wizard-progress-bar">
                        <div className="wizard-progress-bar-container">
                            {[...Array(this.props.ItemWizard.length)].map((x, i) =>
                                (<a onClick={() => this.props.Navigate("jump", i)}
                                    className={this.props.currentStep < i ?
                                        "wizard-progress-bar-circle" : "wizard-progress-bar-circle done"}
                                    key={i}
                                    title={this.props.ItemWizard[i].stepTitle}>
                                </a>)
                            )}
                        </div>
                        <div
                            className="wizard-progress-bar-line"
                            style={{ width: barLength > 100 ? 100 + "%" : barLength + "%" }}>
                        </div>
                    </div>
                    {/* Bulunduğun adımın başlığı
                    <div className="wizard-progress-title">{this.props.CurrentStep.stepTitle}</div>
                    */}
                </div>
            </div>
        );
    }
}

export default ProgressBar;
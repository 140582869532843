function ConfirmationValidate(validateProps) {
    let resultMessage = '';
    let resultTarget = '';
    let resultState = false;

    if(validateProps.adSell && !['physical'].includes(validateProps.type)){
        if(validateProps.agreementChecked && validateProps.deliveryTime){
            resultState = true
        }else{
            if(!validateProps.deliveryTime){
                resultMessage = 'Devam etmek için lütfen teslimat saatini seçin.';
                resultTarget = 'delivery-times';
            }else if(!validateProps.agreementChecked){
                resultMessage = 'Devam etmek için lütfen Oyuncu Pazarı sözleşmesini onaylayın.';
                resultTarget = 'bazaar-agreement';
            }
        }
    }else{
        if(validateProps.agreementChecked){
            resultState = true
        }else{
            resultMessage = 'Devam etmek için lütfen Oyuncu Pazarı sözleşmesini onaylayın.';
            resultTarget = 'bazaar-agreement';
        }
    }

    return {
        "state": resultState,
        "message": resultMessage,
        "target": resultTarget
    }
}

export default ConfirmationValidate;
import React from "react";
import Currency from "../../packs_helper/scripts/Currency";

const PriceQuickSelectButton = props => {
	const OnClick = () => {
		props.onClick(props.amount);
	}

	return (
		<button type="button" className={"btn btn-default" + (props.selected ? ' active' : '')} onClick={OnClick}>{Currency(props.amount)} ₺</button>
	)
}

export default PriceQuickSelectButton;
import React from "react";
import PaymentGridListItem from "./PaymentGridListItem";
import PostData from "../../packs_helper/scripts/PostData";
import DateFormat from "../../packs_helper/scripts/DateFormat";

import PhoneInput from "../Common/PhoneInput";
import DecimalFieldTag from "../Common/DecimalFieldTag";
import PriceFieldTag from "../Common/PriceFieldTag";
import Copy from "../../packs_helper/scripts/Copy";

const groups = {
	transfer: "Yatırmanız Gereken Tutar",
	credit_card: "Kartınızdan Çekilecek Tutar",
	mobile_payment: "Faturanıza Yansıyacak Tutar",
};

const PaymentGridListItemBankTransfer = (props) => {
	const [formStatus, SetFormStatus] = React.useState(false);
	const [amount, SetAmount] = React.useState(0);
	const [waitForResponse, SetWaitForResponse] = React.useState(false);
	const paymentGridListItemRef = React.useRef(null);

	const didMountRef = React.useRef(false);
	React.useEffect(() => {
		if (didMountRef.current) {

			paymentGridListItemRef.current.UpdateMaxHeight();
		} else {
			didMountRef.current = true;
		}
	}, [formStatus]);
	const ToggleForm = () => {
		SetFormStatus(!formStatus);
	};

	const FormSubmitHandler = (e) => {
		e.preventDefault();
		SetWaitForResponse(true);
		const formData = new FormData(e.target);
		formData.append("payment_notification[payment_id]", props.paymentMethod.id);

		const entries = formData.entries();
		let entry;
		while(entry = entries.next().value){
			if(entry[0] !== 'payment_notification[description]' && (!entry[1] || entry[1] == '0')){
				ShowPopup({
					title: 'Hata',
					message: 'Lütfen tüm alanları doldurunuz!'
				});
				SetWaitForResponse(false);
				return false
			}
		}

		PostData("/bakiye-yukle", formData, {
			redirect: false,
		})
			.then((response) => {
				SetWaitForResponse(false);
				if(response.state && response.browser != 'safari'){
					setTimeout(function(){ redirect('/odemelerim') }, 5000);
				}
			})
			.catch((err) => {
				SetWaitForResponse(false);
			});
	};

	const ShowHowToPayment = () => {
		ShowPopup({
			title: "Nasıl Ödeme Bildirimi Yapabilirim?",
			message:
				'<div style="max-width: 800px"> ' +
				props.paymentMethod.how_to_payment +
				"</div>",
		});
	};

	return (
		<PaymentGridListItem
			id={props.paymentMethod.id}
			image={props.paymentMethod.image}
			group={props.paymentMethod.group}
			name={props.paymentMethod.name}
			slogan={props.paymentMethod.slogan}
			waitForResponse={waitForResponse}
			ref={paymentGridListItemRef}
			show={props.show}
			ComponentOpen={props.ComponentOpen}
			mobile={props.mobile}
		>
			{formStatus ? (
				<form
					action="#"
					method="post"
					onSubmit={FormSubmitHandler}
					className="payment-grid-list-item-body-wrapper"
					data-wait-for-response={waitForResponse}
				>
					{(props.paymentMethod.short_description ||
						props.paymentMethod.slogan) && (
						<div className="item-properties">
							{props.paymentMethod.slogan && (
								<div className="item-properties-box">
									<strong>{props.paymentMethod.slogan}</strong>
								</div>
							)}
							{props.paymentMethod.short_description.map((sd) => (
								<div className="item-properties-box" key={sd[0]}>
									{sd[0]}: <strong>{sd[1]}</strong>
								</div>
							))}
						</div>
					)}
					<div className="payment-account-form-grid">
						<PriceFieldTag
							wrapperClass="input-with-label"
							label="Hesabınıza Eklenecek Tutar:"
							name="payment_notification[amount]"
							id={`amount-${props.paymentMethod.id}`}
							readOnly={waitForResponse}
							onChange={SetAmount}
							value={amount}
						/>

						{props.paymentMethod.rate > 0 && (
							<div className="input-with-label">
								<label>{groups[props.paymentMethod.group]}:</label>
								<div className="input">
									{Currency(amount / (1 - props.paymentMethod.rate))} ₺
								</div>
							</div>
						)}

						{props.paymentMethod.ask_which_field.indexOf(1) > -1 && (
							<input
								placeholder="Adınız ve Soyadınız:"
								type="text"
								name="payment_notification[name_surname]"
								id={`name_surname-${props.paymentMethod.id}`}
								defaultValue={props.fullname}
								readOnly={waitForResponse}
							/>
						)}

						<div className="input-with-label">
							<label htmlFor={`payment_notification-${props.paymentMethod.id}`}>
								Tarih:
							</label>
							<input
								type="date"
								name="payment_notification[date_at]"
								id={`payment_notification-${props.paymentMethod.id}`}
								defaultValue={DateFormat(new Date, '%Y-%m-%d')}
								max={DateFormat(new Date, '%Y-%m-%d')}
							/>
						</div>

						{props.paymentMethod.ask_which_field.indexOf(2) > -1 && (
							<div className="input-with-label">
								<label htmlFor={`phone-${props.paymentMethod.id}`}>
									Telefon Numaranız:
								</label>
								<PhoneInput
									name="payment_notification[phone]"
									id={`phone-${props.paymentMethod.id}`}
								/>
							</div>
						)}

						{props.paymentMethod.ask_which_field.indexOf(4) > -1 && (
							<div className="input-with-label">
								<label htmlFor={`transaction_no-${props.paymentMethod.id}`}>
									4 Haneli İşlem Kodu:
								</label>
								<input
									type="text"
									name="payment_notification[transaction_no]"
									id={`transaction_no-${props.paymentMethod.id}`}
								/>
							</div>
						)}

						{props.paymentMethod.ask_which_field.indexOf(0) > -1 && (
							<div className="input-with-label">
								<label htmlFor={`atm_no-${props.paymentMethod.id}`}>
									ATM No:
								</label>
								<input
									type="text"
									name="payment_notification[atm_no]"
									id={`atm_no-${props.paymentMethod.id}`}
								/>
							</div>
						)}

						{props.paymentMethod.ask_which_field.indexOf(3) > -1 && (
							<div className="input-with-label">
								<label
									htmlFor={`identification_number-${props.paymentMethod.id}`}
								>
									T.C. Kimlik No:
								</label>
								<DecimalFieldTag
									name="payment_notification[identification_number]"
									id={`identification_number-${props.paymentMethod.id}`}
									readOnly={waitForResponse}
									maxLength={11}
								/>
							</div>
						)}

						{props.paymentMethod.ask_which_field.indexOf(5) > -1 && (
							<textarea
								name="payment_notification[description]"
								className="right-box"
								placeholder="Açıklamayı yazınız:"
								readOnly={waitForResponse}
							></textarea>
						)}

						{(props.paymentMethod.ask_which_field.length +
							(props.paymentMethod.ask_which_field.indexOf(5) > -1)) %
							2 ===
							1 && <div />}

						<div className="grid-2">
							<button className="btn btn-default" onClick={ToggleForm}>
								Vazgeç
							</button>
							<button className="btn btn-jungle" type="submit">
								{props.paymentMethod.group === "transfer"
									? "Ödeme Bildir"
									: "Ödeme Yap"}
							</button>
						</div>
					</div>
				</form>
			) : (
				<div className="payment-grid-list-item-body-wrapper">
					<div className="payment-account-grid">
						<label>Hesap Sahibi:</label>
						<div>{props.paymentMethod.account_owner}</div>
						<label>Şube No:</label>
						<div>
							{props.paymentMethod.branch_code} {props.branch}
						</div>
						<label>Hesap No:</label>
						<div>{props.paymentMethod.account_number}</div>
						<label>IBAN:</label>
						<strong>
							<button className="btn" onClick={Copy} data-copy={props.paymentMethod.iban.replaceAll(" ","")}>
								<svg className="icon">
									<use href="#gs-icon-payment-methods-copy"></use>
								</svg>
							</button>
							{props.paymentMethod.iban}
						</strong>
					</div>
					<div className="payment-grid-list-item-body-footer">
						<div className="payment-grid-list-item-body-footer-info">
							<div>
								Lütfen <strong>havale işlemini gerçekleştirdikten sonra</strong>{" "}
								ödeme bildirimi yapınız.
							</div>
						</div>
						<button className="btn btn-light" onClick={ShowHowToPayment}>
							Nasıl Ödeme Bildirimi Yapabilirim?
						</button>
						<button className="btn btn-jungle" onClick={ToggleForm}>
							Ödeme Bildir
						</button>
					</div>
				</div>
			)}
		</PaymentGridListItem>
	);
};
export default PaymentGridListItemBankTransfer;

import React from "react";
import CommentEdit from "./CommentEdit";
import ImageWithFallback from "../../Common/ImageWithFallback";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Stars from "../../Common/Stars";
import AvatarHandler from "../../Common/AvatarHandler";

const statusTexts = {
	approved: "Onaylandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
	answer_approved: "Cevaplandı",
	answer_pending: "Beklemede",
	answer_rejected: "Reddedildi",
};

const CommentsToStore = React.forwardRef((props, ref) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const [editModeActive, SetEditMode] = React.useState(false);
	const [answerModeActive, SetAnswerMode] = React.useState(false);
	const boxRef = React.useRef(null);
	const didMountRef = React.useRef(false);

	React.useEffect(() => {
		if (didMountRef.current) {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		}
	}, [answerModeActive]);

	React.useEffect(() => {
		didMountRef.current = true;
	}, []);

	React.useImperativeHandle(ref, () => ({
		UpdateMaxHeight: () => {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		},
	}));

	const ToggleWrapper = () => {
		const boxHeight = boxRef.current.clientHeight;
		if (boxHeight === 0) {
			props.GetDetail(props.id);
			SetBoxHeight(boxRef.current.scrollHeight);
			props.BoxOpen(props.id);
		} else {
			SetBoxHeight(0);
		}
	};

	const ToggleEditMode = () => {
		SetEditMode(!editModeActive);
	};

	const ToggleAnswerMode = () => {
		SetAnswerMode(!answerModeActive);
	};

	React.useEffect(() => {
		if (props.show) {
			ToggleWrapper();
		} else {
			SetBoxHeight(0);
		}
	}, [props.show]);

	return (
		<div className="user-panel-box">
			<header onClick={ToggleWrapper}>
				<StatusIcon status={props.status} />
				<strong>#{props.id}</strong>-<h2>{props.name}</h2>
				<button className={"btn" + (boxHeight === 0 ? "" : " rotate-ccw-180")}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
				</button>
			</header>
			<div
				className="user-panel-box-wrapper"
				style={{ maxHeight: boxHeight }}
				ref={boxRef}
			>
				<div className="user-panel-box-body">
					<div className="comment-container">
						<a
							href={props.content.product_path}
							target="_blank"
							className="comment-image"
						>
							<ImageWithFallback src={props.content.product_image} />
						</a>
						<div className="comment-product-content">
							<div>
							<h3>
								<a href={props.content.product_path} target="_blank">
									{props.name}
								</a>
								</h3>
							</div>

							<div className="comment-product-content-price">
								Tutar:
								<strong>{Currency(props.content.product_cost)} ₺</strong>
							</div>
						</div>

						<a
							href={props.content.comment_user_code ? `/mesajlarim?active_tab=${props.content.comment_user_code}` : null}
							target="_blank"
							className="comment-image"
						>
							<ImageWithFallback
								src={AvatarHandler(props.content.comment_user_avatar, props.content.comment_user_approve_avatar)}
							/>
						</a>
						<div className="comment-content">
							<div className="comment-content-user">
								<h2>
									<a
										href={props.content.comment_user_code ? `/mesajlarim?active_tab=${props.content.comment_user_code}` : null}
										target="_blank"
									>
										{props.content.comment_user_name}
									</a>
								</h2>
								Tarih: <strong>{DateFormat(props.content.created_at)}</strong>
								<Stars
									value={props.content.rating}
									xlinkHref="#gs-icon-user-panel-star"
								/>
							</div>
							<div className="vertical-seperator" />

							{props.content.comment}
						</div>
						<div className="comment-buttons">
							<StatusIcon status={props.status}>
								{statusTexts[props.status]}
							</StatusIcon>

							<button
								className="btn btn-sm btn-blue"
								disabled={props.content.store_comment}
								onClick={ToggleAnswerMode}
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-edit"></use>
								</svg>
								Cevapla
							</button>
						</div>

						{answerModeActive && (
							<React.Fragment>
								<a
									href={props.content.product_path}
									target="_blank"
									className="comment-image"
								>
									<ImageWithFallback
										src={AvatarHandler(props.content.answer_user_avatar, props.content.answer_user_approve_avatar)}
									/>
								</a>
								<div className="comment-content">
									<div className="comment-content-user">
										<h2>{props.content.answer_user_name}</h2>
									</div>
									<div className="vertical-seperator" />
									<CommentEdit
										id={props.id}
										message=""
										rating={5}
										answer={true}
									/>
								</div>
							</React.Fragment>
						)}

						{props.content.store_comment && (
							<React.Fragment>
								<span
									className="comment-image"
								>
									<ImageWithFallback
										src={AvatarHandler(props.content.answer_user_avatar, props.content.answer_user_approve_avatar)}
									/>
								</span>
								<div className="comment-content">
									<div className="comment-content-user">
										<h2>{props.content.answer_user_name}</h2>
									</div>
									<div className="vertical-seperator" />
									{editModeActive ? (
										<CommentEdit
											id={props.id}
											message={props.content.store_comment}
											rating={null}
										/>
									) : (
										props.content.store_comment
									)}
								</div>
								<div className="comment-buttons">
									<StatusIcon status={props.content.answer_status}>
										{statusTexts[props.content.answer_status]}
									</StatusIcon>

									<button
										className="btn btn-sm btn-default"
										onClick={ToggleEditMode}
										disabled={props.content.answer_status === "answer_pending"}
									>
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-edit"></use>
										</svg>
										Düzenle
									</button>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

const StatusIcon = (props) => {
	if (props.status === "approved" || props.status === "answer_approved") {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-jungle"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-approved"></use>
				</svg>
				{props.children}
			</div>
		);
	} else if (
		props.status === "rejected" ||
		props.status === "answer_rejected"
	) {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-red"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-rejected"></use>
				</svg>
				{props.children}
			</div>
		);
	} else {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-orange"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-pending"></use>
				</svg>
				{props.children}
			</div>
		);
	}
};

export default CommentsToStore;

export { StatusIcon };

import React from "react";
import SignBoxCore from "./SignBoxCore";

import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import PasswordResetForm from "./PasswordResetForm";
import PasswordResetComplete from "./PasswordResetComplete";
import ConfirmationForm from "./ConfirmationForm";
import Switcher from "../Common/Switcher";
import PopUp from "../Common/PopUp";
// import Banner from "../../assets_v3/images/sign-box-banner";

class DesktopRenderer extends SignBoxCore {
	render() {
		const { activeTab } = this.state;
		return (
			<div className="gs-sign-grid">
				<div className="gs-sign-form-area">
					<Switcher
						buttons={[
							{
								id: "sign-in",
								text: "Giriş Yap",
							},
							{
								id: "sign-up",
								text: "Üye Ol",
							},
						]}
						onChange={this.ActivateTab}
						active={activeTab}
					/>

					{activeTab === "sign-in" && (
						<SignInForm
							signInPath={this.props.signInPath}
							captchaImage={this.state.captchaImage}
							RefreshCaptchaCode={this.RefreshCaptchaCode}
							ActivateTab={this.ActivateTab}
							pathname={this.pathname}
						/>
					)}

					{activeTab === "sign-up" && (
						<SignUpForm
							signUpPath={this.props.signUpPath}
							captchaImage={this.state.captchaImage}
							RefreshCaptchaCode={this.RefreshCaptchaCode}
							ActivateTab={this.ActivateTab}
							UpdateEmail={this.UpdateEmail}
							email={this.state.email}
						/>
					)}

					{activeTab === "password-reset" && (
						<PasswordResetForm
							passwordResetPath={this.props.passwordResetPath}
							captchaImage={this.state.captchaImage}
							RefreshCaptchaCode={this.RefreshCaptchaCode}
							ActivateTab={this.ActivateTab}
							email={this.state.email}
						/>
					)}

					{activeTab === "confirmation" && (
						<ConfirmationForm
							sendConfirmationPath={this.props.sendConfirmationPath}
							captchaImage={this.state.captchaImage}
							RefreshCaptchaCode={this.RefreshCaptchaCode}
							ActivateTab={this.ActivateTab}
							email={this.state.email}
						/>
					)}

					{activeTab === "password-reset-complete" && (
						<PasswordResetComplete
							renewPasswordPath={this.props.renewPasswordPath}
							captchaImage={this.state.captchaImage}
							RefreshCaptchaCode={this.RefreshCaptchaCode}
							ActivateTab={this.ActivateTab}
						/>
					)}
				</div>
				<a
					href={this.props.image.image_link}
					target="_blank"
					className="gs-sign-banner-area"
					style={{
						backgroundImage: this.props.image.info
							? `url(https://img.gamesatis.com/${this.props.image.info.img}.jpg)`
							: null,
					}}
				>
					{/*<img src={Banner} />*/}
				</a>
			</div>
		);
	}
}

export default DesktopRenderer;
function CountDown(countdownElement){
	const countdownTo = new Date(countdownElement.dataset.countdown);
	const miliseconds = countdownTo - new Date() + window._stf_;
	const secondsToLeft = Math.round(miliseconds / 1000);
	countdownTimer(
		countdownElement,
		secondsToLeft,
		countdownElement.dataset.oncomplete
		);	
}

function countdownTimer(countdownElement, secondsToLeft, onComplete) {
	const { seconds, minutes, hours, days } = countdownFormat(secondsToLeft);
	countdownElement.innerHTML = `${hours}:${minutes}:${seconds}`;
	if (secondsToLeft === 0) {
		if (onComplete) {
			onComplete();
		}
		return false;
	}
	setTimeout(countdownTimer, 1000, countdownElement, secondsToLeft - 1);
}

function countdownFormat(secondsToLeft, padStart = 2) {
	const seconds = (secondsToLeft % 60).toString().padStart(padStart, "0");
	const minutes = (Math.floor(secondsToLeft / 60) % 60)
	.toString()
	.padStart(padStart, "0");
	const hours = (Math.floor(secondsToLeft / (60 * 60)) % (60 * 60))
	.toString()
	.padStart(padStart, "0");
	const days = (Math.floor(secondsToLeft / (24 * 60 * 60)) % (24 * 60 * 60))
	.toString()
	.padStart(padStart, "0");
	return {
		seconds,
		minutes,
		hours,
		days,
	};
}

export default CountDown;
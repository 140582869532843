import React from "react";
import HelpAndSupportCore from "./HelpAndSupportCore";
import HelpAndSupportFilterButton from "./HelpAndSupportFilterButton";
import HelpAndSupportListItem from "./HelpAndSupportListItem";

class DesktopRenderer extends HelpAndSupportCore{
	render() {
		const { activeCategory } = this.state;

		return (
			<section className="container">
				<div className="payment-grid">
					<div className="payment-grid-filter">
						<header>
							<h1>Yardım & Destek</h1>
							<label>Lütfen destek veya yardım almak istediğiniz konuyu seçiniz.</label>

							<div className="input-with-icon">
								<div className="icon">
								<svg>
									<use xlinkHref="#gs-icon-layout-search"></use>
								</svg>
								</div>
								<input type="search" placeholder="Başlıklarda Ara..." onInput={this.FilterCategories} />
							</div>

						</header>
						<div className="payment-grid-filter-body">
						{this.state.filteredCategories.map((category) => (
							<HelpAndSupportFilterButton
								key={"category-" + category.id}
								onClick={this.SetActiveCategory}
								active={this.state.activeCategory.id === category.id}
								category={category}
							/>
							))}
							{!this.state.filteredCategories.length && <div className="payment-grid-filter-body-alert">Sonuç Bulunamadı!</div>}
						</div>
					</div>
					<div className="payment-grid-list">
						{this.state.activeCategory.questions.map((question) => (
							<HelpAndSupportListItem
							key={"question-" + question.id}
							question={question}
							/>
						))}
					</div>
				</div>
			</section>
		);
	}
}

export default DesktopRenderer;
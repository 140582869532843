import React from "react";
import AuctionDetailCore from "./AuctionDetailCore";

import AuctionJoinModal from "./AuctionJoinModal";
import RefundableBalanceModal from "./RefundableBalanceModal";
import AuctionOfferModal from "./AuctionOfferModal";
import CountDown from "./CountDown";
import Switcher from "../Common/Switcher";

import PriceFieldTag from "../Common/PriceFieldTag";
import Currency from "../../packs_helper/scripts/Currency";

const listImages = {
	1: require("../../assets_v3/images/auction-list-1.png"),
	2: require("../../assets_v3/images/auction-list-2.png"),
	3: require("../../assets_v3/images/auction-list-3.png"),
};

class MobileRenderer extends AuctionDetailCore {
	constructor(props){
		super(props);
		this.state.showAllDesc = false;
		this.ToggleShowAllDesc = this.ToggleShowAllDesc.bind(this);
	}

	ToggleShowAllDesc(){
		this.setState(prevState => ({
			showAllDesc: !prevState.showAllDesc
		}));
	}

	render() {
    const { activeTab } = this.state;
    const auctionStartDateTime = new Date(
      this.props.auctionStartDateTime
    ).setMilliseconds(this.serverTimeFix);
    const auctionEndDateTime = new Date(
      this.state.auctionEndDateTime
    ).setMilliseconds(this.serverTimeFix);
    const discountRate = Math.round(
      (1 - this.state.maxOffer / this.props.auctionRealCost) * 100
    );
		return (
			<React.Fragment>
				<section className="container">
					<div className="auction-detail">
						<div className={"auction-detail-desc" + (this.state.showAllDesc ? ' open' : '')} onClick={this.ToggleShowAllDesc}>
							<div className="auction-detail-desc-top">
								<a href={"/share?url=" + this.props.url} className="btn btn-default" data-remote="true" data-method="post" rel="nofollow">
									<svg className="icon">
										<use xlinkHref="#gs-icon-auction-detail-share"></use>
									</svg>
								</a>
								<h1>{this.props.productName}</h1>
								<h2>{this.props.productPackageName}</h2>
							</div>
							<div
								className="auction-detail-desc-text"
								dangerouslySetInnerHTML={this.description()}
							/>
							{this.props.description && (
								<button className="show-more">
									<svg className="icon">
										<use xlinkHref="#gs-icon-layout-caret-down"></use>
									</svg>
								</button>
							)}
						</div>

						<picture>
							<img
								src={
									"https://steamcommunity-a.akamaihd.net/economy/image/" +
									this.props.steamItemImage +
									"/250x200"
								}
								alt={this.props.productName}
							/>
						</picture>

						<div className="auction-detail-props">
							{this.props.csGoFloat > 0 ? (
								<div className="float-rank" data-rank={(this.props.csGoFloat || 0).toFixed(8)}>
									<div className="label">
										Float: {(this.props.csGoFloat || 0).toFixed(8)}
									</div>
									<div className="point"></div>
									<div className="fn"></div>
									<div className="mw"></div>
									<div className="ft"></div>
									<div className="ww"></div>
									<div className="bs"></div>
								</div>
							):(
								<div className="label">
									{this.props.productName}
								</div>
							)}
							<div className="auction-detail-props-boxes">
								<div className="auction-detail-props-box-2">
									<div>
										{(this.props.steamAppId && this.props.steamMarketName) ? (
											<a href={`https://steamcommunity.com/market/listings/${this.props.steamAppId}/${this.props.steamMarketName}`} className="market-button" target="_blank" rel="nofollow">
											<svg className="icon">
												<use xlinkHref="#gs-icon-auction-box-steam"></use>
											</svg>
											<small>Markette Göster</small>
											</a>
										) : '-'}
									</div>
									<div>Nadirlik: {this.props.rarity || '-'}</div>
								</div>

								<div className="auction-detail-props-box-1">
									<img src="" />
									<div>{this.props.collection || '-'}</div>
								</div>

								<div className="auction-detail-props-box-3">
									<button type="button" className="button" disabled>
										<svg className="icon">
											<use xlinkHref="#gs-icon-auction-detail-crop"></use>
										</svg>
										Ekran Görüntüsü
									</button>
									{this.props.inspectUrl ? (
									<a href={this.props.inspectUrl} className="button" target="_blank">
									<svg className="icon">
										<use xlinkHref="#gs-icon-auction-detail-aim"></use>
									</svg>
									In-Game
									</a>
									) : (
									<button type="button" className="button" disabled>
										<svg className="icon">
											<use xlinkHref="#gs-icon-auction-detail-aim"></use>
										</svg>
										In-Game
									</button>
									)}
									<button type="button" className="button" disabled>
										<svg className="icon">
											<use xlinkHref="#gs-icon-auction-detail-3d"></use>
										</svg>
										3B Görünüm
									</button>
								</div>
							</div>
						</div>

						<div className="auction-detail-cost">
							{auctionStartDateTime > this.state.currentDateTime ? (
								<CountDown
									currentDateTime={this.state.currentDateTime}
									endDateTime={auctionStartDateTime}
								/>
							) : auctionEndDateTime > this.state.currentDateTime ? (
								<CountDown
									currentDateTime={this.state.currentDateTime}
									endDateTime={auctionEndDateTime}
								/>
							) : (
								<blockquote>Açık artırma sona erdi!</blockquote>
							)}

							<div className="auction-detail-cost-pricing">
								<div className="deposit">
									Depozito(Giriş Ücreti):
									<span>{Currency(this.props.auctionOfferFee)} ₺</span>
								</div>
								<div className="market-price">
									<svg className="icon">
										<use xlinkHref="#gs-icon-auction-detail-steam"></use>
									</svg>
									<s>{Currency(this.props.auctionRealCost)} ₺</s>
								</div>
								<div className="price">
									<div>{Currency(this.state.maxOffer)} ₺</div>
									{this.props.steamRate > 0 && (
										<div style={{fontSize: '1rem'}}>( {Currency(this.state.maxOffer / this.props.steamRate)} $ )</div>
									)}
								</div>
							</div>

							{auctionEndDateTime > this.state.currentDateTime ? (
								<div className="auction-detail-cost-form">
									<PriceFieldTag
										wrapperClass="input-with-label"
										label="Teklif:"
										onChange={this.UpdateCurrentOffer}
										placeholder={"Min. " + (this.state.maxOffer + 1)}
										value={this.state.currentOffer}
									/>
									<button
										className="btn btn-jungle"
										onClick={this.OfferValidate}
									>
										Teklif Ver
									</button>
								</div>
							) : this.props.currentUserId &&
							  this.props.auctionUserId === this.props.currentUserId ? (
								<React.Fragment>
									<div>Açık artırmayı siz kazandınız!</div>
									<form
										action={this.props.sendToCartPath}
										method="post"
										data-remote="true"
										className="auction-detail-cost-form"
									>
										<input
											type="hidden"
											name="id"
											value={this.props.productId}
										/>
										<div className="input-with-label">
											<label>Adet: </label>
											<input
												type="hidden"
												name="quantity"
												value="1"
												type="text"
												readOnly={true}
											/>
										</div>
										<button type="submit" className="btn btn-jungle">
											Satın Al
										</button>
									</form>
								</React.Fragment>
							) : (
								<div>
									Açık artırma sonucunda, gerçek fiyatı{" "}
									<strong> {Currency(this.props.auctionRealCost)} ₺</strong> olan
									<strong> {this.props.productName}</strong> ürünü
									<strong> {Currency(this.props.auctionMaxOffer)} ₺ ( {Currency(this.props.auctionMaxOffer / this.props.steamRate)} $ )</strong>{" "}
									 teklif vererek{" "}
									<strong>
										 %
										{Math.round(
											(1 -
												this.props.auctionMaxOffer /
													this.props.auctionRealCost) *
												100
										)}
									</strong>{" "}
									 indirimle
									{" "}
									<strong>
										{this.props.auctionUserName ||
											(this.state.offers.length > 0
												? this.state.offers[0].store_name
												: "")}
									</strong>
									{" "} kazanmıştır.
								</div>
							)}
						</div>
					</div>
				</section>

				<section className="container">
					<header className="auction-offers-title">
						<h2>Teklifler</h2>
					</header>
					{this.state.offers.length ? (
						<div className="auction-offers">
							{this.state.offers.map((offer, index) => (
								<div
									className="auction-offers-row"
									key={offer.user_id + offer.name + offer.date}
								>
									<header>
										{index < 3 ? (
											<div>
												<img src={listImages[index + 1]} />
											</div>
										) : (
											<div>{index + 1}</div>
										)}
										{offer.is_online ? (
											<i className="user-online" />
										) : (
											<i className="user-offline" />
										)}
										<div>{offer.name}</div>

										{offer.success_auctions > 0 && (
											<img
												src={this.RankImage(offer.success_auctions)}
												className="auction-offers-row-rank"
												title={
													offer.success_auctions + " adet açık artırma kazandı!"
												}
											/>
										)}
									</header>
									<div className="auction-offers-row-body">
										{!!offer.store_name && (
											<React.Fragment>
												<label>Mağaza:</label>
												<div>
													<a
														href={"/magaza/" + offer.store_slug}
														target="_blank"
													>
														{offer.store_name}
													</a>
												</div>
											</React.Fragment>
										)}
										<label>Teklif:</label>
										<div><strong>{Currency(offer.offer)} ₺</strong></div>

										<label>Teklif Zamanı:</label>
										<div>{offer.date}</div>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="no-offer-alert section-content">
							Henüz hiç teklif verilmemiş! İlk teklifi sen ver.
						</div>
					)}
				</section>

				{this.state.auctionOfferModalVisible && (
					<AuctionOfferModal
						currentOffer={this.state.currentOffer}
						Confirm={this.MakeOffer}
						Hide={() => this.setState({ auctionOfferModalVisible: false })}
					/>
				)}
				{this.state.auctionJoinModalVisible && (
					<AuctionJoinModal
						auctionOfferFee={this.props.auctionOfferFee}
						Confirm={this.MakeOffer}
						Hide={() => this.setState({ auctionJoinModalVisible: false })}
					/>
				)}
				{this.state.refundableBalanceModalVisible && (
					<RefundableBalanceModal
						Hide={() => this.setState({ refundableBalanceModalVisible: false })}
						myBankAccountsPath={this.props.myBankAccountsPath}
						sendMessagePath={this.props.sendMessagePath}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default MobileRenderer;

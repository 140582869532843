import React from "react";

class Paginate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			total_page: 1,
			range: []
		}

		this.url = new URL(window.location.href);
		this.rel = props.rel ? ` ${props.rel}` : "";

		this.MakeUrl = this.MakeUrl.bind(this);
		this.OnClick = this.OnClick.bind(this);
		this.CalculatePages = this.CalculatePages.bind(this);
	}

	componentDidMount(){
		this.CalculatePages();
	}

	componentDidUpdate(prevProps){
		if(prevProps.current_page !== this.props.current_page || prevProps.total_count !== this.props.total_count){
			this.CalculatePages();
		}
	}

	CalculatePages(){
		this.start_page = 1;
		const total_page = Math.ceil(
			parseInt(this.props.total_count) / parseInt(this.props.per_page)
		);
		this.setState({
			total_page
		})
		this.end_page = total_page;

		if (total_page > 4) {
			const newStart = this.props.current_page - 2;
			const newEnd = this.props.current_page + 2;

			if (newStart > 0 && newEnd <= total_page) {
				this.end_page = newEnd;
				this.start_page = newStart;
			}
			else{
				if (newStart < 1) {
					this.start_page = 1;
					this.end_page = 4;
				}

				if (newEnd > total_page) {
					this.end_page = total_page;
					this.start_page = total_page - 3;
				}
			}
		}

		this.range = Array.from(
			{ length: this.end_page - this.start_page + 1 },
			(v, k) => k + this.start_page
		);
	}

	MakeUrl(page) {
		page === 1 ? this.url.searchParams.delete("page"):this.url.searchParams.set("page", page);
		return this.url.toString();
	}

	OnClick(page){
		this.props.onChange(page);
	}

	render() {

		if(this.state.total_page <= 1){
			return null;
		}
		return (
			<ul className="paginate">
				{this.props.current_page > 1 && (
					<li className="prev">
						<A
							href={this.MakeUrl(this.props.current_page - 1)}
							page={this.props.current_page - 1}
							onClick={this.OnClick}
							rel={"prev" + this.rel}
						>
							&lt;
						</A>
					</li>
				)}
				{this.props.current_page > 3 && this.start_page > 1 && (
					<li>
						<A
							href={this.MakeUrl(1)}
							page={1}
							onClick={this.OnClick}
							rel={this.rel}
						>
							1
						</A>
					</li>
				)}
				{this.start_page > 2 && (
					<li>
						<A
							href={null}
							page={'...'}
						>
							...
						</A>
					</li>
				)}

				{this.range.map((page) =>
					page == this.props.current_page ? (
						<li className="active" key={"page-" + page}>
							<span>{page}</span>
						</li>
					) : page == this.props.current_page - 1 ? (
						<li key={"page-" + page}>
							<A href={this.MakeUrl(page)} page={page} onClick={this.OnClick} rel={"prev " + this.rel}>
								{page}
							</A>
						</li>
					) : page == this.props.current_page + 1 ? (
						<li key={"page-" + page}>
							<A href={this.MakeUrl(page)} page={page} onClick={this.OnClick} rel={"next " + this.rel}>
								{page}
							</A>
						</li>
					) : (
						<li key={"page-" + page}>
							<A href={this.MakeUrl(page)} page={page} onClick={this.OnClick} rel={this.rel}>
								{page}
							</A>
						</li>
					)
				)}

				{this.end_page + 1 < this.state.total_page && (
					<li>
						<A
							href={null}
							page={'...'}
						>
							...
						</A>
					</li>
				)}

				{this.props.current_page < this.state.total_page - 2 && this.end_page < this.state.total_page && (
					<li>
						<A
							href={this.MakeUrl(this.state.total_page)}
							page={this.state.total_page}
							onClick={this.OnClick}
							rel={this.rel}
						>
							{this.state.total_page}
						</A>
					</li>
				)}

				{this.props.current_page < this.state.total_page && (
					<li className="next">
						<A
							href={this.MakeUrl(this.props.current_page + 1)}
							page={this.props.current_page + 1}
							onClick={this.OnClick}
							rel={"next " + this.rel}
							key={this.props.current_page + 1}
						>
							&gt;
						</A>
					</li>
				)}
			</ul>
		);
	}
}

const A = props => {
	const OnClick = e => {
		e.preventDefault();
		if(props.onClick){
			props.onClick(props.page)
		}
	};
	return (
		<a href={props.href} onClick={OnClick} rel={props.rel}>
			{props.children}
		</a>
	);
}

export default Paginate;

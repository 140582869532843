import React from "react";
import PopUp from "../Common/PopUp";
import Currency from "../../packs_helper/scripts/Currency";


const AuctionJoinModal = (props) => {
	return (
		<PopUp
			title="Açık Artırma Katılım Onayı"
			Hide={props.Hide}
		>
			<div style={{width: "720px"}}>
				<p>
					Teklif vermek üzere olduğunuz açık artırmanın katılım ücreti
					(depozito) <strong style={{color: "var(--red-color)"}}>{Currency(props.auctionOfferFee)} ₺</strong>'dir.
				</p>
				<hr />
				<p>
					Bu tutar açık artırmaya katıldığınızda <strong>tek seferlik</strong>{" "}
					bakiyenizden düşecektir ve bu açık artırmada{" "}
					<strong style={{color: "var(--red-color)"}}><u>sınırsız teklif</u></strong>{" "}
					verme hakkına sahip olacaksınız. Açık artırma sonunda kaybetmeniz
					halinde bakiyenize iade edilecektir.
				</p>
				<hr />
				<p>
					<strong style={{color: "var(--red-color)"}}>
						{Currency(props.auctionOfferFee)} ₺
					</strong>{" "}
					karşılığında bu açık artırmaya katılmak istiyor musunuz?
				</p>
				<p>
					<small>
						{" "}
						Detaylı bilgi için{" "}
						<a
							href="#auction-rules"
							style={{color: "var(--blue-color"}}
							onClick={props.Hide}
						>
							Açık Artırma Kuralları
						</a>
					</small>
				</p>
			</div>
			<hr />
			<div style={{display: 'flex', gap: 'var(--spacer-2)', justifyContent: 'flex-end'}}>
				<button
					className="btn btn-default"
					type="button"
					onClick={props.Hide}
				>
					Vazgeç
				</button>
				<button className="btn btn-blue" type="button" onClick={props.Confirm}>
					Onayla
				</button>
			</div>
		</PopUp>
	);
};

export default AuctionJoinModal;
import React from 'react';
import SelectBox from "../../../../Common/SelectBox";

class PropertiesSelect extends React.Component {
    render() {
        return (
            <div className={this.props.properties.length > 0 && !this.props.isMobile ? "grid-2":""}>
                {this.props.properties.length > 0 ?
                    this.props.properties.map((property) => (
                    <div className="wizard-input" key={property.id}>
                        <label>{property.name}:</label>
                        <SelectBox
                            xlinkHref="#gs-icon-user-panel-caret-down"
                            prompt={property.name}
                            options={property.values.map((propertyValue) => ({
                                id: propertyValue[0],
                                name: propertyValue[1],
                            }))}
                            selected={this.props.selectedProperty[property.id]}
                            onChange={(id) => this.props.UpdateProperty(property.id, id)}
                            className="grid-2x"
                            key={property.name + property.id}
                            id={'property_'+property.id}
                            location='wizard'
                        />
                    </div>
                )):(
                    <div style={{textAlign:"center"}}>Bu kategoriye uygun herhangi bir özellik yok. Sonraki adıma geçebilirsiniz.</div>
                )}
            </div>
        );
    }
}

export default PropertiesSelect;
import React from "react";
import profileButtons from "./_profileButtons";

// import Cookie from "../../../packs_helper/scripts/Cookie";
const url = new URL(document.location.href);

class UserPanelCore extends React.Component {
	constructor(props) {
		super(props);
		this.defaultPathname = url.pathname;

		this.profileButtons = profileButtons({});
		let activeSection;
		if(this.defaultPathname){
			activeSection = this.profileButtons.find(profileButton => profileButton.pathname === this.defaultPathname);
		}

		this.state = {
			activeSection,
			not_show_identification_number: props.user.not_show_identification_number
		};
		this.ChangeRoute = this.ChangeRoute.bind(this);
		this.UpdatePersonalInfoVisibility = this.UpdatePersonalInfoVisibility.bind(this);
	}

	componentDidMount(){
		window.onpopstate = e => {
			const route = e.currentTarget.location.href.match(/https?:\/\/.*?(\/.*)/);

			if(route){
				this.ChangeRoute(route[1], true);
			}
		}
	}

	ChangeRoute(route, noStatePush){
		const url = new URL("http://127.0.0.1" + route);

		const activeSection = this.profileButtons.find(profileButton => profileButton.pathname === url.pathname);
		if(activeSection){
			if(!noStatePush){
				history.pushState({}, '', route);
			}
			this.setState({
				activeSection
			})			
		}
	}

	UpdatePersonalInfoVisibility(not_show_identification_number){
		this.setState({
			not_show_identification_number
		})
	}

}

export default UserPanelCore;

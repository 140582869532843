import React from "react";
import SearchBoxResult from "./SearchBoxResult";
import PostData from "../../../packs_helper/scripts/PostData";

const steamAppIds = {
	730: "cs2-skin",
	252490: "rust",
	570: "dota-2",
};

class SiteSearch extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			searchText: "",
			searchResults: [],
			writing: false,
			searching: false,
			focus: false
		};

		this.OnChange = this.OnChange.bind(this);
		this.OnSubmit = this.OnSubmit.bind(this);
		this.Search = this.Search.bind(this);
		this.Focus = this.Focus.bind(this);
		this.Blur = this.Blur.bind(this);

		this.lastKeyAction = null;
		this.throttleTimeoutId = null;
	}

	OnChange(e) {
		const searchText = e.currentTarget.value;
		this.setState({
			searchText,
			writing: true,
		});
		this.lastKeyAction = Date.now();

		clearTimeout(this.throttleTimeoutId);
		this.throttleTimeoutId = setTimeout(() => {

			this.Search();
			this.lastKeyAction = null;
		}, 500);
	}

	OnSubmit(e) {


		e.preventDefault();
		this.Search();
	}

	Focus(){
		this.setState({focus: true})
	}

	Blur(){
		setTimeout(() => {
			this.setState({focus: false})
		}, 300);
	}

	Search() {
		this.setState({
			writing: false,
			searching: true
		})
		PostData("/arama", {
			search: this.state.searchText,
			type: "games",
		})
			.then((response) => {
				const searchResults = [
					[
						"products",
						response.products.map((x) => ({
							path: `/${x.category_slug}/${x.slug}`,
							name: x.name,
							image: x.image_file_name,
							key: `${x.id}-${x.name}`,
						})),
					],
					[
						"skins",
						response.skins.map((x) => ({
							// path: `/${steamAppIds[x.steam_app_id]}/${x.slug}?id=${x.id}`,
							path: `/${steamAppIds[x.steam_app_id]}/${x.slug}`,
							name: x.name,
							image: `https://steamcommunity-a.akamaihd.net/economy/image/${x.image_file_name}/120fx96f`,
							key: `${x.id}-${x.name}`,
						})),
					],
					[
						"categories",
						response.categories.map((x) => ({
							path: `/${x.slug}`,
							search: `/${x.slug}?q=${this.state.searchText}`,
							name: x.name,
							image: x.logo_file_name,
							key: `${x.id}-${x.name}`,
						})),
					],
				].filter((x) => x[1].length);


				const hasResult = searchResults.filter((x) => x[1].length).length > 0;



				this.setState({
					searchResults,
					hasResult,
					searching: false
				});
			})
			.catch((err) => {


			});
	}

	render() {
		return (
			<form
				action="/arama"
				className="input-with-icon search-box"
				id="site-search"
				onSubmit={this.OnSubmit}
			>
				<button className="icon">
					<svg>
						<use xlinkHref="#gs-icon-layout-search"></use>
					</svg>
				</button>
				<input
					type="search"
					placeholder="Oyun Ara..."
					value={this.searchText}
					onChange={this.OnChange}
					onFocus={this.Focus}
					onBlur={this.Blur}
				/>
				{this.state.focus && 
				(this.state.hasResult ? (
					<SearchBoxResult searchResults={this.state.searchResults} />
				) : (
					this.state.writing ? (
						<div className="search-box-result-alert">Yazılıyor...</div>
					) : (
					this.state.searching ? (
						<div className="search-box-result-alert">Aranıyor...</div>
					) : (
					this.state.searchText.length ? (
						<div className="search-box-result-alert">Sonuç Bulunamadı!</div>
					) : (
						<div className="search-box-result-alert">Aramak için bir şeyler yazın.</div>
					)))
				))}
			</form>
		);
	}
}

export default SiteSearch;

import React from "react";

const RemoveButton = (props) => {
	const OnClick = () => {
		props.onClick(props.id);
	};
	return (
		<button className="btn btn-red" onClick={OnClick}>
			<svg className="icon">
				<use xlinkHref="#gs-icon-user-panel-rejected"></use>
			</svg>
		</button>
	);
};

export default RemoveButton;
import React from "react";
import ItemCore from "./ItemCore";
import ImageWithFallback from "../../../Common/ImageWithFallback";
import ProgressBarMobile from "../Wizard/ProgressBarMobile";

class MobileRenderer extends ItemCore{
	render() {
		const ItemWizard = this.GetWizardSteps();
		const CurrentStep = ItemWizard[this.state.currentStep];
		return (
			<React.Fragment>
				<div className="item-grid wizard-grid">
					<ProgressBarMobile
						currentStep = {this.state.currentStep}
						Navigate = {
							(direction, step = 0) =>
								this.WizardStep(direction, ItemWizard, this.state.currentStep, step)
						}
						ItemWizard = {ItemWizard}
						CurrentStep = {CurrentStep}
					/>
				</div>
				<div className="wizard-item-form">
					<div className="grid-2x"
						 style={{
							 display: 'flex',
							 alignItems: 'center',
							 justifyContent: 'center',
							 gap: '1rem',
						 }}>
						{this.state.selectedGame && (
							<picture
								className="wizard-category-selection-image"
								style={{
									maxHeight: '4rem', //***** (h / 1.39 = w) *****\\
									maxWidth: '2.94rem',
									borderRadius: '.25rem'
								}}
							>
								<ImageWithFallback
									src={this.state.selectedGame.image.category_image}
								/>
							</picture>
						)}
						<div>
							<div>
								{
									this.state.selectedGame ?
										this.state.selectedGame.name:"Bir kategori seçerek ilan eklemeye başlayın."
								}
							</div>
							{this.state.selectedGame && (
								<div style={{fontSize: 'var(--font-size-sm)'}}>kategorisine ilan ekliyorsunuz</div>
							)}
						</div>
					</div>
					<hr />
					<CurrentStep.stepComponent {...CurrentStep.stepProps}/>
					<div id="wizard-error"></div>
				</div>

				<div className="item-grid wizard-footer-grid">
					<div>
						{this.state.currentStep - 1 !== -1 && (
							<button
								onClick={() => this.WizardStep("back", ItemWizard, this.state.currentStep)}
								className="btn btn-default"
								disabled={this.state.currentStep - 1 === -1}
							>
								{
									this.state.currentStep - 1 !== -1 ?
										"Önceki Adım: " + ItemWizard[this.state.currentStep - 1].stepTitle
										:"İlk Adım"
								}
							</button>
						)}
					</div>
					<div>
						{this.state.currentStep + 1 !== ItemWizard.length && (
							<button
								onClick={()=>this.WizardStep("next", ItemWizard, this.state.currentStep)}
								className="btn btn-default"
								id="nextWizardStep"
								disabled={this.state.currentStep + 1 === ItemWizard.length}
							>
								{
									this.state.currentStep + 1 !== ItemWizard.length ?
										"Sonraki Adım: " + ItemWizard[this.state.currentStep + 1].stepTitle
										:"Son Adım"
								}
							</button>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MobileRenderer;

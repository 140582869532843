import React from "react";
import DesktopRenderer from "./DesktopRenderer";
import MobileRenderer from "./MobileRenderer";

const Notifications = props => {
	if(props.mobile){
		return <MobileRenderer {...props} />;
	}
	else{
		return <DesktopRenderer {...props} />;
	}
}

export default Notifications;
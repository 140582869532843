import React from "react";

const TypeButton = (props) => {
	const OnClick = (e) => {
		e.preventDefault();
		props.onClick(props.id);
	};

	return (
		<a href="#" onClick={OnClick} className={props.active ? "active" : ""}>
			{props.label}
		</a>
	);
};

export default TypeButton;
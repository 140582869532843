import React from "react";

class HelpAndSupportListItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			maxHeight: null,
		};
		this.bodyRef = React.createRef();
		this.ToggleItem = this.ToggleItem.bind(this);
		this.UpdateMaxHeight = this.UpdateMaxHeight.bind(this);
	}

	componentDidMount() {
		if (this.props.show) {
			this.ToggleItem();
		}
	}

	ToggleItem() {
		if (this.props.waitForResponse) {
			return false;
		}
		let newHeight = null;
		if (!this.state.maxHeight) {
			newHeight = this.bodyRef.current.scrollHeight;
		}
		this.setState({ maxHeight: newHeight });
	}

	UpdateMaxHeight() {

		let newHeight = this.bodyRef.current.scrollHeight;
		this.setState({ maxHeight: newHeight });
	}

	get question(){
		return {__html: this.props.question.question};
	}

	get answer(){
		return {__html: this.props.question.answer};
	}

	render() {
		const { maxHeight } = this.state;
		return (
			<div className="payment-grid-list-item">
				<header onClick={this.ToggleItem}>
					<div dangerouslySetInnerHTML={this.question} />
					<button>
						{this.props.maxHeight ? (
							<svg className="icon">
								<use xlinkHref="#gs-icon-payment-methods-caret-up"></use>
							</svg>
						) : (
							<svg className="icon">
								<use xlinkHref="#gs-icon-payment-methods-caret-down"></use>
							</svg>
						)}
					</button>
				</header>
				<div
					className="payment-grid-list-item-body"
					ref={this.bodyRef}
					style={{ maxHeight: maxHeight }}
				>
					<div className="payment-grid-list-item-body-wrapper" dangerouslySetInnerHTML={this.answer} />
				</div>
			</div>
		);
	}
}
export default HelpAndSupportListItem;

import React, {useState} from "react";
import DecimalFieldTag from "../../Common/DecimalFieldTag";
import PostData from "../../../packs_helper/scripts/PostData";

class UpdateIdentification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            lastname: this.props.lastname,
            birthdate: '',
            tc: ''
        };
        this.ValidateIdentity = this.ValidateIdentity.bind(this);
    }

    componentDidMount() {
        if (this.props.birthdate !== null){
            const datePart = this.props.birthdate.split("/")
            this.setState({birthdate: datePart[2] + "-" + datePart[1] + "-" + datePart[0]});
        }
    }

    ValidateIdentity() {
        const formData = {
            name: this.state.name,
            surname: this.state.lastname,
            birthday: this.state.birthdate,
            tc: this.state.tc,
        };
        PostData("/tc-kimlik-kaydet", formData)
            .then((data) => {
                if(data.state){
                    this.props.UpdateData();
                    this.props.SetPopUpNull();
                }
            })
            .catch((err) => { });
    }

    render() {
        return (
            <React.Fragment>
            <div className="grid-1">
                <div className="input-with-edit-btn">
                    <div className="input-with-label">
                        <label>Ad:</label>
                        <input
                            type="text"
                            name="name"
                            defaultValue={this.state.name}
                            onChange={(e)=>this.setState({name:e.target.value})}
                        />
                    </div>
                    <button
                        className="btn btn-light"
                        onClick={(e) => { e.target.closest("div").querySelector("input").select() }}
                    >
                        <svg className="icon">
                            <use href="#gs-icon-user-panel-edit"></use>
                        </svg>
                    </button>
                </div>
                <div className="input-with-edit-btn">
                    <div className="input-with-label">
                        <label>Soyad:</label>
                        <input
                            type="text"
                            name="surname"
                            defaultValue={this.state.lastname}
                            onChange={(e)=>this.setState({lastname:e.target.value})}
                        />
                    </div>
                    <button
                        className="btn btn-light"
                        onClick={(e) => { e.target.closest("div").querySelector("input").select() }}
                    >
                        <svg className="icon">
                            <use href="#gs-icon-user-panel-edit"></use>
                        </svg>
                    </button>
                </div>
                <div className="input-with-label">
                    <label>Doğum Tarihi:</label>
                    <input
                        type="date"
                        name="birthday"
                        defaultValue={this.state.birthdate}
                        onChange={(e) => this.setState({birthdate:e.target.value})}
                    />
                </div>
                <div className="input-with-label">
                    <label>T.C. Kimlik No:</label>
                    <DecimalFieldTag
                        type="text"
                        name="tc"
                        maxLength={11}
                        value={this.state.tc || ""}
                        onChange={(e) => this.setState({tc:e.toString()})}
                    />
                </div>
                <button className="btn btn-blue" onClick={() => this.ValidateIdentity()}>Doğrula</button>
            </div>
            </React.Fragment>
        );
    }
}

export default UpdateIdentification;

// export default async function PostData(url = "", data = {}) {
export default function PostData(url = "", data = {}, options = {}) {
	return new Promise(function (resolve, reject) {
		const defaultOptions = { redirect: true, rawHtml: false };
		options = Object.assign({}, defaultOptions, options);

		const fetchOptions = {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			mode: "cors", // no-cors, *cors, same-origin
			cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
			credentials: "same-origin", // include, *same-origin, omit
			headers: {
				"X-CSRF-Token": document
					.querySelector('meta[name="csrf-token"]')
					.getAttribute("content"),
				"X-Requested-With": "XMLHttpRequest"
			},
			redirect: "follow", // manual, *follow, error
			referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		};

		let formBody, formContentType;
		if (data instanceof FormData) {
			fetchOptions["body"] = data;
			// formContentType = 'multipart/form-data';
			// formContentType = 'application/x-www-form-urlencoded';
			// formContentType = 'text/plain;charset=UTF-8';
			// formContentType = "application/json";
			// formContentType = 'multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM';

			// data.entries().forEach(function(a,b){
			// 	console.log(a)
			// 	console.log(b)
			// });
			for (var value of data.entries()) {

			}
		} else {
			// formBody = JSON.stringify(data);
			fetchOptions["body"] = JSON.stringify(data);
			fetchOptions.headers["Content-Type"] = "application/json";


		}

		// Default options are marked with *
		fetch(url, fetchOptions).then((response) => {
			if (response.redirected) {
				redirect(response.url).then(resolve({ redirected: true }));
			} else {


				// return console.log(response.text());

				// response.headers.forEach(function(a,b,c){
				// 	console.log("***********************");
				// 	console.log("a:");
				// 	console.log(a);
				// 	console.log("b:");
				// 	console.log(b);
				// 	console.log("######################");
				// })

				switch (response.status) {
					case 200:
						try {
							response
								.text()
								.then((text) => {
									try{
										const jsonObj = JSON.parse(text);
										if (jsonObj.message) {
											ShowPopup({
												title: "Site Mesajı",
												message: jsonObj.message,
												redirect: options.redirect && jsonObj.redirect_to,
												autoSize: !options.rawHtml,
												rawHtml: options.rawHtml
											});
										} else if (options.redirect && jsonObj.redirect_to) {
											window.location.href = jsonObj.redirect_to;
										}
										resolve(jsonObj);
										}
									catch(err){


										resolve(text);
										if(options.rawHtml){
											ShowPopup({
												message: text,
												rawHtml: true
											});
										}
									}
								})
								.catch((err) => {


									// response.text().then((text) => {
									// 	resolve(text);
									// });
								});
						} catch (e) {


							// response.text().then((text) => {
							// 	resolve(text);
							// });
						}
						break;
					case 204:
						reject();
						ShowPopup({
							title: "Site Mesajı",
							message:
								"Sunucu boş yanıt verdi. Sayfayı yenileyerek işleminizin gerçekleştiğini kontrol ediniz.",
							autoSize: true,
						});
						break;
					case 401:
						reject();
						ShowPopup({
							title: "Site Mesajı",
							message:
								'Bu işlemi yapabilmek için giriş yapmanız gerekmektedir. Devam etmek için giriş yapınız.<a href="/giris-yap" target="_self">Giriş Yap</a>',
							autoSize: true,
						});
						break;
					case 422:
						reject();
						ShowPopup({
							title: "Site Mesajı",
							message: "Oturumunuz sonlandı lütfen giriş yapınız.",
							autoSize: true,
						});
						break;
					case 413:
						reject();
						ShowPopup({
							title: "Site Mesajı",
							message:
								'<div class="mb-3">Yüklemeye çalıştığınız dosyaların toplam boyutu en fazla 6MB olabilir. Lütfen dosyalarınızı küçülterek yeniden deneyin.</div><div><div class="text-primary">https://www.resizenow.com/tr</div> yada benzer bir online araç kullanarak yeniden boyutlandırabilirsiniz</div>',
							autoSize: true,
						});
						break;
					default:
						reject();
						ShowPopup({
							title: "Site Mesajı",
							message:
								'<div class="lh-125">İşlem yapılırken bilinmeyen bir hata oluştu. Lütfen sayfayı yenileyerek tekrar deneyiniz. Sorun devam ederse yaptığınız işlemi canlı destek ekibimizle paylaşarak yardım alabilirsiniz.</div>',
							autoSize: true,
						});
				}
			}
		});
	});
}

function PropertiesSelectValidate(validateProps) {
    let resultMessage = '';
    let resultTarget = '';
    let resultState = false;

    const selectedProperties = Object.entries(validateProps.selectedProperties);
    const properties = validateProps.properties;

    const controlSelectedProperties = properties.every((property) => {
        const selection = selectedProperties.find((selectedProperty) => {
            return parseInt(selectedProperty[0]) === property.id
        })
        if(!selection[1]){
            resultMessage = property.name + " alanını seçmeniz gerekmektedir!"
            resultTarget = 'property_'+ property.id;
            return false;
        }else{
            return true;
        }
    })

    if(controlSelectedProperties){
        resultState = true
    }

    return {
        "state": resultState,
        "message": resultMessage,
    }
}

export default PropertiesSelectValidate;
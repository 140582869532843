import React from "react";

const TypeButton = (props) => {
	const OnClick = e => {
		e.preventDefault();
		props.onClick(props.id);
	};

	if(props.disabled){
		return (
			<a href="#" disabled={true}>
				<svg className="icon">
					<use xlinkHref={"#gs-icon-user-panel-" + props.icon}></use>
				</svg>
				{props.label}
			</a>
		);		
	}
	else{
		return (
			<a href="#" onClick={OnClick} className={(props.active ? 'active' : '')}>
				<svg className="icon">
					<use xlinkHref={"#gs-icon-user-panel-" + props.icon}></use>
				</svg>
				{props.label}
			</a>
		);
	}
};

export default TypeButton;

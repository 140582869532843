import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import Copy from "../../../packs_helper/scripts/Copy";
import PopUp from "../../Common/PopUp";

const ListKeys = (props) => {
	return (
		<div className="order-info-digital-code-container">
			{props.keys.map((key) => (
				<KeyRow
					id={key.id}
					digital_code={key.key}
					key={key.key}
					order_detail_id={props.order_detail_id}
					report_problem={props.report_problem}
					ChangeRoute={props.ChangeRoute}
					not_show_identification_number={props.not_show_identification_number}
				/>
			))}
		</div>
	);
};

const KeyRow = (props) => {
	const [reportStatus, SetReportStatus] = React.useState(props.report_problem || false);
	const [popup, SetPopup] = React.useState(false);
	const ReportProblem = () => {
		SetPopup(true);
	};
	const ReportProblemSave = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		formData.append('order_detail_id', props.order_detail_id);
		
		// PostData("/sorun-bildir", {
		PostData("/sorunlu-kod-kaydet/" + props.id, formData)
			.then((response) => {
				// SetPopup(false);
				// SetReportStatus(true);

				props.ChangeRoute(response.indirect_to);
			})
			.catch((err) => {


			});
	};

	return (
		<div className="order-info-digital-code-row" key={props.digital_code}>
			<div className="order-info-digital-code">
				<button className="btn" onClick={Copy} data-copy={props.digital_code}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-copy"></use>
					</svg>
				</button>
				{props.not_show_identification_number ? (
				<pre><div>*****-*****-*****</div></pre>
				) : (
				<div>{props.digital_code}</div>
				)}
			</div>
			<button
				onClick={ReportProblem}
				className="btn btn-sm btn-red fixed-width-button"
				disabled={reportStatus}
			>
				Sorun Bildir
			</button>

			{popup && (
				<PopUp
					title="Sorunlu Kod Bildir"
					Hide={() => SetPopup(false)}
					className="settings-popup"
				>
					<form
						action="#"
						onSubmit={ReportProblemSave}
						className="grid-1"
						autoComplete="off"
					>
						<div className="grid-1">
								<textarea name="message" placeholder="Sorununuzu kısaca açıklayın:" />
							<button className="btn btn-blue">Sorun Bildir</button>
						</div>
					</form>
				</PopUp>
			)}
		</div>
	);
};

export default ListKeys;

import React from "react";

const MyCashOutBox = React.forwardRef((props, ref) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const boxRef = React.useRef(null);

	// React.useEffect(() => {
	// 	if (boxRef.current) {
	// 		const boxHeight = boxRef.current.clientHeight;
	// 		SetBoxHeight(boxHeight);
	// 	}
	// }, []);

	React.useImperativeHandle(ref, () => ({
		UpdateMaxHeight: () => {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		},
	}));

	const ToggleWrapper = () => {
		const boxHeight = boxRef.current.clientHeight;
		if (boxHeight === 0) {
			SetBoxHeight(boxRef.current.scrollHeight);
			props.BoxOpen(props.id)
		} else {
			SetBoxHeight(0);
		}
	};

	React.useEffect(() => {
		if (props.show) {
			ToggleWrapper();
		}
		else{
			SetBoxHeight(0);
		}
	}, [props.show]);

	return (
		<div className="user-panel-box">
			<header
				onClick={ToggleWrapper}
			>
				<StatusIcon status={props.status} />
				<img src={props.image} />
				<strong>#{props.id}</strong>-
				<h2>
					{props.bank_name}
					<small>({props.account_owner_name})</small>
				</h2>
				<button
					className={"btn" + (boxHeight === 0 ? '' : ' rotate-ccw-180')}
				>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
				</button>
			</header>
			<div
				className="user-panel-box-wrapper"
				style={{ maxHeight: boxHeight }}
				ref={boxRef}
			>
				<div className="user-panel-box-body">{props.children}</div>
			</div>
		</div>
	);
});

const StatusIcon = (props) => {
	if (props.status === "approved") {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-jungle"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-approved"></use>
				</svg>
				{props.children}
			</div>
		);
	} else if (props.status === "rejected") {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-red"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-rejected"></use>
				</svg>
				{props.children}
			</div>
		);
	} else {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-orange"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-pending"></use>
				</svg>
				{props.children}
			</div>
		);
	}
};

export default MyCashOutBox;
export { StatusIcon };
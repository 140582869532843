import React from "react";

const MyItemBox = React.forwardRef((props, ref) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const boxRef = React.useRef(null);
	const [tooltip, SetTooltip] = React.useState(null);

	let last_update = new Date(props.lastUpdate);
	last_update.setDate(last_update.getDate() + 30)
	last_update = last_update.getDate()+"/"+(last_update.getMonth()+1)+"/"+last_update.getFullYear();

	React.useEffect(() => {
		if (boxRef.current) {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxHeight);
		}
	}, []);

	React.useImperativeHandle(ref, () => ({
		UpdateMaxHeight: () => {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		},
	}));

	const ToggleWrapper = () => {
		const boxHeight = boxRef.current.clientHeight;
		if (boxHeight === 0) {
			SetBoxHeight(boxRef.current.scrollHeight);
			props.BoxOpen(props.id)
		} else {
			SetBoxHeight(0);
		}
	};

	React.useEffect(() => {
		if (props.show) {
			ToggleWrapper();
		}
		else{
			SetBoxHeight(0);
		}
	}, [props.show]);

	const ShowTooltip = (e) => {
		const tooltip = e.currentTarget.dataset.target;
		SetTooltip(tooltip);
	}

	const HideTooltip = () => {
		SetTooltip(null);
	}

	const showEndData = props.status === 'approved' && props.unsales_count > 0 && !props.mobile

	return (
		<div className="user-panel-box">
			<header
				onClick={ToggleWrapper}
			>
				<StatusIcon status={props.status} />
				{/*<img src={props.image} />*/}
				<strong>#{props.id}</strong>
				-
				<h2>{props.name} {props.asset_id && (`(${props.package_name})`)}</h2>
				{showEndData ? (
					<span
						onMouseEnter={ShowTooltip}
						onMouseLeave={HideTooltip}
						data-target="reject-date"
						className="push-left"
					>
						{tooltip === "reject-date" && (
							<div className="tooltip">
								<div>İlanların yayın süresi son güncellemeden itibaren 30 gündür.</div>
								<div>{last_update + ' tarihinde ilanınız otomatik olarak yayından kaldırılacak. '}</div>
								<div>Kaldırılmasını istemiyorsanız belirtilen tarihten önce ilanınızı güncelleyin.</div>
							</div>
						)}
						<span>
							<svg className="icon info">
								<use href="#gs-icon-user-panel-support"></use>
							</svg>
								{' ' + last_update}
						</span>
					</span>
				):(<div></div>)}
				<button
					className={"btn" + (boxHeight === 0 ? showEndData ? '':' push-left' : showEndData ? ' rotate-ccw-180':' push-left rotate-ccw-180')}
					style={showEndData ? {marginLeft: '0px'}:{}}
				>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
				</button>
			</header>
			<div
				className="user-panel-box-wrapper"
				style={{ maxHeight: boxHeight }}
				ref={boxRef}
			>
				<div className="user-panel-box-body">{props.children}</div>
			</div>
		</div>
	);
});

const StatusIcon = (props) => {
	if (props.status === "approved") {
		return (
			<div className={(!props.children ? 'status-icon' : 'status-icon-with-content') + " status-icon-jungle"}>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-approved"></use>
				</svg>
				{props.children}
			</div>
		);
	}
	else if (props.status === "rejected" || props.status === "cancelled") {
		return (
			<div className={(!props.children ? 'status-icon' : 'status-icon-with-content') + " status-icon-red"}>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-rejected"></use>
				</svg>
				{props.children}
			</div>
		);
	}
	else {
		return (
			<div className={(!props.children ? 'status-icon' : 'status-icon-with-content') + " status-icon-orange"}>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-pending"></use>
				</svg>
				{props.children}
			</div>
		);
	}
};

export default MyItemBox;
export { StatusIcon };
import DateDiff from "./DateDiff";
import DateFormat from "./DateFormat";

function RelativeDate(dateString){

	const diff = DateDiff(dateString) / 1000;
	// const dateObj = new Date(dateString);
	// const current = new Date();
	
	if(diff > (365 * 24 * 60 * 60)){
		return DateFormat(dateString);
	}
	else if(diff > (30 * 24 * 60 * 60)){
		return Math.floor(diff / (30 * 24 * 60 * 60)) + " ay önce";
	}
	else if(diff > 24 * 60 * 60){
		return Math.floor(diff / (24 * 60 * 60)) + " gün önce";
	}
	else if(diff > 60 * 60){
		return Math.floor(diff / (60 * 60)) + " saat önce";
	}
	else if(diff > 60){
		return Math.floor(diff / (60)) + " dk önce";
	}
	else if(diff > 3){
		return Math.floor(diff) + " sn önce";
	}
	else{
		return "Şimdi";
	}


		// const years = dateObj.getFullYear();
		// const months = dateObj.getMonth();
		// const days = dateObj.getDate();
		// const hours = dateObj.getHours();
		// const minutes = dateObj.getMinutes();
		// const seconds = dateObj.getSeconds();

		// if(years > current.getFullYear()){
		// 	return DateFormat(dateString);
		// }
		// else if(months > current.getMonth()){
		// 	return (months - current.getMonth()) + " ay önce";
		// }
		// else if(days > current.getDate()){
		// 	return (days - current.getDate()) + " gün önce";
		// }
		// else if(hours > current.getHours()){
		// 	return (hours - current.getHours()) + " saat önce";
		// }
		// else if(minutes > current.getMinutes()){
		// 	return (minutes - current.getMinutes()) + " dakika önce";
		// }
		// else if(seconds > current.getSeconds()){
		// 	return (seconds - current.getSeconds()) + " saniye önce";
		// }
		// else{
		// 	return DateFormat(dateString);
		// }
}

export default RelativeDate;
import React from "react";
import StorePageProductsCore from "./StorePageProductsCore";
import ProductRow from "./ProductRow";
import Paginate from "../../Common/Paginate";
import SelectBox from "../../Common/SelectBox";
import CountDown from "../../Common/CountDown";

class DesktopRenderer extends StorePageProductsCore{
	render() {
		if(new Date(this.props.storeStatus) > new Date()){
			return (
				<div className="alert">
					<p>Bu Mağaza Geçici Olarak Kısıtlanmıştır.</p>
					<CountDown
						endDateTime={this.props.storeStatus}
						countType="textNotation"
					/>
					<p class="sublink">Kısıtlamalar hakkında <a href="/magaza" title="Mağaza Kısıtlamaları Hakkında">daha fazla bilgi</a> edinin.</p>
				</div>
			);
		}else if (this.state.loading_data) {
			 return(
				<div className="alert">
					Yükleniyor...
				</div>
			)
		} else if(this.state.products.length === 0){
			return(
				<div className="alert">
					Mağazanın{" "}
					{this.props.kind === "products" ? "satışta olan" : "satılmış olan"}{" "}
					ilanı bulunmamaktadır!
				</div>
			)
		}else {
			return (
				<section className="store-product-container">
					<div className="store-product-tools">
						<form
							action=""
							onSubmit={this.Filter}
							className="input-with-icon search-box"
						>
							<button className="icon">
								<svg>
									<use xlinkHref="#gs-icon-layout-search"></use>
								</svg>
							</button>
							<input
								type="search"
								placeholder="Mağazada Ara..."
								name="query"
								defaultValue={this.state.queryParams.query}
							/>
						</form>

						<SelectBox
							xlinkHref="#gs-icon-store-page-caret-down"
							options={[
								{
									id: "price-asc",
									name: "Fiyata Göre Artan",
								},
								{
									id: "price-desc",
									name: "Fiyata Göre Azalan",
								},
								{
									id: "date-desc",
									name: "Önce En Yeni",
								},
								{
									id: "date-asc",
									name: "Önce En Eski",
								},
							]}
							prompt="İlanları Sırala"
							onChange={this.ChangeOrder}
							selected={this.state.queryParams.order}
							filter={true}
						/>
					</div>

					{this.state.products.map((product, index) => (
						<ProductRow
							product={product}
							kind={this.props.kind}
							key={index}
						/>
					))}
					{this.state.total_entries > 0 && (
						<Paginate
							onChange={this.ChangePage}
							total_count={this.state.total_entries}
							current_page={this.state.current_page}
							per_page={10}
						/>
					)}
				</section>
			);
		}
	}
}

export default DesktopRenderer;
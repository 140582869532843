import React from "react";

// let gsPopupCount = 0;
const PopUp = (props) => {
	// const random = "p" + (Math.random() * 0xffffffffffff * 100000000000).toString(16);

	const popUpRef = React.useRef(null);
  const escFunction = React.useCallback((event) => {
    if(event.keyCode === 27) {
    	props.Hide();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const Hide = e => {
  	if(popUpRef.current === e.target){
  		props.Hide();
  	}
  }

	return (
		<div className="gs-popup" onClick={Hide} ref={popUpRef}>
			<div className="gs-popup-wrapper">
				<button className="btn gs-popup-close" onClick={props.Hide}></button>
				{props.title && <div className="gs-popup-header">{props.title}</div>}
				<div className={"gs-popup-body " + (props.className || "")}>
					{props.children}
				</div>
			</div>
		</div>
	);
};

export default PopUp;
import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import PopUp from "../../Common/PopUp";

class NotificationsCore extends React.Component {
	constructor(props) {
		super(props);
		const { active_tab, order } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);
		this.state = {
			notifications_count: 0,
			notifications_page: 1,
			notifications: [],
			activeTab: active_tab === undefined ? 'favorilerim' : active_tab,
			my_favorites: {},
			favorite_product_unread: false,
			favorite_store_unread: false,
			notification_unread: false,
			showHistory: false,
			historyDetails: {
				modalTitle: "Fiyat Geçmişi",
				link: "",
				image: "",
				title: "",
				favorite_cost: 0,
				current_cost: 0,
				cost_history: {}
			}
		};
		this.GetNotificationData = this.GetNotificationData.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.RemoveNotification = this.RemoveNotification.bind(this);
		this.RemoveAllNotifications = this.RemoveAllNotifications.bind(this);
		this.ShowHistory = this.ShowHistory.bind(this);

		let isset_notifications = this.props.user.isset_notification;
		if(isset_notifications === null){
			this.state.favorite_product_unread = false;
			this.state.favorite_store_unread = false;
			this.state.notification_unread = false;
		}else{
			this.state.favorite_product_unread = isset_notifications['fav_product_notf'] && this.state.activeTab !== "favorilerim"? true : false;
			this.state.favorite_store_unread = isset_notifications['fav_store_notf']? true : false;
			this.state.notification_unread = isset_notifications['notification'] && this.state.activeTab !== "bildirimlerim"? true : false;
		}

		
		if(!this.props.mobile){
			this.tabs = [
				{
					id: 'favorilerim',
					text: 'Favori İlanlar',
					unread: this.state.favorite_product_unread
				},
				{
					id: 'magazalarim',
					text: 'Takip Edilen Mağazalar',
					unread: this.state.favorite_store_unread,
					disabled: true
				},
				{
					id: 'bildirimlerim',
					text: 'Bildirimlerim', 
					unread: this.state.notification_unread
				},
			]
		}else{
			this.tabs = [
				{
					id: 'favorilerim',
					text: 'İlanlarım',
					unread: this.state.favorite_product_unread
				},
				{
					id: 'magazalarim',
					text: 'Mağazalarım',
					unread: this.state.favorite_store_unread,
					disabled: true
				},
				{
					id: 'bildirimlerim',
					text: 'Bildirimlerim', 
					unread: this.state.notification_unread
				},
			]
		}
		this.ActivateTab = this.ActivateTab.bind(this);
		this.GetFavorite = this.GetFavorite.bind(this);
	}

	componentDidMount() {
		this.ActivateTab(this.state.activeTab);
	}

	ChangePage(notifications_page) {
		this.setState({ notifications_page });
		this.GetNotificationData(notifications_page);
	}

	GetNotificationData(page) {
		PostData("/bildirimlerim", { 
			page
		})
			.then((response) => {
				// TODO response.set_balance
				if (response.length) {
					this.setState({
						notifications: response,
						notifications_count: response[0].total_count,
					});
				}
				else{
					this.setState({
						notifications_count: 0,
						notifications_page: 1,
						notifications: [],
					})
				}
			})
			.catch((err) => {


			});
	}

	GetFavorite(kind){
		PostData("/favorilerim", {kind}).then((response) => {
			this.setState({
				my_favorites: response
			});
		})
	}

	RemoveNotification(notification_id) {
		PostData("/bildirim-sil", { notification_id })
			.then((response) => {
				this.GetNotificationData(this.state.current_page);
			})
			.catch((err) => {


			});
	}

	RemoveAllNotifications() {
		PostData("/tum-bildirimleri-sil")
			.then((response) => {
				if(response.state){
					this.GetNotificationData(1);
				}
			})
			.catch((err) => {


			});
	}

	RemoveFavorite(key) {
		document.getElementById("product_"+key).parentNode.removeChild(document.getElementById("product_"+key));
	}

	ShowHistory(key, status){
		this.setState({ 
			showHistory: !this.state.showHistory
		});

		if(key > -1){
			this.setState({ 
				historyDetails:{
					modalTitle: this.state.my_favorites[key].name + " Fiyat Geçmişi",
					link: this.state.my_favorites[key].category.slug + "/" + this.state.my_favorites[key].slug,
					image: this.state.my_favorites[key].image,
					title: this.state.my_favorites[key].package_name,
					favorite_cost: 0,
					current_cost: this.state.my_favorites[key].cost,
					cost_history: this.state.my_favorites[key].price_history
				}
			});
		}
	}

	ActivateTab(activeTab) {
		this.props.ChangeRoute(`/bildirimlerim?active_tab=${activeTab}`);
		this.setState({ activeTab });
		switch(activeTab){
			case "favorilerim":
				this.GetFavorite("Product");
				this.setState({favorite_product_unread: false});
				break;
			case "magazalarim":
				this.setState({favorite_store_unread: false});
				break;
			case "bildirimlerim":
				this.GetNotificationData(1);
				this.setState({notification_unread: false});
				break;
		}
	}

}


export default NotificationsCore;

import React from "react";
import StorePageCore from "./StorePageCore";
import components from "./components";
import TypeButton from "./TypeButton";
import StoreInfo from "./StoreInfo";
import Currency from "../../packs_helper/scripts/Currency";

class DesktopRenderer extends StorePageCore{
	render() {
		const { ActiveComponent } = this.state;

		const SetRating = rating => {
			this.setState({
				rating: this.state.rating == rating ? null : rating,
			}, () => {
				this.SetActiveComponent(3);
			})
		}
		return (
			<div className="store-grid">
				<StoreInfo
					store={this.props.store}
					commentsStat={this.props.commentsStat}
					rating={this.state.rating}
					SetRating={SetRating}
				/>
				<div className="store-content-container">
					<section className="category-buttons">
						<nav>
							{components.map((type) => (
								<TypeButton
									id={type.id}
									label={type.label}
									onClick={this.SetActiveComponent}
									active={type.id === this.state.ActiveComponent.id}
									key={type.id}
								/>
							))}
						</nav>
					</section>

					<ActiveComponent.component
						storeSlug={this.props.store.slug}
						avatar={this.props.store.avatar}
						storeName={this.props.store.name}
						productCount={this.props.store.productCount}
						kind={ActiveComponent.kind}
						rating={this.state.rating}
						storeStatus={this.props.store.storeStatus}
					/>
				</div>
			</div>
		);
	}
}

export default DesktopRenderer;

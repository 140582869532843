import React from "react";
import DateDiff from "../../../packs_helper/scripts/DateDiff";
import consumer from '../../../packs_helper/scripts/Consumer';
import GlobalVar from "../../GlobalVar";

// const channels = require.context(
// 	"../../../assets/javascripts/channels",
// 	true,
// 	/_channel\.js$/
// );
// channels.keys().forEach(channels);

// app/javascript/channels/consumer.js
// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

// import { createConsumer } from "@rails/actioncable";

class MyMessagesCore extends React.Component {
	constructor(props) {
		super(props);
		// const locations = window.location.search.substr(1).split('&').reduce( (x,y) => { var s = y.split('='); x[s[0]] = s[1]; return x }, {} );

		this.state = {
			user_list_has_order: [],
			user_list: [],
			active_user: null,
			user_message: "",
			active_messages: [],
			previous_messages_count: 0,
			new_message_alert: 0,
			order_details: [],
			orderDetailListOpen: false,
			currentPage: 1,
			maxPage: 1,
			show_sms_box: false,
			activeUserHasSaleWarningVisible: true,
			unread_filter: false,
			// messageWarningVisible: document.cookie.indexOf('message_warn=1') === -1
		};

		this.SelectUserHandler = this.SelectUserHandler.bind(this);
		this.HandleTextareaKeyDown = this.HandleTextareaKeyDown.bind(this);
		this.HandleSmsClick = this.HandleSmsClick.bind(this);
		this.HandleSendClick = this.HandleSendClick.bind(this);
		this.SendMessage = this.SendMessage.bind(this);
		this.LoadPreviousMessages = this.LoadPreviousMessages.bind(this);
		this.LoadNextMessages = this.LoadNextMessages.bind(this);
		this.ScrollToEnd = this.ScrollToEnd.bind(this);
		this.GetChats = this.GetChats.bind(this);
		this.GetNexChats = this.GetNexChats.bind(this);
		this.SetData = this.SetData.bind(this);
		this.ToggleOrderDetailList = this.ToggleOrderDetailList.bind(this);
		this.ShowUserList = this.ShowUserList.bind(this);
		// this.GoToArchievedMessages = this.GoToArchievedMessages.bind(this);
		this.MarkRead = this.MarkRead.bind(this);
		this.ShowUnread = this.ShowUnread.bind(this);
		// this.activeUserHasSale = this.activeUserHasSale.bind(this);
		// this.CloseMessageWarning = this.CloseMessageWarning.bind(this);

		const searchParams = new URL(window.location.href).searchParams;


		this.product_id = searchParams.get('product') !== null ? parseInt(searchParams.get('product')):undefined;
		this.offer = searchParams.get('offer') !== null ? searchParams.get('offer'):undefined;
		this.activeTab = searchParams.get("active_tab");
		this.sendSms = searchParams.get('send_sms');


	}

	componentDidMount() {

		const _this = this;
		// const consumer = createConsumer();

		consumer.subscriptions.create(
			{ channel: "StoreMessagesChannel" },
			{
				connected() {

					_this.channel = this;
					_this.GetChats();
				},
				received: this.SetData,
			}
		).connected();

	}

	componentDidUpdate(prevProps, prevState) {
		const active_user = this.state.active_user;
		if (active_user) {
			document.body.classList.add("message-open");
		} else {
			document.body.classList.remove("message-open");
		}
		if (active_user && active_user.user_code !== prevState.user_code) {
			// this.TextArea.focus();
		}
	}

	componentWillUnmount() {

		// this.channel.unregister(this.SetData);
		if(this.channel){
			this.channel.unsubscribe();
		}
	}

	GetChats() {
		this.channel.perform("get_chats", {
			chat_page: this.state.currentPage,
			active_tab: this.activeTab,
			unread_filter: this.state.unread_filter
		});
	}

	ChatsConcat(oldList, newList) {
		const list = [...oldList, ...newList].sort((a, b) =>
			DateDiff(b.updated_at, a.updated_at)
		);
		return list.filter(
			(l, index, self) =>
				index === self.findIndex((t) => t.user_code === l.user_code)
		);
	}

	GetNexChats() {
		this.setState(
			(prevState) => ({ currentPage: prevState.currentPage + 1 }),
			this.GetChats
		);
	}

	ShowUnread(){
		this.setState(
			{
				user_list: [],
				unread_filter : !this.state.unread_filter,
				currentPage: 1,
				maxPage: 1
			},
			this.GetChats
			);
	}

	SetData(data) {
		const _this = this;
		if (data.response_from == "get_chats") {
			this.setState(
				(prevState) => ({
					user_list_has_order: this.ChatsConcat(
						prevState.user_list_has_order,
						data.orders
					),
					pending_my_orders_store_ids: data.pending_my_orders_store_ids,
					user_list: this.ChatsConcat(prevState.user_list, data.chats),
					maxPage: data.chats_page_count,
					show_sms_box: !!this.sendSms,
				}),
				() => {
					if (
						this.state.active_user &&
						this.state.active_user.id === null &&
						!this.activeTab
					) {
						this.activeTab = this.state.active_user.user_code;
					}
					if (this.activeTab) {
						const active_user =
							data.orders.find((user) => user.user_code === this.activeTab) ||
							data.chats.find((user) => user.user_code === this.activeTab);
						if (active_user) {
							_this.SelectUserHandler(active_user);
						} else {
							_this.channel.perform("get_store_info", {
								user_code: this.activeTab,
							});
						}


						this.activeTab = null;
						// this.sendSms = false;
					}
				}
			);
		} else if (data.response_from == "get_messages") {
			this.setState(
				{
					active_messages: data.messages,
					previous_messages_count: data.count,
					order_details: data.order_details,
				},
				() => {
					_this.ScrollToEnd();
				}
			);
		} else if (data.response_from == "get_prev_messages") {
			if (data.messages.length) {
				const scrollHeight = _this.MessageAreaRef.scrollHeight;

				this.setState(
					(prevState) => ({
						active_messages: data.messages.concat(prevState.active_messages),
						previous_messages_count: data.count,
					}),
					() => {
						_this.MessageAreaRef.scrollTop =
							_this.MessageAreaRef.scrollHeight - scrollHeight;
					}
				);
			}
		} else if (data.response_from == "get_next_messages") {
			if (
				!!this.state.active_user &&
				this.state.active_user.id === data.id &&
				data.messages.length
			) {
				this.setState(
					(prevState) => ({
						active_messages: prevState.active_messages.concat(data.messages),
						new_message_alert:
							_this.MessageAreaRef.scrollHeight -
								_this.MessageAreaRef.scrollTop -
								_this.MessageAreaRef.clientHeight >
							50
								? prevState.new_message_alert + data.messages.length
								: 0,
					}),
					() => _this.ScrollToEnd()
				);
			}
		} else if (data.response_from == "get_store_info") {
			const user_list = this.state.user_list;
			const active_user =
				this.state.user_list_has_order.find(
					(user) => user.user_code === data.user.user_code
				) ||
				this.state.user_list.find((user) => user.user_code === data.user.user_code);

			if (!active_user) {
				user_list.unshift(data.user);
				this.setState({ user_list }, () => _this.SelectUserHandler(data.user));
			}
		} else if (data.response_from == "mark_read") {
			const active_messages = this.state.active_messages;
			const index = this.state.active_messages.findIndex(
				(m) => m.id === data.message_id
			);
			if (index > -1) {
				active_messages[index].is_read = new Date().toISOString();
				this.setState({ active_messages });
			}
		} else if (data.response_from == "all_read") {
			if (data.message_session_id === this.state.active_user.id) {
				const active_messages = this.state.active_messages;
				active_messages.forEach((m) => (m.is_read = new Date().toISOString()));
				this.setState({ active_messages });
			}
		} else if (data.response_from == "alert") {
			// ResponseTrigger(data);
			ShowPopup({
				title: 'Uyarı',
				message: data.message,
				redirect: data.redirect_to
			})
		}
	}

	SelectUserHandler(active_user) {
		if (
			!this.state.active_user ||
			this.state.active_user.user_code !== active_user.user_code
		) {
			this.setState({
				active_messages: [],
				previous_messages_count: 0,
				order_details: [],
				user_message: "",
			});

			if (active_user.user_code) {
				this.props.ChangeRoute("/mesajlarim", active_user.user_code);
				this.channel.perform("get_messages", { id: active_user.id });
			}
		}

		if (
			this.state.active_user &&
			this.state.active_user.user_code !== active_user.user_code
		) {
			this.product_id = undefined;
		}

		active_user.is_read = true;

		this.setState({ active_user });
	}

	HandleTextareaKeyDown(e) {
		const keyCode = e.keyCode || e.which;
		if (keyCode === 13 && !e.shiftKey) {
			e.preventDefault();
			this.SendMessage();
		}
	}

	HandleSmsClick(e) {
		this.setState({ show_sms_box: true });
	}

	HandleSendClick(e) {
		this.SendMessage();
	}

	SendMessage() {
		if (this.state.user_message.trim().length < 3) {
			ShowPopup({
				title: 'Uyarı',
				message: "Mesajınız çok kısa!"
			});
			return false;
		}
		this.channel.perform("write_chat_message", {
			user_code: this.state.active_user.user_code,
			product_id: this.product_id,
			offer: this.offer,
			message: this.product_id === undefined ? this.state.user_message:this.state.user_message.substr(0,30),
		});
		this.setState({ user_message: "" });
		this.product_id = undefined;
	}

	LoadPreviousMessages() {
		const { active_user, active_messages } = this.state;
		if (active_messages.length === 0) {
			return false;
		}
		this.channel.perform("get_messages", {
			id: active_user.id,
			previous_id: active_messages[0].id,
		});
	}

	LoadNextMessages() {
		const { active_user, active_messages } = this.state;
		if (this.state.active_messages.length === 0) {
			return false;
		}
		this.channel.perform("get_messages", {
			id: active_user.id,
			next_id: active_messages[active_messages.length - 1].id,
		});
	}

	MarkRead(message_id) {
		this.channel.perform("mark_read", {
			message_id,
			message_session_id: this.state.active_user.id,
		});
		const active_user =
			this.state.user_list_has_order.find(
				(user) => user.user_code === this.state.active_user.user_code
			) ||
			this.state.user_list.find(
				(user) => user.user_code === this.state.active_user.user_code
			);
		if (active_user) {
			active_user.is_read = true;
		}
	}

	ToggleOrderDetailList() {
		this.setState((prevState) => ({
			orderDetailListOpen: !prevState.orderDetailListOpen,
		}));
	}

	ShowUserList() {
		this.setState(() => ({
			active_user: null,
			order_details: [],
			active_messages: [],
			activeUserHasSaleWarningVisible: true,
		}));
	}

	ScrollToEnd() {
		if (this.MessageAreaRef) {
			this.MessageAreaRef.scrollTop = 999999999;
			this.setState({ new_message_alert: 0 });
		}
	}

	get activeUserHasSale() {
		const { active_user, user_list_has_order, pending_my_orders_store_ids } =
			this.state;
		if (!active_user) {
			return false;
		}
		const ulhoindex = user_list_has_order.findIndex(
			(u) => u.user_code === active_user.user_code
		);
		const pmosiindex = pending_my_orders_store_ids.findIndex(
			(u) => u === active_user.user_code
		);

		if (ulhoindex === -1 && pmosiindex === -1) {
			return false;
		}
		return true;
	}


}


export default MyMessagesCore;

import React from "react";
import components from "./components";

// kind products, sold_products, comments

class StorePageCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			ActiveComponent: components[0],
			rating: null,
		};

		// const pathname = url.pathname;
		// if(pathname){
		// 	const component = Object.entries(components).find(c => c[1].pathname === pathname);
		// 	if(component){
		// 		ActiveComponent = component[0];
		// 	}
		// }

		this.SetActiveComponent = this.SetActiveComponent.bind(this);
	}

	componentDidMount() {
		const url = new URL(document.location.href);
		const paths = url.pathname.split('/');
		const activePath = paths[3];
		const activeComponentIndex = components.findIndex(component => component.path === activePath);
		this.SetActiveComponent(1);

		// this.setState({mounted: true});
	}

	SetActiveComponent(activeComponentId) {
		const ActiveComponent = components.find((c) => c.id === activeComponentId);

		this.setState({ ActiveComponent });
	}
}

export default StorePageCore;

import React from "react";

const SettingsBox = React.forwardRef((props, ref) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const boxRef = React.useRef(null);

	React.useEffect(() => {
		if (boxRef.current) {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxHeight);
		}
	}, []);

	React.useImperativeHandle(ref, () => ({
		UpdateMaxHeight: () => {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		},
	}));

	const ToggleWrapper = () => {
		const boxHeight = boxRef.current.clientHeight;
		if (boxHeight === 0) {
			SetBoxHeight(boxRef.current.scrollHeight);
			props.BoxOpen(props.id)
		} else {
			SetBoxHeight(0);
		}
	};



	React.useEffect(() => {
		if (props.show) {
			ToggleWrapper();
		}
		else{
			SetBoxHeight(0);
		}
	}, [props.show]);
	return (
		<div className="settings-box">
			<header onClick={ToggleWrapper}>
				<svg className="icon">
					<use xlinkHref={"#gs-icon-user-panel-" + props.icon}></use>
				</svg>
				<h2>{props.title}</h2>
				<button className={"btn" + (boxHeight === 0 ? "" : " rotate-ccw-180")}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
				</button>
			</header>
			<div
				className="settings-box-wrapper"
				style={{ maxHeight: boxHeight }}
				ref={boxRef}
			>
				<div className="settings-box-body">{props.children}</div>
			</div>
		</div>
	);
});

export default SettingsBox;
import React from "react";
import ZiraatBank from "../../../assets_v3/images/ziraat-bank";

class SelectForBanks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null,
			isOpen: false,
		};

		this.ToggleSelect = this.ToggleSelect.bind(this);
		this.SetSelected = this.SetSelected.bind(this);
		this.RemoveSelected = this.RemoveSelected.bind(this);
	}

	componentDidUpdate() {}

	ToggleSelect() {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	}

	SetSelected(e) {
		const selectedOption = e.currentTarget;
		this.props.onChange(selectedOption.value);
		this.setState({
			selected: selectedOption.value,
			isOpen: false,
		});
	}

	RemoveSelected() {
		this.setState({
			selected: null,
			isOpen: false,
		});
	}

	render() {
		return (
			<div className={"select" + (this.state.isOpen ? " open" : "")}>
				<label onClick={this.ToggleSelect}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
					<img src={ZiraatBank} />
				</label>
				<div className="options">
					{/*					<button type="button" onClick={this.RemoveSelected}><img src={ZiraatBank} /></button>
					 */}{" "}
					{this.props.options.map((option) => (
						<button type="button" onClick={this.SetSelected} value={option.id}>
							<img src={option.image_url || ZiraatBank} alt={option.name} />
						</button>
					))}
				</div>
			</div>
		);
	}
}

export default SelectForBanks;
import React from "react";
import MyStorePanelCore from "./MyStorePanelCore";
import StoreSettings from "../Settings/StoreSettings";
import DateFormatter from "../../Common/DateFormatter";


class MobileRenderer extends MyStorePanelCore{
	render() {
		const { own_packages, SetData, uploadedFiles } = this.state;
		return (
			<div className="my-comments">
				<div className="settings user-panel-body">
					<StoreSettings
						UpdateData={this.UpdateData}
						store_name={SetData.store_name}
						store_slug={SetData.store_slug}
						success_sales={SetData.success_sales}
						unsuccess_sales={SetData.unsuccess_sales}
						comment_count={SetData.comment_count}
						confirmed={SetData.confirmed}
						approve_store={SetData.approve_store}
						approve_avatar={this.props.user.approve_avatar}
						not_receive_sms={SetData.not_receive_sms}
						avatar={this.props.user.avatar}
						activeBox={"store-settings"}
						UpdateImage={this.UpdateImage}
						RemoveImage={this.RemoveImage}
						uploadedFiles={uploadedFiles}
						BoxOpen={this.BoxOpen}
						mobile={true}
					/>
				</div>
				<div className="store-packages tight">
					<div className="store-packages-package">
						<div className={"package-period package-period-" + own_packages.order}>
							<div className="package-name">
								{own_packages.info.name}
							</div>
							<div className="package-price">
								{own_packages.info.package_content.length > 0 && <span>Bitiş Tarihi:</span>}
								{own_packages.info.package_content.length > 0? (
									<div className="price">
										<span>{DateFormatter(own_packages.end_datetime)}</span>
									</div>
								):(
									<span>
										<a href="/magaza-paketleri" target="_blank" className="btn btn-jungle btn-sell">Satın Al</a>
									</span>
								)}
							</div>
							<div className="package-upgrade">
								<a href="/magaza-paketleri" target="_blank" title="Paket Yükselt">
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-boost"></use>
									</svg>
									Yükselt
								</a>
							</div>
						</div>
						<div className="package-detail">
							{own_packages.info.package_content.length > 0? (Object.keys(own_packages.info.package_content).map((content) =>
								<div className="package-detail-row" key={content}>
									<div className="property-name">
										<ul>
											<li>{own_packages.info.package_content[content].product_name?own_packages.info.package_content[content].product_name:own_packages.info.package_content[content].product_id}</li>
										</ul>
									</div>
									{this.RemainingHandler(own_packages.info.package_content[content].product_type, own_packages.info.package_content[content].remaining, own_packages.info.package_content[content].amount)}
								</div>
							)):(
								<div className="package-detail-row">
									Bu pakete ait hiç özellik yok
								</div>
							)}
						</div>
					</div>
					<section>
						<header>
							<h2>Mağaza Paketleri Hakkında</h2>
						</header>
						<div
							dangerouslySetInnerHTML={this.GetArticle()}
							className="section-content tight-content"
						>
						</div>
					</section>
				</div>
			</div>
		);
	}
}

export default MobileRenderer;

import React from "react";
// import PropTypes from "prop-types";

class Switcher extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeButton: props.active ? props.active : props.buttons[0].id,
			unread: props.buttons.unread,
		};

		this.Switch = this.Switch.bind(this);
	}

	componentDidUpdate(prevProps){
		if(this.props.active !== prevProps.active){
			this.setState({activeButton: this.props.active});
		}
	}

	Switch(id) {
		if(id !== this.state.activeButton){
			this.setState({ activeButton: id });
			this.props.onChange(id);
		}
	}

	render() {
		const { activeButton } = this.state;
		return (
			<div className="switcher">
				{this.props.buttons.map((button) => (
					
					<SwitchButton
						id={button.id}
						text={button.text}
						onClick={this.Switch}
						active={activeButton === button.id}
						key={button.id}
						disabled={button.disabled}
						unread={button.unread}
					/>
				))}
			</div>
		);
	}
}

class SwitchButton extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			unread: props.unread || false,
		}
	}
	render(){
		const OnClick = () => {
			if(this.state.unread){
				this.setState({
					unread: false
				})
			}
			this.props.onClick(this.props.id);
		};
		return (
			<button className={this.props.active ? "active" : ""} onClick={OnClick} disabled={this.props.disabled}>
				{this.props.text} {this.state.unread !== undefined && this.state.unread ? <i className="unread" /> : ""}
			</button>
		)
	}
}

export default Switcher;

import React from 'react';
import BuyAdImage from '../../../../../assets_v3/images/buy-ad-image.jpeg'
import BuyAdImageActive from '../../../../../assets_v3/images/buy-ad-image-active.jpeg'

class AdType extends React.Component {
    render() {
        return (
            <div>
                <div className="grid-2">
                    <div
                        className="input radio radio-image"
                        onClick={() => this.props.SellPurchaseHandler("sell")}
                    >
                        <div className="radio-image-content">
                            <label htmlFor="show_balance_0">
                                Satış İlanı
                            </label>
                            <img
                                id="sellTypeImage"
                                src={this.props.adSell ? BuyAdImageActive:BuyAdImage}
                            />
                        </div>
                        <input
                            type="radio"
                            name="show_balance"
                            id="show_balance_0"
                            checked={this.props.adSell}
                            value="0"
                            onChange={() => ("")}
                        />

                    </div>
                    <div
                        className="input radio radio-image"
                        onClick={() => this.props.SellPurchaseHandler("purchase")}
                    >
                        <div className="radio-image-content">
                            <label htmlFor="show_balance_1">
                                Alım İlanı
                            </label>
                            <img
                                id="buyTypeImage"
                                src={(!this.props.adSell && this.props.adSell !== null) ? BuyAdImageActive:BuyAdImage}
                            />
                        </div>
                        <input
                            type="radio"
                            name="show_balance"
                            id="show_balance_1"
                            checked={!this.props.adSell && this.props.adSell !== null}
                            value="1"
                            onChange={() => ("")}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default AdType;
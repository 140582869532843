import React from "react";

const SiteLiveChatContainer = (props) => {
	return (
		<div className={"site-live-chat messaging" + (props.className ? ` ${props.className}` : "") + (!props.chatStatus ? " offline" : "")}>
			<div className="site-live-chat-title">
				<svg className="icon">
					<use xlinkHref="
					#gs-icon-live-chat-us"></use>
				</svg>
				Yardım Merkezi
				<button onClick={props.CloseChat}>-</button>
			</div>
			<div className="site-live-chat-container">
				{props.back && (
					<button type="button" className="site-live-chat-back-button" onClick={props.back}>
						Geri
					</button>
				)}
				<div className="site-live-chat-content">
					{props.title && <div className="site-live-chat-content-title">{props.title}</div>}
						{props.children}
				</div>
			</div>
		</div>
	);
};

export default SiteLiveChatContainer;
import React from 'react';

class ProgressBarMobile extends React.Component {
    render() {
        let barLength = ((100 / (this.props.ItemWizard.length - 1)) * (this.props.currentStep)) + 1;
        return (
            <div className="wizard-progress">
                <div className="wizard-progress-btn wizard-progress-btn-back">
                    <button
                        className={this.props.currentStep === 0 ? "btn passive":"btn"}
                        onClick={() => this.props.Navigate('back')}
                    >
                        <svg
                            className='icon'
                        >
                            <use xlinkHref="#gs-icon-user-panel-back"></use>
                        </svg>
                        <label>GERİ</label>
                    </button>
                </div>
                <div className="wizard-progress-bar">
                    <div className="wizard-progress-bar-container">
                        {[...Array(this.props.ItemWizard.length)].map((x, i) =>
                            (<a onClick={() => this.props.Navigate("jump", i)}
                                className={this.props.currentStep < i ?
                                    "wizard-progress-bar-circle" : "wizard-progress-bar-circle done"}
                                key={i}
                                title={this.props.ItemWizard[i].stepTitle}>
                            </a>)
                        )}
                    </div>
                    <div
                        className="wizard-progress-bar-line"
                        style={{ width: barLength > 100 ? 100 + "%" : barLength + "%" }}>
                    </div>
                    {/* Bulunduğun adımın başlığı
                    <div className="wizard-progress-title">{this.props.CurrentStep.stepTitle}</div>
                    */}
                </div>
                <div className="wizard-progress-btn wizard-progress-btn-next">
                    <button
                        className={this.props.currentStep === (this.props.ItemWizard.length - 1) ? "btn passive":"btn"}
                        onClick={()=>this.props.Navigate("next")}
                    >
                        <svg
                            className='icon'
                        >
                            <use xlinkHref="#gs-icon-user-panel-next"></use>
                        </svg>
                        <label>İLERİ</label>
                    </button>
                </div>
            </div>
        );
    }
}

export default ProgressBarMobile;
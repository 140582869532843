import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

const SaleDetailButtons = (props) => {

	const SendMessage = () => {
		props.ChangeRoute(`/mesajlarim?active_tab=${props.sale.content.user_code}`);
	};

	const ItemDelivered = () => {
		ShowPopup({
			title: 'Onay Gerekiyor!',
			message: '<p>Ürünü alıcıya teslim ettiniz mi?</p><p><i>Teslim etmediğiniz bir ürünü <strong><u>onaylamayınız</u></strong>. Yanlış beyanda bulunan kullanıcıların <strong><u>üyelikleri kapatılır</u></strong>!</i></p><button value="0" class="btn btn-default" data-close>Hayır, henüz teslim etmedim</button><button value="1" class="btn btn-jungle" data-close>Evet, alıcıya teslim ettim</button>'
		})
		.then(button => {
			if(button.value == 1){
				props.ItemDelivered(props.sale);
			}
		})
	};

	const ItemCancelled = () => {
		ShowPopup({
			title: 'Onay Gerekiyor!',
			message: '<p>Ürünün satışını iptal etmek istiyor musunuz?</p><p><i>Satışı iptal ediyorsunuz. Bu işlem geri alınamaz!</i></p><button value="0" class="btn btn-default" data-close>Vazgeç</button><button value="1" class="btn btn-red" data-close>Evet, satışı iptal et</button>'
		})
		.then(button => {
			if(button.value == 1){
				props.ItemCancelled(props.sale.id);
			}
		})
	};

	const ShowInvoice = () => {
		const form = document.createElement("FORM");
		form.action = "/fatura?id=" + props.sale.id;
		form.target = "_blank";
		form.method = "post";
		form.style.display = "none";

		const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
		const hiddenInput = document.createElement("INPUT");
		hiddenInput.type = "hidden";
		hiddenInput.name = "authenticity_token";
		hiddenInput.value = csrfToken;
		form.appendChild(hiddenInput);

		document.body.appendChild(form);
		form.submit();

	}

	const SendDeliveredMessage = () => {
		PostData("/mesaj-gonder", {
			mesaj: "Gelen takas teklifini kabul ettim ve mobil onay verdim lütfen kontrol edin."
		}).then((response) => {
			window.ToggleLiveChat();
		});
	}

	const SendCancelMessage = () => {
		PostData("/mesaj-gonder", {
			mesaj: "Skinlerimi size satmaktan vazgeçtim lütfen işlemi iptal edin."
		}).then((response) => {
			window.ToggleLiveChat();
		});		
	}


	if (props.sale.kind === '1') { //Goldbar
		return (
			<div className="item-detail-buttons">
				<button
					className="btn btn-sm btn-blue fixed-width-button"
					disabled={true}
				>
					Alıcı ile İletişime Geç
				</button>

				<button
					className="btn btn-sm btn-jungle fixed-width-button"
					disabled={true}
				>
					Teslim Ettim
				</button>

				<button
					className="btn btn-sm btn-red fixed-width-button"
					disabled={true}
				>
					İptal Et
				</button>
			</div>
		);
	} else if (props.sale.kind === '2') { //Sell Us
		if(props.sale.status === 0){
			return (
				<div className="item-detail-buttons">
					<button
						className="btn btn-sm btn-blue fixed-width-button"
						disabled={true}
					>
						Alıcı ile İletişime Geç
					</button>

					<button
						className="btn btn-sm btn-jungle fixed-width-button"
						onClick={SendDeliveredMessage}
					>
						Teslim Ettim
					</button>

					<button
						className="btn btn-sm btn-red fixed-width-button"
						onClick={SendCancelMessage}
					>
						İptal Et
					</button>
				</div>
			);
		}
		else{
			return (
				<div className="item-detail-buttons">
					<button
						className="btn btn-sm btn-blue fixed-width-button"
						disabled={true}
					>
						Alıcı ile İletişime Geç
					</button>

					<button
						className="btn btn-sm btn-jungle fixed-width-button"
						disabled={true}
					>
						Teslim Ettim
					</button>

					<button
						className="btn btn-sm btn-red fixed-width-button"
						disabled={true}
					>
						İptal Et
					</button>
				</div>
			);
		}
	} else if (props.sale.status === 1) {
		return (
			<div className="item-detail-buttons">
				<button
					className="btn btn-sm btn-blue fixed-width-button"
					onClick={SendMessage}
					disabled={!props.sale.content.user_code}
				>
					Alıcı ile İletişime Geç
				</button>

				<button
					className="btn btn-sm btn-jungle fixed-width-button"
					disabled={true}
				>
					Teslim Edildi
				</button>

				<button
					className="btn btn-sm btn-default fixed-width-button"
					onClick={ShowInvoice}
					disabled={!props.sale.invoice}
				>
					INVOICE
				</button>
			</div>
		);
	} else if (props.sale.status === 2) {
		return (
			<div className="item-detail-buttons">
				<button
					className="btn btn-sm btn-blue fixed-width-button"
					onClick={SendMessage}
					disabled={!props.sale.content.user_code}
				>
					Alıcı ile İletişime Geç
				</button>

				<button
					className="btn btn-sm btn-jungle fixed-width-button"
					disabled={true}
				>
					Teslim Edilmedi
				</button>

				<button
					className="btn btn-sm btn-red fixed-width-button"
					disabled={true}
				>
					İptal Edildi
				</button>
			</div>
		);
	} else if (props.sale.status === 0) {
		return (
			<div className="item-detail-buttons">
				<button
					className="btn btn-sm btn-blue fixed-width-button"
					onClick={SendMessage}
					disabled={!props.sale.content.user_code}
				>
					Alıcı ile İletişime Geç
				</button>

				{props.sale.content.is_approvable ? (
					<button
						className="btn btn-sm btn-jungle fixed-width-button"
						disabled={true}
					>
						Teslim Edildi
					</button>
				) : (
					<button
						className="btn btn-sm btn-jungle fixed-width-button"
						onClick={ItemDelivered}
						disabled={props.sale.kind === '4'}
					>
						Teslim Ettim
					</button>
				)}

				{props.sale.content.approve_seller ? (
					<button
						className="btn btn-sm btn-orange fixed-width-button"
						disabled={true}
					>
						Alıcı Onayı Bekleniyor
					</button>
				) : (
					<button
						className="btn btn-sm btn-red fixed-width-button"
						onClick={ItemCancelled}
						disabled={!props.user.confirmed || props.sale.kind === '4'}
					>
						İptal Et
					</button>
				)}
			</div>
		);
	} else {
		return null;
	}
};

export default SaleDetailButtons;

import React from "react";

class ProfileBoxInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editMode: false,
		};
		this.EditModeOn = this.EditModeOn.bind(this);
		this.EditModeOff = this.EditModeOff.bind(this);
		this.SaveForm = this.SaveForm.bind(this);
	}

	EditModeOn() {
		this.setState({ editMode: true });
	}

	EditModeOff() {
		this.setState({ editMode: false });
	}

	SaveForm(e) {
		e.preventDefault();

	}

	render() {
		return (
			<InputParentAutoSelector
				editMode={this.state.editMode}
				SaveForm={this.SaveForm}
			>
				{this.props.canHide && (
					<button className="btn">
						<svg className="icon">
							<use xlinkHref="#gs-icon-profile-eye"></use>
						</svg>
					</button>
				)}
				<label>{this.props.label}:</label>

				{this.state.editMode ? (
					<React.Fragment>
						{this.props.form}
						<button type="submit" className="btn btn-blue">
							Kaydet
						</button>
						<button className="btn btn-light" onClick={this.EditModeOff}>
							Vazgeç
						</button>
					</React.Fragment>
				) : (
					<React.Fragment>
						{this.props.value}
						{this.props.canEdit && (
							<button className="btn btn-light" onClick={this.EditModeOn}>
								<svg className="icon">
									<use xlinkHref="#gs-icon-profile-edit"></use>
								</svg>
							</button>
						)}
					</React.Fragment>
				)}
			</InputParentAutoSelector>
		);
	}
}

function InputParentAutoSelector(props) {
	if (props.editMode) {
		return (
			<form
				action={props.formAction}
				onSubmit={props.SaveForm}
				className="input-with-label"
			>
				{props.children}
			</form>
		);
	} else {
		return <div className="input-with-label">{props.children}</div>;
	}
}
export default ProfileBoxInput;

import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import StarSelect from "../../Common/StarSelect";

class CommentEdit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: props.message,
			rating: props.rating,
			commentSend: false,
		};

		this.SubmitForm = this.SubmitForm.bind(this);
		this.UpdateRating = this.UpdateRating.bind(this);
		this.UpdateMessage = this.UpdateMessage.bind(this);
	}

	UpdateMessage(e) {
		const message = e.currentTarget.value;
		this.setState({ message });
	}

	UpdateRating(rating) {
		this.setState({ rating });
	}

	SubmitForm() {
		const { id } = this.props;
		const { message, rating } = this.state;

		const formData = new FormData();
		formData.append("comment", message);
		formData.append("rating", rating);
		formData.append("id", id);

		let url = '/yorum-guncelle';
		if(this.props.answer){
			url = '/magaza-yorum-cevapla';
		}

		PostData(url, formData)
			.then((response) => {
				if (response.state) {
					this.setState({ commentSend: true });
				}
			})
			.catch((err) => {


			});
	}

	render() {
		if (this.state.commentSend) {
			return (
				<div className="alert">
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-approved"></use>
					</svg>
					{this.props.answer ? 'Yorumunuz kaydedildi!' : 'Yorumunuz güncellendi!'}
				</div>
			);
		} else {
			return (
				<div className="order-comment">
					<textarea
						onChange={this.UpdateMessage}
						value={this.state.message}
						placeholder="Yorumunuzu Buraya Yazabilirsiniz:"
					></textarea>
					<div className="order-comment-buttons">
						{(!this.props.answer && this.props.rating !== null) && (
							<StarSelect
								name={"rating_" + this.props.id}
								onChange={this.UpdateRating}
								defaultChecked={this.state.rating}
							/>
						)}
						<button
							type="button"
							onClick={this.SubmitForm}
							className="btn btn-sm btn-blue fixed-width-button"
						>
							Yorum Yap
						</button>
					</div>
				</div>
			);
		}
	}
}

export default CommentEdit;

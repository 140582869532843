import React from "react";
import StorePageCore from "./StorePageCore";
import components from "./components";
import TypeButton from "./TypeButton";
import StoreInfoMobile from "./StoreInfoMobile";
import Currency from "../../packs_helper/scripts/Currency";

class MobileRenderer extends StorePageCore{
	render() {
		const { ActiveComponent } = this.state;

		return (
			<div className="store-grid">
				<StoreInfoMobile
					store={this.props.store}
					commentsStat={this.props.commentsStat}
				/>
				<div className="store-content-container">
					<section className="category-buttons">
						<nav>
							{components.map((type) => (
								<TypeButton
									id={type.id}
									label={type.labelMobile}
									onClick={this.SetActiveComponent}
									active={type.id === this.state.ActiveComponent.id}
									key={type.id}
								/>
							))}
						</nav>
					</section>

					<ActiveComponent.component
						storeSlug={this.props.store.slug}
						avatar={this.props.store.avatar}
						storeName={this.props.store.name}
						productCount={this.props.store.productCount}
						kind={ActiveComponent.kind}
						storeStatus={this.props.store.storeStatus}
						mobile={true}
					/>
				</div>
			</div>
		);
	}
}

export default MobileRenderer;
import React from "react";
import UserPanelCore from "./UserPanelCore";
import UserPanelMenu from "./UserPanelMenu";
// import profileButtons from "./_profileButtons";

class DesktopRenderer extends UserPanelCore {
	constructor(props){
		super(props);
		if(this.defaultPathname === '/kullanici-paneli'){
			redirect('/profilim');
		}		
	}

	render() {
		const { activeSection } = this.state;
		return (
			<div className="user-panel-wrapper">
				<UserPanelMenu
					activeSection={activeSection.name}
					user={this.props.user}
					pathname={activeSection.pathname}
					ChangeRoute={this.ChangeRoute}
					not_show_identification_number={
						this.state.not_show_identification_number
					}
					side_image={this.props.side_image}
				/>
				<div className="user-panel-body">
					{activeSection.name == "Settings" ? (
						<activeSection.component
							user={this.props.user}
							ChangeRoute={this.ChangeRoute}
							UpdatePersonalInfoVisibility={this.UpdatePersonalInfoVisibility}
						/>
					) : (
						<activeSection.component
							user={this.props.user}
							ChangeRoute={this.ChangeRoute}
							not_show_identification_number={
								this.state.not_show_identification_number
							}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default DesktopRenderer;
import React from "react";
import LiveChatMessaging from "./LiveChatMessaging";
import SiteLiveChatContainer from "./SiteLiveChatContainer";
import Cookie from "../../../packs_helper/scripts/Cookie";
import DateDiff from "../../../packs_helper/scripts/DateDiff";
import PostData from "../../../packs_helper/scripts/PostData";
import consumer from '../../../packs_helper/scripts/Consumer';

// import { createConsumer } from "@rails/actioncable";

const AWS_URL = "https://images.gamesatis.com";
const cookie = new Cookie();

class LiveChat extends React.Component {
	constructor(props) {
		super(props);

		const unreadMessages = parseInt(cookie.get('ChatCount') || "0")
		this.state = {
			view: null,
			messages: [],
			unreadMessages,
			connected: false,
			viewNavigation: undefined,
			prechatComponentMessage: '',
			prechatMessage: "",
		};
		this.ChangeView = this.ChangeView.bind(this);
		this.CloseChat = this.CloseChat.bind(this);
		this.ConnectToLiveChat = this.ConnectToLiveChat.bind(this);
		this.RenderPrechatComponent = this.RenderPrechatComponent.bind(this);

		this.audio = new Audio(`${AWS_URL}/assets/chat-alert.mp3`);

		this.GetData = this.GetData.bind(this);
		this.Connected = this.Connected.bind(this);
		this.Disconnected = this.Disconnected.bind(this);
		this.MessageReceived = this.MessageReceived.bind(this);
		this.EnlargeWindow = this.EnlargeWindow.bind(this);
		this.ClearPrechat = this.ClearPrechat.bind(this);

		// this.activeMessaging = this.activeMessaging.bind(this);
	}

	componentDidMount() {
		window.ToggleLiveChat = (e) => {
			this.EnlargeWindow();
			document.querySelector(".top-navigation").classList.remove("open");
		}

		// const consumer = createConsumer();

		consumer.subscriptions.create(
			{ channel: "MessagesChannel" },
			{
			connected: this.Connected,
			disconnected: this.Disconnected,
			received: this.MessageReceived
			}
		);
	}

	componentWillUnmount() {

		// this.channel.unregister(this.SetData);
		if(this.channel){

			this.channel.unsubscribe();
		}
	}

	ClearPrechat() {
			this.setState({
				prechatComponentMessage: '',
				prechatMessage: ''
			})
	}

	GetData(Callback) {

		const formData = new FormData();
		formData.append("offset", this.state.offset);
		PostData(this.props.get_messages_path, formData).then((response) => this.setState({messages: response.messages}, () => {
			if(Callback){
				Callback();
			}
		}));
	}

	Connected() {

		this.setState({
			connected: true,
		});
	}

	Disconnected() {

		this.setState({
			connected: false,
		});
	}

	MessageReceived(message) {

		if(message.is_admin){
			this.audio.play();
			if(this.state.view !== 'chat'){
				this.setState(prevState => ({unreadMessages: prevState.unreadMessages+1}), () => {
					cookie.set('ChatCount', this.state.unreadMessages, 12 * 60 * 60)
					// document.cookie = `ChatCount=${this.state.unreadMessages};expires=${dayjs().add(12, 'hours').toString()}+";path=/`;
				});
			}
		}

		this.setState(prevState => ({ messages: [...prevState.messages, message] }));
	}

	get activeMessaging(){
		return true;
		const lastMessage = this.state.messages[this.state.messages.length - 1];
		return !!this.props.chat_show_button || this.state.unreadMessages > 0 || (lastMessage && DateDiff(lastMessage.created_at) < 30 * 60 * 1000);
	}

	get prechatMessage(){
		let message = '';
		if(this.state.prechatComponentMessage){
			message = this.state.prechatMessage
		}
		if(this.state.prechatComponentMessage && this.state.prechatMessage){
			message += "\n\n";
		}
		if(this.state.prechatMessage){
			message += this.state.prechatMessage;
		}
		return message;
	}

	EnlargeWindow(){
		// const lastMessage = this.state.messages[this.state.messages.length - 1];
		// console.log("ENLARGE")
		// console.log(lastMessage)
		// console.log(moment().diff(lastMessage.created_at))
		// if(this.state.unreadMessages > 0 || (lastMessage && moment().diff(lastMessage.created_at) < 30 * 60 * 1000)){
		if(this.activeMessaging){
			this.ChangeView('chat')
			// this.setState({view: 'chat'})
		}
		else{
			this.ChangeView('list')
			// this.setState({ view: "list" })
		}
	}

	ChangeView(view, viewNavigation) {
		if(view === 'chat'){
			// this.GetData();
			// document.cookie = `ChatCount=0;expires=${dayjs().add(12, 'hours').toString()}+";path=/`;
			cookie.set('ChatCount', 0, 12 * 60 * 60)
			this.setState({unreadMessages: 0})
		}
		this.setState({ view, viewNavigation });
	}

	CloseChat() {
		this.ChangeView(null);
	}

	ConnectToLiveChat() {
		const prechatMessage = this.state.prechatMessage;
		const prechatComponentMessage = this.state.prechatComponentMessage;

		if (this.props.signedIn && this.state.viewNavigation && this.state.viewNavigation.content && this.state.viewNavigation.content.component && !prechatComponentMessage) {
			ShowPopup({
				message: "Lütfen listeden ilgili seçiminizi yapınız!"
			});
			return false;
		}

		if (prechatMessage.length < 10 || prechatMessage.split(/\W/gm).length < 3) {
			ShowPopup({
				message: "Yazdığınız mesaj çok kısa. Lütfen sorununuzu detaylı bir şekilde belirtiniz."
			});
			return false;
		}
		this.ChangeView("chat");
	}

	RenderPrechatComponent() {
		if (!this.state.viewNavigation || !this.state.viewNavigation.content || !this.state.viewNavigation.content.component) {
			return null;
		}

		// Dynamic component rendering not worked. If you can run a day, remember today...
		const CompnentList = {
			ItemsList: <SiteLiveChatItemsList onChange={(prechatComponentMessage) => this.setState({ prechatComponentMessage })} />,
			OrderList: <SiteLiveChatOrderList onChange={(prechatComponentMessage) => this.setState({ prechatComponentMessage })} />,
		};

		return CompnentList[this.state.viewNavigation.content.component];
	}

	render() {
		const { view } = this.state;
		switch (view) {
			case "list":
				return <SiteLiveChatItem navigation={{ children: this.props.navigation }} ChangeView={this.ChangeView} chatStatus={this.props.chatStatus} activeMessaging={this.activeMessaging} />;
				break;
			case "completed":
				return (
					<SiteLiveChatContainer CloseChat={this.CloseChat} chatStatus={this.props.chatStatus}>
						<div>
							<div className="site-live-chat-help-info">
								<header>Bizimle iletişime geçtiğiniz için teşekkür ederiz.</header>
								<div className="site-live-chat-help-buttons">
									<button onClick={this.CloseChat}>Kapat</button>
								</div>
							</div>
						</div>
					</SiteLiveChatContainer>
				);
				break;
			case "preChat":
					if(this.props.chatStatus){
						return (
							<SiteLiveChatContainer back={() => this.setState({ view: "list" })} CloseChat={this.CloseChat} chatStatus={this.props.chatStatus}>
								<div className="site-live-chat-message-title">Sorununuzu tam olarak yazınız.</div>
								<div className="site-live-chat-message-content">
									{this.props.signedIn && <div className="mb-3">{this.RenderPrechatComponent()}</div>}
									<div className="mb-3">
										<label>Sorununuzu tam olarak yazınız:</label>
										<textarea className="site-live-chat-pre-chat" onChange={(e) => this.setState({ prechatMessage: e.currentTarget.value })} />
									</div>
								</div>
								<div className="site-live-chat-help-buttons">
									<button onClick={this.ConnectToLiveChat}>Canlı Desteğe Bağlan</button>
								</div>
							</SiteLiveChatContainer>
						);						
					}
					else{
						const working_hours = this.props.workingHours;
						return (
							<SiteLiveChatContainer back={() => this.setState({ view: "list" })} CloseChat={this.CloseChat} chatStatus={this.props.chatStatus}>
								<div className="site-live-chat-message-content">
									<div className="site-live-chat-messaging-inputs-alert mt-0">
					          <div className="bold mb-2">Şuan size cevap veremiyoruz,</div>
					          <div className="mb-1">Çalışma saatlerimiz <span className="bold">{working_hours[0]}-{working_hours[1]}</span> arasıdır.</div>
					          <div>Çalışma arkadaşlarımız size en kısa sürede ulaşacaktır.</div>
									</div>
								</div>
							</SiteLiveChatContainer>
						)
					}
				break;
			case "chat":
				return <LiveChatMessaging 
				chatStatus={this.props.chatStatus} 
				sendMessagePath={this.props.send_message_path} 
				CloseChat={this.CloseChat} 
				workingHours={this.props.workingHours} 
				defaultValue={this.prechatMessage}
				messages={this.state.messages}
				connected={this.state.connected}
				CloseMessaging={() => this.ChangeView('list')}
				ClearPrechat={this.ClearPrechat}
				GetData={this.GetData}
				/>;
				break;
			default:
				return (
					<div className={"site-live-chat site-live-chat-toggler" + (!this.props.chatStatus ? " offline" : "") + (this.state.unreadMessages > 0 ? " has-message" : "")}>
						<button className="site-live-chat-title" onClick={this.EnlargeWindow}>
							<svg className="icon">
								<use xlinkHref="#gs-icon-live-chat-us"></use>
							</svg>
							<div>Canlı Destek</div>
						</button>
						{this.state.unreadMessages > 0 && <div className="site-live-chat-unread-messages">{this.state.unreadMessages}</div>}
					</div>
				);
		}
	}
}


class SiteLiveChatItem extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			navigation: null,
		};

		this.OnClick = this.OnClick.bind(this);
		this.ContentHelped = this.ContentHelped.bind(this);
		this.ContentNotHelped = this.ContentNotHelped.bind(this);
		this.CloseChat = this.CloseChat.bind(this);
	}

	OnClick(nav) {
		this.setState({
			navigation: <SiteLiveChatItem navigation={nav} back={() => this.setState({ navigation: null })} ChangeView={this.props.ChangeView} chatStatus={this.props.chatStatus} activeMessaging={this.props.activeMessaging} />,
		});
	}

	ContentHelped() {
		this.props.ChangeView("completed");
	}

	ContentNotHelped() {
		this.props.ChangeView("preChat", this.props.navigation);
	}

	CloseChat() {
		this.props.ChangeView(null);
	}

	render() {
		if (this.state.navigation) {
			return this.state.navigation;
		} else if (this.props.navigation.children.length) {
			return (
				<SiteLiveChatContainer title="Nasıl Yardımcı Olabiliriz?" back={this.props.back} CloseChat={this.CloseChat} chatStatus={this.props.chatStatus}>
					{this.props.navigation.children.map((navigation) => (
						<SiteLiveChatButton navigation={navigation} onClick={this.OnClick} key={navigation.title} />
					))}
					{this.props.activeMessaging && (
						<div className="site-live-chat-button text-red" onClick={() => this.props.ChangeView('chat')}>
						Canlı Destek
					</div>
					)}
				</SiteLiveChatContainer>
			);
		} else {
			return (
				<SiteLiveChatContainer back={this.props.back} CloseChat={this.CloseChat} chatStatus={this.props.chatStatus}>
					<div className="site-live-chat-message-title">{this.props.navigation.title}</div>
					<div className="site-live-chat-message-content" dangerouslySetInnerHTML={{ __html: this.props.navigation.content.source }} />

					<div className="site-live-chat-help-info">
						<header>İçerik size yardımcı oldu mu?</header>
						<div className="site-live-chat-help-buttons">
							<button onClick={this.ContentHelped}>Evet</button>
							<button onClick={this.ContentNotHelped}>Hayır</button>
						</div>
					</div>
				</SiteLiveChatContainer>
			);
			// return (
			// 	<div className="site-live-chat-container">
			// 		{this.props.back && (
			// 			<button type="button" className="site-live-chat-back-button" onClick={this.props.back}>
			// 				Geri
			// 			</button>
			// 		)}
			// 		<div className="site-live-chat-content">
			// 			{!this.props.back && <div className="site-live-chat-content-title">Nasıl Yardımcı Olabiliriz?</div>}

			// 			{this.props.navigation.children.length ? (
			// 				this.props.navigation.children.map((navigation) => <SiteLiveChatButton navigation={navigation} onClick={this.OnClick} key={navigation.title} />)
			// 			) : (
			// 				<div>
			// 					<div className="site-live-chat-message-title">{this.props.navigation.title}</div>
			// 					<hr />
			// 					<div className="site-live-chat-message-content" dangerouslySetInnerHTML={{ __html: this.props.navigation.content.source }} />

			// 					<div className="site-live-chat-help-info">
			// 						<header>İçerik size yardımcı oldu mu?</header>
			// 						<div className="site-live-chat-help-buttons">
			// 							<button onClick={this.ContentHelped}>Evet</button>
			// 							<button onClick={this.ContentNotHelped}>Hayır</button>
			// 						</div>
			// 					</div>
			// 				</div>
			// 			)}
			// 		</div>
			// 	</div>
			// );
		}
	}
}


const SiteLiveChatButton = (props) => {
	const OnClick = () => {
		if (props.navigation.children) {
			props.onClick(props.navigation);
		}
	};

	return (
		<div className="site-live-chat-button" onClick={OnClick}>
			{props.navigation.title}
		</div>
	);
};

// class PrechatMessageArea extends React.Component{
// 	constructor(props){
// 		super(props);

// 		this.state = {
// 			prechatMessage: ''
// 		}
// 	}

// 	Submit(){
// 		const prechatMessage = this.state.prechatMessage;

// 		if(prechatMessage.length < 10 || prechatMessage.split(/\W/gm).length < 3){
// 			swalert2(['Yazdığınız mesaj çok kısa. Lütfen sorununuzu detaylı bir şekilde belirtiniz.'], 'error', null, {buttons:{ok: { className: 'btn-danger'}}, timer: 2500})();
// 			return false;
// 		}
// 		this.props.ConnectToLiveChat(prechatMessage);
// 	}

// 	render(){
// 		return (
// 			<SiteLiveChatContainer back={() => this.setState({ view: "list" })} CloseChat={this.CloseChat}>
// 					<div className="site-live-chat-message-title">Sorununuzu tam olarak yazınız.</div>
// 					<div className="site-live-chat-message-content">
// 						<textarea className="site-live-chat-pre-chat" onChange={e => this.setState({prechatMessage: e.currentTarget.value})} />
// 					</div>
// 					<div className="site-live-chat-help-buttons">
// 						<button onClick={this.ConnectToLiveChat}>Canlı Desteğe Bağlan</button>
// 					</div>
// 			</SiteLiveChatContainer>
// 		);
// 	}
// }

class SiteLiveChatOrderList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: [],
			formLoaded: false,
		};

		const formData = new FormData();
		// formData.append('filter', 0);

		PostData("/siparislerim", formData).then((response) => {
			this.setState({
				options: response.map((order) => ({ id: order.id, name: order.name })),
				formLoaded: true,
			});
		});
		this.OnChange = this.OnChange.bind(this);
	}
	render() {
		return (
			<select className="site-live-chat-content-select" disabled={!this.state.formLoaded} onChange={this.OnChange}>
				{this.state.formLoaded ? <option value="">Sorununuzla ilgili siparişi seçiniz</option> : <option value="">Sipariş listeniz getiriliyor. Lütfen bekleyiniz...</option>}
				{this.state.options.map((option) => (
					<option value={option.id} key={option.id}>
						{option.name}
					</option>
				))}
			</select>
		);
	}
}

class SiteLiveChatItemsList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			options: [],
			formLoaded: false,
			selected: { text: "", value: "" },
		};

		const formData = new FormData();
		// formData.append('filter', 0);

		PostData("/ilanlarim", formData).then((response) => {
			this.setState({
				options: response.my_items.map((item) => ({ id: item.id, name: item.name })),
				formLoaded: true,
			});
		});
		this.OnChange = this.OnChange.bind(this);
	}

	OnChange(e) {
		const selected = {
			text: e.currentTarget.selectedOptions[0].text,
			value: e.currentTarget.selectedOptions[0].value,
		};
		this.setState({ selected });
		if (selected.value) {
			this.props.onChange(`&quot;${selected.text}&quot;&lpar;${selected.value}&rpar; ilan ile ilgili aşağıdaki sorunu yaşıyorum.`);
		} else {
			this.props.onChange(null);
		}
	}

	render() {
		return (
			<div>
				<label>Bekleyen İlanlarınız:</label>
				<select className="site-live-chat-content-select" disabled={!this.state.formLoaded} onChange={this.OnChange} value={this.state.selected.value}>
					{this.state.formLoaded ? <option value="">Sorununuzla ilgili ilanı seçiniz</option> : <option value="">İlan listeniz getiriliyor. Lütfen bekleyiniz...</option>}
					{this.state.options.map((option) => (
						<option value={option.id} key={option.id}>
							{option.name}
						</option>
					))}
				</select>
			</div>
		);
	}
}

export default LiveChat;

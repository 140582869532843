import React from "react";
import PaymentGridListItem from "./PaymentGridListItem";
import PriceQuickSelectButton from "./PriceQuickSelectButton";
import PostData from "../../packs_helper/scripts/PostData";
import Currency from "../../packs_helper/scripts/Currency";

import PhoneInput from "../Common/PhoneInput";
import DecimalFieldTag from "../Common/DecimalFieldTag";
import PriceFieldTag from "../Common/PriceFieldTag";

const groups = {
	transfer: "Yatırmanız Gereken Tutar",
	credit_card: "Kartınızdan Çekilecek Tutar",
	mobile_payment: "Faturanıza Yansıyacak Tutar",
};

const PaymentGridListItemMobilePayment = (props) => {
	const [waitForResponse, SetWaitForResponse] = React.useState(false);
	const [amount, SetAmount] = React.useState(0);

	const FormSubmitHandler = (e) => {
		e.preventDefault();

		SetWaitForResponse(true);
		const formData = new FormData(e.target);
		formData.append("payment_notification[payment_id]", props.paymentMethod.id);

		const entries = formData.entries();
		let entry;
		while(entry = entries.next().value){
			if(entry[0] !== 'payment_notification[description]' && (!entry[1] || entry[1] == '0')){
				ShowPopup({
					title: 'Hata',
					message: 'Lütfen tüm alanları doldurunuz!'
				});
				SetWaitForResponse(false);
				return false
			}
		}

		PostData(props.savePath, formData, {
			redirect: false,
		})
			.then((response) => {


				SetWaitForResponse(false);
				// ShowPopup({
				// 	message: response
				// })
				if(response.state && response.browser != 'safari'){
					setTimeout(function(){ redirect('/odemelerim') }, 5000);
				}
			})
			.catch((err) => {


				SetWaitForResponse(false);
			});
	};

	return (
		<PaymentGridListItem
			id={props.paymentMethod.id}
			image={props.paymentMethod.image}
			group={props.paymentMethod.group}
			name={props.paymentMethod.name}
			slogan={props.paymentMethod.slogan}
			waitForResponse={waitForResponse}
			show={true}
			ComponentOpen={props.ComponentOpen}
			mobile={props.mobile}
		>
			<form
				action="#"
				method="post"
				onSubmit={FormSubmitHandler}
				className="payment-grid-list-item-body-wrapper"
				data-wait-for-response={waitForResponse}
			>
				{(props.paymentMethod.short_description ||
					props.paymentMethod.slogan) && (
					<div className="item-properties">
						{props.paymentMethod.slogan && (
							<div className="item-properties-box">
								<strong>{props.paymentMethod.slogan}</strong>
							</div>
						)}
						{props.paymentMethod.short_description.map((sd) => (
							<div className="item-properties-box" key={sd[0]}>
								{sd[0]}: <strong>{sd[1]}</strong>
							</div>
						))}
					</div>
				)}
				<div className="quick-select-buttons">
						{[25, 50].map((quickSelectAmount) => (
							<PriceQuickSelectButton
								amount={quickSelectAmount}
								onClick={SetAmount}
								key={"pqsb-" + quickSelectAmount}
								selected={quickSelectAmount === amount}
							/>
						))}
					</div>
				<div className="payment-grid-2x2">

					<PriceFieldTag
						wrapperClass="input-with-label input-with-label-payment"
						label="Hesabınıza Eklenecek Tutar:"
						name="payment_notification[amount]"
						id={`amount-${props.paymentMethod.id}`}
						readOnly={waitForResponse}
						onChange={SetAmount}
						value={amount}
						autoFocus={true}
					/>

					{props.paymentMethod.rate > 0 && (
						<div className="input-with-label disabled">
							<label>{groups[props.paymentMethod.group]}:</label>
							<div className="input">
								{Currency(amount / (1 - props.paymentMethod.rate))} ₺
							</div>
						</div>
					)}

					<div className="input-with-label">
						<label htmlFor={`phone-${props.paymentMethod.id}`}>
							Telefon Numaranız:
						</label>
						<PhoneInput
							name="payment_notification[phone]"
							id={`phone-${props.paymentMethod.id}`}
						/>
					</div>
					{props.paymentMethod.rate == 0 && <div />}

					<button className="btn btn-jungle" type="submit">
						Ödeme Bildir
					</button>
				</div>
			</form>
		</PaymentGridListItem>
	);
};
export default PaymentGridListItemMobilePayment;

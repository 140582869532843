import React from "react";
import ItemCore from "./ItemCore";
import ProgressBar from "../Wizard/ProgressBar";

class DesktopRenderer extends ItemCore {
	render() {
		const ItemWizard = this.GetWizardSteps();
		const CurrentStep = ItemWizard[this.state.currentStep];
		return (
			<React.Fragment>
				<div className="item-grid wizard-grid">
					<ProgressBar
						currentStep = {this.state.currentStep}
						Navigate = {
							(direction, step = 0) =>
							this.WizardStep(direction, ItemWizard, this.state.currentStep, step)
						}
						ItemWizard = {ItemWizard}
						CurrentStep = {CurrentStep}
						SelectedGame = {this.state.selectedGame}
						AdSell = {this.state.adSell}
						ItemName = {this.state.itemName}
						Price = {this.state.price}
					/>
				</div>
				<div className="wizard-item-form">
					<CurrentStep.stepComponent {...CurrentStep.stepProps}/>
					<div id="wizard-error"></div>
				</div>
				<div className="item-footer footer-grid">
					<div>
						{this.state.currentStep - 1 !== -1 && (
							<button
								onClick={() => this.WizardStep("back", ItemWizard, this.state.currentStep)}
								className="btn btn-default"
								disabled={this.state.currentStep - 1 === -1}
							>
								{
									this.state.currentStep - 1 !== -1 ?
										"Önceki Adım: " + ItemWizard[this.state.currentStep - 1].stepTitle
										:"İlk Adım"
								}
							</button>
						)}
					</div>
					<div>
						{this.state.currentStep + 1 !== ItemWizard.length && (
							<button
								onClick={()=>this.WizardStep("next", ItemWizard, this.state.currentStep)}
								className="btn btn-default"
								id="nextWizardStep"
								disabled={this.state.currentStep + 1 === ItemWizard.length}
							>
								{
									this.state.currentStep + 1 !== ItemWizard.length ?
										"Sonraki Adım: " + ItemWizard[this.state.currentStep + 1].stepTitle
										:"Son Adım"
								}
							</button>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default DesktopRenderer;


import React from "react";
import SupportTicketsCore from "./SupportTicketsCore";

import SupportTicketBox, { StatusIcon } from "./SupportTicketBox";
import Paginate from "../../Common/Paginate";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Switcher from "../../Common/Switcher";
import SelectBox from "../../Common/SelectBox";
import ImageFileInput from "../../Common/ImageFileInput";
import PopUp from "../../Common/PopUp";

const statusTexts = {
	answered: "Cevaplandı",
	pending: "Beklemede",
	suspended: "Süreç Devam Ediyor",
};

class MobileRenderer extends SupportTicketsCore{
	render() {
		const { support_tickets, support_ticket_count, current_page, activeTab, ticketCategories } =
			this.state;
		return (
			<div className="my-comments">
				<Switcher
					buttons={[
						{
							id: "support-tickets",
							text: "Destek Taleplerim",
						},
						{
							id: "create",
							text: "Yeni Destek Talebi",
						},
					]}
					onChange={this.ActivateTab}
					active={activeTab}
				/>

				<div className="my-comments-tab">
					{support_tickets.map((support_ticket) => (
						<SupportTicketBox
							id={support_ticket.id}
							title={support_ticket.title}
							status={support_ticket.status}
							// ref={(ref) => (support_ticket.ref = ref)}
							key={support_ticket.id}
							BoxOpen={this.BoxOpen}
							show={this.state.activeBox === support_ticket.id}
						>
							<div className="comment-container">
								<div className="comment-content">
									<div className="comment-content-user">
										<h2>{this.props.user.short_name}</h2>
										<strong>{DateFormat(support_ticket.created_at)}</strong>
									</div>
									{support_ticket.message}
								</div>

								<div className="comment-answer-content">
									<div className="comment-answer-content-user">
										<h2>GameSatış</h2>
									</div>
									<div
										dangerouslySetInnerHTML={{ __html: support_ticket.answer }}
									/>
								</div>
							</div>
						</SupportTicketBox>
					))}
				</div>
				<Paginate
					onChange={this.ChangePage}
					total_count={support_ticket_count}
					current_page={current_page}
					per_page={15}
				/>

				{activeTab === "create" && (
					<PopUp
						title="Yeni Destek Talebi Oluştur"
						Hide={() => this.ActivateTab("support-tickets")}
						className="settings-popup"
					>
						<form
							action="#"
							method="post"
							className="grid-1"
							onSubmit={this.CreateNewTicket}
						>
							<div className="input-with-label">
								<label>Konuyu Seçiniz:</label>
								<SelectBox
									xlinkHref="#gs-icon-user-panel-caret-down"
									options={ticketCategories}
									onChange={this.TypeChanged}
									selected={this.formDefaultType}
									name="support_ticket[ticket_type_id]"
									noDark={true}
								/>
							</div>
							{this.state.password_required && (
								<div className="input-with-label">
									<label>
										<abbr title="gerekli">*</abbr> Şifrenizi Giriniz:
									</label>
									<input type="password" name="password" />
								</div>
							)}
							<div className="input-with-label">
								<label>
									<abbr title="gerekli">*</abbr> Başlık Yazınız:
								</label>
								<input
									type="text"
									name="support_ticket[title]"
									defaultValue={this.formDefaultTitle}
								/>
							</div>

							<textarea
								name="support_ticket[message]"
								defaultValue={this.formDefaultMessage}
								placeholder="Mesajınızı buraya yazınız"
							/>

            <ImageFileInput
              onChange={this.UpdateImage}
              onRemove={this.RemoveImage}
              uploadedFiles={this.state.uploadedFiles}
              max={3}
              info='**Görsel "525x420" boyutlarında olmalıdır.'
            />

							<div className="checkbox">
								<input type="checkbox" name="supportCheck" id="supportCheck" value="1" />
								<label htmlFor="supportCheck">
									Vermiş olduğum bilgi ve evrakların tarafıma ait olup hatalı
									olmasından doğacak yükümlülük tarafıma aittir.
								</label>
							</div>

							<button type="submit" className="btn btn-blue">
								Kaydet
							</button>
						</form>
					</PopUp>
				)}
			</div>
		);
	}
}

export default MobileRenderer;

import React from "react";
import PaymentGridListItem from "./PaymentGridListItem";
import PostData from "../../packs_helper/scripts/PostData";
import epinBanner from "../../assets_v3/images/payment-methods/epin";

const PaymentGridListItemEpin = (props) => {
	const [waitForResponse, SetWaitForResponse] = React.useState(false);

	const FormSubmitHandler = (e) => {
		e.preventDefault();

		SetWaitForResponse(true);
		const formData = new FormData(e.target);
		formData.append("payment_notification[payment_id]", props.paymentMethod.id);

		if(!formData.get('code')){
			ShowPopup({
				title: 'Hata',
				message: 'Lütfen epin kodunuzu yazın!'
			});
			SetWaitForResponse(false);
			return false
		}

		PostData('/gs-epin-kullan', formData, {
			redirect: false,
		})
			.then((response) => {


				// if (response.redirected) {
				SetWaitForResponse(false);
				// }
				if(response.state){
					setTimeout(function(){ redirect('/odemelerim') }, 5000);
				}
			})
			.catch((err) => {


				SetWaitForResponse(false);
			});
	};

	const Image = (
		<svg className="icon">
			<use xlinkHref="#gs-icon-payment-methods-epin"></use>
		</svg>
	);
	return (
		<PaymentGridListItem
			id={props.paymentMethod.id}
			image={Image}
			group={props.paymentMethod.group}
			name={props.paymentMethod.name}
			slogan={props.paymentMethod.slogan}
			waitForResponse={waitForResponse}
			show={true}
			ComponentOpen={props.ComponentOpen}
			mobile={props.mobile}
		>
			<form
				action="#"
				method="post"
				onSubmit={FormSubmitHandler}
				className="payment-grid-list-item-body-wrapper payment-grid-list-item-body-wrapper-center"
				data-wait-for-response={waitForResponse}
			>
				<img src={epinBanner} width={240} height={213} />
				<div className="digital-code">
					<input
						type="text"
						name="code"
						readOnly={waitForResponse}
						placeholder="XXXX-XXXX-XXXX-XXXX"
						autoFocus={true}
					/>
				</div>

				<button className="btn btn-jungle" type="submit">
					Kullan
				</button>
			</form>
		</PaymentGridListItem>
	);
};
export default PaymentGridListItemEpin;

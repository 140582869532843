import React from "react";

const helpers = {
	GetCaretPosition: function (ctrl, detailed = false) {
		var CaretPos = 0;

		if (ctrl.isContentEditable) {
			ctrl.focus();
			let _range = document.getSelection().getRangeAt(0);
			let range = _range.cloneRange();
			range.selectNodeContents(ctrl);
			range.setEnd(_range.endContainer, _range.endOffset);

			const endOffset = range.toString().length;
			if (detailed) {
				CaretPos = [endOffset - _range.toString().length, endOffset];
			} else {
				CaretPos = endOffset;
			}
		} else if (ctrl.selectionStart || ctrl.selectionStart == 0) {
			CaretPos = ctrl.selectionStart;
		} else if (document.selection) {
			// Legacy IE
			ctrl.focus();
			var Sel = document.selection.createRange();
			Sel.moveStart("character", -ctrl.value.length);
			CaretPos = Sel.text.length;
		}

		return CaretPos;
	},
	SetCaretPosition: function (ctrl, pos) {
		if (ctrl.isContentEditable) {
			ctrl.focus();
			document.getSelection().collapse(ctrl.childNodes[0], pos);
			return;
		} else if (ctrl.setSelectionRange) {
			ctrl.focus();
			ctrl.setSelectionRange(pos, pos);
		} else if (ctrl.createTextRange) {
			var range = ctrl.createTextRange();
			range.collapse(true);
			range.moveEnd("character", pos);
			range.moveStart("character", pos);
			range.select();
		}
	},
};

class PhoneInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			phone: "",
		};
		this.OnChange = this.OnChange.bind(this);
		this.caretPos = 0;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.phone != this.state.phone) {
			helpers.SetCaretPosition(this.Ref, this.caretPos);
		}
	}

	OnChange(e) {
		this.caretPos = helpers.GetCaretPosition(e.currentTarget);
		const phone = e.currentTarget.value
			.replace(/[^0-9]/g, "")
			.replace(/^0/, "")
			.substr(0, 10)
			.replace(
				/([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})([0-9]{0,2})$/g,
				"$1 $2 $3 $4"
			)
			.trim();
		this.caretPos +=
			(phone.substr(0, this.caretPos).match(/[\s]{1}/g) || []).length -
			(e.currentTarget.value.substr(0, this.caretPos).match(/[\s]{1}/g) || []).length;
		this.setState({
			phone: phone || "",
		});
		if (this.props.onChange) {
			this.props.onChange(phone || "");
		}
	}

	render() {
		return (
			<input
				{...this.props}
				type="text"
				ref={(ref) => (this.Ref = ref)}
				name={this.props.name}
				placeholder="5XX XXX XX XX"
				value={this.state.phone}
				onChange={this.OnChange}
				onPaste={this.OnChange}
			/>
		);
	}
}

export default PhoneInput;
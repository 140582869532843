import React from "react";
import CommentEdit from "./CommentEdit";
import ImageWithFallback from "../../Common/ImageWithFallback";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Stars from "../../Common/Stars";
import AvatarHandler from "../../Common/AvatarHandler";

const statusTexts = {
	approved: "Onaylandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
	answer_approved: "Cevaplandı",
	answer_pending: "Beklemede",
	answer_rejected: "Reddedildi",
};

const MyCommentBox = React.forwardRef((props, ref) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const [editModeActive, SetEditMode] = React.useState(false);
	const boxRef = React.useRef(null);

	// React.useEffect(() => {
	// 	if (boxRef.current) {
	// 		const boxHeight = boxRef.current.clientHeight;
	// 		SetBoxHeight(boxHeight);
	// 	}
	// }, []);

	React.useImperativeHandle(ref, () => ({
		UpdateMaxHeight: () => {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		},
	}));

	const ToggleWrapper = () => {
		const boxHeight = boxRef.current.clientHeight;
		if (boxHeight === 0) {
			props.GetDetail(props.id);
			SetBoxHeight(boxRef.current.scrollHeight);
			props.BoxOpen(props.id);
		} else {
			SetBoxHeight(0);
		}
	};

	const ToggleEditMode = () => {
		SetEditMode(!editModeActive);
	};

	React.useEffect(() => {
		if (props.show) {
			ToggleWrapper();
		} else {
			SetBoxHeight(0);
		}
	}, [props.show]);
	return (
		<div className="user-panel-box">
			<header onClick={ToggleWrapper}>
				<StatusIcon status={props.status} />
				<strong>#{props.id}</strong>-<h2>{props.name}</h2>
				<button className={"btn" + (boxHeight === 0 ? "" : " rotate-ccw-180")}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
				</button>
			</header>
			<div
				className="user-panel-box-wrapper"
				style={{ maxHeight: boxHeight }}
				ref={boxRef}
			>
				<div className="user-panel-box-body">
					<div className="comment-container">
						<a
							href={props.content.product_path}
							target="_blank"
							className="comment-image"
						>
							<ImageWithFallback src={props.content.product_image} />
						</a>
						<div className="comment-product-content">
							<div>
							<h3>
								<a href={props.content.product_path} target="_blank">
									{props.name}
								</a>
								</h3>
							</div>

							<div className="comment-product-content-price">
								Tutar:
								<strong>{Currency(props.content.product_cost)} ₺</strong>
							</div>
						</div>

						<span
							className="comment-image"
						>
							<ImageWithFallback
								src={AvatarHandler(props.content.comment_user_avatar, props.content.comment_user_approve_avatar)}
							/>
						</span>
						<div className="comment-content">
							<div className="comment-content-user">
								<h2>{props.content.comment_user_name}</h2>
								Tarih: <strong>{DateFormat(props.content.created_at)}</strong>
								<Stars
									value={props.content.rating}
									xlinkHref="#gs-icon-user-panel-star"
								/>
							</div>
							<div className="vertical-seperator" />
							{editModeActive ? (
								<CommentEdit
									id={props.id}
									message={props.content.comment}
									rating={props.content.rating}
								/>
							) : (
								props.content.comment
							)}
						</div>
						<div className="comment-buttons">
							<StatusIcon status={props.status}>
								{statusTexts[props.status]}
							</StatusIcon>

							<button
								className="btn btn-sm btn-default"
								onClick={ToggleEditMode}
								disabled={props.status === "pending" || props.status === "rejected"}
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-edit"></use>
								</svg>
								Düzenle
							</button>
						</div>
						{props.content.store_comment && (
							<React.Fragment>
								<a
									href={props.content.answer_user_code ? `/mesajlarim?active_tab=${props.content.answer_user_code}` : null}
									target="_blank"
									className="comment-image"
								>
									<ImageWithFallback
										src={`https://images.gamesatis.com/assets/avatar-set/avatar-${props.content.answer_user_avatar?props.content.answer_user_avatar:"1"}.jpg`}
									/>
								</a>
								<div className="comment-answer-content">
									<div className="comment-answer-content-user">
										<h2>
											<a
												href={props.content.answer_user_code ? `/mesajlarim?active_tab=${props.content.answer_user_code}` : null}
												target="_blank"
											>
												{props.content.answer_user_name}
											</a>
										</h2>
									</div>
									<div className="vertical-seperator" />
									{props.content.store_comment}
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

const StatusIcon = (props) => {
	if (props.status === "approved" || props.status === "answer_approved") {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-jungle"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-approved"></use>
				</svg>
				{props.children}
			</div>
		);
	} else if (
		props.status === "rejected" ||
		props.status === "answer_rejected"
	) {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-red"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-rejected"></use>
				</svg>
				{props.children}
			</div>
		);
	} else {
		return (
			<div
				className={
					(!props.children ? "status-icon" : "status-icon-with-content") +
					" status-icon-orange"
				}
			>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-pending"></use>
				</svg>
				{props.children}
			</div>
		);
	}
};

export default MyCommentBox;
export { StatusIcon };

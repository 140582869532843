import { createConsumer } from "@rails/actioncable";
// const consumer = createConsumer('https://test.gamesatis.com:8080/cable');
const consumer = createConsumer();
export default consumer;

// const consumer = {
//   subscriptions: {
//     create: () => ({
//       connected: () => ({})
//     })
//   }
// };

// export default consumer;

import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import Decimal from "../../../packs_helper/scripts/Decimal";
import Copy from "../../../packs_helper/scripts/Copy";

class MyCartProductCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			requesting: false,
			cartSharePopupVisible: false
		};

		this.storeScore =
			(props.item.store.success_sales /
				(props.item.store.success_sales + props.item.store.unsuccess_sales ||
					1)) *
			100;

		this.OnQuantityChange = this.OnQuantityChange.bind(this);
		// this.OnMinusClick = this.OnMinusClick.bind(this);
		// this.OnPlusClick = this.OnPlusClick.bind(this);
		this.OnDeleteClick = this.OnDeleteClick.bind(this);
		this.ToggleCartSharePopup = this.ToggleCartSharePopup.bind(this);
	}

	OnQuantityChange(quantity) {
		this.setState({
			requesting: true,
		});

		const min = Decimal(this.props.item.minimum_quantity);

		if (min === Number.NaN) {
			this.setState({
				requesting: false,
			});
			return false;
		}

		if (quantity < min) {
			ShowPopup({
				message: `Sipariş miktarını ${min}'den daha küçük yapamazsınız! Sepetten çıkarmak için sil butonunu kullanınız.`,
				title: "Hata",
				autoSize: true,
			});
			this.setState({
				requesting: false,
			});
			return false;
		}

		PostData("/update-cart", {
			quantity,
			product_id: this.props.item.product_id,
			cart_id: this.props.item.cart_id,
		})
			.then((response) => {
				this.props.UpdateItem(this.props.item.product_id, response);
				this.setState({
					requesting: false,
				});
			})
			.catch((err) => {
				this.setState({
					requesting: false,
				});
			});
	}

	ToggleCartSharePopup(){
		this.setState(prevProps => ({
			cartSharePopupVisible: !prevProps.cartSharePopupVisible
		}));
	}

	// OnMinusClick(e) {
	// 	const min = Decimal(this.props.item.minimum_quantity);

	// 	if (this.props.item.quantity <= min) {
	// 		ShowPopup({
	// 			message: `Sipariş miktarını ${min}'den daha küçük yapamazsınız! Sepetten çıkarmak için sil butonunu kullanınız.`,
	// 			title: "Hata",
	// 			autoSize: true,
	// 		});
	// 		return false;
	// 	}

	// 	let quantity = Decimal(this.props.item.quantity);
	// 	if (quantity < 1) {
	// 		quantity -= 0.1;
	// 	} else {
	// 		quantity -= 1;
	// 	}

	// 	PostData("/update-cart", {
	// 		quantity,
	// 		product_id: this.props.item.product_id,
	// 		cart_id: this.props.item.cart_id,
	// 	})
	// 		.then((response) => {
	// 			this.props.UpdateItem(this.props.item.product_id, response);
	// 		})
	// 		.catch((err) => {
	// 			console.log("LAST ERROR");
	// 			console.log(err);
	// 		});
	// }

	// OnPlusClick(e) {
	// 	let quantity = Decimal(this.props.item.quantity);
	// 	if (quantity < 1) {
	// 		quantity += 0.1;
	// 	} else {
	// 		quantity += 1;
	// 	}

	// 	PostData("/update-cart", {
	// 		quantity,
	// 		product_id: this.props.item.product_id,
	// 		cart_id: this.props.item.cart_id,
	// 	})
	// 		.then((response) => {
	// 			this.props.UpdateItem(this.props.item.product_id, response);
	// 		})
	// 		.catch((err) => {
	// 			console.log("LAST ERROR");
	// 			console.log(err);
	// 		});
	// }

	OnDeleteClick(e) {
		ShowPopup({
			message: `Ürün sepetten çıkarılacak! Emin misin?<button value="0" class="btn btn-orange" data-close>Vazgeç</button><button value="1" class="btn btn-default" data-close>Evet</button>`,
			title: "Uyarı",
			autoSize: true,
		}).then((button) => {
			if (button.value == 1) {
				this.setState({
					requesting: true,
				});

				PostData("/update-cart", {
					operation: "delete",
					quantity: 0,
					product_id: this.props.item.product_id,
					cart_id: this.props.item.cart_id,
				})
					.then((response) => {
						this.props.UpdateItem(this.props.item.product_id, response);
						this.setState({
							requesting: false,
						});
					})
					.catch((err) => {
						this.setState({
							requesting: false,
						});
					});
			}
		});
	}
}

export default MyCartProductCore;
import React from "react";
import MyCashOutsCore from "./MyCashOutsCore";
import MyCashOutBox, { StatusIcon } from "./MyCashOutBox";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Paginate from "../../Common/Paginate";
import Switcher from "../../Common/Switcher";

const statuses = [
{
	id: "",
	text: "Tümü",
},
{
	id: "1",
	text: "Onaylandı",
},
{
	id: "0,2",
	text: "Bekliyor",
},
{
	id: "3",
	text: "İptal Edildi",
},
];

const statusTexts = {
	approved: "Onaylandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
};

class DesktopRenderer extends MyCashOutsCore{
	render() {
		const { my_cash_outs, cash_outs_count, current_page, activeTab } = this.state;
		return (
			<div className="my-orders-container grid-1 my-cash-outs">
				<Switcher
					buttons={statuses}
					onChange={this.ActivateTab}
					active={activeTab}
				/>

				{my_cash_outs.length ? (
					<React.Fragment>
				{my_cash_outs.map((my_cash_out) => (
					<MyCashOutBox
						key={my_cash_out.id}
						id={my_cash_out.id}
						bank_name={my_cash_out.user_bank_account.bank.name}
						account_owner_name={my_cash_out.user_bank_account.account_owner_name}
						status={my_cash_out.status}
						image={my_cash_out.user_bank_account.bank.image}
						BoxOpen={this.BoxOpen}
						show={this.state.activeBox === my_cash_out.id}
					>
						<div className="order-detail">
							<img
								className="order-detail-image my-cash-outs-image"
								src={my_cash_out.user_bank_account.bank.image}
							/>
							<div className="order-detail-body">
								<div className="order-detail-body-left">
									<div>
										<label>Tarih:</label>
										<strong>{DateFormat(my_cash_out.created_at)}</strong>
									</div>
									<div className="vertical-seperator" />
									<div className="">
										{my_cash_out.admin_description}
									</div>
								</div>
								<div className="order-detail-body-right">
									Tutar:{" "}
									<strong>
										{Currency(my_cash_out.amount)} ₺
									</strong>
								</div>
								<div className="order-detail-body-status">
									<StatusIcon status={my_cash_out.status}>
										{statusTexts[my_cash_out.status]}
									</StatusIcon>
									{my_cash_out.status == 'pending' && (
										<button onClick={() => this.CancelMyCashOut(my_cash_out)} className="btn btn-red btn-sm">İptal Et</button>
									)}
								</div>
							</div>
						</div>
					</MyCashOutBox>
				))}
					<Paginate
						onChange={this.ChangePage}
						total_count={cash_outs_count}
						current_page={current_page}
						per_page={15}
					/>
				</React.Fragment>
				) : (
				<div className="alert">Listelenecek nakit çekiminiz bulunamadı!</div>
			)}
			</div>
		);
	}
}

export default DesktopRenderer;

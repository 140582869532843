import React from "react";
import Stars from "../Common/Stars";
import RelativeDate from "../../packs_helper/scripts/RelativeDate";
import GlobalVar from "../GlobalVar";

const levels = GlobalVar.STORE_LEVELS;

class StoreInfo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			tooltip: null,
			lastSeen: "-"
		};

		this.ShowTooltip = this.ShowTooltip.bind(this);
		this.HideTooltip = this.HideTooltip.bind(this);
		
	}

	componentDidMount(){
		this.GetLastSeen();
	}

	ShowTooltip(e) {
		const tooltip = e.currentTarget.dataset.target;
		this.setState({ tooltip });
	}

	HideTooltip() {
		this.setState({ tooltip: null });
	}


	GetLastSeen(){
		let lastSeenResult = RelativeDate(this.props.store.lastSeen);
		if(
			lastSeenResult == "Şimdi" 
			|| lastSeenResult.includes("sn önce") 
			|| (lastSeenResult.includes("dk önce") && parseInt(lastSeenResult.substr(0,2)) < 6)
		  ){ 
		  lastSeenResult = 'Çevrimiçi';
		}
		this.setState({ lastSeen: lastSeenResult });
	}

	render() {
		return (
			<div className="store-info-container">
				<div className="store-info-summary">
					<img
						src={this.props.store.avatar}
						alt={this.props.store.name}
						width="80"
						height="80"
					/>
					<div className="store-info-summary-content">
						<h1>{this.props.store.name}</h1>
						<div className="rank-bar">
							<div className="rank-bar-container">
								<div
									className="rank-bar-container-fill"
									data-rank={this.props.store.storeRank}
									style={{ width: 100 - this.props.store.storeRank + "%" }}
								></div>
							</div>
							<div className="rank-bar-text">
								%{Math.round(this.props.store.storeRank)}
							</div>
						</div>

						<div className="store-info-last-seen">
							<div style={{display: this.state.lastSeen === 'Çevrimiçi' ? "none":"block"}}>
								Son Görülme:{" "}
								<strong>{this.state.lastSeen}</strong>
							</div>
							<div style={{display: this.state.lastSeen === 'Çevrimiçi' ? "flex":"none"}}>
								<i className="online" title="Online"></i> {this.state.lastSeen}
							</div>
						</div>
					</div>
				</div>

				<div className="store-info-validation">
					<div className={this.props.store.confirmedStore ? " active" : ""}>
						{this.state.tooltip === "verified-store" && (
							<div className="tooltip">
								{this.props.store.confirmedStore
									? "Onaylı Satıcı"
									: "Onaysız Satıcı"}
							</div>
						)}
						<svg
							className="icon"
							onMouseEnter={this.ShowTooltip}
							onMouseLeave={this.HideTooltip}
							data-target="verified-store"
						>
							<use xlinkHref="#gs-icon-store-page-verified"></use>
						</svg>
					</div>
					<div className={this.props.store.confirmedMail ? " active" : ""}>
						{this.state.tooltip === "verified-mail" && (
							<div className="tooltip">
								{this.props.store.confirmedMail
									? "E-Posta Onaylı"
									: "E-Posta Onaysız"}
							</div>
						)}
						<svg
							className="icon"
							onMouseEnter={this.ShowTooltip}
							onMouseLeave={this.HideTooltip}
							data-target="verified-mail"
						>
							<use xlinkHref="#gs-icon-store-page-message"></use>
						</svg>
					</div>
					<div className={this.props.store.confirmedPhone ? " active" : ""}>
						{this.state.tooltip === "verified-phone" && (
							<div className="tooltip">
								{this.props.store.confirmedPhone
									? "Telefon Onaylı"
									: "Telefon Onaysız"}
							</div>
						)}
						<svg
							className="icon"
							onMouseEnter={this.ShowTooltip}
							onMouseLeave={this.HideTooltip}
							data-target="verified-phone"
						>
							<use xlinkHref="#gs-icon-store-page-phone"></use>
						</svg>
					</div>
					<div className="active">
						{this.state.tooltip === "store-level" && (
							<div className="tooltip">{`${levels[this.props.store.level].label} Mağaza`}</div>
						)}
						<img
							src={`https://images.gamesatis.com/assets/store/${levels[this.props.store.level].icon}-icon.svg`}
							alt={`${levels[this.props.store.level].label} Mağaza`}
							onMouseEnter={this.ShowTooltip}
							onMouseLeave={this.HideTooltip}
							data-target="store-level"
						/>
					</div>
				</div>

				<div className="grid-2 flex">
					{this.props.store.user_code && (
						<React.Fragment>
						<a href={`/mesajlarim?active_tab=${this.props.store.user_code}`} className="btn btn-default">
							<svg
							>
								<use xlinkHref="#gs-icon-store-page-message"></use>
							</svg>
							Mesaj
						</a>
						<a href={`/mesajlarim?active_tab=${this.props.store.user_code}&send_sms=1`} className="btn btn-default">
							<svg
							>
								<use xlinkHref="#gs-icon-store-page-sms"></use>
							</svg>
							SMS
						</a>
						</React.Fragment>
					)}
					<a href={`/share?url=https://gamesatis.com/magaza/${this.props.store.name}`}  className="btn btn-default" data-remote="true" data-method="post" rel="nofollow">
						<svg className="icon">
							<use xlinkHref="#gs-icon-store-page-share"></use>
						</svg>
						Paylaş
					</a>
				</div>

				<div className="store-info-sale-counts">
					<h2>
						Toplam İşlem Adedi:{" "}
						{this.props.store.totalSales}
					</h2>
					<div className="grid-2">
						<div>
							<label>Başarılı Satış:</label>
							<svg className="icon text-jungle">
								<use xlinkHref="#gs-icon-store-page-thumb-up"></use>
							</svg>
							<strong>({this.props.store.successSales})</strong>
						</div>
						<div>
							<label>Başarısız Satış:</label>
							<svg className="icon text-red">
								<use xlinkHref="#gs-icon-store-page-thumb-down"></use>
							</svg>
							<strong>({this.props.store.unsuccessSales})</strong>
						</div>
					</div>
				</div>
				<div>
					<div className="store-info-ratings">
						<div className="ratings-grid">
							{Range(5, 1).map((itr) => (
									<React.Fragment
									key={itr}
									>
										<Stars value={itr} xlinkHref="#gs-icon-store-page-star" onClick={() => this.props.SetRating(itr)} className={this.props.rating == itr ? 'selected' : ''} />
										<div>{starLabels[itr]}</div>
										<div className="rank-bar">
											<div className="rank-bar-container">
												<div
													className="rank-bar-container-fill"
													data-rank={this.props.store.commentsStat[itr]}
													style={{ width: `${100 - this.props.store.commentsStat[itr]}%` }}
												></div>
											</div>
											<div className="rank-bar-text">%{Math.round(this.props.store.commentsStat[itr])}</div>
										</div>
									</React.Fragment>
							))}
						</div>
						<div className="comment-score">
							Ortalama Puan
							<Stars value={this.props.store.commentsAverage} xlinkHref="#gs-icon-store-page-star" />
							<strong>{this.props.store.commentsAverage > 0 ? this.props.store.commentsAverage.toFixed(2) : "-"}</strong>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const starLabels = [null, "Çok Kötü", "Kötü", "İyi", "Çok İyi", "Mükemmel"];

const Range = function (from, to) {
	if (typeof to === "undefined") {
		to = from;
		from = 0;
	}
	const array = [];
	if (from < to) {
		while (from <= to) {
			array.push(from++);
		}
	} else {
		while (from >= to) {
			array.push(from--);
		}
	}
	return array;
};

export default StoreInfo;
import React from "react";

const searchResultNames = {
	categories: "Oyuncu Pazarı",
	products: "Oyunlar",
	skins: "Skinler",
};

const searchResultButtonNames = {
	categories: "Kategoriye Git",
	products: "Oyuna Git",
	skins: "Skine Git",
};

const SearchBoxResult = (props) => {
	return (
		<div className="search-box-result">
			{props.searchResults.map((searchResult) => (
				<div className="search-box-result-group" key={searchResult[0]}>
					<h2>{searchResultNames[searchResult[0]]}</h2>
					<div className="search-box-result-group-items">
						{searchResult[1].map((searchResultItem) => (
							<div
								className={"search-box-result-group-item " + searchResult[0]}
								key={searchResultItem.key}
							>
								<a href={searchResultItem.path}>
									<img src={searchResultItem.image} />
								</a>
								<a href={searchResultItem.path}>{searchResultItem.name}</a>
								<a href={searchResultItem.path} className="btn btn-blue">
									{searchResultButtonNames[searchResult[0]]}
									<svg className="icon">
										<use xlinkHref="#gs-icon-layout-link"></use>
									</svg>
								</a>
								{searchResultItem.search && (
									<a href={searchResultItem.search} className="btn btn-jungle">
										Kategoride Ara
										<svg className="icon">
											<use xlinkHref="#gs-icon-layout-search"></use>
										</svg>
									</a>
								)}
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

export default SearchBoxResult;
import React from "react";
import UserPanelCore from "./UserPanelCore";
import UserPanelMenu, { Button } from "./UserPanelMenu";
// import profileButtons from "./_profileButtons";

class MobileRenderer extends UserPanelCore {
	constructor(props) {
		super(props);

		this.GoBack = this.GoBack.bind(this);
	}

	GoBack() {
		this.setState({
			activeSection: null,
		});
		history.pushState({}, "", "/profilim");
	}

	render() {
		const { activeSection } = this.state;

		if (activeSection) {
			return (
				<div className="user-panel-wrapper">
					<div className="user-panel-body">
						<div className="back-button-container">
							<Button
								ChangeRoute={this.GoBack}
								name="go-back"
								label="Geri"
								icon="caret-down"
								className="go-back-button"
							/>
						</div>
					{activeSection.name == "Settings" ? (
						<activeSection.component
							user={this.props.user}
							ChangeRoute={this.ChangeRoute}
							UpdatePersonalInfoVisibility={this.UpdatePersonalInfoVisibility}
							mobile={this.props.mobile}
						/>
					) : (
						<activeSection.component
							user={this.props.user}
							ChangeRoute={this.ChangeRoute}
							mobile={this.props.mobile}
							not_show_identification_number={
								this.state.not_show_identification_number
							}
						/>
					)}
					</div>
				</div>
			);
		} else {
			return (
				<div className="user-panel-wrapper">
				<UserPanelMenu
					user={this.props.user}
					ChangeRoute={this.ChangeRoute}
					not_show_identification_number={
						this.state.not_show_identification_number
					}
					side_image={this.props.side_image}
				/>
				</div>
			);
		}
	}
}

export default MobileRenderer;
import React from "react";
import PostAnAdCore, {types} from "./PostAnAdCore";
import TypeButton from "./TypeButton";
import PopUp from "../../Common/PopUp";
import UpdateIdentification from "../Common/UpdateIdentification";
import UpdatePhone from "../Common/UpdatePhone";
import UpdateStoreName from "../Common/UpdateStoreName";
import DateFormatter from "../../Common/DateFormatter";

const fraudImage = require("../../../assets_v3/images/dolandirici_dikkat.jpg");

class DesktopRenderer extends PostAnAdCore {
	constructor(props) {
		super(props);
	}

	requestStoreNameConfirmation(){
		if (this.state.data.store_name !== null){
			this.UpdateData();
			if (!this.state.data.approve_store){
				ShowPopup({
					title: 'Bilgilendirme',
					message: '<div>Mağaza adınız kontrol aşamasındadır.</div> ' +
						'<div><br />Onaylandıktan sonra size Canlı Destek üzerinden bilgilendirme mesajı gelecek.</div>' +
						'<div>Lütfen bekleyiniz...</div>'
				})
			}
		}
	}

	render() {
		const ActiveComponent = this.state.activeComponent;
		let data = this.state.data;
		if (data === undefined){
			data = {
				identification_number: false,
				gsm_verification: false,
			}
		}

		if(this.state.popup === "selection_info"){
			let countDown = 15;
			const interval = setInterval(() => {
				countDown--;
				const countDownSpan = document.getElementById('popupCountDown');
				if (!countDownSpan) {
					clearInterval(interval);
				}else{
					countDownSpan.innerHTML = countDown;
					if (countDown === 0) {
						clearInterval(interval);
						this.setState({ popup: "selection_info_with_close" });
					}
				}
			}, 1000);
		}

		const all_confirmation = data.identification_number && data.gsm_verification && data.store_name && data.approve_store
		return (
			<div className="post-an-add-container">
				<div className="category-buttons">
					<nav>
						{types.map((type) => (
							<TypeButton
							id={type.id}
							icon={type.icon}
							label={type.label}
							onClick={this.SetActiveComponent}
							active={this.state.activeComponent && type.id === this.state.activeComponent.id}
							disabled={type.needPermission && (this.props.rate === null || !all_confirmation)}
							key={type.id}
							/>
						))}
					</nav>
				</div>
				<React.Fragment>
				{ all_confirmation ? (
						<ActiveComponent.component
							id={this.state.activeComponent.id}
							type={this.state.activeComponent.type}
							user={this.props.user}
							ChangeRoute={this.props.ChangeRoute}
						/>
					)
					:
					(
						<div className="item-warning">
							<div className="item-warning-title">
								Eksik Bilgi
							</div>
							<div className="item-warning-sub-title">
								Devam etmek için aşağıdaki doğrulamaları tamamlayın lütfen.
							</div>
							<div className="validation-buttons">
								<a
									onClick={() => this.setState({popup: 'identity'})}
									className={data.identification_number ? "btn btn-jungle":'btn btn-default'}
									disabled={data.identification_number}
								>
									<svg className="icon">
										<use href={data.identification_number ? "#gs-icon-user-panel-approved" : "#gs-icon-user-panel-warning"}></use>
									</svg>
									{data.identification_number ? 'TC Doğrulandı!':'TC Doğrulanamadı!'}

								</a>
								<a
									onClick={() => this.setState({popup: 'change-gsm'})}
									className={data.gsm_verification ? "btn btn-jungle":'btn btn-default'}
									disabled={data.gsm_verification}
								>
									<svg className="icon">
										<use href={data.gsm_verification ? "#gs-icon-user-panel-approved" : "#gs-icon-user-panel-warning"}></use>
									</svg>
									{data.gsm_verification ? 'Telefon Doğrulandı!':'Telefon Doğrulanamadı!'}
								</a>
								<a
									onClick={() => this.setState({popup: 'change-store'})}
									className={data.store_name !== null ? "btn btn-jungle":'btn btn-default'}
									disabled={data.store_name !== null}
								>
									<svg className="icon">
										<use href={data.store_name !== null ? "#gs-icon-user-panel-approved" : "#gs-icon-user-panel-warning"}></use>
									</svg>
									{data.store_name !== null ? 'Mağaza Adı Belirlendi!':'Mağaza Adı Belirlenmedi!'}
								</a>
								<a
									onClick={() => this.requestStoreNameConfirmation()}
									className={data.approve_store ? "btn btn-jungle":'btn btn-default'}
									disabled={data.approve_store || data.store_name === null}
								>
									<svg className="icon">
										<use href={data.approve_store ? "#gs-icon-user-panel-approved" : "#gs-icon-user-panel-warning"}></use>
									</svg>
									{!data.approve_store || data.store_name === null ? 'Mağaza Adı Doğrulanamadı!':'Mağaza Adı Doğrulandı!'}

								</a>
							</div>
						</div>
					)
				}
				</React.Fragment>
				{this.state.popup === "identity" && (
					<PopUp
						title="Kimlik Doğrulama"
						Hide={() => this.setState({ popup: null })}
					>
						<UpdateIdentification
							name={data.name}
							lastname={data.lastname}
							birthdate={DateFormatter(data.birthdate)}
							identification_number={data.identification_number}
							UpdateData={() => this.UpdateData()}
							SetPopUpNull={() => this.setState({popup:null})}
						/>
					</PopUp>
				)}
				{this.state.popup === "change-gsm" && (
					<PopUp
						title="Telefon Numarası Kaydet"
						Hide={() => this.setState({ popup: null })}
					>
						<UpdatePhone
							UpdateData={() => this.UpdateData()}
							gsm_verification_timeout={data.gsm_verification_timeout}
							SetPopUpNull={() => this.setState({popup: null})}
						/>
					</PopUp>
				)}
				{this.state.popup === "change-store" && (
					<PopUp
						title="Mağaza Adı Oluştur"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<UpdateStoreName
							UpdateData={() => this.UpdateData()}
							SetPopUpNull={() => this.setState({popup: null})}
						/>
					</PopUp>
				)}
				{this.state.popup === "selection_info" && (
					<PopUp
						title="Bilgilendirme"
						Hide={() => console.log("you can't close this popup")}
						className="settings-popup"
					>
						<div><img src={fraudImage} height="500" /></div>
						<hr />
						<div>
							<button className="btn btn-jungle" disabled style={{width: '100%'}}>
								Tekrar Gösterme (<span id="popupCountDown">15</span>)
							</button>
						</div>
					</PopUp>
				)}
				{this.state.popup === "selection_info_with_close" && (
					<PopUp
						title="Bilgilendirme"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<div><img src={fraudImage} height="500" /></div>
						<hr />
						<div><button className="btn btn-jungle" style={{width: '100%'}} onClick={() => {
							this.SelectionWarningClose(); this.setState({ popup: null });
						}}>Tekrar Gösterme</button></div>
					</PopUp>
				)}
			</div>
		);
	}
}

export default DesktopRenderer;

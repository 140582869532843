import React from "react";
import KoItem from "./KoItem";
import Item from "./Item";

const ItemChooseWrapper = (props) => {
	const [categoryId, SetCategoryId] = React.useState(props.id);

	const OnChange = (catId) => {
		SetCategoryId(Number(catId));
		let newRoute = `/ilan-ekle?active_tab=${props.type}`
		if(catId !== null && catId !== undefined && parseInt(catId) > 0)
			newRoute = newRoute + `&selected_game=${catId}`
		props.ChangeRoute(newRoute);
	}

	const componentDidMountRef = React.useRef(null);

  React.useEffect(() => {
  	if(!componentDidMountRef.current){
  		componentDidMountRef.current = true;
  		return false;
  	}
  		OnChange(props.id);

  }, [props.id, props.type]);

  React.useEffect(() => {
		const { selected_game } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);
		const selectedGame = parseInt(selected_game);
		if(selectedGame){
			SetCategoryId(Number(selectedGame));
		}
  }, []);

	const ActiveComponent = categoryId === 20127 ? KoItem : Item;
	return (<ActiveComponent {...props} categoryId={categoryId} onChange={OnChange} />);
};

export default ItemChooseWrapper;

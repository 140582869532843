import React from "react";
import StorePageCommentsCore from "./StorePageCommentsCore";
import CommentProduct from "./CommentProduct";
import Stars from "../../Common/Stars";
import ImageWithFallback from "../../Common/ImageWithFallback";
import ImagePath from "../../../packs_helper/scripts/ImagePath";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Paginate from "../../Common/Paginate";

class MobileRenderer extends StorePageCommentsCore{
	render() {
		if (this.state.comments.length === 0) {
			return <div className="alert">Mağazaya hiç yorum yapılmamış!</div>;
		} else {
			return (
				<div className="comment-wrapper">
					{this.state.comments.map((comment) => {
						let image, link;
						if(comment.product.steam_item){
							image = `https://steamcommunity-a.akamaihd.net/economy/image/${comment.product.steam_item.image}/100fx80f`;
							link = `/${comment.product.category.slug}/${comment.product.steam_item.slug}?id=${comment.product.id}`;
						}
						else{
							image = ImagePath(
												"products",
												"images",
												comment.product.id,
												"thumb",
												comment.product.image_file_name
											);
							link = `/${comment.product.category.slug}/${comment.product.slug}`;
						}
						return (
							<section
								className="comment"
								key={comment.user.short_name + comment.created_at}
							>
								<header>
									<h2>
										{comment.user.short_name}{" "}
										<small>{DateFormat(comment.created_at)}</small>
									</h2>
									<Stars
										value={comment.rating}
										xlinkHref="#gs-icon-store-page-star"
									/>
								</header>
								<div className="section-content">
									{comment.comment}
									{comment.product && <CommentProduct {...comment.product} />}

									{comment.our_comment && (
										<div className="comment-reply">
											<h3>
												<svg className="icon">
													<use xlinkHref="#gs-icon-comment-box-reply"></use>
												</svg> {this.props.storeName}
											</h3>
											<div className="comment-reply-content">
												{comment.our_comment}
											</div>
										</div>
									)}

									{/*
									<div className="comment-reply">
										<a
											href={link}
											target="_blank"
											className="comment-image"
										>
										<ImageWithFallback src={image} />
										</a>
										<a
											href={link}
											target="_blank"
											className="comment-content"
										>{comment.product.name}
										<small>{comment.product.package_name}</small>
										</a>
									</div>
									*/}
								</div>
							</section>
						);
					})}

				{this.state.total_entries > 0 && (
					<Paginate
						onChange={this.ChangePage}
						total_count={this.state.total_entries}
						current_page={this.state.current_page}
						per_page={10}
					/>
				)}

				</div>
			);
		}
	}
}

export default MobileRenderer;

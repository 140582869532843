import React, {Component, Fragment} from "react"

class HelperClass {
	zeroPad(str, pad = 2) {
		return ("00" + str).slice(-2);
	}

	dateFormat(dateStr, format) {
		if (!dateStr) {
			return "";
		} else {
			const date = new Date(dateStr);
			if (isNaN(date)) {
				return "";
			} else if (format === "date") {
				return (
					this.zeroPad(date.getDate()) +
					"/" +
					this.zeroPad(date.getMonth() + 1) +
					"/" +
					date.getFullYear()
				);
			} else if (format === "hour") {
				return (
					this.zeroPad(date.getHours()) + ":" + this.zeroPad(date.getMinutes())
				);
			} else if (format === "time") {
				return (
					this.zeroPad(date.getHours()) +
					":" +
					this.zeroPad(date.getMinutes()) +
					":" +
					this.zeroPad(date.getSeconds())
				);
			} else {
				return (
					this.zeroPad(date.getDate()) +
					"/" +
					this.zeroPad(date.getMonth() + 1) +
					"/" +
					date.getFullYear() +
					" - " +
					this.zeroPad(date.getHours()) +
					":" +
					this.zeroPad(date.getMinutes())
				);
			}
		}
	}

	decimal(number, decimalPlace = 2) {
		const number_f = parseFloat(number);
		if (Number.isNaN(number_f)) {
			return 0;
		}
		if (decimalPlace === -1) {
			const number_i = parseInt(number);
			if (number_i === number_f) {
				return number_i;
			} else {
				return number_f.toFixed(2);
			}
		} else {
			return number_f.toFixed(decimalPlace);
		}
	}

	Range(lowEnd, highEnd, inverse) {
		if (!inverse) {
			var arr = [],
				c = highEnd - lowEnd + 1;
			while (c--) {
				arr[c] = highEnd--;
			}
		} else {
			var arr = [],
				c = 0;
			while (lowEnd >= highEnd) {
				arr[c++] = lowEnd--;
			}
		}

		return arr;
	}

	CSRFToken(pure = false) {
		const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
		if (pure) {
			return csrfToken;
		} else {
			return (
				<input type="hidden" name="authenticity_token" value={csrfToken} />
			);
		}
	}

	Copy(e) {
		let data = e.currentTarget.dataset.copy;
		if (data instanceof Array) {
			data = data.join("\n");
		}
		const textarea = document.createElement("TEXTAREA");
		textarea.value = data;
		textarea.style.position = "fixed";
		textarea.style.top = 0;
		textarea.style.left = 0;
		textarea.style.width = 0;
		textarea.style.height = 0;
		textarea.style.zIndex = 9999999999999;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand("copy");
		document.body.removeChild(textarea);
	}

	Post(url, formData, resCallback) {
		if (!formData) {
			formData = new FormData();
		}
		formData.append("authenticity_token", this.CSRFToken(true));
		axios
			.post(url, formData)
			.then(function(response) {
				if(response.status === 204){
					swalert2(
						["Sunucu boş yanıt verdi. Sayfayı yenileyerek işleminizin gerçekleştiğini kontrol ediniz."],
						"error",
						window.location.href,
						{ buttons: {} }
					)();
				}
				else {
					if (!!response.data.set_balance) {
						// console.log("SET BALANCE");
						// console.log(response.data.set_balance)
						// DataBus.Set("set_balance", response.data.set_balance);
						if(window !== "undefined"){
							const event = document.createEvent("Event");
							event.value = response.data.set_balance;
							event.initEvent("set_balance", false, true); 
							window.dispatchEvent(event);
						}
					}
					if (!!response.data.message) {
						swalert2(
							[response.data.message],
							!response.data.state ? "error" : "success",
							response.data.redirect_to,
							{ timer: 2500, buttons: false }
						)();
						if (!!response.data.indirect_to) {
							let active_link = null;
							let active_tab = null;

							const index = response.data.indirect_to.indexOf("?");
							if (index === -1) {
								active_link = response.data.indirect_to;
							} else {
								active_link = response.data.indirect_to.substr(0, index);
								active_tab = "&" + response.data.indirect_to.substr(index + 1);
							}
							DataBus.Set("indirect_to", {
								active_link,
								active_tab,
							});
						} else {
							resCallback(response.data);
						}
					} else if (!!response.data.indirect_to) {
						DataBus.Set("indirect_to", response.data.indirect_to);
					} else if (!!response.data.redirect_to) {
						window.location.href = response.data.redirect_to;
					} else {
						resCallback(response.data);
					}
				}
			})
			.catch(function(error) {
				switch(error.response.status){
					case 401:
						swalert2(
							[
								'Bu işlemi yapabilmek için giriş yapmanız gerekmektedir. Devam etmek için giriş yapınız.<a href="action:login" target="_self">Giriş Yap</a>',
							],
							"error",
							null,
							{ buttons: { ok: { className: "btn-danger" } } }
						)();
					break;
					case 422:
						swalert2(
							["Oturumunuz sonlandı lütfen giriş yapınız."],
							"error",
							window.location.href,
							{ buttons: {} }
						)();
						break;
					case 413:
						swalert2(
							['<div class="mb-3">Yüklemeye çalıştığınız dosyaların toplam boyutu en fazla 6MB olabilir. Lütfen dosyalarınızı küçülterek yeniden deneyin.</div><div><div class="text-primary">https://www.resizenow.com/tr</div> ya da benzer bir online araç kullanarak yeniden boyutlandırabilirsiniz</div>'],
							"error",
							null,
							{ buttons: {} }
						)();
						break;
						default:
						swalert2(
							['<div class="lh-125">İşlem yapılırken bilinmeyen bir hata oluştu. Lütfen sayfayı yenileyerek tekrar deneyiniz. Sorun devam ederse yaptığınız işlemi canlı destek ekibimizle paylaşarak yardım alabilirsiniz.</div>'],
							"error",
							null,
							{ buttons: {} }
						)();
				}
				resCallback({state: false});
			});
	}

	Params(param) {
		var map = {};
		const search = window.location.search;
		if ("" != search) {
			var groups = search.substr(1).split("&"),
				i;

			for (i in groups) {
				i = groups[i].split("=");
				map[decodeURIComponent(i[0])] = decodeURIComponent(i[1]);
			}
		}

		if(param){
			return map[param];
		}
		else{
			return map;
		}
	}

	ImagePath({ type, filetype, id, size, imagename }) {
		const parts = id
			.toString()
			.padStart(9, 0)
			.match(/\d{1,3}/g)
			.join("/");
		return `https://s3.eu-central-1.amazonaws.com/images.gamesatis.com/${type}/${filetype}/${parts}/${size}/${imagename}`;
	}

	JustFloat(value) {
		const val = value.match(/[0-9]*[\,\.]?[0-9]{0,2}/)[0].replace(",", ".");
		if (!val) {
			return "";
		} else if (val.substr(-1) === ".") {
			return val;
		} else {
			return Number.parseFloat(val);
		}
	}

	JustInteger(value) {
		return Number.parseInt(value.match(/[0-9]*/)[0]);
	}

	JustNumber(value) {
		return value.match(/[0-9]*/)[0];
	}

	GetCaretPosition(ctrl, detailed = false) {
		var CaretPos = 0;

		if (ctrl.isContentEditable) {
      ctrl.focus()
      let _range = document.getSelection().getRangeAt(0)
      let range = _range.cloneRange()
      range.selectNodeContents(ctrl)
      range.setEnd(_range.endContainer, _range.endOffset)

      const endOffset = range.toString().length;
      if(detailed){
      	CaretPos = [endOffset - _range.toString().length, endOffset];
      }
      else{
      	CaretPos = endOffset;
      }
    }
    else if (ctrl.selectionStart || ctrl.selectionStart == 0) {
			// Standard.
   //    if(detailed){
			// 	CaretPos = [ctrl.selectionStart, ctrl.selectionEnd];
			// }
			// else{
				CaretPos = ctrl.selectionStart;
			// }
		} else if (document.selection) {
			// Legacy IE
			ctrl.focus();
			var Sel = document.selection.createRange();
			Sel.moveStart("character", -ctrl.value.length);
			CaretPos = Sel.text.length;
		}

		return CaretPos;
	}

	SetCaretPosition(ctrl, pos) {
    if (ctrl.isContentEditable) {
        ctrl.focus()
        document.getSelection().collapse(ctrl.childNodes[0], pos)
        return
    }
		else if (ctrl.setSelectionRange) {
			ctrl.focus();
			ctrl.setSelectionRange(pos, pos);
		} else if (ctrl.createTextRange) {
			var range = ctrl.createTextRange();
			range.collapse(true);
			range.moveEnd("character", pos);
			range.moveStart("character", pos);
			range.select();
		}
	}

 IsValidUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
	}
}
const helpers = new HelperClass();


class CountDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			countdown: this.props.seconds,
			ended: false,
		};
		this.intervalId = setInterval(
			(_this) => {
				let countdown = _this.state.countdown - 1;
				if (countdown < 0) {
					clearInterval(this.intervalId);
					_this.props.onComplete();
					_this.setState({
						countdown: 0,
						ended: true,
					});
					return false;
				}
				_this.setState({ countdown: countdown });
			},
			1000,
			this
		);
	}

	FormatTime(time) {
		let pads = "";
		if(this.props.zeropad){
			pads = "00";
		}
		const hours = (pads + Math.floor(time / 3600)).slice(-2);
		const mins = (pads + Math.floor((time - hours * 3600) / 60)).slice(-2);
		const secs = ("00" + (time % 60)).slice(-2);
		return (
			<div>
				{hours > 0 && <span>{hours}:</span>}
				<span>{mins}:</span>
				<span>{secs}</span>
			</div>
		);
	}

	componentWillUnmount(){
		clearInterval(this.intervalId);
	}

	render() {
		if (!this.state.ended) {
			return this.FormatTime(this.state.countdown);
		} else {
			return <div />;
		}
	}
}

class AutoReload extends React.Component {
	constructor(props) {
		super(props);
		const random = Math.round(Math.random() * 999999999);
		this.inputId = `reload-${random}`;

		this.state = {
			active: !!this.props.active,
			seconds: this.props.seconds,
		};
		this.Reset = this.Reset.bind(this);
		this.Interval = null;
		if (this.state.active) {
			this.Start();
		}
	}

	componentWillUnmount() {
		this.Stop();
	}

	Start() {
		this.state.seconds = this.props.seconds;
		const _this = this;
		this.Interval = setInterval(() => {
			let seconds = this.state.seconds - 1;
			if (seconds === -1) {
				seconds = this.props.seconds;
				this.props.reload();
			}
			this.setState({ seconds });
		}, 1000);
	}

	Stop() {
		clearInterval(this.Interval);
		this.Interval = null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.active != prevProps.active) {
			this.setState({ active: this.props.active });
		}

		if (!this.state.active && !!prevState.active) {
			this.Stop();
		} else if (!!this.state.active && !prevState.active) {
			this.Start();
		}
	}

	Reset() {
		this.setState({
			active: !!this.props.active,
			seconds: this.props.seconds,
		});
	}

	render() {
		return null;
		// return (
		// <div className="custom-checkbox-2 pl-3">
		//   <input type="checkbox" id={this.inputId} onChange={e => this.setState({active: e.target.checked, seconds: this.props.seconds })} checked={this.state.active} />
		//   <label htmlFor={this.inputId}>
		//      <div className="d-flex"><span className="mr-1">Otomatik yenile</span>
		//      {!!this.state.active && (
		//         <small className="align-self-end">
		//         ({this.state.seconds === 0 ? 'Yükleniyor...' : this.state.seconds})
		//         </small>
		//      )}
		//      </div>
		//    </label>
		// </div>
		// )
	}
}

class RateStars extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hoverRate: this.props.Rate || 5,
			currentRate: this.props.Rate || 5,
		};

		this.CanChange = !!this.props.CurrentRate;

		this.SetRate = this.SetRate.bind(this);
		this.ResetRate = this.ResetRate.bind(this);
		this.CurrentRate = this.CurrentRate.bind(this);
		this.HoverRate = this.HoverRate.bind(this);
		this.ClearTimeoutId = null;
		if (this.CanChange) {
			this.CurrentRate();
		}
	}

	CurrentRate() {
		this.props.CurrentRate(this.state.currentRate);
	}

	SetRate(e) {
		e.preventDefault();
		const _this = this;
		this.setState(
			{
				currentRate: this.state.hoverRate,
			},
			() => {
				_this.CurrentRate();
			}
		);
	}

	ResetRate() {
		this.setState({
			hoverRate: this.state.currentRate,
		});
	}

	HoverRate(e) {
		const hoverRate = parseInt(e.target.dataset.ratingValue);
		this.setState({
			hoverRate,
		});
	}

	render() {
		if (!this.CanChange) {
			return (
				<div
					className={
						"br-wrapper br-theme-fontawesome-stars d-inline-block" +
						(this.props.small ? " br-theme-fontawesome-stars-sm" : "")
					}
				>
					<div className="br-widget">
						{helpers.Range(1, this.state.hoverRate).map((rate) => (
							<a
								data-rating-value={rate}
								data-rating-text={rate}
								className="br-selected"
								key={rate}
							></a>
						))}
						{helpers.Range(this.state.hoverRate + 1, 5).map((rate) => (
							<a
								data-rating-value={rate}
								data-rating-text={rate}
								key={rate}
							></a>
						))}
					</div>
				</div>
			);
		} else {
			return (
				<div
					className={
						"br-wrapper br-theme-fontawesome-stars d-inline-block" +
						(this.props.small ? " br-theme-fontawesome-stars-sm" : "")
					}
					onMouseLeave={this.ResetRate}
				>
					<div className="br-widget">
						{helpers.Range(1, this.state.hoverRate).map((rate) => (
							<a
								href="#"
								data-rating-value={rate}
								data-rating-text={rate}
								className="br-selected"
								onClick={this.SetRate}
								onMouseEnter={this.HoverRate}
								key={rate}
							></a>
						))}
						{helpers.Range(this.state.hoverRate + 1, 5).map((rate) => (
							<a
								href="#"
								data-rating-value={rate}
								data-rating-text={rate}
								onClick={this.SetRate}
								onMouseEnter={this.HoverRate}
								key={rate}
							></a>
						))}
					</div>
				</div>
			);
		}
	}
}

class TextareaWithLines extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lineCount: 10,
			bulk_codes: "",
		};
		this.OnChange = this.OnChange.bind(this);
	}

	OnChange(e) {
		const bulk_codes = e.target.value;
		const bulk_codes_array = bulk_codes.split("\n");
		const lineCount = bulk_codes_array.length;

		this.setState({
			bulk_codes,
			lineCount: lineCount < 10 ? 10 : lineCount,
		});
		this.props.onChange(bulk_codes_array);
	}

	render() {
		return (
			<div className="textarea-with-line-numbers">
				<div className="lines">
					{helpers.Range(1, this.state.lineCount).map((line) => (
						<div key={line}>{line}</div>
					))}
				</div>
				<textarea
					onChange={this.OnChange}
					onPaste={this.OnChange}
					style={{
						height: `${this.state.lineCount * 17}px`,
					}}
				/>
			</div>
		);
	}
}

class NumberFieldTag extends React.Component {
	constructor(props) {
		super(props);
		const value = this.props.defaultValue || "";
		this.state = {
			value,
		};
		this.Filter = this.Filter.bind(this);
		this.Max = parseInt(this.props.max) || Math.pow(10, this.props.maxLength || 18);
	}

	Filter(e) {
		let value = e.target.value.replace(/[^0-9]*/g, "");
		if(value > this.Max){
			value = this.Max;
		}
		else if(value <= 0){
			value = '';
		}
		this.setState({
			value,
		});
	}

	render() {
		return (
			<input
				type={this.props.type || "text"}
				name={this.props.name}
				className={this.props.className}
				maxLength={this.props.maxLength}
				required={this.props.required !== undefined}
				placeholder={this.props.placeholder}
				value={this.state.value}
				onChange={this.Filter}
				onPaste={this.Filter}
			/>
		);
	}
}

const FeeConfirm = function(props){
	return (
	<CustomModalCenter title="Onay Gerekiyor" Hide={props.Hide} width="720px">
		<p className="h6">{props.message}</p>
		<hr />
		<div className="text-right">
		{props.sendDisabled ? (
			<button className="gs-button rounded mr-2" type="button" disabled={true}>
				<div style={{display:'flex', height:'1em', alignItems:'flex-end'}}>
					<span>Kaydediliyor, bekleyiniz</span>
					<span className="loading-dots"><span></span><span></span><span></span></span>
				</div>
			</button>
			) : (
			<button className="gs-button rounded mr-2" type="button" onClick={props.Approved}>
				Kabul Et ve İlanı Yayınla
			</button>
			)}
			<button className="gs-button button-secondary rounded mr-2" type="button" onClick={props.Hide}>
				Kapat
			</button>
		</div>
	</CustomModalCenter>
	)
}

class SearchableSelect extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			focus: false,
			search: "",
			//selectedItem: null,
			hoverIndex: 0,
			selectedItem: !!this.props.value ? this.props.items.find(item => item.id === this.props.value) : null,
		}
		this.Select = this.Select.bind(this);
		this.InputBlur = this.InputBlur.bind(this);
		this.Focus = this.Focus.bind(this);
		this.KeyDown = this.KeyDown.bind(this);
		this.SearchChangeHandler = this.SearchChangeHandler.bind(this);
		// this.filteredItems = this.props.items;
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.search !== this.state.search){
			// this.filteredItems = this.props.items.filter(item => item.name.toLocaleLowerCase().indexOf(this.state.search) > -1);
		}
		if(prevProps.items.length !== this.props.items.length){
			// this.filteredItems = this.props.items.filter(item => item.name.toLocaleLowerCase().indexOf(this.state.search) > -1);
			this.setState({
				selectedItem: !!this.props.value ? this.props.items.find(item => item.id === this.props.value) : null,
			})
		}
		if(prevProps.value !== this.props.value){
			this.setState({
				selectedItem: !!this.props.value ? this.props.items.find(item => item.id === this.props.value) : null,
			})
		}
	}

	Select(item){
		this.setState({
			selectedItem: item,
			focus: false,
			search: ""
		});
		this.props.onChange(item.id);
	}

	InputBlur(){
		if(!this.state.search){
			setTimeout(() => {
			this.setState({
				focus: false, 
				search: ""
			})
		}, 300);
		}
	}

	Focus(e){
		this.setState({focus: true}, () => this.input.focus());
		if(this.props.onClick){
			this.props.onClick(e);
		}
	}

	KeyDown(e){
		let { hoverIndex } = this.state;
		const key = event.key || event.keyCode;
		if(key === 'ArrowDown' || key === 40){
			e.preventDefault();
			if(hoverIndex < (this.filteredItems.length - 1)){
				this.setState({hoverIndex: hoverIndex + 1});
			}
		}
		else if(key === 'ArrowUp' || key === 38){
			e.preventDefault();
			if(hoverIndex > 0){
				this.setState({hoverIndex: hoverIndex - 1});
			}
		}
		else if(key === 'Enter' || key === 13){
			e.preventDefault();
			this.Select(this.filteredItems[hoverIndex]);
		}
		else if(key === 'Escape' || key === 'Esc' || key === 27){
			e.preventDefault();
			this.setState({search: '', focus: false});
		}
	}

	SearchChangeHandler(e){
		const search = e.target.value.toLocaleLowerCase();
		this.setState({
			search
		})
	}

	get filteredItems(){
		return this.props.items.filter(item => item.name.toLocaleLowerCase().indexOf(this.state.search) > -1);
	}

	render(){
		return (
			<div className="searchable-select">
				{!this.state.focus ? (
					<button type="button" onClick={this.Focus}>{this.state.selectedItem ? this.state.selectedItem.name : this.props.prompt}</button>
					) : (
					<div>
						<input type="text" ref={ref => this.input=ref} onBlur={this.InputBlur} onChange={this.SearchChangeHandler} onKeyDown={this.KeyDown} />
						<ul>
							{this.filteredItems.length > 0 ? this.filteredItems.map((item, index) => (
								<SearchableSelectItem item={item} onClick={this.Select} hover={this.state.hoverIndex === index} key={item.id} />
							)) : (
								<li>
									Sonuç bulunamadı!
								</li>
							)}
						</ul>
					</div>
				)}
			</div>
		)
	}
}

const SearchableSelectItem = props => {
	const ClickHandler = () => {
		props.onClick(props.item);
	}
	return (
			<li onClick={ClickHandler} className={(props.hover ? 'hover' : '')}>
				{props.item.name}
			</li>
		)
} 

class CountryListSelect extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			focus: false,
			search: "",
			selectedItem: !!props.value ? this.props.items.find(item => item.alpha_2 === props.value) : null
		}
		this.Select = this.Select.bind(this);
		this.InputBlur = this.InputBlur.bind(this);
		this.Focus = this.Focus.bind(this);
		this.SearchChangeHandler = this.SearchChangeHandler.bind(this);
		// this.filteredItems = this.filteredItems.bind(this);
	}

	Select(item){
		this.setState({
			selectedItem: item,
			focus: false,
			search: ""
		});
		this.props.onChange(item.alpha_2);
	}

	InputBlur(){
		if(!this.state.search){
			setTimeout(() => {
			this.setState({
				focus: false, 
				search: ""
			})
		}, 300);
		}
	}

	Focus(){
		this.setState({focus: true}, () => this.input.focus())
	}

	SearchChangeHandler(e){
		this.setState({
			search: e.target.value.toLocaleLowerCase()
		})
	}

	get filteredItems(){
		return this.props.items.filter(item => item.keywords.indexOf(this.state.search) > -1);
	}

	render(){
		return (
			<div className={"searchable-select " + (this.props.className || '') }>
				{!this.state.focus ? (
					<button type="button" onClick={this.Focus}>{this.state.selectedItem ? (
						<React.Fragment>
							<img src={this.state.selectedItem.image} alt={this.state.selectedItem.alpha_2} />
							{' '}(+{this.state.selectedItem.country_code})
						</React.Fragment>
						) : this.props.prompt }</button>
					) : (
					<div>
						<input type="text" ref={ref => this.input=ref} onBlur={this.InputBlur} onChange={this.SearchChangeHandler} />
						<ul>
							{this.filteredItems.length > 0 ? this.filteredItems.map((item, index) => (
								<CountryListSelectItem item={item} onClick={this.Select} key={item.alpha_2} />
							)) : (
								<li>
									Sonuç bulunamadı!
								</li>
							)}
						</ul>
					</div>
				)}
			</div>
		)
	}
}

const CountryListSelectItem = props => {
	const ClickHandler = () => {
		props.onClick(props.item);
	}
	return (
			<li onClick={ClickHandler}>
				<img src={props.item.image} alt={props.alpha_2} />
				{' ' + props.item.name + ' '}(+{props.item.country_code})
			</li>
		)
} 

class PhoneInput extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			phone: ''
		}
		this.OnChange = this.OnChange.bind(this);
		this.caretPos = 0;
	}




	componentDidUpdate(prevProps, prevState){
		if(prevState.value != this.state.phone){
			helpers.SetCaretPosition(this.Ref, this.caretPos);
		}
	}

	OnChange(e){
		this.caretPos = helpers.GetCaretPosition(e.target);
		const phone = e.target.value.replace(/[^0-9.]/g, '').replace(/^0/, '').substr(0,10).replace(/([0-9]{0,3})([0-9]{0,3})([0-9]{0,2})([0-9]{0,2})$/g, "$1 $2 $3 $4").trim()
		this.caretPos += (phone.substr(0,this.caretPos).match(/[\s]{1}/g) || []).length - (e.target.value.substr(0,this.caretPos).match(/[\s]{1}/g) || []).length;
		this.setState({ 
			phone: phone || ''
		})
	}

	render(){
		return (
			<input type="text" ref={ref => this.Ref = ref} name={this.props.name} placeholder="5XX XXX XX XX" className="form-control rounded flex-fill" step="1" value={this.state.phone} onChange={this.OnChange} onPaste={this.OnChange} />
		)
	}
}


export { helpers };

import React from "react";
import PopUp from "../Common/PopUp";
import Currency from "../../packs_helper/scripts/Currency";
import PostData from "../../packs_helper/scripts/PostData";

class RefundableBalanceModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
		};

		this.SendChatMessage = this.SendChatMessage.bind(this);
	}

	SendChatMessage() {
		this.setState({ sending: true });
		PostData(this.props.sendMessagePath, {
				mesaj:
					"Satışlardan elde ettiğim gelir ile site içerisinde alışveriş yapmak istiyorum, lütfen kontrol eder misiniz?",
			}).then(response => {
				ShowPopup({
					title: 'Sistem',
					message: "Talebiniz alınmıştır. İşleminizle ilgili kısa süre içerisinde size dönüş yapılacaktır!"
				})
				// window.ToggleLiveChat();
				this.setState({ sending: false }, () => this.props.Hide());
			});
	}

	render() {
		return (
			<PopUp
				title="Bakiye Harcama Uyarısı"
				Hide={this.props.Hide}
			>
				<div style={{width: '720px'}}>
					<p>
						Ürün satışlarından elde ettiğiniz bakiyeniz ile açık artırmaya
						katılamazsınız. Sadece NAKİT ÇEKİM talebinde bulunabilirsiniz.
					</p>
					<hr />
					<p>
						Bakiyenizi nakit olarak çekmek için lütfen "Ödeme Talep Et" butonuna
						basın. Bakiyenizi ile alışveriş yapabilmek için lütfen "Harcama
						Yapmak İstiyorum" butonuna basın.
					</p>
				</div>
				<hr />
				<div style={{display: 'flex', gap: 'var(--spacer-2)', justifyContent: 'flex-end'}}>
					<a
						href={this.props.myBankAccountsPath}
						className="btn btn-jungle"
					>
						Ödeme Talep Et
					</a>
					{!this.state.sending ? (
						<button
							type="button"
							className="btn btn-blue"
							onClick={this.SendChatMessage}
						>
							Harcama Yapmak İstiyorum
						</button>
					) : (
						<button type="button" className="btn btn-blue" disabled>
							Bekleyiniz...
						</button>
					)}
				</div>
			</PopUp>
		);
	}
}

export default RefundableBalanceModal;
import React from "react";
import NotificationsCore from "./NotificationsCore";
// import NotificationBox from "./NotificationBox";
import FavoriteBox from "./FavoriteBox";
import RemoveButton from "./RemoveButton";
import PostData from "../../../packs_helper/scripts/PostData";
import Paginate from "../../Common/Paginate";
import Switcher from "../../Common/Switcher";
import ImageWithFallback from "../../Common/ImageWithFallback";
import PopUp from "../../Common/PopUp";
import DateFormat from "../../../packs_helper/scripts/DateFormat";

	const StatusIcon = (props) => {
		if (props.status === "approved") {
			return (
				<div className={(!props.children ? 'status-icon' : 'status-icon-with-content') + " status-icon-jungle"}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-approved"></use>
					</svg>
					{props.children}
				</div>
			);
		}
		else if (props.status === "rejected") {
			return (
				<div className={(!props.children ? 'status-icon' : 'status-icon-with-content') + " status-icon-red"}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-rejected"></use>
					</svg>
					{props.children}
				</div>
			);
		}
		else {
			return (
				<div className={(!props.children ? 'status-icon' : 'status-icon-with-content') + " status-icon-orange"}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-pending"></use>
					</svg>
					{props.children}
				</div>
			);
		}
	};

	const PriceHistoryModal = (props) => {
		return (
			<PopUp title={props.FavoriteDetail.modalTitle} Hide={props.OnClose}>
				<div>
					<small>
						Güncel Fiyat: {props.FavoriteDetail.current_cost} TL
					</small>
				</div>
				{props.FavoriteDetail.cost_history ? (
					Object.keys(props.FavoriteDetail.cost_history).map((price, key) => (
						<div>
							<small>
								{DateFormat(props.FavoriteDetail.cost_history[price].time)} -> {props.FavoriteDetail.cost_history[price].cost} TL
							</small>
						</div>
				))):(
					<div >
						<small>
							Bu ilana ait fiyat geçmişi yok.
						</small>
					</div>
				)}
			</PopUp>
		);
	};

class DesktopRenderer extends NotificationsCore {
	render() {
		const { notifications, notifications_count, notifications_page, activeTab, tabs, my_favorites, ShowHistory, historyDetails } = this.state;
		return (
			<div className="notifications-container grid-1">
				<Switcher
					buttons={this.tabs}
					onChange={this.ActivateTab}
					active={this.state.activeTab}
				/>
				{this.state.activeTab === "favorilerim" && (
					<React.Fragment>
						{this.state.my_favorites.length === 0 ? (
							<div className="alert">Favori ilan listesi.</div>
						) : (
							<div id="favorite_list" className="grid-6">
								{Object.keys(this.state.my_favorites).map((favorite, key) => (
									<FavoriteBox
										key={key}
										id={key} 
										product={this.state.my_favorites[key]}
										onPress={this.RemoveFavorite}
										showHistoryToggle={this.ShowHistory}
										showHistoryModal={this.state.showHistory}
									/>
							  	))}
								{this.state.showHistory && (
									<PriceHistoryModal
										OnClose={this.ShowHistory}
										FavoriteDetail={this.state.historyDetails}
									/>
								)}
							</div>
						)}
					</React.Fragment>
				)}
				{this.state.activeTab === "magazalarim" && (
					<React.Fragment>
						<div className="alert">Takip edilen mağazalar.</div>
					</React.Fragment>
				)}
				{this.state.activeTab === "bildirimlerim" && (
					<React.Fragment>
						{notifications.map((notification) => (
							<div className="notification-row" key={notification.id}>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-notifications"></use>
								</svg>
								<strong>#{notification.id}</strong>
								<span>-</span>
								<div dangerouslySetInnerHTML={{ __html: notification.message }} />
								<RemoveButton
									id={notification.id}
									onClick={this.RemoveNotification}
								/>
							</div>
						))}
						{notifications_count > 0 ? (
							<div className="notifications-footer">
								<button
									className="btn btn-red btn-xs"
									onClick={this.RemoveAllNotifications}
								>
									Tümünü Sil
								</button>
								<Paginate
									onChange={this.ChangePage}
									total_count={notifications_count}
									current_page={notifications_page}
									per_page={15}
								/>
							</div>
						) : (
							<div className="alert">Hiç bildiriminiz bulunmuyor!</div>
						)}
					</React.Fragment>
				)}
			</div>
		);
	}
}
export default DesktopRenderer;
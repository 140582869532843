import React from 'react';
import ImageFileInput from "../../../../Common/ImageFileInput";

class PictureSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            videoPopActive: 0,
            videoLink: ''
        }
    }

    render() {

        function getId(url) {
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            const match = url.match(regExp);
            return (match && match[2].length === 11)
                ? match[2]
                : null;
        }
        const videoLinkInputTest = document.getElementById('videoLinkInput');
        if(this.state.videoPopActive === 1 && videoLinkInputTest == null){
            ShowPopup({
                title: 'Youtube Video Linki Kaydet',
                message:
                    "<div><input placeholder='Youtube video linki ekleyin' type='text' id='videoLinkInput' /></div>" +
                    "<div id='video-warning-area' style='margin-top:1rem; display: none; color:red;'>* Video bulunamadı lütfen linki kontrol edin.</div>" +
                    "<div style='margin-top:1rem'>* Sadece Youtube videoları eklenebilir.</div>" +
                    "<div style='margin-top:1rem'>* Lütfen geçerli bir video linki ekleyin.</div>" +
                    //"<div style='margin-top:.5rem'><a>Video Nasıl Yüklenir?</a></div>" +
                    "<div><button class='btn btn-default' data-value='0' data-close>Vazgeç</button>" +
                    "<button id='video-save-btn' class='btn btn-jungle' disabled data-value='1' data-close>Video Ekle</button></div>",
            }).then(button => {
                if (button.dataset.value === "1") {
                    const videoId = getId(this.state.videoLink)
                    if(videoId !== null){
                        this.setState({videoPopActive: 0});
                        const videoValue = { currentTarget: { value: videoId } };
                        this.props.UpdateVideoLink(videoValue);
                    }
                }else{
                    this.setState({videoPopActive: 0});
                }
            });
            const videoLinkInput = document.getElementById('videoLinkInput');
            videoLinkInput.addEventListener('input', () => {
                const videoId = getId(videoLinkInput.value)
                if(videoId !== null){
                    document.getElementById('video-save-btn').removeAttribute('disabled')
                    document.getElementById('video-warning-area').style.display = 'none'
                }else{
                    document.getElementById('video-save-btn').setAttribute('disabled','disabled')
                    document.getElementById('video-warning-area').style.display = 'block'
                }
                this.setState({videoLink: videoLinkInput.value})
            });
        }

        return (
            <div className="wizard-grid-image">
                {this.props.uploadedFiles.length >= 1 ? (
                    <div className="wizard-grid-image-content">
                        <label className="upload-image-button">
                            <div>
                                <img
                                    src={URL.createObjectURL(this.props.uploadedFiles[0])}
                                    index={0}
                                    key={this.props.uploadedFiles[0].name}
                                />
                                <button className="btn" onClick={() => this.props.onRemove(0)}>
                                    <svg className="icon close-icon">
                                        <use xlinkHref="#gs-icon-user-panel-rejected"></use>
                                    </svg>
                                </button>
                            </div>
                        </label>
                    </div>
                ):(
                    <div className="wizard-grid-image-content">
                        <ImageFileInput
                            onChange={this.props.onChange}
                            onRemove={this.props.onRemove}
                            uploadedFiles={this.props.uploadedFiles}
                            max={this.props.max}
                            info='*Görsel "525x420" boyutlarında olmalıdır.'
                            noPreview={true}
                            title='Kapak Fotoğrafı'
                            location='wizard'
                        />
                    </div>
                )}

                {this.props.uploadedFiles.length >= 2 ? (
                    <div className="wizard-grid-image-content">
                        <label className="upload-image-button">
                            <div>
                                <img
                                    src={URL.createObjectURL(this.props.uploadedFiles[1])}
                                    index={1}
                                    key={this.props.uploadedFiles[1].name}
                                />
                                <button className="btn" onClick={() => this.props.onRemove(0)}>
                                    <svg className="icon close-icon">
                                        <use xlinkHref="#gs-icon-user-panel-rejected"></use>
                                    </svg>
                                </button>
                            </div>
                        </label>
                    </div>
                ):(
                    <div className="wizard-grid-image-content">
                        <ImageFileInput
                            onChange={this.props.onChange}
                            onRemove={this.props.onRemove}
                            uploadedFiles={this.props.uploadedFiles}
                            max={this.props.max}
                            info='*Görsel "525x420" boyutlarında olmalıdır.'
                            noPreview={true}
                            title='2. Fotoğraf'
                            location='wizard'
                        />
                    </div>
                )}

                {this.props.uploadedFiles.length >= 3 ? (
                    <div className="wizard-grid-image-content">
                        <label className="upload-image-button">
                            <div>
                                <img
                                    src={URL.createObjectURL(this.props.uploadedFiles[2])}
                                    index={2}
                                    key={this.props.uploadedFiles[2].name}
                                />
                                <button className="btn" onClick={() => this.props.onRemove(0)}>
                                    <svg className="icon close-icon">
                                        <use xlinkHref="#gs-icon-user-panel-rejected"></use>
                                    </svg>
                                </button>
                            </div>
                        </label>
                    </div>
                ):(
                    <div className="wizard-grid-image-content">
                        <ImageFileInput
                            onChange={this.props.onChange}
                            onRemove={this.props.onRemove}
                            uploadedFiles={this.props.uploadedFiles}
                            max={this.props.max}
                            info='*Görsel "525x420" boyutlarında olmalıdır.'
                            noPreview={true}
                            title='3. Fotoğraf'
                            location='wizard'
                        />
                    </div>
                )}
                {this.props.videoLink === '' ? (
                    <div
                        className="wizard-grid-image-content"
                        onClick={()=> this.setState({videoPopActive: 1})}
                    >
                        <label className="upload-image-button">
                            <div>
                                <svg className="icon">
                                    <use xlinkHref="#gs-icon-user-panel-no-image"></use>
                                </svg>
                                <strong style={{color:'var(--blue-color)'}}>Video Ekle</strong><small>*Link olarak eklenebilir.</small>
                            </div>
                        </label>
                    </div>
                ):(
                    <div className="wizard-grid-image-content">
                        <label className="upload-image-button">
                            <button className="btn" onClick={() => this.props.UpdateVideoLink({ currentTarget: { value: '' } })}>
                                <svg className="icon close-icon">
                                    <use xlinkHref="#gs-icon-user-panel-rejected"></use>
                                </svg>
                            </button>
                            <div>
                                <iframe
                                    style={{ width: '100%'}}
                                    src={"https://www.youtube.com/embed/" + this.props.videoLink}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </label>

                    </div>
                )}
            </div>
        );
    }
}

export default PictureSelect;
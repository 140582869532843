import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class StorePageCommentsCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			comments: [],
			current_page: 1,
			total_entries: 0,
		};

		this.ChangePage = this.ChangePage.bind(this);
		this.GetData = this.GetData.bind(this);
	}

	componentDidMount() {
		this.GetData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.kind !== this.props.kind || prevProps.rating !== this.props.rating) {
			this.setState({ products: [] });
			this.GetData();
		}
	}

	ChangePage(current_page) {
		this.setState({ current_page }, this.GetData);
	}

	GetData() {
		PostData("/get_store_details", {
			page: this.state.current_page,
			kind: this.props.kind,
			slug: this.props.storeSlug,
			rating: this.props.rating
		})
			.then((response) => {


				this.setState({ ...response });
				// this.setState({
				// });
			})
			.catch((err) => {


			});
	}

}

export default StorePageCommentsCore;

import React from "react";
import DecimalFieldTag from "../Common/DecimalFieldTag";


const HQuantityField = props => {
	const [quantity, SetQuantity] = React.useState(props.value || 1);
	const step = props.step || 1;
	const min = props.min || step;
	const max = props.max || 500;
	const OnChange = (typeof props.onChange === 'string') ? eval(props.onChange) : props.onChange;


	React.useEffect(() => {
		if(OnChange && props.value != quantity){
			OnChange(quantity);
		}
	}, [quantity]);

	const OnMinusClick = () => {
		let newQuantity = quantity - step;
		if(newQuantity < min){
			newQuantity = min;
		}
		SetQuantity(newQuantity);
	}

	const OnPlusClick = () => {
		let newQuantity = quantity + step;
		if(newQuantity > max){
			newQuantity = max;
		}
		SetQuantity(newQuantity);
	}

	const OnQuantityChange = newQuantity => {
		if(newQuantity < min){
			newQuantity = min;
		}
		else if(newQuantity > max){
			newQuantity = max;
		}
		SetQuantity(newQuantity);
	}

	return(
		<div className="quantity-field">
			<label>Adet:</label>
			<div className="quantity-field-input">
				<button type="button" onClick={OnMinusClick}>-</button>
				<DecimalFieldTag
					{...props}
					onChange={OnQuantityChange}
					name={props.name}
					value={quantity}
					onBlur={OnQuantityChange}
				/>
				<button type="button" onClick={OnPlusClick}>+</button>
			</div>
		</div>
	)
}

export default HQuantityField;

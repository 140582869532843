import React from "react";
import ImageWithFallback from "../../Common/ImageWithFallback";
import { StatusIcon } from "./MyItemBox";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import DateDiff from "../../../packs_helper/scripts/DateDiff";
import PostData from "../../../packs_helper/scripts/PostData";
import Decimal from "../../../packs_helper/scripts/Decimal";
import PopUp from "../../Common/PopUp";
import DecimalFieldTag from "../../Common/DecimalFieldTag";
import CountDown from "./CountDown";
import SelectBox from "../../Common/SelectBox";
import GlobalVar from "../../GlobalVar";

const statusTexts = {
	approved: "Yayınlandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
	cancelled: "Reddedildi",
};

const ItemDetail = (props) => {
	const [popup, SetPopup] = React.useState(false);
	const [boostSaving, SetBoostSaving] = React.useState(false);
	const [boostFormVisible, ToggleBoostFormVisibility] = React.useState(false);
	let feeConfirm = false;

	const currentDateTime = new Date();
	const createDate =  new Date(props.createTime).getFullYear() +  new Date(props.createTime).getMonth() + new Date(props.createTime).getDate();
	const today = new Date().getFullYear() +  new Date().getMonth() + new Date().getDate();
	const Delete = () => {
		let message = "Ürün silinecektir!";
		if ( createDate === today && !props.haveFee){
			message = message + "<br /><br />Not: Günlük ücretsiz ilan hakkınız 2'dir. " +
				"Bugün eklediğiniz bir ilanı siliyorsunuz. " +
				"Paketiniz yok ise bu işlem ücretsiz ilan hakkınızdan düşecektir.";
		}
		if (props.item_type === 7 && parseInt(props.unsales_count) > 0) {
			// Steam Random Key ilanı kaldırırken çıkan uyarı mesajı
			message =
				'Ürün silinecektir!<br><br>' +
				'<strong class="text-danger">Dikkat!</strong><br>' +
				'<div class="mt-1">' +
				'Ürününüze bağlı satılmamış dijital kodlar bulunmaktadır. ' +
				'Ürününüzle birlikte bu kodlar da sistemden kaldırılacaktır. ' +
				'Kodlarınızı kaybetmemek için silmeyi onaylamadan önce not aldığınızdan emin olunuz!' +
				'</div>';
		}

		ShowPopup({
			title: "Uyarı",
			message:
				message +
				'<button class="btn btn-default" data-close value="0">Vazgeç</button>' +
				'<button class="btn btn-jungle" data-close value="1">Onayla</button>',
		}).then((button) => {
			if (button.value == 1) {
				PostData("/item-ilani-sil", { id: props.id }).then((response) => {
					if (response.state) {
						props.GetData();
					}
				});
			}
		});
	};

	const UpdateClickHandler = () => {
		SetPopup(true);
	};

	const Update = (e, confirm) => {
		e.preventDefault();
		let postUrl = "/urun-guncelle";
		if(props.type === "purchase") postUrl = "/item-alim-ilani-guncelle";

		const formData = new FormData(e.target);
		formData.append("id", props.id);
		if (confirm) {
			formData.append("confirm_fee", 1);
		}

		PostData(postUrl, formData).then((response) => {
			if (response.state) {
				SetPopup(false);
				props.GetData(props.currentPage);
			} else if (response.fee) {
				ShowPopup({
					title: "Onay Gerekiyor",
					message:
						response.fee +
						'<button class="btn btn-default" value="0" data-close>Vazgeç</button>' +
						'<button class="btn btn-jungle" value="1" data-close>Onayla</button>',
				}).then((button) => {
					if (button.value == 1) {
						Update(e, true);
					}
				});
			}
		});
	};

	const ShowDigitalCodes = () => {
		if (props.item_type !== 7) {
			return false;
		}

		PostData("/dijital-kodlarim", { id: props.id }).then((response) => {
			const message = '<div class="digital-code-list">' +
				response.map((key, index) => `<pre>${key}</pre>`).join('') + '</div>';
			ShowPopup({
				title: "Ürüne ait satılmamış dijital kodlar",
				message,
			});
		});
	};

	const BoostFormActivate = () => {
		props.GetStorePackage();
		ToggleBoostFormVisibility(true);
	}

	const ConfirmBoost = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const boost_id = formData.get("boost_id");
		if (!boost_id) {
			ShowPopup({
				title: "Hata",
				message: "Boost seçimi yapmadınız!",
			});
			return false;
		}
		const boost = props.boosts.find((boost) => boost.id == boost_id);
		if (!boost) {
			ShowPopup({
				title: "Hata",
				message:
					"İşlem sırasında bir hata oluştu! " +
					"Lütfen sayfayı yenileyerek tekrar deneyin. " +
					"Sorun devam ederse canlı destek ekibimizle paylaşarak yardım alabilirsiniz.",
			});
			return false;
		}

		formData.append("product_id", props.id);
		if (feeConfirm) {
			formData.append("pre_provision", "false");
		} else {
			formData.append("pre_provision", "true");
		}

		PostData("/boost", formData).then((response) => {
			if (response.state) {
				ToggleBoostFormVisibility(false);
				props.GetData();
			} else if(response.waiting_response) {
				ShowPopup({
					title: "İlanı Boostlamayı Onayla",
					message: response.boost_message +
						' <button class="btn btn-default" data-close value="0">Vazgeç</button>' +
						'<button class="btn btn-jungle" data-close value="1">Onayla</button>',
				}).then((button) => {
					if (button.value == 1) {
						SetBoostSaving(true);
						feeConfirm = true;
						ConfirmBoost(e);
					}
				});
			}else{
				SetBoostSaving(false);
			}
		});
	};

	const HidePopup = () => {
		SetPopup(false);
	};

	let link;
	if(props.item_type === 1){
		link = props.category_slug + '/' + props.steam_slug + '?id=' + props.id;
	}
	else{
		link = props.category_slug + '/' + props.product_slug;
	}
	return (
		<div className="item-detail">
			<a href={link} target="_blank">
				{props.mobile ? (
					<ImageWithFallback
						className="item-detail-image"
						src={props.image.includes(".jpg") ? props.image.replace('-thumb.', '.') : (props.image + ".jpg").replace('-thumb.', '.')}
					/>
				) :
					props.image ? (
						<ImageWithFallback
							className="item-detail-image"
							src={props.image.includes(".jpg") ? props.image : props.image.includes("steamcommunity") ?  props.image : props.image + ".jpg"}
						/>
					):(
						<ImageWithFallback
							className="item-detail-image"
							src="no-image.jpg"
						/>
					)
				}
			</a>
			<div className="item-detail-body">
				<div className="item-detail-body-left">
					<div>
						<label>Tarih:</label>
						<strong>{DateFormat(props.created_at)}</strong>
					</div>
					{props.asset_id && (
						<>
							<div>
								<label>Asset ID:</label>
								<strong>{props.asset_id}</strong>
							</div>
							<div>
								<label>
									<a href={props.trade_url} target="_blank">Trade URL</a>
								</label>
							</div>
						</>
					)}
					<div>
						<label>Stok:</label>
						<strong>{props.unsales_count}</strong>
					</div>
				</div>
				{props.item_status == "approved" && (
					<div className="item-detail-body-center">
						İlanınız Yayınlanmıştır.
					</div>
				)}
				{(props.item_status == "rejected" ||
					props.item_status == "cancelled") && (
					<div className="item-detail-body-center">
						İlanınız satış kurallarına uygun görülmediği gerekçesiyle
						tarafımızca yayına alınmamıştır.
					</div>
				)}
				{props.item_status == "pending" && (
					<div className="item-detail-body-center">
						İlanınız kontrol edildikten sonra yayınlanacaktır.
					</div>
				)}
				<div className="item-detail-body-right">
					Fiyat: <strong>{Currency(props.cost)} ₺</strong>
				</div>

				<div className="item-detail-body-status">
					{props.item_status === 'approved' && parseInt(props.unsales_count) === 0 ? (
						<StatusIcon status={props.item_status}>
							Satıldı
						</StatusIcon>
					) : (
						<StatusIcon status={props.item_status}>
							{statusTexts[props.item_status]}
						</StatusIcon>
					)}

					{props.item_type === 7 && parseInt(props.unsales_count) > 0 && (
						<button className="btn btn-blue btn-sm" onClick={ShowDigitalCodes}>
							Keyleri Göster
						</button>
					)}

					{parseInt(props.unsales_count) > 0 &&
						(DateDiff(props.boost_end_datetime) < 0 ? (
							<span className="btn btn-sm btn-orange">
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-flash"></use>
								</svg>
								<span className="boost-desc">
									Boost'un bitmesine kalan süre:
									<div className="ml-1 text-monospace">
										<CountDown
											endDateTime={props.boost_end_datetime}
											onComplete={props.GetData}
										/>
									</div>
								</span>
							</span>
						) : (
							props.item_status == "approved" &&
							parseInt(props.unsales_count) > 0 &&
							props.boosts.length > 0 &&
							(props.item_type === 7 ? (
								<span className="btn btn-sm btn-default">
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-flash"></use>
									</svg>
									<span className="boost-desc">
										Dijital kodlarda kullanılamaz.
									</span>
								</span>
							) : props.user.confirmed ?
								props.type !== "purchase" ? (
									<button
										type="button"
										className="btn btn-sm btn-jungle"
										onClick={() => BoostFormActivate()}
									>
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-flash"></use>
										</svg>
										Boostla
									</button>
								):(
									<button
										type="button"
										className="btn btn-sm btn-jungle"
										disabled
									>
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-flash"></use>
										</svg>
										Boostlanamaz
									</button>
								)
								: (
									<span className="btn btn-sm btn-default">
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-flash"></use>
									</svg>
									<span className="boost-desc">
										Sadece onaylı mağazalar ilanlarını öne çıkarabilir.
									</span>
								</span>
								))
						))}
				</div>
			</div>
			<div className="item-detail-buttons">
				{parseInt(props.unsales_count) === 0 ? (
					<React.Fragment>
						<button
							className="btn btn-sm btn-blue fixed-width-button"
							onClick={UpdateClickHandler}
						>
							İlanı Güncelle
						</button>
						{props.item_type === 7 ? (
							<button className="btn btn-blue btn-sm" onClick={ShowDigitalCodes}>
								Keyleri Göster
							</button>
						):(
							<button className="btn btn-default" disabled={true}>
								Stok Yok
							</button>
						)}
						{props.item_status === 'approved' && [1, 7].indexOf(props.item_type) === -1 && (
							<button
								className="btn btn-sm btn-jungle"
								onClick={UpdateClickHandler}
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-reload"></use>
								</svg>
								Yeniden Sat
							</button>
						)}
					</React.Fragment>
				) : (
					<React.Fragment>
						<button
							className="btn btn-sm btn-blue fixed-width-button"
							onClick={UpdateClickHandler}
							disabled={props.item_status !== "approved"}
						>
							İlanı Güncelle
						</button>
						<button
							className="btn btn-sm btn-red fixed-width-button"
							onClick={Delete}
							disabled={props.item_status !== "approved"}
						>
							İlanı Yayından Kaldır
						</button>
					</React.Fragment>
				)}
			</div>

			{popup && (
				<PopUp
					title={
						parseInt(props.unsales_count) > 0
							? "İlanı Güncelle"
							: "İlanı Yeniden Satışa Çıkar"
					}
					Hide={HidePopup}
					className="settings-popup"
				>
					<form
						action="#"
						onSubmit={Update}
						className="grid-1"
						autoComplete="off"
					>
						{(([1, 7, 2].indexOf(props.item_type) === -1 &&
								props.cid !== 20127) ||
							props.cid === 21352) && (
							<div>
								<label>
									Başlık{" "}
									<small className="text-red">
										(*Başlık bilgi amaçlıdır, değiştirilemez.)
									</small>
								</label>
								<div className="input">{props.name}</div>
							</div>
						)}
						<textarea
							name="description"
							placeholder="Açıklama"
							defaultValue={props.description}
							rows="6"
							maxLength={1200}
							style={{
								display:
									props.item_status === "approved" &&
									parseInt(props.unsales_count) === 0
										? " none"
										: "",
							}}
						/>

						<div className="input-with-label">
							<label>Fiyat:</label>
							<DecimalFieldTag name="cost" value={Decimal(props.cost)} />
						</div>
						<div>
							<SelectBox
								xlinkHref="#gs-icon-user-panel-caret-down"
								prompt={"Teslimat Süresi Seçin"}
								options={GlobalVar.DELIVERY_TIMES.map((property) => ({
									id: property,
									name: 'Teslimat ' + property + ' Saat İçerisinde Gerçekleşir.',
								}))}
								selected={props.delivery_time ? props.delivery_time : 1}
								className="grid-2x"
								maxHeight="11rem"
								noDark={true}
								name="delivery_time"
							/>
						</div>
						{!!props.keys && (
							<React.Fragment>
								<strong>Satılmamış dijital kodlarınız aşağıdadır.</strong>
								<div style={{ maxHeight: "27vh", overflow: "auto" }}>
									{props.keys.map((key, index) => (
										<div key={index}>
											<pre>{key}</pre>
										</div>
									))}
								</div>
							</React.Fragment>
						)}

						{props.item_type !== 7 && props.item_type !== 1 && (
							<React.Fragment>
								<div className="input-with-label">
									<label>Stok Miktarı:</label>
									{/* boost item_type: 6, hesap-satisi item_type: 3*/}
									{props.item_status === "approved" &&
									parseInt(props.unsales_count) === 0 ? (
										<DecimalFieldTag
											name="quantity"
											placeholder="Adet"
											max={[3, 6].indexOf(props.item_type) > -1 ? 5 : 9999999}
										/>
									) : (
										<div className="input">
											{Decimal(props.unsales_count, -1)}
										</div>
									)}
								</div>
							</React.Fragment>
						)}
						<div className="grid-2">
							<button
								className="btn btn-default"
								type="button"
								onClick={HidePopup}
							>
								Vazgeç
							</button>
							<button className="btn btn-blue" type="submit">
								Güncelle
							</button>
						</div>
					</form>
				</PopUp>
			)}

			{boostFormVisible && (
				<PopUp
					Hide={() => ToggleBoostFormVisibility(false)}
					title="İlanı Boostla"
				>
					<form action="#" onSubmit={ConfirmBoost}>
						<h4>Öne çıkarmak için seçim yapınız.</h4>
						<div>Öncelikle varsa paket haklarınızdan düşer.</div>
						<hr />
						{props.boosts.map((boost) => (
							<React.Fragment key={boost.id}>
								<div className="radio">
									<input
										type="radio"
										name="boost_id"
										id={`boost-${boost.id}`}
										value={boost.id}
									/>
									<label className='multi-row' htmlFor={`boost-${boost.id}`}>
										<div>{boost.name} {props.boost_discount ?
										(
											<span>(<del>{Currency(boost.cost)} ₺</del> / <span className='price'>{Currency(boost.cost * (100 - props.boost_discount) / 100)} ₺</span>)</span>
										):(
											<span>({Currency(boost.cost)} ₺)</span>
										)}
										<div>{boost.remaining > -1 ? "Kalan Paket Hakkı: " + boost.remaining : ""}</div>
										</div>
									</label>
								</div>

							</React.Fragment>
						))}
						<hr />
						<div className="text-right">
							<button
								type="button"
								className="btn btn-default"
								onClick={() => ToggleBoostFormVisibility(false)}
							>
								Vazgeç
							</button>{" "}
							<button
								type="submit"
								className="btn btn-jungle"
								disabled={boostSaving}
							>
								İlanı Boostla
							</button>
						</div>
					</form>
				</PopUp>
			)}
		</div>
	);
};

export default ItemDetail;

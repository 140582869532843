function AdTypeValidate(validateProps) {
    let resultMessage = "";
    let resultTarget = "";
    let resultState = false;

    const AdTypeMessage = "İlan tipini seçmelisiniz.";

    if (validateProps.adSell !== null)
        resultState = true;

    if(resultState === false){
        resultMessage = AdTypeMessage
    }

    return {
        "state": resultState,
        "message": resultMessage,
        "target": resultTarget
    };
}

export default AdTypeValidate;
import React from "react";

const StringFieldTag = (props) => {
	const [value, SetValue] = React.useState(props.value || props.defaultValue || "");

	React.useEffect(() => {
		SetValue(props.value || props.defaultValue || "")
  },[props.value, props.defaultValue]);

	const OnChange = e => {
		// debugger;
		let newValue = e.currentTarget.value.match(/[a-zA-Z\u00C0-\u02B8 ]+/gm);
		if(!newValue){
			newValue = '';
		}
		else{
			newValue = newValue.join('');
		}

		SetValue(newValue);
		if(props.onChange){
			props.onChange(newValue);
		}
	}

	return (
		<input
			{...props}
			type="text"
			name={props.name}
			maxLength={props.maxLength || 65000}
			value={value}
			onChange={OnChange}
			// onPaste={(e) => SetValue(JustFloat(e.target.value) || "")}
		/>
	);
};

export default StringFieldTag;
import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

// import productDetailImage from "../../../assets_v3/images/product-detail.jpg";
class MyOrdersCore extends React.Component {
	constructor(props) {
		super(props);

		const { active_tab, order } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);

		this.activeBox = parseInt(order) || null;

		this.state = {
			current_page: 1,
			order_count: 0,
			order_details: [],
			activeBox: null,
			activeTab: active_tab === undefined ? '' : active_tab,
		};

		this.GetData = this.GetData.bind(this);
		this.GetDetail = this.GetDetail.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		// this.ShowInvoice = this.ShowInvoice.bind(this);
		// this.DownloadExcel = this.DownloadExcel.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
		this.ActivateTab = this.ActivateTab.bind(this);


	}

	componentDidMount() {
		this.GetData(this.state.current_page);
	}

	BoxOpen(activeBox){
		this.setState({
			activeBox
		})
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetData(page) {
		PostData("/siparislerim", {
			page,
			filter: this.state.activeTab,
		})
			.then((response) => {
				if(response.order_details.length){
					this.setState({
						order_count: response.order_count,
						order_details: response.order_details.map((order_detail) => ({
							...order_detail,
							content: {
								keys: [],
							},
						})),
						activeBox: this.activeBox || response.order_details[0].id
					}, () => {
						this.activeBox = null;
					});
				}
			})
			.catch((err) => {


			});
	}

	GetDetail(order_detail_id) {
		PostData("/siparis-detayi", { order_detail_id })
			.then((response) => {
				const order_details = this.state.order_details;
				const index = order_details.findIndex(
					(order_detail) => order_detail.id == order_detail_id
				);
				order_details[index].content = response;

				this.setState({ order_details }, () =>
					order_details[index].ref.UpdateMaxHeight()
				);
			})
			.catch((err) => {


			});
	}

	ActivateTab(activeTab) {
		this.props.ChangeRoute(`/siparislerim?active_tab=${activeTab}`);
		this.setState({ activeTab, order_details: [], order_count: 0, current_page: 1 }, () => this.GetData(1));
	}

	// ShowInvoice(e) {
	// 	e.preventDefault();
	// 	const id = e.currentTarget.dataset.id;

	// 	const form = document.createElement("FORM");
	// 	form.action = "/fatura?id=" + id;
	// 	form.target = "_blank";
	// 	form.method = "post";
	// 	form.style.display = "none";

	// 	const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
	// 	const hiddenInput = document.createElement("INPUT");
	// 	hiddenInput.type = "hidden";
	// 	hiddenInput.name = "authenticity_token";
	// 	hiddenInput.value = csrfToken;
	// 	form.appendChild(hiddenInput);

	// 	document.body.appendChild(form);
	// 	form.submit();
	// }

	// DownloadExcel(e) {
	// 	const id = e.currentTarget.dataset.id;

	// 	const form = document.createElement("FORM");
	// 	form.action = "/kodlar.xls?id=" + id;
	// 	form.target = "_blank";
	// 	form.method = "post";
	// 	form.style.display = "none";

	// 	const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
	// 	const hiddenInput = document.createElement("INPUT");
	// 	hiddenInput.type = "hidden";
	// 	hiddenInput.name = "authenticity_token";
	// 	hiddenInput.value = csrfToken;
	// 	form.appendChild(hiddenInput);

	// 	document.body.appendChild(form);
	// 	form.submit();
	// }
}

export default MyOrdersCore;

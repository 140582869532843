import React from "react";
import PopUp from "../../Common/PopUp";
import Currency from "../../../packs_helper/scripts/Currency";
import Copy from "../../../packs_helper/scripts/Copy";

class CartSharePopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sending: false,
		};

		this.inputRef = React.createRef();
		this.Copy = this.Copy.bind(this);
	}

	Copy(e){
		Copy(e);
		this.inputRef.current.focus();
		this.inputRef.current.setSelectionRange(0,this.inputRef.current.value.length);
	}

	render() {
		return (
			<PopUp
				title="Referans Bağlantısı"
				Hide={this.props.Hide}
			>
				<div style={{width: '720px'}}>
					<p>
						Ürün adı bağlantısını paylaş! Arkadaşların her satın aldığında <strong>{Currency(this.props.bonus * this.props.total / 100 / this.props.quantity)} TL</strong> bonus kazan!
					</p>
					<div style={{display: 'flex'}}>
						<input type="text" id="cart-share-copy" readOnly={true} value={this.props.code} ref={this.inputRef} />
						<button className="btn btn-default" onClick={this.Copy} data-copy={this.props.code}>Kopyala</button>
					</div>
				</div>
				<hr />
				<div className="gs-popup-buttons-container">
					<button onClick={this.props.Hide}>Kapat</button>
				</div>
			</PopUp>
		);
	}
}

export default CartSharePopup;
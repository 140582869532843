import React from "react";
/*
countType
	store_block: Cezalı Mağaza
*/
class CountDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentDateTime: new Date(),
		};
		this.Calculate = this.Calculate.bind(this);
	}

	componentDidMount(){
		setInterval(() => {
			this.setState({
				currentDateTime: new Date()
			})
		})
	}

	Calculate() {
		const endDateTime = new Date(this.props.endDateTime);
		const leftTime = Math.floor(
			(endDateTime - this.state.currentDateTime) / 1000
		);
		if (leftTime >= 0) {
			const days = Math.floor((leftTime / 3600) / 24)
				.toString();
			const hours = Math.floor((leftTime / 3600) - (parseInt(days) * 24))
				.toString();
			const minutes = Math.floor(((leftTime / 3600) * 60) - (((parseInt(hours) * 60) + ((parseInt(days) * 24)) * 60)))
				.toString()
				.padStart(2, "0");
			const seconds = Math.floor(leftTime % 60)
				.toString()
				.padStart(2, "0");
			return [days, hours, minutes, seconds];
		} else {
			if (this.props.onComplete) {
				this.props.onComplete();
			}
			return ["00", "00", "00", "00"];
		}
	}
	render() {
		let [days, hours, minutes, seconds] = this.Calculate();
		let sequenceTime = days + ":" + hours + ":" + minutes + ":" + seconds;
		let countIcon = null;
		switch(this.props.countType){
			case "textNotation":
				if(days === "00" || days == 0){
					sequenceTime = hours + " Saat " + minutes + " Dakika " + seconds + " Saniye";
					if(hours === "00" || hours == 0){
						sequenceTime = minutes + " Dakika " + seconds + " Saniye";
						if(minutes === "00"){
							sequenceTime = seconds + " Saniye";
						}
					}
				}else{
					sequenceTime = days + " Gün " + hours + " Saat " + minutes + " Dakika " + seconds + " Saniye";
				}
				
			break;
			default:
				sequenceTime = days + ":" + hours + ":" + minutes + ":" + seconds;
			break;
		}
		if(this.props.countIcon) countIcon = '#gs-icon-user-panel-' + this.props.countIcon;

		return (
			<div className="countdown">
				<svg className="icon">
					<use xlinkHref={countIcon}></use>
				</svg>
				<span>{sequenceTime}</span>
			</div>
		);
	}
}

export default CountDown;
import React from "react";
import DateDiff from "../../../packs_helper/scripts/DateDiff";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import AvatarHandler from "../../Common/AvatarHandler";
import ImageWithFallback from "../../Common/ImageWithFallback";
import GlobalVar from "../../GlobalVar";

const UserListItem = (props) => {
	const isOnline =
		DateDiff(props.user.last_seen || "1970-01-01") < 600000;
	const OnClick = () => props.onClick(props.user);
	let avatar = 1;
	let approve_avatar = false;
	props.user.s_avatar !== null ? avatar = props.user.s_avatar : avatar = props.user.avatar;
	if(props.user.s_avatar !== null) approve_avatar = true;
	return (
		<div
			className={
				"user-list-item" +
				(props.selected ? " active" : "") +
				(!props.user.is_read ? " not-read" : "")
			}
			onClick={OnClick}
		>
			<ImageWithFallback
				src={AvatarHandler(avatar, approve_avatar)}
			/>
			<div className="content">
				<div className="sender">
					{props.user.user_code !== GlobalVar.GAMESATIS_MESSAGE_USERCODE ? props.user.user_name: "GameSatış "}
					<i
						className={
							"user-status user-status-" + (props.user.user_code === GlobalVar.GAMESATIS_MESSAGE_USERCODE || isOnline ? "online" : "offline")
						}
						title={props.user.user_code === GlobalVar.GAMESATIS_MESSAGE_USERCODE || isOnline ? "Online" : "Offline"}
					/>
					{props.order && (
						<div
							className={
								"inprofile-status wait" +
								(props.user.approve_seller ? " text-green" : "")
							}
							title={
								props.user.approve_seller
									? "Alıcı Onayı Bekleniyor"
									: "Satıcı Onayı Bekleniyor"
							}
						/>
					)}
				</div>
				<div className="info-row">
					{props.user.order_detail_product_name && (
						<div className="product-info">
							{props.user.order_detail_product_name}
						</div>
					)}
					<div className="last-message"><span><code dangerouslySetInnerHTML={{__html: props.user.last_message}}></code></span></div>
					{props.user.updated_at && (
						<div
							className="date"
							title={DateFormat(props.user.updated_at, "%d/%m/%Y - %H:%M")}
						>
							{DateFormat(props.user.updated_at, "%d/%m/%Y")}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserListItem;
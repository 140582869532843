import React from "react";

class SelectBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: props.selected,
			isOpen: false,
			filteredOptions: [],
			hoverIndex: -1,
			className: null,
			optionsHeight: 0,
		};

		this.ToggleSelect = this.ToggleSelect.bind(this);
		this.ToggleSelectHandler = this.ToggleSelectHandler.bind(this);
		this.SetSelected = this.SetSelected.bind(this);
		this.RemoveSelected = this.RemoveSelected.bind(this);

		this.FilterOptions = this.FilterOptions.bind(this);
		this.KeyUp = this.KeyUp.bind(this);
		this.OptionClickHandler = this.OptionClickHandler.bind(this);
		this.OptionListener = this.OptionListener.bind(this);
		this.ClickListener = this.ClickListener.bind(this);
		this.KeyListener = this.KeyListener.bind(this);

		this.optionsRef = React.createRef();
		this.selectRef = React.createRef();

		this.gsPopup = document.createElement("DIV");
		this.gsPopup.id = "p" + (Math.random() * 0xffffffffffff * 100000000000).toString(16);
		this.gsPopup.className = "gs-popup";
		this.gsPopup.tabIndex = 0;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.options !== this.props.options) {
			this.setState({
				filteredOptions: this.props.options,
			});
		}
		if (prevProps.selected !== this.props.selected) {
			this.setState({
				selected: this.props.selected,
			});
		}
	}

	componentWillUnmount(){
		document.removeEventListener("scroll", this.OptionListener);
		document.removeEventListener("click", this.ClickListener);
		document.removeEventListener("keyup", this.KeyListener);
	}

	OptionListener() {
		this.setState({
			className: "",
			optionsHeight: 'auto',
		});
	}

	ClickListener(e){
		e.preventDefault();
		if(e.path.findIndex(x => x === this.selectRef.current) === -1){
			this.ToggleSelect(false);
		}
	}

	KeyListener(e){
		e.preventDefault();
		const key = e.keyCode;
		if (key === 27) {
			this.ToggleSelect(false);
		}
	}

	ToggleSelectHandler(){
		this.ToggleSelect(!this.state.isOpen);
	}

	ToggleSelect(state) {
		!this.props.noDark &&
			state ? document.body.append(this.gsPopup) : this.gsPopup.remove()
		this.setState({
				isOpen: state,
				filteredOptions: this.props.options,
				hoverIndex: -1,
			},
			() => {
				if (this.state.isOpen) {
					this.OptionListener();
					document.addEventListener("scroll", this.OptionListener);
					document.addEventListener("click", this.ClickListener);
					document.addEventListener("keyup", this.KeyListener);
				} else {
					document.removeEventListener("scroll", this.OptionListener);
					document.removeEventListener("click", this.ClickListener);
					document.removeEventListener("keyup", this.KeyListener);
				}
			}
		);
	}

	OptionClickHandler(e) {
		const selectedOption = e.currentTarget;
		this.SetSelected(selectedOption.value);
	}

	SetSelected(selected) {
		if(this.props.onChange){
			this.props.onChange(selected);
		}
		this.setState({
			selected,
		});
		this.ToggleSelect(false);
	}

	RemoveSelected() {
		this.setState({
			selected: null,
		});
		this.ToggleSelect(false);
	}

	KeyUp(e) {
		const key = e.keyCode;

		if (key === 13) {
			this.SetSelected(this.state.filteredOptions[this.state.hoverIndex].id);
		} else if (key === 27) {
			e.preventDefault();
			this.ToggleSelect(false);
		} else if (key === 38) {
			e.preventDefault();
			let hoverIndex = this.state.hoverIndex - 1;
			if (hoverIndex <= 0) {
				hoverIndex = 0;
			}
			this.setState({
				hoverIndex,
			});
		} else if (key === 40) {
			e.preventDefault();
			let hoverIndex = this.state.hoverIndex + 1;
			if (hoverIndex >= this.state.filteredOptions.length - 1) {
				hoverIndex = this.state.filteredOptions.length - 1;
			}
			this.setState({
				hoverIndex,
			});
		}
	}

	FilterOptions(e) {
		const value = e.currentTarget.value.toLocaleLowerCase();
		const filteredOptions = this.props.options.filter(
			(option) => (React.isValidElement(option.name) ? option.name.props.title.toLocaleLowerCase('tr').indexOf(value) : option.name.toLocaleLowerCase('tr').indexOf(value)) > -1
		);
		this.setState({
			filteredOptions,
			hoverIndex: value.length ? 0 : -1,
		}, this.OptionListener);
	}

	render() {
		let selectedItem;
		if (this.state.selected) {
			selectedItem = this.props.options.find(
				(option) => option.id == this.state.selected
			);
		} else {
			selectedItem = {
				id: '',
				name: this.props.location === 'wizard' ? 'Seçiniz' : this.props.prompt || "Seçiniz",
			};
		}
		return (
			<div
				className={[
					parseInt(this.state.selected) > -1 ? this.state.isOpen ? "select":"select selected" : 'select',
					this.props.className,
					this.state.className,
					this.state.isOpen && "open",
				]
					.filter((x) => x)
					.join(" ")}
				ref={this.selectRef}
				id={this.props.id && this.props.id}
			>
				{this.props.name && <input type="hidden" name={this.props.name} value={selectedItem ? selectedItem.id : ''} />}
				{!this.state.isOpen && selectedItem && (
					<label onClick={this.ToggleSelectHandler}>
						{this.props.iconLeft && (
							<svg className="icon">
								<use xlinkHref={this.props.xlinkHref}></use>
							</svg>
						)}
						<span>{selectedItem.name}</span>
						{!this.props.iconLeft && (
							<svg className="icon">
								<use xlinkHref={this.props.xlinkHref}></use>
							</svg>
						)}
					</label>
				)}
				{this.state.isOpen && (
					<label onClick={this.ToggleSelectHandler}>
						{/*{this.props.iconLeft && (*/}
						{/*	<svg className="icon" onClick={this.ToggleSelectHandler}>*/}
						{/*		<use xlinkHref={this.props.xlinkHref}></use>*/}
						{/*	</svg>*/}
						{/*)}*/}
						{/*{this.props.filter ? (*/}
						{/*	<input*/}
						{/*		type="text"*/}
						{/*		placeholder="Filtrele"*/}
						{/*		onChange={this.FilterOptions}*/}
						{/*		autoFocus={true}*/}
						{/*		onKeyUp={this.KeyUp}*/}
						{/*	/>*/}
						{/*) : (*/}
						{/*	<span onClick={this.ToggleSelectHandler}>{selectedItem.name}</span>*/}
						{/*)}*/}
						{/*{!this.props.iconLeft && (*/}
						{/*	<svg className="icon" onClick={this.ToggleSelectHandler}>*/}
						{/*		<use xlinkHref={this.props.xlinkHref}></use>*/}
						{/*	</svg>*/}
						{/*)}*/}
						<span>{this.props.prompt}</span>
					</label>
				)}
				<div
					className="options"
					ref={this.optionsRef}
					style={{ height: this.state.optionsHeight, maxHeight: this.props.maxHeight}}
				>
					{/*					<button type="button" onClick={this.RemoveSelected}><img src={CsGo} /></button>
					 */}{" "}
					{this.state.filteredOptions.map((option, index) => (
						<button
							type="button"
							onClick={this.OptionClickHandler}
							value={option.id}
							key={option.id + option.name}
							className={index === this.state.hoverIndex ? parseInt(this.state.selected) === option.id ? "hover selected" : "hover" : parseInt(this.state.selected) === option.id ? "selected" : ""}
						>
							{option.name}
							{parseInt(this.state.selected) === option.id && (
								<svg className="icon"><use href="#gs-icon-layout-select-checked"></use></svg>
							)}
						</button>
					))}
				</div>
			</div>
		);
	}
}

export default SelectBox;

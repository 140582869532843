import React from 'react';
import SelectBox from "../../../../Common/SelectBox";
import ItemProductBox from "../ItemProductBox";

class Confirmation extends React.Component {
    render() {
        return (
            <div className="wizard-confirmation-container">
                <div className='wizard-product-view'>
                    <ItemProductBox
                        firstImage={this.props.firstImage}
                        itemName={this.props.itemName}
                        price={this.props.price}
                        user={this.props.user}
                        categoryName={this.props.categoryName}
                    />
                </div>
                <div
                    className="wizard-confirmation-right"
                    style={!this.props.isMobile && {gridTemplateRows: this.props.adSell ? 'auto 1fr auto':'1fr auto'}}>

                    {this.props.adSell && !['physical'].includes(this.props.type) && (
                        <div className="wizard-delivery-times">
                            <div id="delivery-times" style={{padding:'.25rem'}}>
                                <label>İlanım satıldığında</label>
                                <SelectBox
                                    xlinkHref="#gs-icon-user-panel-caret-down"
                                    prompt={"Seçiniz"}
                                    options={this.props.deliveryTimes.map((property) => ({
                                        id: property,
                                        name: property + ' Saat',
                                    }))}
                                    onChange={this.props.DeliveryTimeChange}
                                    selected={this.props.deliveryTimeSelected}
                                    className="grid-2x"
                                    maxHeight="20rem"
                                />
                                <label>içerisinde teslim edeceğim.</label>
                            </div>
                        </div>
                    )}

                    {this.props.adSell ?
                        !['physical'].includes(this.props.type) ? (
                            <div className="wizard-warning-area">
                                <div>- Güvenliğiniz için tanımadığınız insanlarla <u>kişisel bilgilerinizi paylaşmayın!</u></div>
                                <div>- İlanınız satıldığında <u>Satış İşlemlerim</u> sayfasından kontrol ettikten sonra teslimat yapın!</div>
                                <div>- GameSatış dışında yapılan işlemlerin kanıt niteliği taşımayacağı için <u>tüm işlemleri ve görüşmeleri site üzerinden yapın!</u></div>
                                <div>- <u>Site dışı işlemler ve Takas işlemleri yasaktır.</u> Bunu teklif etmeyin, teklif edenleri bize bildirin.</div>
                                <div>- Potansiyel alıcılarınız ile paylaşmak istediğiniz video var ise bunu youtube vb bir platforma yükleyip talep edenlere <u>link olarak gönderin.</u></div>
                                <div>- Herhangi bir sorunuz olursa Canlı Destek üzerinden <u>bizimle iletişime geçin!</u></div>
                            </div>
                        ):(
                            <div className="wizard-warning-area">
                                <div>- Paylaşılan kişisel bilgilerin paylaşılan kişi tarafından kötüye kullanılmasından <u>GameSatış sorumlu değildir!</u></div>
                                <div>- Kargo ile teslimat yapacaksınız <u>Kapıda Ödeme</u> seçeneği ile işlem yapmanız hem sizin hemde alıcınızın güvenliği için önemlidir.!</div>
                                <div>- GameSatış dışında yapılan işlemlerin kanıt niteliği taşımayacağı için <u>tüm işlemleri ve görüşmeleri site üzerinden yapın!</u></div>
                                <div>- Potansiyel alıcılarınız ile paylaşmak istediğiniz video var ise bunu youtube vb bir platforma yükleyip talep edenlere <u>link olarak gönderin.</u></div>
                                <div>- Herhangi bir sorunuz olursa Canlı Destek üzerinden <u>bizimle iletişime geçin!</u></div>
                            </div>
                    ):(
                        <div className="wizard-warning-area">
                            <div>- Güvenliğiniz için tanımadığınız insanlarla <u>kişisel bilgilerinizi paylaşmayın!</u></div>
                            <div>- GameSatış dışında yapılan işlemlerin kanıt niteliği taşımayacağı için <u>tüm işlemleri ve görüşmeleri site üzerinden yapın!</u></div>
                            <div>- <u>Site dışı işlemler ve Takas işlemleri yasaktır.</u> Bunu teklif etmeyin, teklif edenleri bize bildirin.</div>
                            <div>- Kendi banka, ininal, papara <u>hesabına para isteyen kişilere</u> itibar etmeyin hemen bize bildirin.</div>
                            <div>- Potansiyel satıcılarınızdan paylaşmasını istediğiniz video var ise bunu youtube vb bir platforma yükletip <u>link olarak talep edin.</u></div>
                            <div>- Herhangi bir sorunuz olursa Canlı Destek üzerinden <u>bizimle iletişime geçin!</u></div>
                        </div>
                    )}

                    <div className="wizard-confirm-row">
                        <div className="confirm-sell">
                            <div className="checkbox" id="bazaar-agreement" style={{padding:'.25rem'}}>
                                <input
                                    type="checkbox"
                                    name="sale_agreement"
                                    id="sale_agreement"
                                    onChange={(e) => this.props.agreementCheck(e)}
                                    value={this.props.agreementChecked}
                                />
                                <label htmlFor="sale_agreement">
                                    <div>
                                        <a href="/kullanici-sozlesmesi#oyuncu-pazari" target="_blank">
                                            Oyuncu Pazarı Sözleşmesi
                                        </a>{" "}
                                        ve şartlarını okudum kabul ediyorum.
                                    </div>
                                </label>
                            </div>
                            <div className="send-button">
                                <button
                                    className="btn btn-blue"
                                    onClick={this.props.saveProduct}
                                    disabled={this.props.sendDisabled}
                                >
                                    {
                                        this.props.adSell && !['physical'].includes(this.props.type) ?
                                        "SATIŞA ÇIKART":"İLANI YAYINLA"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Confirmation;
import React from "react";
import PostData from "../../packs_helper/scripts/PostData";
import Cookie from "../../packs_helper/scripts/Cookie";
import PopUp from "../Common/PopUp";
import Currency from "../../packs_helper/scripts/Currency";
import CountryListSelect from "../Common/CountryListSelect";
import PhoneInput from "../Common/PhoneInput";
import DecimalFieldTag from "../Common/DecimalFieldTag";
import GlobalVar from "../GlobalVar";

// import PropTypes from "prop-types";

class SignInForm extends React.Component {
	constructor(props) {
		super(props);

		const cookie = new Cookie();
		this.state = {
			email: "",
			phone: "",
			password: "",
			captcha_code: "",
			trust_machine: cookie.get('trust_machine'),
			login_type: "email",
			popup: null,
		};

		this.Login = this.Login.bind(this);
		this.KeyDownHandler = this.KeyDownHandler.bind(this);
		this.SwitchToPasswordReset = this.SwitchToPasswordReset.bind(this);
		this.SwitchToConfirmationForm = this.SwitchToConfirmationForm.bind(this);

		this.ShowTooltip = this.ShowTooltip.bind(this);
		this.HideTooltip = this.HideTooltip.bind(this);
	}

	ShowTooltip(e) {
		const tooltip = e.currentTarget.dataset.target;
		this.setState({ tooltip });
	}

	HideTooltip() {
		this.setState({ tooltip: null });
	}

	Login(e) {
		e.preventDefault();

		const { email, phone, password, captcha_code, trust_machine, login_type } = this.state;
    	const m_phone = phone.replaceAll(" ", "");
		const params = {
			email,
			phone: m_phone,
			password,
			captcha_code,
			trust_machine,
			login_type
		};

		if (e.type == "submit") {
			const formData = new FormData(e.target);

			[...formData.entries()].forEach((data) => {
				params[data[0]] = data[1];
			});
		}


		this.setState({ waitForResponse: true });
		PostData(this.props.signInPath, params)
			.then((response) => {
				if (response.state && this.props.pathname != '/giris-yap') {
					window.location.reload();
				} else {
					const popup = response.email
						? "mail-authentication"
						: response.phone
						? "phone-authentication"
						: response.sms
						? "sms-authentication"
						: null;
					this.setState({
						popup,
					});
					if (!popup) {
						this.props.RefreshCaptchaCode();
					}
				}
			})
			.catch((err) => {


			});
	}

	KeyDownHandler(e){
		const keyCode = e.key || e.code;
		if(keyCode === 'Enter'){
			this.Login(e);
		}
	}

	SwitchToPasswordReset(){
		this.props.ActivateTab('password-reset')
	}

	SwitchToConfirmationForm(){
		this.props.ActivateTab('confirmation')
	}

	ChangeLoginType(login_type){
		this.setState({ login_type })
		document.getElementById(login_type + "_login").checked = true
	}

	render() {
		return (
			<React.Fragment>
				<div className="grid-2">
					<div
						className={this.state.login_type === "email" ? "input radio text-green":"input radio text-red"}
						id="email_selection"
						onClick={() => this.ChangeLoginType('email')}
					>
						<input type="radio" name="login_type" id="email_login" defaultChecked={true} value="0" />
						E-posta ile Giriş
					</div>
					<div
						className={this.state.login_type === "phone" ? "input radio text-green":"input radio text-red" }
						id="phone_selection"
						onClick={() => this.ChangeLoginType('phone')}
					>
						<input type="radio" name="login_type" id="phone_login" value="1" />
						Telefon ile Giriş
					</div>
				</div>

				{this.state.login_type === "email" ? (
					<input
						type="text"
						id="email"
						name="email"
						value={this.state.email}
						onInput={(e) => this.setState({ email: e.target.value })}
						onKeyDown={this.KeyDownHandler}
						placeholder="E-Posta Adresi:"
						autoFocus
					/>
				):(
					<div className="phone-grid">
						<div>+90</div>
						<PhoneInput
							id="phone"
							name="phone"
							value={this.state.phone}
							onChange={(phone) => this.setState({ phone })}
							autoFocus
						/>
					</div>
				)}

					<input
						type="password"
						id="password"
						name="password"
						value={this.state.password}
						onInput={(e) => this.setState({ password: e.target.value })}
						onKeyDown={this.KeyDownHandler}
						placeholder="Şifre:"
					/>

				<div className="input-with-captcha">
					<input
						type="text"
						name="captcha_code"
						value={this.state.captcha_code}
						onInput={(e) => this.setState({ captcha_code: e.target.value })}
						onKeyDown={this.KeyDownHandler}
						placeholder="Güvenlik Kodu:"
						maxLength={4}
					/>
					<img src={this.props.captchaImage} />
					<button onClick={this.props.RefreshCaptchaCode}>
						<svg className="icon">
							<use xlinkHref="#gs-icon-sign-box-reload"></use>
						</svg>
					</button>
				</div>

				<div className="checkbox">
					<input
						type="checkbox"
						name="trust_machine"
						id="trust_machine"
						value="1"
						checked={this.state.trust_machine}
						onChange={(e) => this.setState({ trust_machine: e.target.checked })}
					/>
					<label htmlFor="trust_machine">
						<div>
							Bu cihaz güvenilir
							{this.state.tooltip === "trust-machine-tooltip" && (
								<div className="tooltip">
								Bu cihazdan yapacağınız girişlerde SMS veya Mail doğrulama istenmez.
								</div>
							)}
							<svg
							className="icon"
							onMouseEnter={this.ShowTooltip}
							onMouseLeave={this.HideTooltip}
							data-target="trust-machine-tooltip"
							>
								<use xlinkHref="#gs-icon-sign-box-question"></use>
							</svg>
						</div>
					</label>
				</div>

				<button className="btn btn-blue" onClick={this.Login}>
					Giriş Yap
				</button>
				<button className="btn btn-default" onClick={this.SwitchToPasswordReset}>Şifremi Unuttum</button>
				<button className="btn btn-default" onClick={this.SwitchToConfirmationForm}>Üyelik Doğrulama</button>

				{this.state.popup === "mail-authentication" && (
					<PopUp
						title="E-Posta Doğrulama Kodu"
						Hide={() => this.setState({ popup: null })}
						className="code-popup"
					>
						<form action="#" onSubmit={this.Login} autoComplete="off" data-append>
							<div className="input-with-label">
								<label htmlFor="email_code">E-Posta Onay Kodu:</label>
								<input type="text" id="email_code" name="email_code" />
								<input type="hidden" name="email_code_button" value="1" />
							</div>
							<button type="submit" className="btn btn-blue">
								Giriş
							</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "phone-authentication" && (
					<PopUp
						title="SMS Onay Kodu"
						Hide={() => this.setState({ popup: null })}
						className="code-popup"
					>
						<form action="#" onSubmit={this.Login} autoComplete="off" data-append>
							<div className="input-with-label">
								<label htmlFor="email_code">SMS Onay Kodu:</label>
								<input type="text" id="email_code" name="email_code" />
								<input type="hidden" name="email_code_button" value="1" />
							</div>
							<button type="submit" className="btn btn-blue">
								Giriş
							</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "sms-authentication" && (
					<PopUp
						title="SMS Doğrulama Kodu"
						Hide={() => this.setState({ popup: null })}
						className="code-popup"
					>
						<form action="#" onSubmit={this.Login} autoComplete="off" data-append>
							<div className="input-with-label">
								<label htmlFor="sms_code">SMS Doğrulama Kodu:</label>
								<input type="text" id="sms_code" name="sms_code" />
								<input type="hidden" name="sms_code_button" value="1" />
							</div>
							<button type="submit" className="btn btn-blue">
								Giriş
							</button>
						</form>
					</PopUp>
				)}
			</React.Fragment>
		);
	}
}

export default SignInForm;

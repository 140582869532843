import React from "react";
import PaymentGridListItem from "./PaymentGridListItem";
import PriceQuickSelectButton from "./PriceQuickSelectButton";
import PostData from "../../packs_helper/scripts/PostData";
import Currency from "../../packs_helper/scripts/Currency";

import PhoneInput from "../Common/PhoneInput";
import SelectBox from "../Common/SelectBox";
import DecimalFieldTag from "../Common/DecimalFieldTag";
import PriceFieldTag from "../Common/PriceFieldTag";

const groups = {
	transfer: "Yatırmanız Gereken Tutar",
	credit_card: "Kartınızdan Çekilecek Tutar",
	mobile_payment: "Faturanıza Yansıyacak Tutar",
};

const months = Array(12)
	.fill()
	.map((x, index) => {
		const y = (index + 1).toString().padStart(2, "0");
		return { id: y, name: y };
	});
const date = new Date();
const year = date.getFullYear();
const years = Array(12)
	.fill()
	.map((x, index) => {
		const y = (year + index).toString();
		return { id: y, name: y };
	});

const PaymentGridListItemCreditCard = (props) => {
	const [amount, SetAmount] = React.useState(0);
	const [expiryMonth, SetExpiryMonth] = React.useState(0);
	const [waitForResponse, SetWaitForResponse] = React.useState(false);
	const paymentGridListItemRef = React.useRef(null);

	const FormSubmitHandler = (e) => {
		e.preventDefault();

		SetWaitForResponse(true);
		const formData = new FormData(e.target);
		formData.append("payment_notification[payment_id]", props.paymentMethod.id);

		const entries = formData.entries();
		let entry;
		while(entry = entries.next().value){
			if(entry[0] !== 'payment_notification[description]' && (!entry[1] || entry[1] == '0')){
				ShowPopup({
					title: 'Hata',
					message: 'Lütfen tüm alanları doldurunuz!'
				});
				SetWaitForResponse(false);
				return false
			}
		}

		PostData(props.savePath, formData, {
			redirect: false,
		})
			.then((response) => {
				SetWaitForResponse(false);
				if(response.state && response.browser != 'safari'){
					setTimeout(function(){ redirect('/odemelerim') }, 5000);
				}
			})
			.catch((err) => {
				SetWaitForResponse(false);
			});
	};

	return (
		<PaymentGridListItem
			id={props.paymentMethod.id}
			image={props.paymentMethod.image}
			group={props.paymentMethod.group}
			name={props.paymentMethod.name}
			slogan={props.paymentMethod.slogan}
			waitForResponse={waitForResponse}
			ref={paymentGridListItemRef}
			show={props.show}
			ComponentOpen={props.ComponentOpen}
			mobile={props.mobile}
		>
			<form
				action="#"
				method="post"
				onSubmit={FormSubmitHandler}
				className="payment-grid-list-item-body-wrapper"
				data-wait-for-response={waitForResponse}
				autoComplete="off"
			>
				{(props.paymentMethod.short_description || props.paymentMethod.slogan) && (
					<div className="item-properties">
						{props.paymentMethod.slogan && (
							<div className="item-properties-box">
								<strong>{props.paymentMethod.slogan}</strong>
							</div>
						)}
						{props.paymentMethod.short_description.map((sd) => (
							<div className="item-properties-box" key={sd[0]}>
								{sd[0]}: <strong>{sd[1]}</strong>
							</div>
						))}
					</div>
				)}
				<div className="quick-select-buttons">
					{props.quickSelectAmounts.map((quickSelectAmount) => (
						<PriceQuickSelectButton
							amount={quickSelectAmount}
							onClick={SetAmount}
							key={"pqsb-" + quickSelectAmount}
							selected={quickSelectAmount === amount}
						/>
					))}
				</div>
				<div className="payment-account-form-grid">
						<PriceFieldTag
						wrapperClass="input-with-label input-with-label-payment"
						label="Hesabınıza Eklenecek Tutar:"
						placeholder="0,00"
						name="payment_notification[amount]"
						id={`amount-${props.paymentMethod.id}`}
						readOnly={waitForResponse}
						onChange={SetAmount}
						value={amount}
						autoFocus={true}
						/>
					{props.paymentMethod.rate > 0 && (
						<div className="input-with-label disabled">
							<label>{groups[props.paymentMethod.group]}:</label>
							<div className="input">
								{Currency(amount / (1 - props.paymentMethod.rate))} ₺
							</div>
						</div>
					)}

					{props.paymentMethod.ask_which_field.indexOf(1) > -1 && (
						<div className="input-with-label">
							<label htmlFor={`name_surname-${props.paymentMethod.id}`}>
								Adınız ve Soyadınız:
							</label>
							<input
								type="text"
								name="payment_notification[name_surname]"
								id={`name_surname-${props.paymentMethod.id}`}
								defaultValue={props.fullname}
								readOnly={waitForResponse}
							/>
						</div>
					)}

					{props.paymentMethod.ask_which_field.indexOf(2) > -1 && (
						<div className="input-with-label">
							<label htmlFor={`phone-${props.paymentMethod.id}`}>
								Telefon Numaranız:
							</label>
							<PhoneInput
								name="payment_notification[phone]"
								id={`phone-${props.paymentMethod.id}`}
							/>
						</div>
					)}

					{props.paymentMethod.ask_which_field.indexOf(6) > -1 && (
						<React.Fragment>
							<div className="input-with-label">
								<label htmlFor={`card-${props.paymentMethod.id}`}>
									Kart No:
								</label>
								<DecimalFieldTag
									name="payment_notification[card]"
									id={`card-${props.paymentMethod.id}`}
									readOnly={waitForResponse}
									maxLength={19}
								/>
							</div>
							<div className="input-with-label">
								<label htmlFor={`expiry_month-${props.paymentMethod.id}`}>
									Son K. Tarihi:
								</label>
								<SelectBox
									name="payment_notification[expiry_month]"
									id={`expiry_month-${props.paymentMethod.id}`}
									xlinkHref="#gs-icon-payment-methods-caret-down"
									prompt="Ay"
									options={months}
								/>

								<SelectBox
									name="payment_notification[expiry_year]"
									xlinkHref="#gs-icon-payment-methods-caret-down"
									prompt="Yıl"
									options={years}
								/>
							</div>
							<div className="input-with-label">
								<label htmlFor={`cvv2-${props.paymentMethod.id}`}>CVV2:</label>
								<DecimalFieldTag
									name="payment_notification[cvv2]"
									id={`cvv2-${props.paymentMethod.id}`}
									readOnly={waitForResponse}
									maxLength={3}
								/>
							</div>
						</React.Fragment>
					)}

					{props.paymentMethod.ask_which_field.indexOf(4) > -1 && (
						<div className="input-with-label">
							<label htmlFor={`transaction_no-${props.paymentMethod.id}`}>
								4 Haneli İşlem Kodu:
							</label>
							<input
								type="text"
								name="payment_notification[transaction_no]"
								id={`transaction_no-${props.paymentMethod.id}`}
							/>
						</div>
					)}

					{props.paymentMethod.ask_which_field.indexOf(0) > -1 && (
						<div className="input-with-label">
							<label htmlFor={`atm_no-${props.paymentMethod.id}`}>
								ATM No:
							</label>
							<input
								type="text"
								name="payment_notification[atm_no]"
								id={`atm_no-${props.paymentMethod.id}`}
							/>
						</div>
					)}

					{props.paymentMethod.ask_which_field.indexOf(3) > -1 && (
						<div className="input-with-label">
							<label
								htmlFor={`identification_number-${props.paymentMethod.id}`}
							>
								T.C. Kimlik No:
							</label>
							<DecimalFieldTag
								name="payment_notification[identification_number]"
								id={`identification_number-${props.paymentMethod.id}`}
								readOnly={waitForResponse}
								maxLength={11}
							/>
						</div>
					)}

					{props.paymentMethod.ask_which_field.indexOf(5) > -1 && (
						<textarea
							className="right-box"
							placeholder="Açıklamayı yazınız:"
							readOnly={waitForResponse}
						></textarea>
					)}

					{props.paymentMethod.ask_which_field.length % 2 === 1 && <div />}

					<button className="btn btn-jungle" type="submit">
						{props.paymentMethod.group === "transfer"
							? "Ödeme Bildir"
							: "Ödeme Yap"}
					</button>
				</div>
			</form>
		</PaymentGridListItem>
	);
};

export default PaymentGridListItemCreditCard;

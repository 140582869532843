import React from "react";
import PopUp from "../Common/PopUp";

const CouponCodeModal = (props) => {
	const [couponCode, SetCouponCode] = React.useState("");

	const OnChange = (e) => {
		const code = e.currentTarget.value;
		SetCouponCode(code);
	};

	const OnClick = () => {
		props.UseCouponCode(couponCode);
	};

	return (
		<PopUp title="Kupon Kodu Kullan" Hide={props.Hide}>
			<div className="grid-1">
				<input
					placeholder="Kupon Kodunuz:"
					type="text"
					placeholder="XXXXXX"
					value={couponCode}
					onChange={OnChange}
				/>
				<button className="btn btn-blue" onClick={OnClick}>
					Kullan
				</button>
			</div>
		</PopUp>
	);
};

export default CouponCodeModal;
function PriceAmountValidate(validateProps) {
    let resultMessage = '';
    let resultTarget = '';
    let resultState = false;
    const adType = validateProps.type;
    const adPrice = validateProps.priceValue;
    const minPrice = validateProps.minPrice;
        /*
        adType === 'accounts' ?
        GlobalVar.MIN_PRICE.ACCOUNT
        : adType === 'boost' ?
            GlobalVar.MIN_PRICE.BOOST
            : adType === 'items' ?
                GlobalVar.MIN_PRICE.ITEM
                    : GlobalVar.MIN_PRICE.ACCOUNT;
         */

    if(adPrice < minPrice) {
        resultMessage = 'En az ' + minPrice + ' ₺ fiyat girmelisiniz.';
        resultTarget = 'price-container-for-price';
    }else if(!validateProps.adSell && !validateProps.showBalance){
        resultMessage = 'Yeterli Bakiye Gözüksün mü? sorusunu yanıtlamalısınız.';
        resultTarget = 'show-balance-select';
    }else{
        resultState = true;
    }

    return {
        "state": resultState,
        "message": resultMessage,
        "target": resultTarget
    }
}

export default PriceAmountValidate;
import React from "react";
const ImageFileInput = (props) => {
	const onRemove = file => {
		if(props.onRemove){
			props.onRemove(file)
		}
	}

	function svgContent() {
		return (
			<svg className="icon">
				<use xlinkHref="#gs-icon-user-panel-no-image"></use>
			</svg>
		);
	}

	function titleContent(){
		if(props.location && props.location === "store-settings")
			return "Avatar Yükle";
		if(props.title)
			return props.title;
		return "Görsel Yükle"
	}

	function subContent(){
		if(props.location && props.location === "store-settings")
			return "";
		if(props.location && props.location === 'wizard')
			return "";
		return (<small> (Maks. {props.max})</small>);
	}

	return (
		<label className={(props.location && props.location === "store-settings")?"upload-image-button upload-tight":"upload-image-button"}>
			<div>
				{svgContent()}
				<strong>
					{titleContent()}
					{subContent()}
				</strong>
				{props.info && (
					<small>{props.info}</small>
				)}
			</div>
			<input
				type="file"
				multiple={props.multiple || false}
				onChange={props.onChange}
				accept=".jpg,.jpeg,.png,image/jpeg,image/png"
			/>
			{!props.noPreview && (
				<div className="image-preview-container">
					{props.uploadedFiles.map((file, index) => (
						<Preview
							file={file}
							index={index}
							onRemove={onRemove}
							key={file.name}
						/>
					))}
				</div>
			)}
		</label>
	);
};

const Preview = props => {
	const src = URL.createObjectURL(props.file)
	const onRemove = e => {
		e.preventDefault();
		props.onRemove(props.index)
	}

	return (
		<img
			src={URL.createObjectURL(props.file)}
			className="image-preview"
			onClick={onRemove}
		/>
)
}
export default ImageFileInput;
import React from "react";

class LiveChatAdminContentEditor extends React.Component{
	constructor(props){
		super(props);
		this.EditorChanged = this.EditorChanged.bind(this);
	}

	componentDidMount(){
		if(typeof sceditor !== 'undefined'){
			sceditor.create(this.editor, scEditorOptions);

			this.sceditor = sceditor.instance(this.editor);
			if(this.props.defaultValue){
				this.sceditor.wysiwygEditorInsertHtml(this.props.defaultValue)
			}
			sceditor.BBCodeParser();

			this.sceditor.bind('keyup', this.EditorChanged);
			this.sceditor.bind('nodechanged', this.EditorChanged);
			this.sceditor.bind('blur', this.EditorChanged);
		}
	}

	EditorChanged(e){
		// console.log("changed")
		// console.log(this.sceditor.getWysiwygEditorValue())
		// console.log(sceditor)
		const value = sceditor.toHTML(this.sceditor.getWysiwygEditorValue())
		this.props.onChange(value)
	}

	render(){
		return <textarea ref={ref => this.editor = ref} defaultValue={this.defaultValue} />
	}
}






/*
class EditableDivOld extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			content: [
				['B', 'Merhaba Dünya'],
				['I', 'Merhaba Dünya'],
				['U', 'Merhaba Dünya'],
			]
		}

		this.Click = this.Click.bind(this);
		this.KeyUp = this.KeyUp.bind(this);
	}

	KeyUp(e){


		return false;
		content = value;
		this.setState(prevState => ({content: prevState.content}))

	}

	KeyDown(e){
		const pos = helpers.GetCaretPosition(e.target);
		const key = e.keyCode || e.which;

		// const length = e.target.innerText.length
		// if(pos === length && key === 39){
		// 	e.preventDefault();
		// 	const nextEl = e.target.nextElementSibling;
		// 	if(nextEl){
		// 		nextEl.focus()
		// 	}
		// }
		// else if(pos === 0 && key === 37){
		// 	e.preventDefault();
		// 	const prevEl = e.target.previousElementSibling
		// 	if(prevEl){
		// 		prevEl.focus();
		// 		helpers.SetCaretPosition(prevEl, prevEl.innerText.length)
		// 	}
		// }
	}


	Click(e, type){

		const pos = helpers.GetCaretPosition(this.EditorRef, true);

		if(pos[0] !== pos[1]){

		}
		// this.setState(prevState => ({content: [...prevState.content, ['B', 'Bold Yazı']]}))
	}

	render(){
		return (
			<div>
			<div>
				<EditorButton onClick={this.Click} type={['B', 'Bold Yazı']} text="Bold" />
				<EditorButton onClick={this.Click} type={['I', 'Italic Yazı']} text="Italic" />
				<EditorButton onClick={this.Click} type={['U', 'Altı Çizgili Yazı']} text="Underline" />
				<EditorButton onClick={this.Click} type={['BT', 'Büyük Yazı']} text="Big Text" />
				<EditorButton onClick={this.Click} type={['MT', 'Normal Yazı']} text="Normal Text" />
				<EditorButton onClick={this.Click} type={['ST', 'KüçükYazı']} text="Small Text" />
			</div>
			<div className="border p-2" contentEditable={true} suppressContentEditableWarning={true} ref={ref => this.EditorRef = ref} onKeyUp={this.KeyUp}>
				{this.state.content.map(ch => {
					return <BBCodeElement {...ch} onKeyDown={this.KeyDown} onKeyUp={this.KeyUp} key={ch.join('-')} />
				})}
			</div>
			</div>
		)
	}
}

const EditorButton = props => {
	const onClick = e => {
		props.onClick(e, props.type)
	}

	return <button type="button" className="btn btn-secondary" onClick={onClick}>{props.text}</button>
}

*/

export default LiveChatAdminContentEditor;

import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import CsGo from "../../../assets_v3/images/cs-go";
import PanelSkinBox from "./PanelSkinBox";
// import ImagePath from "../../../packs_helper/scripts/ImagePath";
// import deliveryHours from "../../../packs_helper/scripts/deliveryHours";
import SelectBox from "../../Common/SelectBox";
import GlobalVar from "../../GlobalVar";

const images = {
	20336: '/assets/user-panel/cs-go.svg',
	21209: '/assets/user-panel/dota-2.svg',
	21224: '/assets/user-panel/rust.svg'
};

class Skin extends React.Component {
	constructor(props) {
		super(props);

		const { selected_game } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);
		this.selectedGame = parseInt(selected_game);

		this.state = {
			selectedGame: null,
			categories: [],
			skin_games: [],
			tradeUrl: "",
			steamName: null,
			steamAvatar: null,
			skins: [],
			selectedSkins: [],
			selectedSkinsCount: 0,
			agreementChecked: false,
			sendDisabled: false,
			deliveryTime: '',
			steamRate: 0,

			// deliveryStartTime: '',
			// deliveryEndTime: '',
		};

		this.ChangeGame = this.ChangeGame.bind(this);
		this.UpdateTradeUrl = this.UpdateTradeUrl.bind(this);
		this.GetInventory = this.GetInventory.bind(this);
		this.GetGames = this.GetGames.bind(this);
		this.SelectionChange = this.SelectionChange.bind(this);
		this.SendSelectedSkins = this.SendSelectedSkins.bind(this);

		this.DeliveryTimeChange = this.DeliveryTimeChange.bind(this);

		// this.ChangeDeliveryStartTime = this.ChangeDeliveryStartTime.bind(this);
		// this.ChangeDeliveryEndTime = this.ChangeDeliveryEndTime.bind(this);

	}

	componentDidMount() {
		this.GetGames();
	}

	GetGames() {
		PostData("/ilan-oyunlari", {
			type: "skins",
		}).then((response) => {
			const {
				categories,
				rate,
				suggestedPriceRate,
				maximumPriceRate,
				confirmed_store_rate,
				steamRate
			} = response;

			const categoryItems = categories.map((category) => ({
				...category,
				image_url: images[category.id]
			}));

			if (
				!this.selectedGame ||
				categoryItems.findIndex(
					(categoryItem) => categoryItem.id === this.selectedGame
				) === -1
			) {
				this.selectedGame = categoryItems[0].id;
			}

			this.setState(
				{
					...response,
					categories: categoryItems,
					selectedGame: this.selectedGame,
				},
				() => {
					this.selectedGame = null;
				}
			);
		});
	}

	ChangeGame(selectedGame) {
		this.setState({ selectedGame });
	}

	UpdateTradeUrl(e) {
		const tradeUrl = e.currentTarget.value;
		this.setState({ tradeUrl });
	}

	DeliveryTimeChange(deliveryTime){
		this.setState({deliveryTime})
	}

	// ChangeDeliveryStartTime(deliveryStartTime) {
	// 	this.setState({ deliveryStartTime });
	// }
	//
	// ChangeDeliveryEndTime(deliveryEndTime) { // .
	// 	this.setState({ deliveryEndTime });
	// }

	GetInventory() {
		this.setState({sendDisabled: true});
		const app_id = this.state.skin_games.find(
			(skin_game) => skin_game.cat_id == this.state.selectedGame
		).app_id;
		PostData("/envanteri-getir/" + app_id, { trade_url: this.state.tradeUrl })
			.then((response) => {
				this.setState({
					steamName: response.userId,
					steamAvatar: response.avatar,
					skins: Object.values(response.result),
				});
				if (this.state.skins.length === 0){
					// ShowPopup({ message: "Envanterinizde uygun skin bulunamadı!", title: "Hata" });
				}
				this.setState({sendDisabled: false});
			})
			.catch((err) => { this.setState({sendDisabled: false}); });
	}

	SendSelectedSkins() {
		this.setState({ sendDisabled: true });
		const formData = new FormData();

		if (!this.state.agreementChecked) {
			ShowPopup({ message: "Lütfen sözleşmeyi onaylayınız!", title: "Hata" });
			this.setState({ sendDisabled: false });
			return false;
		}

		formData.append("product[category_id]", this.state.selectedGame);
		formData.append("active", true);

		const selectedSkins = this.state.selectedSkins;
		if (selectedSkins.length === 0) {
			ShowPopup({ message: "Hiç seçim yapmadınız!", title: "Hata" });
			this.setState({ sendDisabled: false });
			return false;
		}

		if (!this.state.deliveryTime) {
			ShowPopup({
				message: "Teslimat zamanını girmeniz gerekmektedir!",
				title: "Hata",
			});
			this.setState({ sendDisabled: false });
			return false;
		}

		// if (!this.state.deliveryStartTime || !this.state.deliveryStartTime) {
		// 	ShowPopup({
		// 		message: "Teslimat zamanını girmeniz gerekmektedir!",
		// 		title: "Hata",
		// 	});
		// 	this.setState({ sendDisabled: false });
		// 	return false;
		// }

		formData.append(
			'product[delivery_time]',
			this.state.deliveryTime
		);
		// formData.append(
		// 	'product[item_user_available_time_start]',
		// 	this.state.deliveryStartTime
		// );
		// formData.append(
		// 	'product[item_user_available_time_end]',
		// 	this.state.deliveryEndTime
		// );


		selectedSkins.forEach((selectedSkin) => {
			const key = selectedSkin.key;
			formData.append("assetid[" + key + "]", selectedSkin.key);
			formData.append("keys[" + key + "]", selectedSkin.keys);
			formData.append("quantity[" + key + "]", selectedSkin.quantity);
			formData.append("price[" + key + "]", selectedSkin.cost);
			formData.append("description[" + key + "]", selectedSkin.description);
		});

		PostData("/item-ilani-ver", formData).then((response) => {
			if (response.state) {
				this.props.ChangeRoute("/ilanlarim");
			} else {
				this.setState({ sendDisabled: false });
			}
		});
	}

	SelectionChange(status, itemObj) {
		const selectedSkins = this.state.selectedSkins;
		let selectedSkinsCount = this.state.selectedSkinsCount;
		const index = selectedSkins.findIndex(
			(selectedSkin) => selectedSkin.key === itemObj.key
		);
		if (!status) {
			if (index > -1) {
				selectedSkins.splice(index, 1);
				selectedSkinsCount -= itemObj.quantity;
				this.setState({ selectedSkins, selectedSkinsCount });
			}
		} else {
			if (index > -1) {
				selectedSkins[index] = itemObj;
				this.setState({ selectedSkins });
			} else {
				selectedSkins.push(itemObj);
				selectedSkinsCount += itemObj.quantity;
				this.setState({ selectedSkins, selectedSkinsCount });
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="skin-query-grid">
					<SelectForSkin
						options={this.state.categories}
						onChange={this.ChangeGame}
						selected={this.state.selectedGame}
					/>

					<div className="skin-query-grid-form">
						<label htmlFor="query-form">TRADE URL:</label>
						<input
							id="query-form"
							type="text"
							value={this.state.tradeUrl}
							onChange={this.UpdateTradeUrl}
							placeholder="Trade URL'nizi buraya yapıştırın"
						/>
						<button className="btn btn-blue" disabled={this.state.sendDisabled} onClick={this.GetInventory}>
							Envanteri Getir
						</button>
						<a
							href="https://steamcommunity.com/id/example/tradeoffers/privacy#trade_offer_access_url"
							target="_blank"
							className="btn btn-orange"
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-steam"></use>
							</svg>
						</a>
					</div>
				</div>

				{this.state.skins.length ? (
					<React.Fragment>
						<div className="skin-result-grid">
							{this.state.confirmed_store_rate && (
								<div className="alert skin-query-alert">
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-warning"></use>
									</svg>
									<div>
										Komisyon oranınızın %{this.state.confirmed_store_rate}{" "}
										olması için mağazanızı doğrulamanız gerekiyor. Nasıl mağaza
										doğrulanır öğrenmek için{" "}
										<a
											href="/magaza-ve-kimlik-dogrulama"
											target="_blank"
											className="text-underline"
										>
											tıklayın!
										</a>
									</div>
								</div>
							)}
							{this.state.skins.map((skin) => (
								<PanelSkinBox
									key={skin.market_name + skin.keys[0]}
									suggestedPriceRate={this.state.suggestedPriceRate}
									maximumPriceRate={this.state.maximumPriceRate}
									rate={this.state.rate}
									steamRate={this.state.steamRate}
									SelectionChange={this.SelectionChange}
									{...skin}
								/>
							))}
						</div>
						<div className="skin-result-footer item-footer">
							<div className="delivery-time-grid">
								<strong>TESLİMAT SAATİ:</strong>
								<SelectBox
									xlinkHref="#gs-icon-user-panel-caret-down"
									prompt={"Seçiniz"}
									options={GlobalVar.DELIVERY_TIMES.map((property) => ({
										id: property,
										name: property + ' Saat',
									}))}
									onChange={this.DeliveryTimeChange}
									selected={this.state.deliveryTime}
									className="grid-2x"
									maxHeight="20rem"
								/>
							</div>
							<hr />
							<div className="checkbox">
								<input
									type="checkbox"
									name="sale_agreement"
									id="sale_agreement"
									onChange={(e) =>
										this.setState({ agreementChecked: e.currentTarget.checked })
									}
								/>
								<label htmlFor="sale_agreement">
									<div>
										<a href="/kullanici-sozlesmesi#oyuncu-pazari" target="_blank">
											Oyuncu Pazarı Sözleşmesi
										</a>{" "}
										ve şartlarını okudum kabul ediyorum.
									</div>
								</label>
							</div>

							<div className="skin-result-footer-info">
								{this.state.selectedSkinsCount} Adet Eklendi
							</div>

							<button
								className="btn btn-blue"
								onClick={this.SendSelectedSkins}
								disabled={
									this.state.sendDisabled ||
									this.state.selectedSkins.length === 0
								}
							>
								SATIŞA ÇIKART
							</button>
						</div>
					</React.Fragment>
				) : (
					<div></div>
				)}
			</React.Fragment>
		);
	}
}

class SelectForSkin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null,
			isOpen: false,
		};

		this.ToggleSelect = this.ToggleSelect.bind(this);
		this.SetSelected = this.SetSelected.bind(this);
		this.RemoveSelected = this.RemoveSelected.bind(this);
	}

	// componentDidMount() {
	// 	if(this.props.selected){
	// 		this.setState({
	// 			selected: this.props.selected
	// 		})
	// 	}
	// }

	ToggleSelect() {
		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));
	}

	SetSelected(e) {
		const selectedOption = e.currentTarget;
		this.props.onChange(selectedOption.value);
		this.setState({
			selected: selectedOption.value,
			isOpen: false,
		});
	}

	RemoveSelected() {
		this.setState({
			selected: null,
			isOpen: false,
		});
	}

	render() {
		const selectedItem = this.props.options.find(
			(option) => option.id == this.props.selected
		);
		return (
			<div className={"select" + (this.state.isOpen ? " open" : "")}>
				{selectedItem && (
					<label onClick={this.ToggleSelect}>
						<svg className="icon">
							<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
						</svg>
						<img src={selectedItem.image_url || CsGo} alt={selectedItem.name} width={96} height={48} />
					</label>
				)}
				<div className="options">
					{/*					<button type="button" onClick={this.RemoveSelected}><img src={CsGo} /></button>
					 */}{" "}
					{this.props.options.map((option) => (
						<button
							type="button"
							onClick={this.SetSelected}
							value={option.id}
							key={option.id + option.name}
						>
							<svg className="icon">
							</svg>
							<img src={option.image_url || CsGo} alt={option.name} width={96} height={48} />
						</button>
					))}
				</div>
			</div>
		);
	}
}

export default Skin;

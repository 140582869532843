import React from "react";
import LiveChatAdminContentEditor from "./LiveChatAdminContentEditor";

class LiveChatAdminItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			highlight: false,
			dragOverIn: false,
			dragOverAfter: false,
		};

		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDrop = this.handleDrop.bind(this);

		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
	}

	handleDragStart(navigation) {
		this.props.DragStart(navigation);
	}

	handleDrop(navigation, type) {

		this.props.Drop(navigation);
		this.setState({ [type]: false });
	}

	handleDragEnter(navigation, type) {
		this.setState({ [type]: true });
	}

	handleDragLeave(navigation, type) {
		this.setState({ [type]: false });
	}

	render() {
		return (
			<LiveChatAdminItemObject
				navigation={this.props.navigation}
				AddChild={this.props.AddChild}
				RemoveChild={this.props.RemoveChild}
				AddProperty={this.props.AddProperty}
				RemoveProperty={this.props.RemoveProperty}
				UpdateProperty={this.props.UpdateProperty}
				highlight={this.state.highlight}
				handleDragStart={this.handleDragStart}
				handleDrop={this.handleDrop}
				handleDragOver={this.handleDragOver}
				handleDragEnter={this.handleDragEnter}
				handleDragLeave={this.handleDragLeave}

				DragStart={this.props.DragStart}
				Drop={this.props.Drop}
				
				dragOverIn={this.state.dragOverIn}
				dragOverAfter={this.state.dragOverAfter}
			/>
		);
	}
}

const LiveChatAdminItemObject = (props) => {
	const handleDragStart = (e) => {
		// e.preventDefault();
		props.handleDragStart(props.navigation);
	};

	const handleDrop = (e, type) => {
		e.preventDefault();
		props.handleDrop(props.navigation, type);
	};

	const handleDragOver = (e, type) => {
		e.preventDefault();
	};

	const handleDragEnter = (e, type) => {
		e.preventDefault();
		props.handleDragEnter(props.navigation, type);
	};

	const handleDragLeave = (e, type) => {
		e.preventDefault();
		props.handleDragLeave(props.navigation, type);
	};

	const ComponentChangeHandler = type => {
		if(type === props.navigation.content.component){
			props.RemoveProperty(props.navigation.content, 'component')
		}
		else{
			props.AddProperty(props.navigation.content, "component", type)
		}
	}

	const anyContent = props.navigation.content !== undefined || props.navigation.imageUrl !== undefined || props.navigation.videoUrl !== undefined;
	return (
		<div>
			<div className={"site-live-chat-admin-item" + (props.highlight ? " highlight" : "")}>
			<div className="drag-button" draggable="true" onDragStart={handleDragStart} />
				<div className="form-group">
					<label>Başlık:</label>
					<input className="form-control" type="text" onChange={(e) => props.UpdateProperty(props.navigation, "title", e.currentTarget.value)} value={props.navigation.title} />
				</div>
				{props.navigation.content !== undefined && (
					<div className="form-group">
						<label>İçerik:</label>
						<LiveChatAdminContentEditor defaultValue={props.navigation.content.source} onChange={(value) => props.UpdateProperty(props.navigation.content, "source", value)} />
						<div className="mt-1">
							<button className="btn btn-default btn-xs mr-2" onClick={() => ComponentChangeHandler("OrderList")}>
								Sipariş Listesi{props.navigation.content.component === "OrderList" ? " (Eklendi)" : ""}
							</button>
							<button className="btn btn-default btn-xs mr-2" onClick={() => ComponentChangeHandler("ItemsList")}>
								İlanlar Listesi{props.navigation.content.component === "ItemsList" ? " (Eklendi)" : ""}
							</button>
							<button className="btn btn-default btn-xs pull-right" type="button" onClick={e => props.RemoveProperty(props.navigation, "content")}>
								İçerik Bölümünü Kaldır
							</button>
						</div>
					</div>
				)}
				{props.navigation.imageUrl !== undefined && (
					<div className="form-group">
						<label>Fotoğraf URL:</label>
						<div className="input-group">
							<input className="form-control" type="text" onChange={(e) => props.UpdateProperty(props.navigation, "imageUrl", e.currentTarget.value)} value={props.navigation.imageUrl} />
							<span className="input-group-btn">
								<button className="btn btn-default" type="button" onClick={e => props.RemoveProperty(props.navigation, "imageUrl")}>
									<i className="fa fa-times" />
								</button>
							</span>
						</div>
					</div>
				)}
				{props.navigation.videoUrl !== undefined && (
					<div className="form-group">
						<label>Video URL:</label>
						<input className="form-control" type="text" onChange={(e) => props.UpdateProperty(props.navigation, "videoUrl", e.currentTarget.value)} value={props.navigation.videoUrl} />
					</div>
				)}
				<div className="actions">
					{!anyContent && (
						<button className="btn btn-default btn-sm mr-2" onClick={() => props.AddChild(props.navigation)}>
							Alt Basamak Ekle
						</button>
					)}
					{props.navigation.children.length === 0 && (
						<span>
							{props.navigation.content === undefined && (
								<button className="btn btn-default btn-sm mr-2" onClick={() => props.AddProperty(props.navigation, "content", {})}>
									İçerik Ekle
								</button>
							)}
							{props.navigation.imageUrl === undefined && (
								<button className="btn btn-default btn-sm mr-2" onClick={() => props.AddProperty(props.navigation, "imageUrl")}>
									Fotoğraf Ekle
								</button>
							)}
							{props.navigation.videoUrl === undefined && (
								<button className="btn btn-default btn-sm mr-2" onClick={() => props.AddProperty(props.navigation, "videoUrl")}>
									Video Ekle
								</button>
							)}
							<button className="btn btn-danger btn-sm" onClick={() => props.RemoveChild(props.navigation)}>
								Sil
							</button>
						</span>
					)}
				</div>
				{props.navigation.children.map((childNavigation) => (
					<LiveChatAdminItem 
					navigation={childNavigation} 
					AddChild={props.AddChild} 
					RemoveChild={props.RemoveChild} 
					AddProperty={props.AddProperty} 
					RemoveProperty={props.RemoveProperty} 
					UpdateProperty={props.UpdateProperty} 
					
					DragStart={props.DragStart} 
					Drop={props.Drop} 
					
					key={childNavigation.id} 
					/>
				))}
				<div 
				className={"drop-area" + (props.dragOverIn ? " drag-over" : "")} 
				onDrop={(e) => handleDrop(e, "dragOverIn")} 
				onDragOver={(e) => handleDragOver(e, "dragOverIn")} 
				onDragEnter={(e) => handleDragEnter(e, "dragOverIn")} 
				onDragLeave={(e) => handleDragLeave(e, "dragOverIn")} 
				></div>
			</div>
		</div>
	);
};

export default LiveChatAdminItem;
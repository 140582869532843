import React from "react";
import PostData from "../../packs_helper/scripts/PostData";

class UploadIdCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmKnowledge: false,
			// confirmKnowledge: true,
		};
		this.ConfirmKnowledge = this.ConfirmKnowledge.bind(this);
	}

	ConfirmKnowledge() {
		this.setState({
			confirmKnowledge: true,
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="upload-id-card-wrapper">
					<header className="page-header">
						<a href="/">
							<img src={this.props.logo} alt="GameSatış" width="120" />
						</a>
						<h1>Mağaza & Kimlik Doğrulama Sistemi</h1>
					</header>

					{this.state.confirmKnowledge ? (
						<Video {...this.props} />
					) : (
						<UploadIdCardKnowledge
							ConfirmKnowledge={this.ConfirmKnowledge}
							{...this.props}
						/>
					)}
				</div>
			</React.Fragment>
		);
	}
}

class Video extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			videoWidth: 240,
			videoHeight: 320,
			snapshot: null,
			error: <div>Kameranıza erişebilmemiz için izin vermeniz bekleniyor!</div>,
			saved: false,
			containerWidth: 1920,
			containerHeight: 320,
		};

		this.videoElementRef = React.createRef();
		this.canvasElementRef = React.createRef();
		this.containerRef = React.createRef();

		this.OnPlay = this.OnPlay.bind(this);
		this.CaptureImage = this.CaptureImage.bind(this);
		this.CanvasClickHandler = this.CanvasClickHandler.bind(this);
		this.RemoveSnapshot = this.RemoveSnapshot.bind(this);
		this.SendLinkToMobile = this.SendLinkToMobile.bind(this);
		this.SetContainerSize = this.SetContainerSize.bind(this);
		this.StartMediaDevice = this.StartMediaDevice.bind(this);

		if (typeof window === "undefined") {
			global.window = {};
		}

		this.SendImage = this.SendImage.bind(this);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.SetContainerSize);
	}

	SetContainerSize() {
		if (!this.containerRef.current) {
			return false;
		}
		this.setState(
			{
				containerWidth: 1920,
				containerHeight: 320,
			},
			() => {
				const { videoWidth, videoHeight } = this.state;

				const height = this.containerRef.current.offsetHeight;
				const width = this.containerRef.current.offsetWidth;

				let containerWidth, containerHeight;
				if ((width / height) > (videoWidth / videoHeight)) {
					containerWidth = (videoWidth * height) / videoHeight;
					containerHeight = (videoHeight * containerWidth) / videoWidth;
				} else {
					containerHeight = (videoHeight * width) / videoWidth;
					containerWidth = (videoWidth * containerHeight) / videoHeight;
				}

				this.setState({
					containerWidth,
					containerHeight,
				});
			}
		);
	}

	StartMediaDevice(constraints) {
		return new Promise((resolve, reject) => {
			navigator.mediaDevices
				.getUserMedia(constraints)
				.then((stream) => {
					const tracks = stream.getVideoTracks();
					const capabilities = tracks[0].getCapabilities();
					const facingMode = capabilities.facingMode || [];
					const userMode = facingMode.find((x) => x === "user");

					// const trackConstraints = tracks[0].getConstraints()

					// if (capabilities.width.max < 1080 || capabilities.height.max < 675) {
					// 	this.setState({
					// 		error: (
					// 			<React.Fragment>
					// 				<div>Kameranız evrak çekimi için yeterli çözünürlüğe sahip değil!</div>
					// 				<div>
					// 					<button onClick={this.SendLinkToMobile}>Buraya tıklayarak</button> cep telefonuna sms ile göndereceğimiz linki kullanarak bu sayfaya telefonundan ulaşabilir ve telefonun ile fotoğraf çekerek doğrulama yapabilirsin.
					// 				</div>
					// 			</React.Fragment>
					// 		),
					// 	});
					// 	return false;
					// }

					if(constraints.width > 3840){
					if (userMode) {
						constraints.height = (capabilities.width.max / 16) * 9;
						constraints.width = capabilities.width.max;
					} else {
						constraints.width = (capabilities.height.max / 16) * 9;
						constraints.height = capabilities.height.max;
					}
					}

					// if (userMode) {
					// 	constraints.height = capabilities.width.max / 1.6;
					// 	constraints.width = capabilities.width.max;
					// } else {
					// 	constraints.width = capabilities.height.max / 1.6;
					// 	constraints.height = capabilities.height.max;
					// }

					this.ApplyConstraint(tracks[0], constraints);

					this.setState({ error: null }, () => {
						this.videoElementRef.current.srcObject = stream;
						this.ctx = this.canvasElementRef.current.getContext("2d");
						this.SetCanvasResolution();
					});
					resolve(true);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
	componentDidMount() {
		window.addEventListener("load", this.SetContainerSize, false);
		window.addEventListener("resize", this.SetContainerSize, false);

		let constraints = {
			video: {
				facingMode: "user",
				width: 7680,
				height: 7680,
			},
			audio: false,
		};

		this.StartMediaDevice(constraints).catch((err) => {
			constraints = {
				video: {
					facingMode: "user",
					width: 1920,
					height: 1080,
				},
				audio: false,
			};
			this.StartMediaDevice(constraints).catch((error) => {
				this.setState({
					error: (
						<React.Fragment>
							<div>
								Kameranıza erişirken bir sorunla karşılaştık. Hata:{" "}
								<strong>{error.message}</strong>
							</div>
							<div>
								Eğer şuan kullandığın cihazda bir kamera mevcut değil ise{" "}
								<button onClick={this.SendLinkToMobile}>
									buraya tıklayarak
								</button>{" "}
								cep telefonuna sms ile göndereceğimiz linki kullanarak bu
								sayfaya telefonundan ulaşabilir ve telefonun ile fotoğraf
								çekerek doğrulama yapabilirsin.
							</div>
						</React.Fragment>
					),
				});
			});
		});
	}

	ApplyConstraint(track, constraints) {
		track
			.applyConstraints(constraints)
			.then((e) => {})
			.catch((e) => {
				// this.ApplyConstraint(track, constraints);
			});
	}

	SendLinkToMobile() {
		PostData(this.props.sendLinkPath);
	}

	SetCanvasResolution() {
		this.ctx.clearRect(0, 0, this.state.canvasWidth, this.state.canvasHeight);
	}

	SendImage() {
		this.setState({ saved: true });
		const formData = new FormData();

		formData.append(
			"file",
			this.state.snapshot,
			this.props.imageUniqueId + ".jpg"
		);

		PostData(this.props.savePath, formData).then((response) => {
			if (!response.state) {
				this.setState({ saved: false });
			}
		});
	}

	CaptureImage() {
		const { videoWidth, videoHeight, canvasWidth, canvasHeight } = this.state;
		const rate = this.videoElementRef.current.offsetWidth / videoWidth;

		this.ctx.drawImage(
			this.videoElementRef.current,

			0,
			0,
			videoWidth,
			videoHeight,

			0,
			0,
			canvasWidth,
			canvasHeight
		);

		this.canvasElementRef.current.toBlob(
			(snapshot) => {
				this.setState({
					snapshot,
				});
			},
			"image/jpeg",
			0.92
		);
	}

	RemoveSnapshot() {
		this.setState({ snapshot: null });
		this.SetCanvasResolution();
	}
	OnPlay() {
		const { videoWidth, videoHeight } = this.videoElementRef.current;
		this.setState(
			{
				videoWidth,
				videoHeight,
				canvasHeight: 2160,
				canvasWidth: (2160 * videoWidth) / videoHeight,
			},
			this.SetContainerSize
		);
	}

	CanvasClickHandler() {
		if (this.state.snapshot) {
			this.RemoveSnapshot();
		} else {
			this.CaptureImage();
		}
	}

	render() {
		const {
			snapshot,
			videoWidth,
			videoHeight,
			canvasWidth,
			canvasHeight,
			containerWidth,
			containerHeight,
			error,
		} = this.state;
		return (
			<div className="upload-id-card-container">
				<div className="left-col">
					<div className="left-col-info">
						<div className="left-col-info-title">DİKKAT!</div>
						<div className="left-col-info-row">
							<img src={this.props.assetsRoot + "gms-verify-icon-center.svg"} />
							Yüzünüz fotoğrafta <strong>net</strong> gözükmeli.
						</div>
						<div className="left-col-info-row">
							<img src={this.props.assetsRoot + "gms-verify-icon-focus.svg"} />
							Fotoğrafta <strong>bulanıklık</strong> olmamalı.
						</div>
						<div className="left-col-info-row">
							<img src={this.props.assetsRoot + "gms-verify-icon-hat.svg"} />
							Kafanızı kapatan <strong>eşya</strong> olmamalı.
						</div>
						<div className="left-col-info-row">
							<img
								src={this.props.assetsRoot + "gms-verify-icon-glasses.svg"}
							/>
							Yüzünüzü kapatan <strong>eşya</strong> olmamalı.
						</div>

						<div className="left-col-info-footer">
							<small>
								Kameranızı kullanarak <strong>çekim yapabilmeniz</strong> için
								tarayıcınızdan "gamesatis.com" için "Kameranızı kullanma" izni
								vermeniz gerekir.
							</small>
							<img
								src={this.props.assetsRoot + "gms-verify-allow-tip.png"}
								width="300"
								height="130"
							/>
						</div>
					</div>

					{!error &&
						(snapshot ? (
							<button
								type="button"
								onClick={this.RemoveSnapshot}
								className="btn btn-orange"
							>
								<img
									src={this.props.assetsRoot + "gms-verify-icon-portrait.svg"}
								/>
								Tekrar Çek
							</button>
						) : (
							<button
								type="button"
								onClick={this.CaptureImage}
								className="btn btn-green"
							>
								<img
									src={this.props.assetsRoot + "gms-verify-icon-portrait.svg"}
								/>
								Fotoğrafımı Çek
							</button>
						))}
				</div>

				<div className="right-col">
					{error ? (
						<div className="error-container">
							<div className="error-container-body">{error}</div>
						</div>
					) : (
						<React.Fragment>
							<div
								className={
									"upload-photo-preview" + (snapshot ? " captured" : "")
								}
								ref={this.containerRef}
							>
								<div
									className="upload-photo-preview-body"
									style={{ width: containerWidth, height: containerHeight }}
								>
									<div
										style={{
											position: "absolute",
											top: ".25rem",
											right: ".25rem",
											zIndex: 999999,
											backgroundColor: "black",
											padding: ".25rem",
											borderRadius: ".25rem",
											color: "lawngreen",
											opacity: 0.4,
										}}
									>
										{videoWidth}x{videoHeight}
									</div>
									<video
										ref={this.videoElementRef}
										onPlay={this.OnPlay}
										width={canvasWidth}
										height={canvasHeight}
										controls={false}
										autoPlay={true}
										playsInline={true}
									/>
									<canvas
										ref={this.canvasElementRef}
										width={canvasWidth}
										height={canvasHeight}
										onClick={this.CanvasClickHandler}
									/>
								</div>
							</div>

							{!error &&
								(snapshot ? (
									<button
										type="button"
										onClick={this.RemoveSnapshot}
										className="btn btn-orange hide-lg"
									>
										<img
											src="/assets/upload-id-card/gms-verify-icon-portrait.svg" />
										Tekrar Çek
									</button>
								) : (
									<button
										type="button"
										onClick={this.CaptureImage}
										className="btn btn-green hide-lg"
									>
										<img src="/assets/upload-id-card/gms-verify-icon-portrait.svg" />
										Fotoğrafımı Çek
									</button>
								))}

							<button
								type="button"
								onClick={this.SendImage}
								className="btn btn-blue"
								disabled={!snapshot || this.state.saved}
							>
								<img src="/assets/upload-id-card/gms-verify-icon-2.svg" />
								Gönder
							</button>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

const UploadIdCardKnowledge = (props) => {
	const GoToVerification = function () {
		redirect(props.verificationPath);
	};

	if (!props.preCheck) {
		ShowPopup({
			title: "Site Mesajı",
			message:
				'Mağaza & Kimlik Doğrulama yapabilmeniz için öncelikle Telefon ve T.C. doğrulaması yapmanız gerekmektedir.<button value="0" data-close>Kapat</button><button value="goToVerification" class="btn btn-blue" data-close>T.C. Kimlik ve Telefon Doğrula</button>',
		}).then((button) => {
			if (button.value === "goToVerification") {
				GoToVerification();
			}
		});
	}

	return (
		<div className="upload-id-card-knowledge">
			<div className="upload-id-card-knowledge-left">
				<img src={props.assetsRoot + "gms-verify-knowledge-1.png"} />
			</div>
			<div className="upload-id-card-knowledge-right">
				<header>Nasıl Olmamalı?</header>
				<p>
					Yüzünüz veya kimliğinizin görünmesini engelleyecek her türlü aksesuar,
					takı ve bilimum eklemeden kaçının.
				</p>
				<footer>
					<img src={props.assetsRoot + "gms-verify-icon-glasses.svg"} />
					<img src={props.assetsRoot + "gms-verify-icon-hat.svg"} />
				</footer>
			</div>

			<div className="upload-id-card-knowledge-left">
				<img src={props.assetsRoot + "gms-verify-knowledge-2.png"} />
			</div>
			<div className="upload-id-card-knowledge-right">
				<header>Nasıl Olmalı?</header>
				<p className="mb-2">
					Çektiğiniz fotoğrafta yüzünüz net olarak görünmeli, herhangi bir
					bulanıklık olmamalı ve kimliğiniz okunaklı olmalıdır.
				</p>
				<p>
					Üzerinde gamesatis.com - tarih (gün/ay/yıl) yazan bir kağıt ile
					birlikte kimliğinizi göndermeniz gerekmektedir.
				</p>
				<footer>
					<img src={props.assetsRoot + "gms-verify-icon-focus.svg"} />
					<img src={props.assetsRoot + "gms-verify-icon-center.svg"} />
				</footer>
			</div>

			<div className="upload-id-card-knowledge-full grid-1">
				<div className="upload-id-card-knowledge-left">
					<img src={props.assetsRoot + "gms-verify-icon.svg"} />
				</div>
				<div className="upload-id-card-knowledge-right">
					<header>Rahat Olun, Verileriniz Güvende.</header>
					<p>
						<small>
							Bizimle paylaşmakta olduğunuz veri ve bilgileriniz, sizlere daha
							güvenli bir alışveriş ortamı oluşturabilmemizi sağlamaktadır.
						</small>
					</p>
					<p>
						<small>
							<em>
								Bizimle paylaşmış olduğunuz veri ve bilgileriniz hiçbir koşulda
								3. şahıslarla paylaşılmaz.
							</em>
						</small>
					</p>
				</div>
			</div>

			{props.preCheck ? (
				<button
					type="button"
					className="btn btn-blue upload-id-card-knowledge-full"
					onClick={props.ConfirmKnowledge}
				>
					<img src={props.assetsRoot + "gms-verify-icon-2.svg"} />
					Okudum & Anladım
				</button>
			) : (
				<button
					type="button"
					className="btn btn-red upload-id-card-knowledge-full"
					onClick={GoToVerification}
				>
					<img src={props.assetsRoot + "gms-verify-icon-2.svg"} />
					T.C. Kimlik ve Telefon Doğrulamaya Git
				</button>
			)}
		</div>
	);
};

export default UploadIdCard;
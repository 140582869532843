import React from "react";
import PaymentMethodsCore from "./PaymentMethodsCore";
import PaymentGridListItemCreditCard from "./PaymentGridListItemCreditCard";
import PaymentGridListItemBankTransfer from "./PaymentGridListItemBankTransfer";
import PaymentGridListItemMobilePayment from "./PaymentGridListItemMobilePayment";
import PaymentGridListItemExchange from "./PaymentGridListItemExchange";
import PaymentGridListItemEpin from "./PaymentGridListItemEpin";
import PaymentGridListItemPromo from "./PaymentGridListItemPromo";
import PaymentGridFilterButton from "./PaymentGridFilterButton";

const paymentGroupToComponents = {
	credit_card: PaymentGridListItemCreditCard,
	transfer: PaymentGridListItemBankTransfer,
	mobile_payment: PaymentGridListItemMobilePayment,
	exchange: PaymentGridListItemExchange,
	epin: PaymentGridListItemEpin,
	promo: PaymentGridListItemPromo,
};

class DesktopRenderer extends PaymentMethodsCore{
	constructor(props){
		super(props);
		this.ComponentOpen = this.ComponentOpen.bind(this);
		// this.state.openComponent = -1;
	}

	ComponentOpen(activeTab){
		this.setState({
			activeTab
		})
	}


	render() {
		const { activeGroup, activeTab } = this.state;
		const PaymentGridListItemComponent = paymentGroupToComponents[activeGroup];
		const exchangePermission = this.props.paymentMethods.find((paymentMethod) => paymentMethod["name"].includes("Takas"));
		return (
			<section className="container">
				<div className="payment-grid">
					<div className="payment-grid-filter">
						<header>
							<h1>Ödeme Seçenekleri</h1>
							<label>Lütfen ödeme yönteminizi seçiniz</label>
						</header>
						<div className="payment-grid-filter-body">
							<PaymentGridFilterButton
								onClick={this.ChangeActiveGroup}
								group="credit_card"
								icon="credit-card"
								active={activeGroup === "credit_card" ? " active" : ""}
								label="Kredi/Banka Kartı"
							/>
							<PaymentGridFilterButton
								onClick={this.ChangeActiveGroup}
								group="transfer"
								active={activeGroup === "transfer" ? " active" : ""}
								icon="bank-transfer"
								label="Havale/EFT"
							/>
							{/*<PaymentGridFilterButton*/}
							{/*	onClick={this.ChangeActiveGroup}*/}
							{/*	group="mobile_payment"*/}
							{/*	active={activeGroup === "mobile_payment" ? " active" : ""}*/}
							{/*	icon="mobile-payment"*/}
							{/*	label="Mobil Ödeme"*/}
							{/*/>*/}

							{exchangePermission && (
									<PaymentGridFilterButton
									onClick={this.ChangeActiveGroup}
									group="exchange"
									active={activeGroup === "exchange" ? " active" : ""}
									icon="exchange"
									label="Takas"
								/>
								)
							}
							
							<PaymentGridFilterButton
								onClick={this.ChangeActiveGroup}
								group="epin"
								active={activeGroup === "epin" ? " active" : ""}
								icon="epin"
								label="GameSatış E-Pin"
							/>
							<PaymentGridFilterButton
								onClick={this.ChangeActiveGroup}
								group="promo"
								active={activeGroup === "promo" ? " active" : ""}
								icon="promo"
								label="GameSatış Promo"
							/>
						</div>
					</div>
					<div className="payment-grid-list">
						{this.state.filteredPaymentMethods.map((paymentMethod) => (
							<PaymentGridListItemComponent
								show={paymentMethod.id == this.state.activeTab || this.state.filteredPaymentMethods.length === 1}
								paymentMethod={paymentMethod}
								fullname={this.props.fullname}
								savePath={this.props.savePath}
								key={`payment-${paymentMethod.id}`}
								ComponentOpen={this.ComponentOpen}
								quickSelectAmounts={this.quickSelectAmounts}
							/>
						))}
					</div>
				</div>
			</section>
		);
	}
}

export default DesktopRenderer;
import React from "react";
import KoItemCore from "./KoItemCore";
import SelectBox from "../../../Common/SelectBox";
import PriceFieldTag from "../../../Common/PriceFieldTag";
import GlobalVar from "../../../GlobalVar";

class MobileRenderer extends KoItemCore{
	render() {

		return (
			<React.Fragment>
				<div className="item-grid">
					<SelectBox
						xlinkHref="#gs-icon-user-panel-caret-down"
						options={this.state.categories}
						onChange={this.ChangeGame}
						selected={this.state.selectedGame && this.state.selectedGame.id}
						filter={true}
					/>

					<SelectBox
						xlinkHref="#gs-icon-user-panel-caret-down"
						options={this.state.servers}
						onChange={this.ChangeServer}
						selected={this.state.selectedServer && this.state.selectedServer.id}
						prompt="SERVER SEÇİNİZ..."
						filter={true}
					/>

					<SelectBox
						xlinkHref="#gs-icon-user-panel-caret-down"
						options={this.state.items}
						onChange={this.ChangeItem}
						selected={this.state.selectedItem && this.state.selectedItem.id}
						prompt="ITEM SEÇİNİZ..."
						filter={true}
					/>
				</div>

				<div className="item-form">
					<picture className="item-form-picture">
						<img src={this.state.imagePath} />
					</picture>

					<div className="item-form-grid">
						<SelectBox
							xlinkHref="#gs-icon-user-panel-caret-down"
							prompt={"TYPE"}
							options={this.state.types}
							onChange={this.ChangeType}
							selected={this.state.selectedType && this.state.selectedType.id}
						/>

						<SelectBox
							xlinkHref="#gs-icon-user-panel-caret-down"
							prompt={"GRADE"}
							options={this.state.grades}
							onChange={this.ChangeGrade}
							selected={this.state.selectedGrade && this.state.selectedGrade.id}
						/>

						<div className="grid-2x">
							<input
								placeholder="Ürün Adı:"
								type="text"
								id="name"
								disabled={true}
								value={this.state.itemName}
							/>
						</div>

						<PriceFieldTag
							wrapperClass="input-with-label"
							label="Fiyat:"
							id="price"
							onChange={this.UpdatePrice}
							value={this.state.price}
						/>

						<PriceFieldTag
							wrapperClass="input-with-label"
							label="Hesabınıza Geçecek Tutar:"
							id="user-price"
							onChange={this.UpdateUserPrice}
							value={this.state.user_price}
						/>

						<textarea placeholder="Açıklama" onChange={this.ChangeDescription} />
					</div>
				</div>

				<div className="item-footer">
					<div className="delivery-time-grid">
						<strong>TESLİMAT SAATİ:</strong>
						<SelectBox
							xlinkHref="#gs-icon-user-panel-caret-down"
							prompt={"Seçiniz"}
							options={GlobalVar.DELIVERY_TIMES.map((property) => ({
								id: property,
								name: property + ' Saat',
							}))}
							onChange={this.DeliveryTimeChange}
							selected={this.state.deliveryTime}
							className="grid-2x"
							maxHeight="20rem"
						/>
					</div>

					<div className="checkbox">
						<input
							type="checkbox"
							name="sale_agreement"
							id="sale_agreement"
							onChange={(e) =>
								this.setState({ agreementChecked: e.currentTarget.checked })
							}
						/>
						<label htmlFor="sale_agreement">
							<div>
								<a href="/kullanici-sozlesmesi#oyuncu-pazari" target="_blank">
									Oyuncu Pazarı Sözleşmesi
								</a>{" "}
								ve şartlarını okudum kabul ediyorum.
							</div>
						</label>
					</div>

					<button
						className="btn btn-blue"
						onClick={this.SaveProduct}
						disabled={this.state.sendDisabled}
					>
						SATIŞA ÇIKART
					</button>
				</div>
			</React.Fragment>
		);
	}
}

export default MobileRenderer;

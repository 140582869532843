import React from "react";

const DateFormatter = (props) => {
   if (props !== null){
        const inputDate = new Date(props);
        const month = (inputDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
        const day = inputDate.getDate()
            .toString()
            .padStart(2, "0");
        return day + "/" + month + "/" + inputDate.getFullYear();
   }else{
       return null;
   }
};

export default DateFormatter;
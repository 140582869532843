import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import SelectBox from "../../Common/SelectBox";
import PopUp from "../../Common/PopUp";
import ImageWithFallback from "../../Common/ImageWithFallback";
import PriceFieldTag from "../../Common/PriceFieldTag";
import Decimal from "../../../packs_helper/scripts/Decimal";
import Currency from "../../../packs_helper/scripts/Currency";
import GlobalVar from "../../GlobalVar";

class DigitalCode extends React.Component {
	constructor(props) {
		super(props);

		this.keyId = 1;
		this.state = {
			categories: [],
			rate: 0,
			price: 0,
			user_price: 0,
			products: [],
			selectedGame: null,
			selectedProduct: null,
			description: "",
			digitalCodes: [...Array(10)].map((x) => ({ id: this.keyId++, value: "" })),
			sendDisabled: false,
			showBulkForm: false,
			agreementChecked: false,
			sell_agreement: true,
		};

		this.GetGames = this.GetGames.bind(this);
		this.GetProducts = this.GetProducts.bind(this);

		this.ChangeGame = this.ChangeGame.bind(this);
		this.ChangeProduct = this.ChangeProduct.bind(this);
		this.ChangeDescription = this.ChangeDescription.bind(this);

		this.SaveProduct = this.SaveProduct.bind(this);
		this.OnCodeChange = this.OnCodeChange.bind(this);
		this.AddNewRowHandler = this.AddNewRowHandler.bind(this);
		this.AddNewRow = this.AddNewRow.bind(this);
		this.RemoveRow = this.RemoveRow.bind(this);
		this.ToggleBulkForm = this.ToggleBulkForm.bind(this);
		this.BulkFormApply = this.BulkFormApply.bind(this);
		this.UpdatePrice = this.UpdatePrice.bind(this);
		this.UpdateUserPrice = this.UpdateUserPrice.bind(this);

	}

	componentDidMount() {
		this.GetGames();
	}

	GetGames() {
		PostData("/ilan-oyunlari", {
			type: "digital_codes",
		}).then((response) => {
			this.setState({
				categories: response.categories,
				rate: response.rate,
				selectedGame: response.categories[0],
			});
			this.GetProducts(response.categories[0].id);
		});
	}

	GetProducts(category_id) {
		PostData("/ilan-urunleri", {
			category_id,
		}).then((response) => {
			this.setState({
				products: response.products,
				selectedProduct: response.products[0],
			});
		});
	}

	ChangeGame(selectedGameId) {
		const categories = this.state.categories;
		const selectedGame = categories.find(
			(category) => category.id == selectedGameId
		);
		this.setState({ selectedGame });
	}
	ChangeProduct(selectedProductId) {
		const products = this.state.products;
		const selectedProduct = products.find(
			(product) => product.id == selectedProductId
		);
		this.setState({ selectedProduct });
	}

	ChangeDescription(e) {
		const description = e.currentTarget.value;
		this.setState({ description });
	}

	UpdatePrice(price) {
		const user_price = price * (1 - this.state.rate / 100);
		this.setState({ price, user_price });
	}

	UpdateUserPrice(user_price) {
		const price = user_price / (1 - this.state.rate / 100);
		this.setState({ price, user_price });
	}

	SaveProduct(confirm) {
		this.setState({ sendDisabled: true });
		const formData = new FormData();

		if (!this.state.agreementChecked) {
			ShowPopup({ message: "Lütfen sözleşmeyi onaylayınız!", title: "Hata" });
			this.setState({ sendDisabled: false });
			return false;
		}

		if (!this.state.price) {
			ShowPopup({ message: "Lütfen ilana fiyat girin!", title: "Hata" });
			this.setState({ sendDisabled: false });
			return false;
		}

		const min_cost = Decimal(this.state.selectedProduct.cost);

		if (min_cost > this.state.price) {
			ShowPopup({
				message: "Birim fiyat en az " + Currency(min_cost) + " ₺ olabilir.",
				title: "Hata",
			});
			this.setState({ sendDisabled: false });
			return false;
		}

		const digitalCodes = this.state.digitalCodes.map((digitalCode) =>
			digitalCode.value.trim()
		);
		const filteredDigitalCodes = digitalCodes.filter(
			(digitalCode, index) =>
				!!digitalCode && digitalCodes.indexOf(digitalCode) == index
		);

		if (filteredDigitalCodes.length === 0) {
			ShowPopup({
				message:
					"Dijital kod satışı yapabilmek için en az 1 adet kod girmeniz gerekmektedir.",
				title: "Hata",
			});
			this.setState({ sendDisabled: false });
			return false;
		} else {
			for (var i in filteredDigitalCodes) {
				formData.append("product[digital_codes][]", filteredDigitalCodes[i]);
			}
		}

		formData.append("product[cost]", this.state.price);
		formData.append("product[category_id]", this.state.selectedGame.id);
		formData.append("product[product_id]", this.state.selectedProduct.id);
		formData.append("product[description]", this.state.description);
		formData.append("advertItemCheck", this.state.agreementChecked);

		PostData("/item-ilani-ver", formData).then((response) => {
			if (response.state) {
				this.props.ChangeRoute("/ilanlarim", "");
			}
			// else if (!!response.fee) {
			// 	_this.setState({
			// 		feeConfirm: response.fee,
			// 		sendDisabled: false,
			// 	});
			// }
			else {
				this.setState({ sendDisabled: false });
			}
		});
	}
	AddNewRowHandler() {
		this.AddNewRow();
	}

	AddNewRow(value = "") {
		if(this.state.digitalCodes.length <= GlobalVar.DIGITAL_CODE_MAX_ROW){
			this.keyId++;
			const digitalCodes = this.state.digitalCodes;
			digitalCodes.push({
				id: this.keyId,
				value,
			});
			this.setState({ digitalCodes });
		}else{
			ShowPopup({
				message: "Tek seferde en fazla " + GlobalVar.DIGITAL_CODE_MAX_ROW + " adet kod ekleyebilirsiniz.",
				title: "Hata"
			})
		}
	}

	RemoveRow(id) {
		const digitalCodes = this.state.digitalCodes;
		const index = digitalCodes.findIndex(
			(digitalCode) => digitalCode.id === id
		);

		digitalCodes.splice(index, 1);
			this.setState({ digitalCodes }, () => {
				if(digitalCodes.length === 0){
					this.AddNewRow('');
				}
			});
	}

	OnCodeChange(id, value) {
		const digitalCodes = this.state.digitalCodes;
		const index = digitalCodes.findIndex(
			(digitalCode) => digitalCode.id === id
		);
		digitalCodes[index].value = value;
		this.setState({ digitalCodes: digitalCodes });
	}

	ToggleBulkForm() {
		this.setState((prevState) => ({ showBulkForm: !prevState.showBulkForm }));
	}

	BulkFormApply(value) {
		const digitalCodes = this.state.digitalCodes.filter((x) => x.value.trim());
		this.setState({ digitalCodes }, () => {
			const lines = value.split("\n");
			lines.filter((x) => x.trim()).forEach((line) => this.AddNewRow(line));
		});
	}

	render() {
		const digitalCodeCount = this.state.digitalCodes.filter((x) =>
			x.value.trim()
		).length;
		return (
			<React.Fragment>
				{this.state.sell_agreement && this.state.selectedGame?.buy_sell_description !== '' ? (<>
					<div className="digital-code-grid" style={{textAlign: 'center', gridTemplateColumns: '1fr'}}>
						Random Key Satış Kuralları
					</div>
					<div className="digital-code-form" style={{gridTemplateColumns: '1fr'}} dangerouslySetInnerHTML={{__html: this.state.selectedGame?.buy_sell_description}} />
					<div className="digital-code-footer" style={{justifyContent: 'end'}}>
						<button
							className="btn btn-blue"
							onClick={() => this.setState({sell_agreement: false})}
						>
							Okudum, onaylıyorum!
						</button>
					</div>
				</>):(<>
					<div className="digital-code-grid">
						<SelectBox
							options={this.state.categories}
							onChange={this.ChangeGame}
							selected={this.state.selectedGame && this.state.selectedGame.id}
							filter={true}
							xlinkHref="#gs-icon-user-panel-caret-down"
						/>

						<SelectBox
							options={this.state.products}
							onChange={this.ChangeProduct}
							selected={
								this.state.selectedProduct && this.state.selectedProduct.id
							}
							filter={true}
							xlinkHref="#gs-icon-user-panel-caret-down"
						/>
					</div>
					{this.state.selectedProduct && (
						<React.Fragment>
							<div className="digital-code-form">
								<picture>
									<ImageWithFallback
										src={this.state.selectedProduct.info}
									/>
								</picture>

								<div>
									<PriceFieldTag
										wrapperClass="input-with-label"
										label="Fiyat:"
										placeholder="0,00"
										id="price"
										onChange={this.UpdatePrice}
										value={this.state.price}
									/>

									<PriceFieldTag
										wrapperClass="input-with-label"
										label="Hesabınıza Geçecek Tutar:"
										placeholder="0,00"
										id="price"
										onChange={this.UpdateUserPrice}
										value={this.state.user_price}
									/>
									<div className="item-form digital-code-desc">
										<div className="input-with-label grid-4x for-text-area">
											<small className="text-limit-counter">{this.state.description.length}/1200</small>
											<textarea
												placeholder="Açıklama"
												className="grid-4x"
												onChange={this.ChangeDescription}
												maxLength={1200}
											/>
										</div>
									</div>
								</div>

								<div>
									<div className="digital-code-form-list">
										<div className="digital-code-form-list-header">
											<label>Dijital Kodlar:</label>
											<button
												className="btn btn-sm btn-blue"
												onClick={this.AddNewRowHandler}
											>
												Kod Ekle
											</button>
											<button
												className="btn btn-sm btn-blue"
												onClick={this.ToggleBulkForm}
											>
												Toplu Kod Ekle
											</button>
										</div>
										<div className="digital-code-form-list-body">
											{this.state.digitalCodes.map((digitalCode, index) => (
												<DigitalCodeInput
													value={digitalCode.value}
													onChange={this.OnCodeChange}
													onRemove={this.RemoveRow}
													order={index + 1}
													rows={GlobalVar.DIGITAL_CODE_MAX_ROW}
													duplicate={
														digitalCode.value.trim() &&
														this.state.digitalCodes.findIndex(
															(dc) => dc.value === digitalCode.value
														) != index
													}
													id={digitalCode.id}
													key={digitalCode.id}
												/>
											))}
										</div>
									</div>
								</div>
							</div>

							<div className="digital-code-footer">
								<div className="checkbox">
									<input
										type="checkbox"
										name="sale_agreement"
										id="sale_agreement"
										onChange={(e) =>
											this.setState({ agreementChecked: e.currentTarget.checked })
										}
									/>
									<label htmlFor="sale_agreement">
										<div>
											<a href="/kullanici-sozlesmesi#oyuncu-pazari" target="_blank">
												Oyuncu Pazarı Sözleşmesi
											</a>{" "}
											ve şartlarını okudum kabul ediyorum.
										</div>
									</label>
								</div>

								<div className="skin-result-footer-info">
									{digitalCodeCount} Adet Eklendi
								</div>

								<button
									className="btn btn-blue"
									onClick={this.SaveProduct}
									disabled={
										this.state.sendDisabled ||
										this.state.digitalCodes.length === 0
									}
								>
									Kaydet
								</button>
							</div>
						</React.Fragment>
					)}

					{this.state.showBulkForm && (
						<DigitalCodeBulkForm
							onClose={this.ToggleBulkForm}
							onSave={this.BulkFormApply}
						/>
					)}
				</>)}
			</React.Fragment>
		);
	}
}

const DigitalCodeInput = (props) => {
	const OnChange = (e) => {
		const value = e.currentTarget.value
			.replace(/^\s+/, "")
			.replace(/\s+$/, " ");
		// props.value = code;
		props.onChange(props.id, value);
	};
	const OnRemove = (e) => {
		props.onRemove(props.id);
	};

	const className = props.duplicate ? "duplicate" : null;
	return (
		<React.Fragment>
			<label className={className}>{props.order}.</label>
			<input
				type="text"
				onChange={OnChange}
				value={props.value}
				className={className}
			/>
			<button className="btn btn-red" onClick={OnRemove}>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-rejected"></use>
				</svg>
			</button>
		</React.Fragment>
	);
};

const DigitalCodeBulkForm = (props) => {
	const [lineCount, SetLineCount] = React.useState(10);
	const [value, SetValue] = React.useState("");

	const OnChange = (e) => {
		const v = e.currentTarget.value;
		const lineCount = v.split("\n").length;
		if(lineCount < GlobalVar.DIGITAL_CODE_MAX_ROW){
			SetLineCount(lineCount < 10 ? 10 : lineCount);
			SetValue(v);
		}else{
			ShowPopup({
				message: "Tek seferde en fazla " + GlobalVar.DIGITAL_CODE_MAX_ROW + " adet kod ekleyebilirsiniz.",
				title: "Hata"
			})
		}
	};

	const OnSave = (e) => {
		props.onSave(value);
		SetValue("");
		props.onClose();
	};

	const lineArray = [...Array(lineCount)].map((x, y) => y + 1);
	return (
		<PopUp title="Toplu Kod Ekleme" Hide={props.onClose}>
			<div>
				<small>
					Her satıra bir kod gelecek şekilde dijital kodlarınızı ekleyin.
				</small>
			</div>
			<div className="digital-code-bulk-list">
				<ol>
					{lineArray.map((line) => (
						<li key={line}>{line}.</li>
					))}
				</ol>
				<textarea
					style={{ height: lineCount * 1.5 + "em" }}
					onChange={OnChange}
					value={value}
				/>
			</div>
			<div className="digital-code-bulk-list-footer">
				<button className="btn btn-default" onClick={props.onClose}>
					Kapat
				</button>
				<button className="btn btn-blue" onClick={OnSave}>
					Ekle
				</button>
			</div>
		</PopUp>
	);
};

export default DigitalCode;

import React from "react";
import ProfileBoxInput from "../_ProfileBoxInput";
import SettingsBox from "./SettingsBox";
import PopUp from "../../Common/PopUp";
import PostData from "../../../packs_helper/scripts/PostData";

class NotificationSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			popup: null,
		};
		this.TogglePopup = this.TogglePopup.bind(this);
		this.SaveNotification = this.SaveNotification.bind(this);
	}

	TogglePopup(e) {
		const popup = e.currentTarget.value;

		this.setState({
			popup,
		});
	}

	SaveNotification(e){
		e.preventDefault();
		const formData = new FormData(e.target);

		PostData("/bildirim-izinleri", formData)
			.then((data) => {
				this.props.UpdateData();
				this.setState({ popup: null });


			})
			.catch((err) => {


			});
	}

	render() {
		const sms_send_permission = this.props.sms_send_permission || this.props.sms_send_permission === null;
		const email_send_permission = this.props.email_send_permission || this.props.email_send_permission === null;
		return (
			<SettingsBox
			icon="notification"
			title="Bildirim Tercihleri"
			id="notification-settings"
			show={this.props.activeBox === "notification-settings"}
			BoxOpen={this.props.BoxOpen}
			>
				<div className="profile-settings">

					<div className="settings-input">
						<label>SMS Bildirimleri:</label>
						<div className={"settings-input-text " + (sms_send_permission  ? 'text-green' : 'text-red')}>{sms_send_permission ? 'AÇIK' : 'KAPALI'}</div>
						<button
							className="btn btn-light"
							onClick={this.TogglePopup}
							value="change-sms-notifications"
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-edit"></use>
							</svg>
						</button>
					</div>

					<div className="settings-input">
						<label>E-Posta Bildirimleri:</label>
						<div className={"settings-input-text " + (email_send_permission ? 'text-green' : 'text-red')}>{email_send_permission ? 'AÇIK' : 'KAPALI'}</div>
						<button
							className="btn btn-light"
							onClick={this.TogglePopup}
							value="change-email-notifications"
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-edit"></use>
							</svg>
						</button>
					</div>
				</div>

				{this.state.popup === "change-sms-notifications" && (
					<PopUp
						title="SMS Bildirimleri"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.SaveNotification}
							className="grid-1"
							autoComplete="off"
						>
						<div>Bana özel kampanya ve fırsatların SMS olarak gönderilmesini istiyorum.</div>
						<div className="grid-2">
							<div className="input radio text-red">
								<input type="radio" name="sms_send_permission" id="sms_send_permission_0" defaultChecked={!sms_send_permission} value="0" />
								<label htmlFor="sms_send_permission_0">KAPALI:</label>
							</div>
							<div className="input radio text-green">
								<input type="radio" name="sms_send_permission" id="sms_send_permission_1" defaultChecked={sms_send_permission} value="1" />
								<label htmlFor="sms_send_permission_1">AÇIK:</label>
							</div>
							</div>
							<button className="btn btn-blue">Değiştir</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "change-email-notifications" && (
					<PopUp
						title="E-Posta Bildirimleri"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.SaveNotification}
							className="grid-1"
							autoComplete="off"
						>
						<div>Bana özel kampanya ve fırsatların E-Posta olarak gönderilmesini istiyorum.</div>
						<div className="grid-2">
							<div className="input radio text-red">
								<input type="radio" name="email_send_permission" id="email_send_permission_0" defaultChecked={!email_send_permission} value="0" />
								<label htmlFor="email_send_permission_0">KAPALI:</label>
							</div>
							<div className="input radio text-green">
								<input type="radio" name="email_send_permission" id="email_send_permission_1" defaultChecked={email_send_permission} value="1" />
								<label htmlFor="email_send_permission_1">AÇIK:</label>
							</div>
							</div>
							<button className="btn btn-blue">Değiştir</button>
						</form>
					</PopUp>
				)}

			</SettingsBox>
		);
	}
}

export default NotificationSettings;

import React from 'react';
import DecimalFieldTag from "../../../../Common/DecimalFieldTag";
import PriceFieldTag from "../../../../Common/PriceFieldTag";
import StringFieldTag from "../../../../Common/StringFieldTag";
import SelectBox from "../../../../Common/SelectBox";
import ConfirmAccountImage from "../../../../../assets_v3/images/user-panel/confirm-account.jpeg"
import ConfirmAccountMobileImage from "../../../../../assets_v3/images/user-panel/confirm-account-mobile.jpeg"

import ConfirmedAccountImage from "../../../../../assets_v3/images/user-panel/confirmed-account.jpeg"
import ConfirmedAccountMobileImage from "../../../../../assets_v3/images/user-panel/confirmed-account-mobile.jpeg"
import GlobalVar from "../../../../GlobalVar";


class PriceAmount extends React.Component {
    render() {
        return (
            <div>
                {this.props.adSell && !['physical'].includes(this.props.type) && (
                    <div className='wizard-price-alert'>
                        {
                            !this.props.userConfirmed ? (
                                <a href="/magaza-ve-kimlik-dogrulama" target="_blank">
                                    <img
                                        src={this.props.isMobile ? ConfirmAccountMobileImage:ConfirmAccountImage}
                                    />
                                </a>
                            ):(
                                <a href="/magaza-paketleri" target="_blank">
                                    <img
                                        src={this.props.isMobile ? ConfirmedAccountMobileImage:ConfirmedAccountImage}
                                    />
                                </a>
                            )
                        }
                    </div>
                )}
                <div className={this.props.isMobile ? "wizard-price-content":"grid-2"}>

                    <div>
                        <PriceFieldTag
                            wrapperClass="input-with-label grid-2x"
                            label="Fiyat:"
                            id="price"
                            onChange={this.props.PriceChange}
                            value={this.props.priceValue}
                            max={999999}
                            autoFocus
                        />
                    </div>
                    {!this.props.adSell && (
                        <div>
                            <SelectBox
                                xlinkHref="#gs-icon-user-panel-caret-down"
                                prompt={"Yeterli Bakiye Gözüksün mü?"}
                                className="grid-2x"
                                selected={this.props.showBalance}
                                options={
                                    [{id: 1, name: "Evet", value: 1}, {id: 0, name: "Hayır", value: 0}]
                                }
                                onChange={this.props.UpdateShowBalance}
                                id='show-balance-select'
                            />
                        </div>
                    )}
                    {this.props.adSell && !['physical'].includes(this.props.type) && (
                        <div className="input-with-label">
                            <label htmlFor="commissionRate">Size Özel Komisyon Oranı:</label>
                            <StringFieldTag
                                id="commissionRate"
                                value={
                                    '%' +
                                    ["accounts", "boost"].includes(this.props.type) &&
                                    this.props.priceValue >= GlobalVar.LIMIT_CHANGE_RATE && GlobalVar.RATE_AFTER_LIMIT < this.props.rate ?
                                        GlobalVar.LIMIT_CHANGE_RATE + " ₺ üstü %" + GlobalVar.RATE_AFTER_LIMIT + " Komisyon"
                                    :
                                        this.props.rate
                                }
                                disabled
                            />
                        </div>
                    )}
                    {this.props.adSell && !['physical'].includes(this.props.type) && (
                        <div>
                            <PriceFieldTag
                                wrapperClass="input-with-label grid-2x"
                                label="Hesabınıza Geçecek Tutar:"
                                id="user-price"
                                onChange={this.props.UserPriceChange}
                                value={this.props.userPriceValue}
                            />
                        </div>
                    )}
                    {this.props.adSell && (
                        <div className="input-with-label">
                            <label htmlFor="quantity">Adet:</label>
                            <DecimalFieldTag
                                id="quantity"
                                value={this.props.quantityValue}
                                min={this.props.quantityMin}
                                max={this.props.quantityMax}
                                onChange={this.props.QuantityChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default PriceAmount;
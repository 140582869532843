import React from "react";
import PopUp from "../../Common/PopUp";
import PostData from "../../../packs_helper/scripts/PostData";

class SmsMessageBox extends React.Component {
	constructor(props) {
		super(props);
		this.messageLimit = 140;
		this.state = {
			message: props.message.substr(0, this.messageLimit),
			confirm: false
		};
		this.SendMessage = this.SendMessage.bind(this);
		// this.RenderCharLength = this.RenderCharLength.bind(this);
	}

	SendMessage() {
		let _this = this;
		const message = this.state.message;
		const post_path = "/saticiya-sms-gonder";
		if (message.length > this.messageLimit) {
			ShowPopup({
				message: `Mesajınız ${this.messageLimit} karakterden uzun olamaz!`,
			});
			return false;
		}

		const formData = new FormData();
		if (!!this.props.ProductId) {
			formData.append("product_id", this.props.ProductId);
		} else if (!!this.props.UserCode) {
			formData.append("user_code", this.props.UserCode);
		} else {
			ShowPopup({
				message: "Hatalı bir istekte bulundunuz!",
			});
			return false;
		}
		formData.append("message", message);
		if(!this.state.confirm){
			formData.append("pre_provision", "true");
		}else{
			formData.append("pre_provision", "false");
			this.setState({confirm: false});
		}
		PostData(post_path, formData).then((response) => {
				if (response.waiting_response){
					ShowPopup({
						title: "Site Mesajı",
						message: response.sms_message + ' <button class="btn btn-default" data-close value="0">Vazgeç</button><button class="btn btn-jungle" data-close value="1">Onayla</button>',
					}).then((button) => {
						if (button.value == 1) {
							this.setState({confirm: true});
							this.SendMessage();
							this.props.Hide();
						}
					});
				}
			}
		);
	}

	RenderCharLength() {
		const length = this.messageLimit - this.state.message.length;
		if (length < 0) {
			return (
				<span className="text-red">
					<strong>{length}</strong>
				</span>
			);
		} else {
			return <span>{length}</span>;
		}
	}

	render() {
		return (
			<PopUp
				title="SMS Gönder"
				Hide={this.props.Hide}
				className="settings-popup"
			>
				<div className="grid-1">
					<textarea
						placeholder="Mesajınızı buraya yazabilirsiniz"
						value={this.state.message}
						onChange={(e) => this.setState({ message: e.currentTarget.value })}
						onPaste={(e) => this.setState({ message: e.currentTarget.value })}
						maxLength={this.messageLimit}
						rows="5"
					/>
					<small className="character-counter">
						<label>Kalan karakter:</label> {this.RenderCharLength()}
					</small>
					<div className="grid-2">
						<button
							type="button"
							className="btn btn-default"
							onClick={this.props.Hide}
						>
							Vazgeç
						</button>
						<button
							className="btn btn-blue"
							onClick={this.SendMessage}
							disabled={
								!this.state.message.length ||
								this.state.message.length > this.messageLimit
							}
						>
							Gönder
						</button>
					</div>
				</div>
			</PopUp>
		);
	}
}

export default SmsMessageBox;
import React, { Component, Fragment } from "react";

class HelpAndSupportCore extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeCategory: props.categories[0],
			filteredCategories: props.categories,
		};

		this.SetActiveCategory = this.SetActiveCategory.bind(this);
		this.FilterCategories = this.FilterCategories.bind(this);
	}

	SetActiveCategory(activeCategory) {
		this.setState({ activeCategory });
	}

	FilterCategories(e){
		e.preventDefault();
		const term = e.currentTarget.value.toLocaleLowerCase();
		const filteredCategories = this.props.categories.filter(category => category.name.toLocaleLowerCase().indexOf(term) > -1);
		this.setState({filteredCategories});
	}
}

export default HelpAndSupportCore;
import React from "react";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";

const NotificationsBox = React.forwardRef((props, ref) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const boxRef = React.useRef(null);

	// React.useEffect(() => {
	// 	if (boxRef.current) {
	// 		const boxHeight = boxRef.current.clientHeight;
	// 		SetBoxHeight(boxHeight);
	// 	}
	// }, []);

	React.useImperativeHandle(ref, () => ({
		UpdateMaxHeight: () => {
			const boxHeight = boxRef.current.clientHeight;
			SetBoxHeight(boxRef.current.scrollHeight);
		},
	}));

	const ToggleWrapper = () => {
		const boxHeight = boxRef.current.clientHeight;
		if (boxHeight === 0) {
			SetBoxHeight(boxRef.current.scrollHeight);
		} else {
			SetBoxHeight(0);
		}
	};

	return (
		<div className="user-panel-box">
			<header onClick={ToggleWrapper}>
				<div className="status-icon status-icon-jungle">
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-current"></use>
					</svg>
				</div>
				<strong>{DateFormat(props.created_at)}</strong>-
				<h2>
					<div>
						Tutar:
						<strong className="text-red">{Currency(props.amount)} ₺</strong>
					</div>
					-
					<div>
						Bakiye:
						<strong className="text-green">{Currency(props.total)} ₺</strong>
					</div>
				</h2>
				<button className={"btn" + (boxHeight === 0 ? "" : " rotate-ccw-180")}>
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
					</svg>
				</button>
			</header>
			<div
				className="user-panel-box-wrapper"
				style={{ maxHeight: boxHeight }}
				ref={boxRef}
			>
				<div className="user-panel-box-body">{props.children}</div>
			</div>
		</div>
	);
});

export default NotificationsBox;
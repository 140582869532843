import React from "react";
import CashOutCore from "./CashOutCore";
import SelectBox from "../../Common/SelectBox";
import Currency from "../../../packs_helper/scripts/Currency";
import PriceFieldTag from "../../Common/PriceFieldTag";
import ZiraatBank from "../../../assets_v3/images/ziraat-bank";
import IninalKartBarkod from "../../../assets_v3/images/ininal-kart-barkod";
import Switcher from "../../Common/Switcher";
import SaveBankConfirmationPopup from "./SaveBankConfirmationPopup";
import GlobalVar from "../../GlobalVar";

class MobileRenderer extends CashOutCore {
	render() {
		return (
			<div className="cash-out-container grid-1">
				<Switcher
					buttons={this.tabs}
					onChange={this.ActivateTab}
					active={this.state.activeTab}
				/>
				{this.state.activeTab === "" && (
					<React.Fragment>
						<div className="cash-out-select-bank">
							<div>
								<SelectBox
									className="bank-select"
									xlinkHref="#gs-icon-user-panel-caret-down"
									options={this.state.my_banks.map((my_bank) => ({
										id: my_bank.id,
										name: (
											<img
												src={my_bank.bank.image || ZiraatBank}
												width="120"
												height="60"
												title={my_bank.bank.name}
											/>
										),
									}))}
									onChange={this.ChangeBank}
									selected={this.state.selectedBank.id}
									filter={true}
								/>
							</div>
							<div className="cash-out-select-bank-info">
								<div>
									<label>Hesap Sahibi:</label>{" "}
									<strong>{this.state.selectedBank.account_owner_name}</strong>
								</div>
								<div>
									<label>IBAN:</label>{" "}
									<strong>{this.state.selectedBank.iban}</strong>
								</div>
							</div>
							<div className="cash-out-select-bank-buttons">
								<button
									className="btn btn-jungle btn-sm"
									onClick={() => this.setState({ addBank: true })}
								>
									Yeni Banka Ekle
								</button>
								<button
									className="btn btn-red btn-sm"
									onClick={this.RemoveSelectedBank}
								>
									Hesabı Sil
								</button>
							</div>
						</div>

						<div className="cash-out-alert">
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: ".5rem",
										marginBottom: ".5rem"
									}}
								>
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-warning"></use>
									</svg>
									<div>
										Nakite çevirebilmek için en az {GlobalVar.MIN_CASHOUT} ₺ bakiyeniz olması gerekiyor.
									</div>
								</div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: ".5rem",
									}}
								>
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-warning"></use>
									</svg>
									<div>
										Sadece satışlardan elde edilen gelirleri çekebilirsiniz.
									</div>
								</div>
							</div>
						</div>
						<div className="cash-out-form">
							<div className="cash-out-input">
								<label>
									ÇEKİLEBİLİR TUTAR
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-support"></use>
									</svg>
								</label>
								<div className="cash-out-input-text">
									{Currency(this.state.refundable_balance)} ₺
								</div>
							</div>

							{this.state.refundable_balance >
							0 ? (
								<React.Fragment>
									<PriceFieldTag
										label="ÇEKMEK İSTEDİĞİNİZ TUTAR"
										onChange={this.CashOnChange}
										value={this.state.currentValue > 0 ? this.state.currentValue: ''}
										placeholder={
											"MAX: " +
											Currency(
												this.state.refundable_balance -
													this.state.currentCommission
											)
										}
									/>

									<div className="cash-out-input">
										<label>MASRAF</label>
										<div className="cash-out-input-text">
											{Currency(this.state.currentCommission)} ₺
										</div>
									</div>

									<button className="btn btn-blue" onClick={this.CashOut}>
										{(this.state.selectedBank.bank && (this.state.selectedBank.bank.id === this.PaparaId || this.state.selectedBank.bank.id === this.PaparaIbanId) && this.state.currentValue > 0) &&
											Currency(Number(this.state.currentValue) + Number(this.state.currentCommission)) + ' ₺ Bakiyeden Düş ve '
										}
										GÖNDER
									</button>
								</React.Fragment>
							) : (
								<React.Fragment>
									<div className="cash-out-input alert">
										ÇEKİLEBİLİR BAKİYENİZ BULUNMAMAKTADIR!
									</div>
								</React.Fragment>
							)}
						</div>{" "}
					</React.Fragment>
				)}

				{this.state.activeTab === "banka-ekle" && (
					<React.Fragment>
						<div className="cash-out-alert">
							<div>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: ".5rem",
									}}
								>
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-warning"></use>
									</svg>
									<div>
										Bakiyenizi Sadece{" "}
										<strong>
											{this.props.user.name + " " + this.props.user.surname}
										</strong>{" "}
										adına kayıtlı banka hesaplarına çekebilirsiniz.
									</div>
								</div>

								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: ".5rem",
										marginTop: ".5rem",
									}}
								>
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-warning"></use>
									</svg>
									<div>
										Nakit çekim talepleriniz hafta içi:{" "}
										<strong>09:00 - 16:00</strong> saatleri arasında yapılır.
									</div>
								</div>
							</div>
						</div>
						<div
							className={
								"cash-out-select-bank cash-out-add-bank" +
								(this.state.selectedFormBank.id === this.IninalId
									? " add-ininal-card"
									: "")
							}
						>
							<div>
								<SelectBox
									className="bank-select"
									xlinkHref="#gs-icon-user-panel-caret-down"
									options={this.state.banks.map((bank) => ({
										id: bank.id,
										name: (
											<img
												src={bank.image || ZiraatBank}
												width="120"
												height="60"
												title={bank.name}
											/>
										),
									}))}
									onChange={this.ChangeBankInForm}
									selected={this.state.selectedFormBank.id}
									name="iban"
									filter={true}
									maxHeight={'20rem'}
								/>
							</div>
							<div className="cash-out-add-bank-grid">
								{this.state.selectedFormBank.id === this.IninalId && (
									<img src={IninalKartBarkod} className="ininal-card-image" />
								)}

								{this.state.selectedFormBank.id === this.IninalId && (
									<React.Fragment>
										<small>Hesap Sahibi Ad Soyad:</small>
										<div className="input">
											{this.props.user.name} {this.props.user.surname}
										</div>
										<small>BARKOD NO:</small>
										<input
											type="text"
											className="thin-input"
											placeholder="4 123456 789100"
											value={this.state.ibanInForm}
											onChange={this.BarcodeChangeHandler}
											maxLength={15}
										/>
										<div className="cash-out-select-bank-buttons">
											<button className="btn btn-blue btn-sm" onClick={this.SaveBankHandler}>
												Ekle
											</button>
										</div>
									</React.Fragment>
								)}
								{this.state.selectedFormBank.id === this.PaparaId && (
									<React.Fragment>
										<div className="cash-out-add-bank-grid">
											<small>Hesap Sahibi Ad Soyad:</small>
											<div className="input">
												{this.props.user.name} {this.props.user.surname}
											</div>
											<small>Papara No:</small>
											<input
												type="text"
												className="thin-input"
												placeholder="Papara hesap numaranız yazın."
												value={this.state.ibanInForm}
												onChange={this.PaparaChangeHandler}
												maxLength={10}
											/>
										</div>
										<div className="cash-out-select-bank-buttons">
											<button
												className="btn btn-blue"
												onClick={this.SaveBankHandler}
											>
												Bul ve Ekle
											</button>
										</div>
									</React.Fragment>
								)}
								{(this.state.selectedFormBank.id !== this.PaparaId && this.state.selectedFormBank.id !== this.IninalId) && (
									<React.Fragment>
										<small>Hesap Sahibi Ad Soyad:</small>
										<div className="input">
											{this.props.user.name} {this.props.user.surname}
										</div>
										<small>IBAN:</small>
										<input
											type="text"
											placeholder="TR00 0000 0000 00000 0000 0000"
											value={this.state.ibanInForm}
											onChange={this.InputChangeHandler}
										/>

										<div className="cash-out-select-bank-buttons">
											<button className="btn btn-blue btn-sm" onClick={this.SaveBankHandler}>
												Ekle
											</button>
										</div>
									</React.Fragment>
								)}
							</div>
						</div>
					</React.Fragment>
				)}

				{this.state.saveBankConfirmationVisible && (
					<SaveBankConfirmationPopup
						user={this.props.user}
						currentCommission={this.state.currentCommission}
						commissionRate={this.PaparaCommission}
						onResponse={this.SaveBankConfirmationHandler}
					/>
				)}
			</div>
		);
	}
}

export default MobileRenderer;
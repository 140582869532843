function DateDiff(dateString1, dateString2){
	const date1 = new Date(dateString1);
	const date2 = new Date(dateString2);
	if(!dateString2){
		return new Date() - date1;
	}
	else{
		return date1 - date2;
	}
}

export default DateDiff;
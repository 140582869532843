function DateFormat(dateString, format){
	if(!format){
		format = '%d/%m/%Y - %H:%M';
	}
	const dateObj = new Date(dateString);
	// dateObj.toLocaleFormat(format);

	const options = {
		'%m':	("00" + (dateObj.getMonth() + 1)).slice(-2),
		'%d': ("00" + dateObj.getDate()).slice(-2),
		'%Y': dateObj.getFullYear(),
		'%H': ("00" + dateObj.getHours()).slice(-2),
		'%M': ("00" + dateObj.getMinutes()).slice(-2),
		'%S': ("00" + dateObj.getSeconds()).slice(-2),
	};

	const regex = format.match(/%.{1}|.{1}/g);

	return regex.map(r => options[r] || r).join('');
}

export default DateFormat;
import React from "react";
import LiveChatAdminItem from "./LiveChatAdminItem";


class LiveChatAdmin extends React.Component {
	constructor(props) {
		super(props);
		const navigation = dataPreserve(this.props.navigation);
		this.state = {
			navigation,
			sendDisabled: false,
			draggingItem: null,
		};
		this.AddMain = this.AddMain.bind(this);
		this.AddChild = this.AddChild.bind(this);
		this.RemoveChild = this.RemoveChild.bind(this);
		this.AddProperty = this.AddProperty.bind(this);
		this.RemoveProperty = this.RemoveProperty.bind(this);
		this.UpdateProperty = this.UpdateProperty.bind(this);

		this.SaveForm = this.SaveForm.bind(this);
		this.DragStart = this.DragStart.bind(this);
		this.Drop = this.Drop.bind(this);

		this.Undo = this.Undo.bind(this);
		this.Redo = this.Redo.bind(this);

		this.History = new HistoryRecords(this.state.navigation);
	}
	
	AddMain(nav) {
		const id = Date.now();
		this.setState((prevState) => ({ navigation: [...prevState.navigation, { id, title: "", children: [] }] }), () => this.History.Add(this.state.navigation));
	}

	AddChild(nav) {
		const id = Date.now();
		nav.children.push({ id, title: "", children: [], parent: nav });
		this.setState((prevState) => ({ navigation: prevState.navigation }), () => this.History.Add(this.state.navigation));
	}

	RemoveChild(nav, skipConfirmation = false) {
		if (!skipConfirmation && !confirm(nav.title + " silinecek. Devam etmek istiyor musunuz?")) {
			return false;
		}

		debugger;
		if (!nav.parent) {
			const index = this.state.navigation.findIndex((n) => n.id === nav.id);
			if (index > -1) {
				this.state.navigation.splice(index, 1);
				this.setState((prevState) => ({ navigation: prevState.navigation }), () => this.History.Add(this.state.navigation));
			}
		} else {
			const index = nav.parent.children.findIndex((n) => n.id === nav.id);
			if (index > -1) {
				nav = nav.parent.children.splice(index, 1);
				this.setState((prevState) => ({ navigation: prevState.navigation }), () => this.History.Add(this.state.navigation));
			}
		}
	}

	AddProperty(nav, property, defaultValue = "") {
		nav[property] = defaultValue;

		this.setState((prevState) => ({ navigation: prevState.navigation }), () => this.History.Add(this.state.navigation));
	}

	RemoveProperty(nav, property) {
		delete nav[property];
		this.setState((prevState) => ({ navigation: prevState.navigation }), () => this.History.Add(this.state.navigation));
	}

	UpdateProperty(nav, property, value, skipHistory = false) {
		nav[property] = value;
		this.setState((prevState) => ({ navigation: prevState.navigation }), () => { if(!skipHistory){ this.History.Add(this.state.navigation) } });
	}

	SaveForm() {
		this.setState({ sendDisabled: true });
		const formData = new FormData();
		formData.append("info", JSON.stringify(dataSimplifier(this.state.navigation)));
		const _this = this;
		helpers.Post(this.props.save_path, formData, function(response) {
			if (response.state) {
				modalert("Veriler başarıyla kaydedildi!", "Sonuç", "success");
			} else {
				modalert("Veriler kaydedilirken bir sorun oluştu!", "Sonuç", "error");
			}
			_this.setState({ sendDisabled: false });
		});
	}

	DragStart(navigation) {
		this.setState({ draggingItem: navigation });
	}

	Drop(target) {
		const navigation = this.state.draggingItem;
		target.children.push(navigation);
		this.RemoveChild(navigation, true);
		const index = target.children.findIndex(nav => nav.id === navigation.id);
		// if(index > -1){
		// 	target.children[index].parent = target;
		// }
		// else{
		// }
	}

	Undo(){
		const navigation = this.History.Undo();
		this.setState({ navigation })
	}

	Redo(){
		this.setState({ navigation: this.History.Redo() })
	}

	render() {
		return (
			<div className="box box-success">
				<div className="box-header with-border">
					<h3 className="box-title">Canlı Destek Adımları</h3>
					<div className="box-tools">
						<button
							onClick={this.Undo}
							className="btn btn-info btn-sm"
							disabled={!this.History.hasHistory}
						>
							Geri Al
						</button>
						<button onClick={this.Redo} className="btn btn-info btn-sm" disabled={!this.History.hasFuture}>
							İleri Al
						</button>
					</div>
				</div>
				<div className="box-body">
					<div className="site-live-chat-admin-container">
			<div className="site-live-chat-admin-item">
						{this.state.navigation.map((navigation, index) => (
							<LiveChatAdminItem 
							navigation={navigation} 
							AddChild={this.AddChild} 
							RemoveChild={this.RemoveChild} 
							AddProperty={this.AddProperty} 
							RemoveProperty={this.RemoveProperty} 
							UpdateProperty={this.UpdateProperty} 

							DragStart={this.DragStart} 
							Drop={this.Drop} 

							key={navigation.id} 
							/>
						))}


				<div 
				// className={"drop-area" + (props.dragOverIn ? " drag-over" : "")} 
				// onDrop={(e) => handleDrop(e, "dragOverIn")} 
				// onDragOver={(e) => handleDragOver(e, "dragOverIn")} 
				// onDragEnter={(e) => handleDragEnter(e, "dragOverIn")} 
				// onDragLeave={(e) => handleDragLeave(e, "dragOverIn")} 
				>Alt Eleman Olarak Ekle</div>



						<button className="btn btn-default btn-sm" onClick={() => this.AddMain(this.state.navigation)}>
							Yeni Ana Başlık
						</button>
					</div>
					</div>
				</div>
				<div className="box-footer clearfix">
					<button type="button" className="btn btn-success" disabled={this.state.sendDisabled} onClick={this.SaveForm}>
						Kaydet
					</button>
				</div>
			</div>
		);
	}
}

const dataSimplifier = (navigation) => {
	const newNavigation = [];
	for (let i = 0; i < navigation.length; i++) {
		newNavigation[i] = Object.assign({}, navigation[i]);
		if (navigation[i].children) {
			newNavigation[i].children = dataSimplifier(navigation[i].children);
		}
		if (navigation[i].parent) {
			delete newNavigation[i].parent;
		}
	}
	return newNavigation;
};

const dataPreserve = (navigation, parent) => {
	for (let i = 0; i < navigation.length; i++) {
		if (navigation[i].children) {
			navigation[i].children = dataPreserve(navigation[i].children, navigation[i]);
		}
		if (parent) {
			navigation[i].parent = parent;
		}
	}
	return navigation;
};

class HistoryRecords {
	constructor(navigation) {
		this.index = -1;
		this.history = [];
		this.Add(navigation);
	}
	Add(navigation) {
		if (this.index < this.history.length - 1) {
			this.history.splice(this.index + 1);
		}
		this.history.push(JSON.stringify(dataSimplifier(navigation)));
		this.index++;
	}

	Undo(navigation) {
		if (this.index > -1) {
			this.index--;
		}
		return this.current;
	}

	Redo(navigation) {
		if (this.index < this.history.length - 1) {
			this.index++;
		}
		return this.current;
	}

	get current() {
		return dataPreserve(JSON.parse(this.history[this.index]));
	}

	get hasFuture() {
		return this.index < this.history.length - 1;
	}

	get hasHistory() {
		return this.index > -1;
	}
}

const BBCode = bbcodeString => {

	const splitedBBcode = bbcodeString.split(/[\r\n]/gm)

	for(let i = 0; i < splitedBBcode.length; i++){

	}

	// let element;
	switch(props[0]){
		case 'B':
			return <strong onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</strong>
		break;
		case 'U':
			return <u onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</u>
		break;
		case 'I':
			return <i onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</i>
		break;
		case 'BT': 
			return <span onKeyUp={KeyUp} onKeyDown={props.onKeyDown} className="h1">{props[1]}</span>
		break;
		case 'MT':
			return <span onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</span>
		break;
		case 'ST':
			return <small onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</small>
		break;
		default:
			return <span>{props[1]}</span>
	}
	return element;
}

// const BBCode = props => {

// 	this.ToHtml = () => {

// 	}

// 	this.ToBBCode = () => {

// 	}

// 	// let element;
// 	switch(props[0]){
// 		case 'B':
// 			return <strong onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</strong>
// 		break;
// 		case 'U':
// 			return <u onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</u>
// 		break;
// 		case 'I':
// 			return <i onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</i>
// 		break;
// 		case 'BT': 
// 			return <span onKeyUp={KeyUp} onKeyDown={props.onKeyDown} className="h1">{props[1]}</span>
// 		break;
// 		case 'MT':
// 			return <span onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</span>
// 		break;
// 		case 'ST':
// 			return <small onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</small>
// 		break;
// 		default:
// 			return <span>{props[1]}</span>
// 	}
// 	return element;
// }


const BBCodeElement2 = props => {
	const KeyUp = (e) => {
		props.onKeyUp(props[1], e.currentTarget.innerText)
		// props[1] = e.currentTarget.innerText;
	}

	// let element;
	switch(props[0]){
		case 'B':
			return <strong onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</strong>
		break;
		case 'U':
			return <u onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</u>
		break;
		case 'I':
			return <i onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</i>
		break;
		case 'BT': 
			return <span onKeyUp={KeyUp} onKeyDown={props.onKeyDown} className="h1">{props[1]}</span>
		break;
		case 'MT':
			return <span onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</span>
		break;
		case 'ST':
			return <small onKeyUp={KeyUp} onKeyDown={props.onKeyDown}>{props[1]}</small>
		break;
		default:
			return <span>{props[1]}</span>
	}
	return element;
}


export default LiveChatAdmin;
import React from "react";
import PaymentGridListItem from "./PaymentGridListItem";
import PostData from "../../packs_helper/scripts/PostData";
import DateFormat from "../../packs_helper/scripts/DateFormat";

import PhoneInput from "../Common/PhoneInput";
import DecimalFieldTag from "../Common/DecimalFieldTag";
import PriceFieldTag from "../Common/PriceFieldTag";

const groups = {
	transfer: "Yatırmanız Gereken Tutar",
	credit_card: "Kartınızdan Çekilecek Tutar",
	mobile_payment: "Faturanıza Yansıyacak Tutar",
};

const PaymentGridListItemExchange = (props) => {
	const [waitForResponse, SetWaitForResponse] = React.useState(false);
	const [amount, SetAmount] = React.useState(0);

	const FormSubmitHandler = (e) => {
		e.preventDefault();

		SetWaitForResponse(true);
		const formData = new FormData(e.target);
		formData.append("payment_notification[payment_id]", props.paymentMethod.id);

		const entries = formData.entries();
		let entry;
		while(entry = entries.next().value){
			if(entry[0] !== 'payment_notification[description]' && (!entry[1] || entry[1] == '0')){
				ShowPopup({
					title: 'Hata',
					message: 'Lütfen tüm alanları doldurunuz!'
				});
				SetWaitForResponse(false);
				return false
			}
		}

		PostData(props.savePath, formData, {
			redirect: false,
		})
			.then((response) => {


				// if (response.redirected) {
				SetWaitForResponse(false);
				// }
				if(response.state){
					setTimeout(function(){ redirect('/odemelerim') }, 5000);
				}
			})
			.catch((err) => {


				SetWaitForResponse(false);
			});
	};

	const Image = (
		<svg className="icon">
			<use xlinkHref="#gs-icon-payment-methods-exchange"></use>
		</svg>
	);
	return (
		<PaymentGridListItem
			id={props.paymentMethod.id}
			image={Image}
			group={props.paymentMethod.group}
			name={props.paymentMethod.name}
			slogan={props.paymentMethod.slogan}
			waitForResponse={waitForResponse}
			show={true}
			ComponentOpen={props.ComponentOpen}
			mobile={props.mobile}
		>
			<form
				action="#"
				method="post"
				onSubmit={FormSubmitHandler}
				className="payment-grid-list-item-body-wrapper"
				data-wait-for-response={waitForResponse}
			>
				<div className="payment-account-form-grid">
					<PriceFieldTag
						wrapperClass="input-with-label"
						label="Hesabınıza Eklenecek Tutar:"
						name="payment_notification[amount]"
						id={`amount-${props.paymentMethod.id}`}
						readOnly={waitForResponse}
						onChange={SetAmount}
						value={amount}
					/>

					{props.paymentMethod.rate > 0 && (
						<div className="input-with-label">
							<label>{groups[props.paymentMethod.group]}:</label>
							<div className="input">
								{Currency(amount / (1 - props.paymentMethod.rate))} ₺
							</div>
						</div>
					)}

					<input
						placeholder="Adınız ve Soyadınız:"
						type="text"
						name="payment_notification[name_surname]"
						id={`name_surname-${props.paymentMethod.id}`}
						defaultValue={props.fullname}
						readOnly={waitForResponse}
					/>

					<div className="input-with-label">
						<label htmlFor={`date_at-${props.paymentMethod.id}`}>Tarih:</label>
						<input
							type="date"
							name="payment_notification[date_at]"
							id={`date_at-${props.paymentMethod.id}`}
							defaultValue={DateFormat(new Date, '%Y-%m-%d')}
							max={DateFormat(new Date, '%Y-%m-%d')}
						/>
					</div>

					<textarea
						className="right-box"
						placeholder="Açıklamayı yazınız:"
						readOnly={waitForResponse}
					></textarea>

					<button className="btn btn-jungle" type="submit">
						Ödeme Bildir
					</button>
				</div>
			</form>
		</PaymentGridListItem>
	);
};
export default PaymentGridListItemExchange;

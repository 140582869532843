import React from "react";
import SettingsBox from "./SettingsBox";
import PopUp from "../../Common/PopUp";
import PostData from "../../../packs_helper/scripts/PostData";
import UpdateIdentification from "../Common/UpdateIdentification";
import UpdatePhone from "../Common/UpdatePhone";

class ProfileSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gsm: '',
			popup: null,
			saving: false,
		};
		this.TogglePopup = this.TogglePopup.bind(this);
		this.TogglePersonalInfoVisibility = this.TogglePersonalInfoVisibility.bind(this);
	}

	componentDidMount(){
		// this.ParamsControl();
	}

	ParamsControl(){
		if (this.state.popup === null){
			const params = new URLSearchParams(window.location.search);
			if (params.has('id_verification') && !this.props.identification_number_control){
				const e = { currentTarget: { value: 'identity'} };
				this.TogglePopup(e);
			}else if(params.has('gsm_verification')){
				this.setState({ popup: 'change-gsm' });
			}
		}
	}

	TogglePopup(e) {
		const popup = e.currentTarget.value;
		this.setState({
			popup,
		});
	}

	TogglePersonalInfoVisibility(e){
		e.stopPropagation();
		PostData("/update_user_info_visibility", {
			not_show_identification_number: !this.props.not_show_identification_number
		})
			.then((data) => {
				this.props.UpdateData();
			})
			.catch((err) => { });
	}

	render() {
		return (
			<SettingsBox
				icon="person"
				title={(
					<React.Fragment>
						<span>Kişisel Bilgiler</span>
					</React.Fragment>
				)}
				id="profile-settings"
				show={this.props.activeBox === "profile-settings"}
				BoxOpen={this.props.BoxOpen}
			>
				<div className="profile-settings">
					<div className="settings-input">
						{/*
						<button className="btn">
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye"></use>
							</svg>
						</button>
						*/}
						<label>Ad Soyad:</label>
						{this.props.not_show_identification_number ? (
							<div className="settings-input-text">
								{this.props.name[0]}***** {this.props.lastname[0]}******
							</div>
						) : (
							<div className="settings-input-text">
								{this.props.name} {this.props.lastname}
							</div>
						)}
					</div>

					<div className="settings-input">
						{/*
						<button className="btn">
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye"></use>
							</svg>
						</button>
						*/}
						<label>E-Posta Adresi:</label>
						{this.props.not_show_identification_number ? (
							<div className="settings-input-text">
								{this.props.email.split('@').map(x => `${x[0]}******`).join('@')}
							</div>
						) : (
							<div className="settings-input-text">
								{this.props.email}
							</div>
						)}
						{/*
						<button
							className="btn btn-light"
							onClick={this.TogglePopup}
							value="e-mail"
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-edit"></use>
							</svg>
						</button>
						*/}
					</div>

					<div className="settings-input">
						{/*
						<button className="btn">
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye"></use>
							</svg>
						</button>
						*/}
						<label>Doğum Tarihi:</label>
						{this.props.not_show_identification_number ? (
							<div className="settings-input-text">****-**-**</div>
						) : (
							<div className="settings-input-text">{this.props.birthdate}</div>
						)}
					</div>

					<div className="settings-input">
						{/*
						<button className="btn">
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye"></use>
							</svg>
						</button>
						*/}
						<label>Telefon:</label>
						{this.props.not_show_identification_number ? (
							<div
								className={
									"settings-input-text " + (this.props.gsm_verification ? "text-green" : "text-red")
								}
							>
								5** *** ** **
							</div>
						) : (
							<div
								className={
									"settings-input-text " + (this.props.gsm_verification ? "text-green" : "text-red")
								}
							>
								{this.props.gsm}
							</div>
						)}
						{!this.props.gsm_verification && (
							<button
								className="btn btn-light"
								onClick={this.TogglePopup}
								value={"change-gsm"}
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-edit"></use>
								</svg>
							</button>
						)}
					</div>

					<div className="settings-input">
						{/*
						<button className="btn">
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye"></use>
							</svg>
						</button>
						*/}
						<label>T.C. Kimlik No:</label>
						{this.props.identification_number ? (
							<div className="settings-input-text">***********</div>
						) : (
							<React.Fragment>
								<div className="settings-input-text">
									<button
										className="btn btn-xs btn-red"
										onClick={this.TogglePopup}
										value="identity"
									>
										Doğrulanmadı
									</button>
								</div>
								<button
									className="btn btn-light"
									onClick={this.TogglePopup}
									value="identity"
								>
									<svg className="icon">
										<use href="#gs-icon-user-panel-edit"></use>
									</svg>
								</button>
							</React.Fragment>
						)}
					</div>

					{this.props.not_show_identification_number ? (
						<button
							className="btn btn-default"
							title="Kişisel Bilgileri Göster"
							onClick={this.TogglePersonalInfoVisibility}
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye"></use>
							</svg>
							Kişisel Bilgileri Göster
						</button>
					) : (
						<button
							className="btn btn-darker"
							title="Kişisel Bilgileri Gizle"
							onClick={this.TogglePersonalInfoVisibility}
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-eye-off"></use>
							</svg>
							Kişisel Bilgileri Gizle
						</button>
					)}

				</div>

				{this.state.popup === "identity" && (
					<PopUp
						title="Kimlik Doğrulama"
						Hide={() => this.setState({ popup: null })}
					>
						<UpdateIdentification
							name={this.props.name}
							lastname={this.props.lastname}
							birthdate={this.props.birthdate}
							identification_number={this.props.identification_number}
							UpdateData={() => this.props.UpdateData()}
							SetPopUpNull={() => this.setState({popup:null})}
						/>
					</PopUp>
				)}

				{this.state.popup === "change-gsm" && (
					<PopUp
						title="Telefon Numarası Kaydet"
						Hide={() => this.setState({ popup: null })}
					>
						<UpdatePhone
							UpdateData={() => this.props.UpdateData()}
							gsm_verification_timeout={this.props.gsm_verification_timeout}
							SetPopUpNull={() => this.setState({popup:null})}
						/>
					</PopUp>
				)}

				{/*{this.state.popup === "verify-gsm" && this.state.gsm_verification_timeout > 0 && (*/}
				{/*	<PopUp*/}
				{/*		title="Telefon Numarası Doğrula"*/}
				{/*		Hide={() => this.setState({ popup: null })}*/}
				{/*	>*/}
				{/*		<form*/}
				{/*			action="#"*/}
				{/*			onSubmit={this.VerifyGSM}*/}
				{/*			className="grid-1"*/}
				{/*			autoComplete="off"*/}
				{/*		>*/}
				{/*			<div className="input-group">*/}
				{/*				<input type="text" name="secure_code" id="secure_code" placeholder="Güvenlik Kodu" />*/}
				{/*			</div>*/}
				{/*			<small>*/}
				{/*				Yeni kod talep etmek için*/}
				{/*				<strong><CountDown seconds={this.state.gsm_verification_timeout} /></strong>*/}
				{/*				kaldı*/}
				{/*			</small>*/}
				{/*			<button className="btn btn-blue">Doğrula</button>*/}
				{/*		</form>*/}
				{/*	</PopUp>*/}
				{/*)}*/}
			</SettingsBox>
		);
	}
}

export default ProfileSettings;

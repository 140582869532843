import React from "react";
import PostData from "../../packs_helper/scripts/PostData";

class PasswordResetForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			captcha_code: "",
			contract: "",
		};

		this.PasswordReset = this.PasswordReset.bind(this);
		this.KeyDownHandler = this.KeyDownHandler.bind(this);
		this.SwitchToSignIn = this.SwitchToSignIn.bind(this);
		this.SwitchToConfirmationForm = this.SwitchToConfirmationForm.bind(this);
	}

	PasswordReset(e) {
		e.preventDefault();

		const { email, captcha_code, contract } = this.state;

		const params = {
			email,
			captcha_code,
			contract,
		};

		if (!contract) {
			ShowPopup({
				title: "Sistem Mesajı!",
				message:
					"Devam etmek için lütfen parola yenileme talimatlarının e-posta olarak gönderilmesini onaylayın!",
			});
			this.setState({ waitForResponse: false });
			return false;
		}

		this.setState({ waitForResponse: true });
		PostData(this.props.passwordResetPath, params)
			.then((response) => {


				if (!response.state) {
					this.props.RefreshCaptchaCode();
				}
				else{
					this.props.ActivateTab('sign-in');
				}
			})
			.catch((err) => {


			});
	}

	KeyDownHandler(e){
		const keyCode = e.key || e.code;
		if(keyCode === 'Enter'){
			this.Login(e);
		}
	}

	SwitchToSignIn(){
		this.props.ActivateTab('sign-in')
	}

	SwitchToConfirmationForm(){
		this.props.ActivateTab('confirmation')
	}

	render() {
		return (
			<React.Fragment>
				<input
					type="text"
					name="email"
					value={this.state.email}
					onInput={(e) => this.setState({ email: e.target.value })}
					onKeyDown={this.KeyDownHandler}
					placeholder="E-Posta Adresi:"
					autoFocus
				/>

				<div className="input-with-captcha">
					<input
						type="text"
						name="captcha_code"
						value={this.state.captcha_code}
						onInput={(e) => this.setState({ captcha_code: e.target.value })}
						onKeyDown={this.KeyDownHandler}
						maxLength={4}
						placeholder="Güvenlik Kodu:"
					/>
					<img src={this.props.captchaImage} />
					<button onClick={this.props.RefreshCaptchaCode}>
						<svg className="icon">
							<use xlinkHref="#gs-icon-sign-box-reload"></use>
						</svg>
					</button>
				</div>

				<div className="checkbox">
					<input
						type="checkbox"
						name="contract"
						id="contract"
						value="1"
						checked={this.state.contract}
						onChange={(e) => this.setState({ contract: e.target.checked })}
					/>
					<label htmlFor="contract">Parola yenileme talimatlarını gönder.</label>
				</div>

				<button className="btn btn-blue" onClick={this.PasswordReset}>
					Gönder
				</button>
				<button className="btn btn-default" onClick={this.SwitchToSignIn}>Giriş Yap</button>
				<button className="btn btn-default" onClick={this.SwitchToConfirmationForm}>Üyelik Doğrulama</button>

			</React.Fragment>
		);
	}
}

export default PasswordResetForm;

import React from "react";
import RelativeDate from "../../../packs_helper/scripts/RelativeDate";
import Decimal from "../../../packs_helper/scripts/Decimal";
import DateFormat from "../../../packs_helper/scripts/DateFormat";

const smileys = {
	":D": "😀",
	":)": "🙂",
	":(": "😟",
};


const MessageAreaItem = (props) => {
	if (!props.message.me && !props.message.is_read) {
		setTimeout(() => {
			props.MarkRead(props.message.id);
			props.message.is_read = true;
		}, 2000);
	}

	const message = props.message.message.replace(
		/:[D)]{1}/g,
		(match) => smileys[match] || ""
	);
	// props.message.info.path.substr(0, 6) === '/cs-go' ? props.message.info.path + "?id=" + props.message.info.product_id : props.message.info.path
	return (
		<div className={"message-area-item " + (props.message.me ? "right" : "left")}>
			<header>{RelativeDate(props.message.created_at)}</header>
		<div className="message-area-item-body">
			{!!props.message.info &&
				(props.message.info.type === "product_info" ? (
					<React.Fragment>
						<div className="message-area-item-product-info">
							<a
								className="top-info"
								href={props.message.info.path}
								target="_blank"
							>
								{props.message.info.is_offer ? (
									"Önerilen #" + props.message.info.product_id + " numaralı ilan " +
									"görmek için tıklayın."
								):(
									"Bu mesaj #" + props.message.info.product_id + " numaralı ilanı " +
									"üzerinden gönderildi."
								)}
							</a>
						</div>
					</React.Fragment>
				) : props.message.info.type === "order" ? (
					<div className="message-area-item-order-info">
						<div className="top-info">
							*Bu mesaj sipariş sırasında otomatik oluşturuldu!
						</div>
						<h5 className="title">Sipariş Detayları:</h5>
						<div className="info">
							<div className="box-description">
								{Decimal(props.message.info.quantity, -1)} x{" "}
								{props.message.info.product_name}
							</div>
							<span className="text-blue">Sipariş verildi!</span>
							{props.message.me ? (
									<a
										href={
											"/satislarim?id=" +
											props.message.info.detail_id
										}
										target="_blank"
										className="text-green"
									>
										Satış İşlemi
									</a>
								) : (
									props.message.info.detail_id && (
									<a
										href={
											"/siparislerim?order=" +
											props.message.info.detail_id
										}
										target="_blank"
									>
										Yorum Yap
									</a>
								))}
						</div>
					</div>
				) : props.message.info.type === "problem" ? (
					<div className="message-area-item-problem">
						<div className="top-info">
							*Bu mesaj sorun hakkında otomatik oluşturuldu!
						</div>
						<h5 className="title">Sorun Detayları:</h5>
						<div className="info">
							{Decimal(props.message.info.quantity, -1)} x{" "}
							{props.message.info.product_name}
							{/*<a href={props.message.info.path} target="_blank">
								Siparişe Git
							</a>*/}
						</div>
					</div>
				) : null)}
			<div className="message-text">
				<code dangerouslySetInnerHTML={{__html: message}}></code>
				{!!props.message.me && (
					<span
						className={
							"read_info" + (!!props.message.is_read ? " is_read" : "")
						}
						title={
							props.message.is_read
								? DateFormat(props.message.is_read, "%d/%m/%Y - %H:%M")
								: ""
						}
					>
						✓✓
					</span>
				)}
			</div>
		</div>
		</div>
	);
};

export default MessageAreaItem;

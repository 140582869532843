import React from "react";
import PopUp from "../Common/PopUp";
import Currency from "../../packs_helper/scripts/Currency";

const AuctionOfferModal = (props) => {
	return (
		<PopUp
			title="Açık Artırma Teklif Onayı"
			Hide={props.Hide}
		>
			<div style={{width: '720px'}}>
				<p>
					Teklifinizi{" "}
					<strong style={{color: 'var(--red-color)'}}>
						{Currency(props.currentOffer)} ₺
					</strong>{" "}
					olarak güncelliyorsunuz. Devam etmek istiyor musunuz?
				</p>
			</div>
			<hr />
			<div style={{display: 'flex', gap: 'var(--spacer-2)', justifyContent: 'flex-end'}}>
				<button
					className="btn btn-default"
					type="button"
					onClick={props.Hide}
				>
					Vazgeç
				</button>
				<button className="btn btn-blue" type="button" onClick={props.Confirm}>
					Onayla
				</button>
			</div>
		</PopUp>
	);
};

export default AuctionOfferModal;
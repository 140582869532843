import React from "react";
import SiteLiveChatContainer from "./SiteLiveChatContainer";
import Cookie from "../../../packs_helper/scripts/Cookie";
import PostData from "../../../packs_helper/scripts/PostData";
import RelativeDate from "../../../packs_helper/scripts/RelativeDate";

const cookie = new Cookie();

class LiveChatMessaging extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			offset: 0,
			currentMessage: '',
			// sending: false,
		};

		this.SendChatMessageHandler = this.SendChatMessageHandler.bind(this);
		this.SendChatMessage = this.SendChatMessage.bind(this);

		// this.Connected = this.Connected.bind(this);
		// this.Disconnected = this.Disconnected.bind(this);
		// this.MessageReceived = this.MessageReceived.bind(this);

		// this.prechatMessage = this.props.defaultValue;



		// this.Connection = App.cable.subscriptions.create("MessagesChannel", {
		// 	connected: this.Connected,
		// 	disconnected: this.Disconnected,
		// 	received: this.MessageReceived,
		// });
	}

	// Connected() {
	// 	this.setState({
	// 		connected: true
	// 	})
	// 	if(this.prechatMessage){
	// 		// this.SendChatMessage(this.prechatMessage);
	// 		// this.prechatMessage = null;
	// 	}
	// }
	// Disconnected() {
	// 			this.setState({
	// 		connected: false
	// 	})
	// }

	// MessageReceived(message) {
	// 	console.log("Message received")
	// 	console.log(message)
	// 	this.setState((prevState) => ({
	// 		messages: [...prevState.messages, message],
	// 	}), () => {
	// 			this.ScrollToEnd();
	// 		console.log(this.state.messages)
	// 	});
	// }

	componentDidMount(){
		// document.cookie = `ChatCount=0;expires=${dayjs().add(12, 'hours').toString()}+";path=/`;
		cookie.set('ChatCount', 0, 12 * 60 * 60);

		this.historyRef.scrollTop = 99999999;

		if(this.props.defaultValue){
			const defaultValue = this.props.defaultValue;
			this.props.GetData(() => {
				this.SendChatMessage(defaultValue);
			});
			this.props.ClearPrechat();
		}
		else{
			this.props.GetData();
		}
	}

	componentDidUpdate(prevProps){
		if(this.props.messages.length > prevProps.messages.length){
			this.historyRef.scrollTop = 99999999;
		}
	}

	SendChatMessageHandler(e) {
		e.preventDefault();
		if(this.UserMessagesExceedLimit(this.props.messages)){
			ShowPopup({
				message: 'Yeni mesaj gönderebilmek için lütfen canlı destek ekibinin cevap vermesini bekleyiniz.'
			});
			return false;
		}
		const message = this.state.currentMessage;
		this.SendChatMessage(message);
	}

	SendChatMessage(message) {
		if(message.trim().length < 1){
			return false;
		}
		this.setState({currentMessage: ''})
		const _this = this;
		const formData = new FormData();
		formData.append('mesaj', message)

		PostData(this.props.sendMessagePath, formData).then((response) => {
			// this.setState({currentMessage: ''})
		});
	}

	UserMessagesExceedLimit(messages){
		const maxMessageCount = 5;
		const limit = messages.length - maxMessageCount;
		if(limit < 0){
			return false;
		}
	  for(let i = messages.length - 1; i >= limit; i--){
	    if(messages[i].sender !== 'user'){
	      return false;
	    }
	  }
  	return true;
	}

	render() {
		const working_hours = this.props.workingHours;

		return (
			<SiteLiveChatContainer CloseChat={this.props.CloseChat} className="messaging" chatStatus={this.props.chatStatus}>
				{/*<button type="button" className="site-live-chat-content-close-button" onClick={this.props.CloseMessaging}>*/}
				{/*	<svg className="icon">*/}
				{/*		<use xlinkHref="#gs-icon-live-chat-support"></use>*/}
				{/*	</svg>*/}
				{/*</button>*/}
				<div className="site-live-chat-message-title">
				Canlı Destek
				<small>(Sabah {working_hours[0]} - Gece {working_hours[1]})</small>
				</div>
				<div className="site-live-chat-messaging">
					<div ref={(ref) => (this.historyRef = ref)} className="site-live-chat-messaging-history">
						{this.props.messages.map((message) => (
							<div className={"site-live-chat-messaging-history-content " + message.sender} key={message.id}>
								<header>
									<div className="site-live-chat-messaging-history-content-sender">{message.sender === "user" ? "Siz" : message.nickname === "system" ? "Sistem Mesajı" : message.nickname}</div>
									<div className="site-live-chat-messaging-history-content-time">{RelativeDate(message.created_at)}</div>
								</header>
								<div dangerouslySetInnerHTML={{ __html: message.message }} />
							</div>
						))}
					</div>
					{this.props.chatStatus ? this.props.connected ? (
						<form onSubmit={this.SendChatMessageHandler} className="site-live-chat-messaging-inputs">
							<input onChange={e => this.setState({currentMessage: e.currentTarget.value})} value={this.state.currentMessage} placeholder="Mesajınızı buraya yazın..." />
							<button type="submit">
							<svg className="icon">
								<use xlinkHref="#gs-icon-live-chat-send"></use>
							</svg>
							</button>
						</form>
					) : (
						<div className="site-live-chat-messaging-inputs-alert">
							Canlı desteğe bağlanılıyor. Lütfen bekleyin
							<span className="loading-dots"><span></span><span></span><span></span></span>
						</div>
					) : (
						<div className="site-live-chat-messaging-inputs-alert">
		          <div className="bold mb-2">Şuan size cevap veremiyoruz,</div>
		          <div className="mb-1">Çalışma saatlerimiz <span className="bold">{working_hours[0]}-{working_hours[1]}</span> arasıdır.</div>
		          <div>Çalışma arkadaşlarımız size en kısa sürede ulaşacaktır.</div>
						</div>
					)}
				</div>
			</SiteLiveChatContainer>
		);
	}
}

export default LiveChatMessaging;

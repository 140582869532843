import React from "react";

const MessageWarning = (props) => {
	return (
		<CustomModalCenter title="Mesajlaşma Uyarısı" Hide={props.Hide}>
			<picture>
				<source
					media="(max-width: 599px)"
					srcSet={require("../../../assets_v3/images/store-message-warn-mobile")}
				/>
				<source
					media="(min-width: 600px)"
					srcSet={require("../../../assets_v3/images/store-message-warn")}
				/>
				<img
					src={require("../../../assets_v3/images/store-message-warn")}
					alt="Mesajlaşma Bilgilendirmesi"
					className="w-100"
				/>
			</picture>
			<div className="text-right">
				<button
					className="btn btn-blue"
					type="button"
					onClick={(e) => props.Hide(e, true)}
				>
					Bugün Tekrar Gösterme
				</button>
			</div>
		</CustomModalCenter>
	);
};

export default MessageWarning;
import React from "react";
import ImageWithFallback from "../../Common/ImageWithFallback";

const CommentProduct = (props) => {
	let image, link;
	if (props.steam_item) {
		image = `https://steamcommunity-a.akamaihd.net/economy/image/${props.steam_item.image}/125fx100f`;
		link = `/${props.category.slug}/${props.steam_item.slug}?id=${props.id}`;
	} else {
		image = props.image;
		link = `/${props.category.slug}/${props.slug}`;
	}

	return (
		<a href={link} target="_blank" className="comment-product">
			<ImageWithFallback src={image} width={35} height={28} />
			{props.name} {props.package_name && <span>({props.package_name})</span>}
		</a>
	);
};

export default CommentProduct;
import React from "react";
import PostData from "../../packs_helper/scripts/PostData";
import StringFieldTag from "../Common/StringFieldTag";
import Envelope from "../../assets_v3/images/sign-box-envelope"

class SignUpForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: "",
			surname: "",
			email: "",
			password: "",
			password_confirmation: "",
			captcha_code: "",
			contract: true,
			waitForResponse: false,
			signUpCompleted: false,
			digital_approve: true
		};
		this.SignUp = this.SignUp.bind(this);
		this.SwitchToConfirmationForm = this.SwitchToConfirmationForm.bind(this);
	}

	SignUp() {
		this.setState({ waitForResponse: true });
		const {
			name,
			surname,
			email,
			password,
			password_confirmation,
			captcha_code,
			contract,
			digital_approve
		} = this.state;

		if (password !== password_confirmation) {
			ShowPopup({
				title: "Sistem Mesajı!",
				message: "Girdiğiniz şifreler aynı değil!",
			});
			this.setState({ waitForResponse: false });
			return false;
		}

		if (!contract) {
			ShowPopup({
				title: "Sistem Mesajı!",
				message:
					'Üye olabilmek için "Açık Rıza Metni" ve "Aydınlatma Metni" kutusunu işaretlemeniz gerekmektedir!',
			});
			this.setState({ waitForResponse: false });
			return false;
		}

		PostData(this.props.signUpPath, {
			name,
			surname,
			email,
			password,
			password_confirmation,
			digital_approve,
			captcha_code,
			contract: contract
		}, {
			redirect: false
		})
			.then((data) => {


				if (data.state) {
					this.setState({signUpCompleted: true});
					this.props.UpdateEmail(email);
					// window.location.reload();
				}
				else{
					this.setState({ waitForResponse: false });
					this.props.RefreshCaptchaCode();
				}
			})
			.catch((err) => {
				this.setState({ waitForResponse: false });
			})
	}

	SwitchToConfirmationForm(){
		this.props.ActivateTab('confirmation')
	}

	render() {
		if(this.state.signUpCompleted){
			return (
				<React.Fragment>
					<img src={Envelope} />
					<p>Üyelik oluştururken girmiş olduğunuz E-Posta adresine bir <strong><i>Aktivasyon Maili</i></strong> gönderilmiştir.</p>
					<p>Tarafınıza iletilen <strong><i>Aktivasyon Maili</i></strong>’nde bulunan <strong><i>Linke</i></strong> tıklayıp üyeliğinizi tamamlayınız.</p>
					<p><small>Kayıt olduğunuz E-Posta adresinin Gelen Kutusu ya da Spam Klasörünü kontrol ediniz. <i>Eğer mail gelmediyse <a href="#" onClick={this.SwitchToConfirmationForm}><strong>lütfen tıklayın</strong></a></i>.</small></p>
				</React.Fragment>
				);
		}

		return (
			<React.Fragment>
				<StringFieldTag
					placeholder="Ad:"
					name="name"
					value={this.state.name}
					onChange={(name) => this.setState({ name })}
					maxLength={65}
					autoFocus
				/>

				<StringFieldTag
					placeholder="Soyad:"
					name="surname"
					value={this.state.surname}
					onChange={(surname) => this.setState({ surname })}
					maxLength={65}
				/>

				<input
					placeholder="E-Posta Adresi:"
					type="text"
					name="email"
					value={this.state.email}
					onInput={(e) => this.setState({ email: e.target.value })}
				/>

				<input
					placeholder="Şifre:"
					type="password"
					name="password"
					value={this.state.password}
					onInput={(e) => this.setState({ password: e.target.value })}
				/>

				<input
					placeholder="Şifre Tekrar:"
					type="password"
					name="password_confirmation"
					value={this.state.password_confirmation}
					onInput={(e) =>
						this.setState({ password_confirmation: e.target.value })
					}
				/>

				<div className="input-with-captcha">
					<input
						type="text"
						name="captcha_code"
						maxLength={4}
						value={this.state.captcha_code}
						onInput={(e) => this.setState({ captcha_code: e.target.value })}
						placeholder="Güvenlik Kodu:"
					/>
					<img src={this.props.captchaImage} />
					<button onClick={this.props.RefreshCaptchaCode}>
						<svg className="icon">
							<use xlinkHref="#gs-icon-sign-box-reload"></use>
						</svg>
					</button>
				</div>

				<div className="checkbox">
					<input
						type="checkbox"
						name="contract"
						id="contract"
						value="1"
						checked={this.state.contract}
						onChange={(e) => this.setState({ contract: e.target.checked })}
					/>
					<label htmlFor="contract">
						<div>
							<a href="/kullanici-sozlesmesi#acik-riza-metni" target="_blank">Açık Rıza Metni</a> ve {' '}
							<a href="/kullanici-sozlesmesi#aydinlatma-metni" target="_blank">Aydınlatma Metni</a> şartlarını okudum kabul
							ediyorum.
						</div>
					</label>
				</div>

				<div className="checkbox">
					<input
						type="checkbox"
						name="digital_approve"
						id="digital_approve"
						value="1"
						checked={this.state.digital_approve}
						onChange={(e) => this.setState({ digital_approve: e.target.checked })}
					/>
					<label htmlFor="digital_approve">
						<div>
							Kampanyalardan haberdar olmak için sms ve elektronik ileti almak istiyorum.
						</div>
					</label>
				</div>

				<button className="btn btn-blue" onClick={this.SignUp}>
					Üye Ol
				</button>

				<label>
					<div>
						Üye Ol'a basarak <a href="/kullanici-sozlesmesi" target="_blank">Kullanıcı Sözleşmesi</a> ve şartlarını kabul
						ediyorum.
					</div>
				</label>
			</React.Fragment>
		);
	}
}

export default SignUpForm;

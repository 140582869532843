import React from "react";
import GlobalVar from "../GlobalVar";

function AvatarHandler(avatar, avatar_type){
    let avatar_result;
    if(!avatar_type)
        avatar_result = GlobalVar.AWS_URL + GlobalVar.AvatarSetPath + avatar + GlobalVar.AvatarExtension;
    else
        avatar_result = GlobalVar.AWS_URL + GlobalVar.AvatarUploadedPath + avatar + GlobalVar.AvatarExtension;

    return avatar_result;
}

export default AvatarHandler;
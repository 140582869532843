import React from "react";
import PopUp from "../../Common/PopUp";
import GlobalVar from "../../GlobalVar";

const SaveBankConfirmationPopup = (props) => {
	const [confirm1, SetConfirm1] = React.useState(false);
	const [confirm2, SetConfirm2] = React.useState(false);
	const [confirm3, SetConfirm3] = React.useState(false);

	return (
		<PopUp
			title="Lütfen okuyun ve onaylayın!"
			Hide={(e) => props.onResponse({target: {value: 0}})}
		>
			<p>
					Bakiyenizi Sadece <strong>{props.user.name} {props.user.surname}</strong> adına kayıtlı banka hesaplarına çekebilirsiniz.{" "}
			</p>

				<div className="checkbox" style={{marginBottom: '.5rem'}}>
					<input
						type="checkbox"
						name="confirm1"
						id="confirm1"
						value="1"
						checked={confirm1}
						onChange={(e) => SetConfirm1(e.target.checked)}
					/>
					<label htmlFor="confirm1">
						Eklediğim hesabın
						<strong style={{margin: "0 .25em"}}>{props.user.name} {props.user.surname}</strong>
						adına olduğunu onaylıyorum.
					</label>
				</div>
				<div className="checkbox" style={{marginBottom: '.5rem'}}>
					<input
						type="checkbox"
						name="confirm2"
						id="confirm2"
						value="1"
						checked={confirm2}
						onChange={(e) => SetConfirm2(e.target.checked)}
					/>
					<label htmlFor="confirm2">
						<div>
							Vermiş olduğum hesap bilgilerinin hatalı olması ya da hesap sahibinin
							<strong style={{margin: "0 .25em"}}>{props.user.name} {props.user.surname}</strong>
							olmaması durumunda ödememin hesabıma aktarılamayacağını biliyor ve onaylıyorum.
						</div>
					</label>
				</div>

				<div className="checkbox" style={{marginBottom: '.5rem'}}>
					<input
						type="checkbox"
						name="confirm3"
						id="confirm3"
						value="1"
						checked={confirm3}
						onChange={(e) => SetConfirm3(e.target.checked)}
					/>
					<label htmlFor="confirm3">
						<div>
							{props.isPapara ?
								(
									<div>
										Bilgilerin hatalı olması halinde <strong>talep ettiğim tüm ücretin</strong> geri gelmeyeceğini biliyorum.
									</div>
								)
								:
								(
									<div>
										Bilgilerin hatalı olması halinde <strong>{GlobalVar.CASHOUT_COMMISSION} ₺</strong> masrafın hesabıma gelmeyeceğini biliyorum.
									</div>
								)
							}

						</div>
					</label>
				</div>

			<hr />
			<div className="grid-2 popup-buttons">
				<button
					value={0}
					className="btn btn-default"
					onClick={props.onResponse}
				>
					Vazgeç
				</button>
				<button
					value={1}
					className="btn btn-jungle"
					disabled={!confirm1 || !confirm2 || !confirm3}
					onClick={props.onResponse}
				>
					Banka hesabını kaydet
				</button>
			</div>
		</PopUp>
	);
};

export default SaveBankConfirmationPopup;
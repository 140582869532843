import React from "react";
import Decimal from "../../packs_helper/scripts/Decimal";

function JustFloat(value) {
	const val = value.toString().match(/[0-9]*[\,\.]?[0-9]{0,2}/)[0].replace(",", ".");
	if (!val) {
		return "";
	} else if (val.substr(-1) === ".") {
		return val.replace(".", ",");
	} else {
		return Number.parseFloat(val).toString().replace(".", ",");
	}
}

const DecimalFieldTag = (props) => {
	const [value, SetValue] = React.useState(JustFloat(props.value || props.defaultValue || ""));

	React.useEffect(() => {
		SetValue(JustFloat(props.value || props.defaultValue || ""))
  },[props.value, props.defaultValue]);

	const OnChange = e => {
		let price = JustFloat(e.currentTarget.value || "");
		if(props.max && price > props.max){
			price = props.max;
		}
		SetValue(price);
		if(props.onChange){
			props.onChange(Decimal(price));
		}
	}

	const OnBlur = e => {
		if(props.onBlur){
			if(!props.onBlur(Decimal(value))){
				SetValue(props.value);
			}
		}
	}

	// const OnPaste = e => {
	// 	SetValue(JustFloat(e.target.value) || "");
	// }
	return (
		<input
			{...props}
			type="text"
			name={props.name}
			maxLength={props.maxLength || 8}
			value={value}
			onChange={OnChange}
			onBlur={OnBlur}
			autoComplete="off"
			// onPaste={(e) => SetValue(JustFloat(e.target.value) || "")}
		/>
	);
};

export default DecimalFieldTag;
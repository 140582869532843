import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";
import StarSelect from "../../Common/StarSelect";

class Comment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			message: "",
			rating: 5,
			commentSend: false,
		};
		this.SubmitForm = this.SubmitForm.bind(this);
		this.UpdateRating = this.UpdateRating.bind(this);
		this.UpdateMessage = this.UpdateMessage.bind(this);
	}

	UpdateMessage(e) {
		const message = e.currentTarget.value;
		this.setState({ message });
	}

	UpdateRating(rating) {
		this.setState({ rating });
	}

	SubmitForm() {
		const { comment_to_product, type, product_id, order_detail_id } =
			this.props;
		const { message, rating } = this.state;
		let url;
		if (comment_to_product) {
			if (type == "item") {
				url = "item_yorum_yap/" + product_id;
			} else {
				url = "urune_yorum_yap/" + product_id;
			}
		} else {
			url = "urune_yorum_yap/" + product_id;
			// url = "yorum_yap/" + category_id;
		}

		const formData = new FormData();
		formData.append("page", "/siparislerim");
		formData.append("comment", message);
		formData.append("rating", rating);
		formData.append("order_detail_id", order_detail_id);

		PostData(url, formData)
			.then((response) => {
				if (response.state) {
					this.setState({ commentSend: true });
				}
			})
			.catch((err) => {


			});
	}

	render() {
		if (this.state.commentSend) {
			return (
				<div className="alert">
					<svg className="icon">
						<use xlinkHref="#gs-icon-user-panel-approved"></use>
					</svg>
					Yorumunuz gönderildi!
				</div>
			);
		} else {
			return (
				<div className="order-comment">
					<textarea
						onChange={this.UpdateMessage}
						value={this.state.message}
						placeholder="Yorumlarınız bizim için önemli, lütfen deneyiminizi paylaşın."
					></textarea>
					<div className="order-comment-buttons">
						<StarSelect
							name={"rating_" + this.props.order_detail_id}
							onChange={this.UpdateRating}
							defaultChecked={this.state.rating}
						/>
						<button
							type="button"
							onClick={this.SubmitForm}
							className="btn btn-sm btn-blue fixed-width-button"
						>
							Yorum Yap
						</button>
					</div>
				</div>
			);
		}
	}
}

export default Comment;

import React from "react";
import MyCartProductCore from "./MyCartProductCore";
import CartSharePopup from "./CartSharePopup";
import Currency from "../../../packs_helper/scripts/Currency";
// import DecimalFieldTag from "../../Common/DecimalFieldTag";
import HQuantityField from "../../Global/HQuantityField";

class MobileRenderer extends MyCartProductCore {
	render() {
		return (
			<React.Fragment>
				<div className="my-cart-left">
					<img src={this.props.item.image} />
				</div>
				<div className="my-cart-center">
					<div className="my-cart-center-wrapper">
						<div className="my-cart-center-top">
							<div className="my-cart-product-info">
								<div className="my-cart-product-info-flex">
									<h2>
										<a href={this.props.item.product_path} target="_blank">
											{this.props.item.name}
										</a>
									</h2>
									<button
										onClick={this.OnDeleteClick}
										className="my-cart-product-form-delete"
                    disabled={this.state.requesting}
									>
										<svg className="icon">
											<use xlinkHref="#gs-icon-my-cart-trash"></use>
										</svg>
									</button>
									{this.props.item.share_cart && (
									<button
										onClick={this.ToggleCartSharePopup}
										data-copy={this.props.item.share_cart}
										className="btn btn-default"
									>
										<svg className="icon">
											<use xlinkHref="#gs-icon-my-cart-share"></use>
										</svg>
									</button>
									)}
								</div>

								{this.props.item.package_name ? (
									<h3
										className={
											this.props.item.package_name.substr(0, 3) === "!!!"
												? "highlight"
												: ""
										}
									>
										{this.props.item.package_name.replace(/^!!!/, "")}
									</h3>
								) : (
									<h3 />
								)}
							</div>
						</div>
						<div className="my-cart-center-center">
							<div className="my-cart-store">
								<img
									src={this.props.item.store.avatar}
									alt={this.props.item.store.name}
									width="60"
									height="60"
								/>
								{this.props.item.store.slug ? (
									<h2>
										<a
											href={"/magaza/" + this.props.item.store.slug}
											target="_blank"
										>
											{this.props.item.store.name}
										</a>
									</h2>
								) : (
									<h2>{this.props.item.store.name}</h2>
								)}
								<div className="rank-bar">
									<div className="rank-bar-container">
										<div
											className="rank-bar-container-fill"
											data-rank={this.props.item.store.score}
											style={{ width: `${100 - this.props.item.store.score}%` }}
										></div>
									</div>
									<div className="rank-bar-text">
										%{Math.round(this.props.item.store.score)}
									</div>
								</div>

								<a
									href={`/magaza/${this.props.item.store.slug}/yorumlar`}
									rel="nofollow"
								>
									Mağaza Yorumları ({this.props.item.store.comment_count})
								</a>
								<a
									href={`/magaza/${this.props.item.store.slug}`}
									rel="nofollow"
								>
									Mağazanın Diğer Ürünleri (
									{this.props.item.store.product_count})
								</a>
							</div>

						</div>

						<div className="my-cart-center-bottom">
							<HQuantityField
								name="quantity"
								value={this.props.item.quantity}
								onChange={this.OnQuantityChange}
                disabled={this.state.requesting}
							/>
							<div className="my-cart-pricing">
								<div className="original-price">
									{this.props.item.product_cost >
										this.props.item.product_discount_cost && (
										<React.Fragment>
											{Currency(this.props.item.product_cost)} ₺
											<label>
												%
												{Math.round(
													100 -
														(this.props.item.product_discount_cost /
															this.props.item.product_cost) *
															100
												)}
											</label>
										</React.Fragment>
									)}
								</div>
								<div className="selling-price">
									{Currency(this.props.item.product_discount_cost)} ₺
								</div>
							</div>

						</div>
					</div>

					<div className="my-cart-center-alert">
						<svg className="icon">
							<use xlinkHref="#gs-icon-my-cart-warning"></use>
						</svg>
						<div>
							Lütfen siparişlerinizi kontrol ediniz! Satın aldığınız E-pinlerin
							iadesi yoktur. İade-İptal Koşulları için{" "}
							<a href="/kullanici-sozlesmesi#mal-veya-hizmet-iade-ve-iptal-kosullari" target="_blank">
								tıklayınız
							</a>
							.
						</div>
					</div>
				</div>
				{this.state.cartSharePopupVisible && (
					<CartSharePopup 
						Hide={this.ToggleCartSharePopup}
						code={this.props.item.share_cart}
						bonus={this.props.item.bonus}
						quantity={this.props.item.quantity}
						total={this.props.item.product_discount_cost}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default MobileRenderer;

import React from "react";

class CountDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentDateTime: new Date(),
		};
		this.Calculate = this.Calculate.bind(this);
	}

	Calculate() {
		const endDateTime = new Date(this.props.endDateTime);
		const leftTime = Math.floor(
			(endDateTime - this.props.currentDateTime) / 1000
		);
		if (leftTime >= 0) {
			const hours = Math.floor(leftTime / 3600)
				.toString()
				.padStart(2, "0");
			const minutes = Math.floor((leftTime - hours * 3600) / 60)
				.toString()
				.padStart(2, "0");
			const seconds = Math.floor(leftTime % 60)
				.toString()
				.padStart(2, "0");
			return [hours, minutes, seconds];
		} else {
			if (this.props.onComplete) {
				this.props.onComplete();
			}
			return ["00", "00", "00"];
		}
	}
	render() {
		const [hours, minutes, seconds] = this.Calculate();
		return (
			<div className="countdown">
				<svg className="icon">
					<use xlinkHref="#gs-icon-auction-box-time-ending"></use>
				</svg>
				<span>{hours}:{minutes}:{seconds}</span>
			</div>
		);
	}
}

export default CountDown;
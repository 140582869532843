import React from "react";

class CountDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		this.Calculate = this.Calculate.bind(this);
	}

	Calculate() {
		if (this.props.seconds >= 0) {
			const minutes = Math.floor(this.props.seconds / 60)
				.toString()
				.padStart(2, "0");
			const seconds = Math.floor(this.props.seconds % 60)
				.toString()
				.padStart(2, "0");
			return [minutes, seconds];
		} else {
			if (this.props.onComplete) {
				this.props.onComplete();
			}
			return ["00", "00"];
		}
	}
	render() {
		const [minutes, seconds] = this.Calculate();
		return (
				<span>{minutes}:{seconds}</span>
		);
	}
}

export default CountDown;
import React from "react";
import BuyNowPopupCore from "./BuyNowPopupCore";

import DecimalFieldTag from "../../Common/DecimalFieldTag";
import StringFieldTag from "../../Common/StringFieldTag";
import PhoneInput from "../../Common/PhoneInput";
import CountryListSelect from "../../Common/CountryListSelect";
import PopUp from "../../Common/PopUp";
// import Banner from "../../../assets_v3/images/sign-box-banner"
import Banner from "../../../assets_v3/images/fast-buy-email-warn.jpeg"

class DesktopRenderer extends BuyNowPopupCore{
	render() {
		return (
			<PopUp Hide={this.props.Hide} className="gs-sign">
			<div className="gs-sign-grid">
				<div className="gs-sign-form-area">
					<StringFieldTag
						name="name"
						value={this.state.name}
						maxLength={65}
						onChange={(name) => this.setState({ name })}
	        			disabled={this.state.requesting}
						placeholder="Ad:"
					/>
					<StringFieldTag
						name="surname"
						value={this.state.surname}
						maxLength={65}
						onChange={(surname) => this.setState({ surname })}
	        			disabled={this.state.requesting}
						placeholder="Soyad:"
					/>
					<input
						type="text"
						name="email"
						value={this.state.email}
						onInput={(e) => this.setState({ email: e.target.value })}
	        			disabled={this.state.requesting}
						placeholder="E-Posta Adresi:"
					/>

					<div className="phone-grid">
						<CountryListSelect 
							items={this.transformedCountryCodes} 
							prompt="Ülke kodu"
							value={this.state.alpha_2}
							onChange={alpha_2 => this.setState({alpha_2})}
							icon="#gs-icon-my-cart-caret-down"
		          			disabled={this.state.requesting}
						/>

						{this.state.alpha_2 === "TR" ? (
							<PhoneInput
								onChange={(gsm) => this.setState({ gsm })}
	              				disabled={this.state.requesting}
							/>
						) : (
							<DecimalFieldTag
								placeholder="Mobile Number"
								maxLength={15}
								onChange={(gsm) => this.setState({ gsm })}
	              				disabled={this.state.requesting}
							/>
						)}
					</div>

					<div className="my-cart-alert">
						<svg className="icon">
							<use xlinkHref="#gs-icon-my-cart-warning"></use>
						</svg>
						<div>
						Kodlarınız E-Posta olarak gönderilecektir.
						</div>
					</div>

					<div className="input-with-captcha">
						<input
							type="text"
							name="captcha_code"
							value={this.state.captcha_code}
							maxLength={4}
							onInput={(e) => this.setState({ captcha_code: e.target.value })}
							placeholder="Güvenlik Kodu:"
						/>
						<img src={this.state.captchaImage} />
						<button onClick={this.RefreshCaptchaCode} disabled={this.state.requesting}>
							<svg className="icon">
								<use xlinkHref="#gs-icon-my-cart-reload"></use>
							</svg>
						</button>
					</div>

					<div className="checkbox">
						<input
							type="checkbox"
							name="contract"
							id="contract"
							value="1"
							checked={this.state.contract}
							onChange={(e) => this.setState({ contract: e.target.checked })}
	            			disabled={this.state.requesting}
						/>
						<label htmlFor="contract">
							<div>
								<a href="/kullanici-sozlesmesi">Kullanıcı Sözleşmesi</a> ve şartlarını okudum kabul
								ediyorum.
							</div>
						</label>
					</div>

					<button className="btn btn-jungle" onClick={this.MakePayment} disabled={this.state.requesting}>
						Ödemeye Geç
					</button>
				</div>
				<div className="gs-sign-banner-area">
					<img src={Banner} />
				</div>
			</div>
			</PopUp>
		);
	}

}

export default DesktopRenderer;

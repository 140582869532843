import StorePageProducts from "./StorePageProducts";
import StorePageComments from "./StorePageComments";

const components = [
	{
		id: 1,
		component: StorePageProducts,
		label: "Aktif İlanlar",
		labelMobile: "Aktif",
		kind: "products",
	},
	{
		id: 2,
		component: StorePageProducts,
		label: "Satılan İlanlar",
		labelMobile: "Satılanlar",
		kind: "sold-products",
		path: 'satilan-ilanlar'
	},
	{
		id: 3,
		component: StorePageComments,
		label: "Mağaza Yorumları",
		labelMobile: "Yorumlar",
		kind: "comments",
		path: 'yorumlar'
	},
];

export default components;

import React from "react";
import MyItemBox from "./MyItemBox";
import ItemDetail from "./ItemDetail";
import PostData from "../../../packs_helper/scripts/PostData";
import Paginate from "../../Common/Paginate";
import Switcher from "../../Common/Switcher";
import GlobalVar from "../../GlobalVar";

const statuses = [
	{
		id: "",
		text: "Tümü",
	},
	{
		id: "1",
		text: "Onaylandı",
	},
	{
		id: "0",
		text: "Bekliyor",
	},
	{
		id: "2",
		text: "İptal Edildi",
	},
	{
		id: "999",
		text: "Stoku Bitenler",
	}
];

class MyItems extends React.Component {
	constructor(props) {
		super(props);

		const { active_tab, q } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);

		this.state = {
			current_page: 1,
			boosts: [],
			my_items_count: 0,
			my_items: [],
			activeBox: null,
			activeTab: active_tab === undefined ? '' : active_tab,
			boost_discount: 0,
			store_package: "",
			q
		};
		this.GetData = this.GetData.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
		this.ActivateTab = this.ActivateTab.bind(this);
		this.GetPackage = this.GetPackage.bind(this);
	}

	componentDidMount() {
		this.GetData(this.state.current_page);
	}

	BoxOpen(activeBox){
		this.setState({
			activeBox
		})
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetPackage(){
		PostData("/magaza-paketlerim", {}).then((response) => {
			let boosts = this.state.boosts;
			let boost_discount = 0;
			for(let boost_key in boosts){
				boosts[boost_key].remaining = -1;
				response.exist_store_packages[0] && response.exist_store_packages[0].info.package_content.map((product) => {
					if(product.product_id === boosts[boost_key].id){
						boosts[boost_key].remaining = product.remaining;
					}
					if(product.product_id === GlobalVar.PACKAGE_PRODUCTS.BOOST_DISCOUNT_RATE_PRODUCT_ID){
						boost_discount = product.remaining
					}
				});
			}
			this.setState({ boosts, boost_discount });
		});
	}

	GetData(page) {
		PostData("/ilanlarim", {page, filter: this.state.activeTab, q: this.state.q})
			.then((response) => {
				if (response.my_items.length) {
					const my_items_count = response.my_items[0].total_count;
					this.setState({
						my_items_count,
						boosts: response.boost,
						my_items: response.my_items,
						activeBox: response.my_items[0].id
					});
				}
			})
			.catch((err) => {
			});
	}

	ActivateTab(activeTab) {
		this.props.ChangeRoute(`/ilanlarim?active_tab=${activeTab}`);
		this.setState({ activeTab, my_items: [] }, this.GetData);
	}

	render() {
		const { my_items, my_items_count, activeTab } = this.state;
		return (
			<div className="my-items-container grid-1">

				<Switcher
					buttons={statuses}
					onChange={this.ActivateTab}
					active={activeTab}
				/>

				<form action="" className="section-content filter-top">
					<div className="input-with-icon search-box">
						<button className="icon">
							<svg>
								<use xlinkHref="#gs-icon-layout-search"></use>
							</svg>
						</button>
						<input 
							type="search" 
							placeholder="İlan ID veya ilan başlığıyla ara..." 
							name="q" 
							value={this.state.q} 
							onChange={(e) => { 
								this.setState({q: e.target.value}) 
							}} 
						/>
					</div>
				</form>

				{this.state.my_items.length ? (
					<React.Fragment>
						{this.state.my_items.map((my_item) => (
							<MyItemBox
								id={my_item.id}
								name={my_item.name}
								status={my_item.item_status}
								package_name={my_item.package_name}
								asset_id={my_item.asset_id}
								image={
									my_item.type == "purchase" ?
										my_item.category
										:
										my_item.image
								}
								ref={(ref) => (my_item.ref = ref)}
								key={my_item.id}
								BoxOpen={this.BoxOpen}
								show={this.state.activeBox === my_item.id}
								lastUpdate={my_item.last_update}
								mobile={this.props.mobile}
								unsales_count={my_item.unsales_count}
							>

								<ItemDetail
									{...my_item}
									boosts={this.state.boosts}
									boost_discount={this.state.boost_discount}
									user={this.props.user}
									GetData={this.GetData}
									mobile={this.props.mobile}
									GetStorePackage={this.GetPackage}
									StorePackage={this.state.store_packages}
									asset_id={my_item.asset_id}
									trade_url={my_item.trade_url}
									createTime={my_item.created_at}
									haveFee={my_item.from_store_package > 0 && my_item.adFee !== null}
									currentPage={this.state.current_page}
								/>
							</MyItemBox>
						))}
						<Paginate
							onChange={this.ChangePage}
							total_count={my_items_count}
							current_page={this.state.current_page}
							per_page={15}
						/>
					</React.Fragment>
				) : (
					<div className="alert">Listelenecek ilan bulunamadı!</div>
				)}
			</div>
		);
	}
}

export default MyItems;
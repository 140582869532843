import React from "react";
import ImageWithFallback from "../../Common/ImageWithFallback";
import Currency from "../../../packs_helper/scripts/Currency";
import DecimalFieldTag from "../../Common/DecimalFieldTag";
import PostData from "../../../packs_helper/scripts/PostData";

const ProductRow = (props) => {
	const [value, SetValue] = React.useState(1);

	const UpdatePrice = (value) => {
		SetValue(value);
	};

	const BuyProduct = () => {
		PostData(
			"/sepete-gonder",
			{
				id: props.product.id,
				quantity: value.toString(),
			},
			{ rawHtml: true }
		)
		.then((response) => { })
		.catch((err) => { });
	};

	let image, link;
	if(props.product.steam_item){
		image = `https://steamcommunity-a.akamaihd.net/economy/image/${props.product.steam_item.image}/125fx100f`;
		link = `/${props.product.category.slug}/${props.product.steam_item.slug}?id=${props.product.id}`;
	}
	else{
		image = props.product.image
		// image = ImagePath(
		// 					"products",
		// 					"images",
		// 					props.product.id,
		// 					"thumb",
		// 					props.product.image_file_name
		// 				);
		link = `/${props.product.category.slug}/${props.product.slug}`;
	}
	return (
		<div className="store-product-row">
			<a
				href={link}
				className="store-product-row-name"
				target="_blank"
			>
					<ImageWithFallback
						src={image}
						width={125}
						height={100}
					/>
			</a>
			<a
				href={link}
				target="_blank"
			>
				{props.product.name}{" "}
				<small>{props.product.package_name}</small>
			</a>
			<div className="store-product-row-price">
				<label>Satış Fiyatı</label>
				{Currency(props.product.cost)} ₺
			</div>

			{props.kind === "sold-products" ? (
				<React.Fragment>
					<div></div>

					<button className="btn btn-red" disabled={true}>
						Satıldı
					</button>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="input-with-label">
						<label>Adet:</label>
						<DecimalFieldTag onChange={UpdatePrice} value={value} />
					</div>

					<button onClick={BuyProduct} className="btn btn-jungle">
						Satın Al
					</button>
				</React.Fragment>
			)}
		</div>
	);
};

export default ProductRow;
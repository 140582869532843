import React from "react";
import MyMessagesCore from "./MyMessagesCore";
import UserListItem from "./UserListItem";
import MessageAreaItem from "./MessageAreaItem";
import OrderDetailList from "./OrderDetailList";
import SmsMessageBox from "./SmsMessageBox";
import GlobalVar from "../../GlobalVar";




class MobileRenderer extends MyMessagesCore{
	render() {
		if (this.state.messages === null) {
			return (
				<div data-wait-for-response="true">
				</div>
			);
		} else {
			return (
				<div
					id="messages-container"
					className={this.state.active_user ? "open" : ""}
				>
					<div className="left-side">
						<div className="user-list pending-orders-list">
							<header className="side-header">
								Bekleyen Satış Mesajlarım
								{/* <button className="btn btn-link p-0 btn-xs float-right" onClick={this.GoToArchievedMessages}>Arşivlenmiş Mesajlar</button>*/}
							</header>
							<div className="user-list-scroll-area">
								{!!this.state.user_list_has_order.length ? (
									this.state.user_list_has_order.map((list) => (
										<UserListItem
											user={list}
											selected={
												this.state.active_user &&
												list.user_code === this.state.active_user.user_code
											}
											onClick={this.SelectUserHandler}
											order="true"
											key={list.user_code}
										/>
									))
								) : (
										<img
											src={require("../../../assets_v3/images/messages_no_pending_sales.png")}
											className="warning-image"
										/>
								)}
							</div>
						</div>
						<div className="user-list message-list">
							<header className="side-header">
								Mesajlarım
								<button
									className="btn btn-link p-0 btn-xs"
									onClick={this.ShowUnread}
									title={this.state.unread_filter?"Tüm Mesajlar":"Okunmamış Mesajlar"}
								>
									<svg className={this.state.unread_filter?"icon all-icon":"icon unread-icon"}>
										<use xlinkHref="#gs-icon-user-panel-my-messages"></use>
									</svg>
									{this.state.unread_filter ? "Tümü" : "Okunmamış"}
								</button>
							</header>
							<div className="user-list-scroll-area">
								{!!this.state.user_list.length ? (
									this.state.user_list.map((list) => (
										<UserListItem
											user={list}
											selected={
												this.state.active_user &&
												list.user_code === this.state.active_user.user_code
											}
											onClick={this.SelectUserHandler}
											key={list.user_code}
										/>
									))
								) : (
									<div className="alert alert-secondary m-2">
										Mesajınız yok!
									</div>
								)}
								{this.state.currentPage < this.state.maxPage && (
									<button
										onClick={this.GetNexChats}
										className="btn btn-blue"
									>
										Sonraki
									</button>
								)}
							</div>
						</div>
					</div>
					<div className="right-side">
						{this.state.active_user && (
							<div className="right-side-inner">
								<header className="side-header">
									<button className="back" onClick={this.ShowUserList}>
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
										</svg>
										{this.state.active_user.user_code !== GlobalVar.GAMESATIS_MESSAGE_USERCODE ?
											(
												<span>
													<div>
													{this.state.active_user.user_name + " "}
													</div>
													<div>
													{this.state.active_user.approve_store && (
														<span>(
															{this.state.active_user.store_slug ?
																(
																	<a href={'/magaza/' + this.state.active_user.store_slug} target='_blank'>
																		{this.state.active_user.store_name}
																	</a>
																)
																:
																this.state.active_user.store_name}
															)</span>
													)}
													</div>
										</span>
											)
											:
											"GameSatış Bilgilendirme"
										}
									</button>
								<OrderDetailList order_details={this.state.order_details} ChangeRoute={this.props.ChangeRoute} className={this.state.orderDetailListOpen ? "open" : ""} />
								{this.state.order_details.length > 1 && (
									<button className="btn btn-jungle btn-sm order-detail-list-opener" onClick={this.ToggleOrderDetailList}>
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-caret-down"></use>
										</svg>
									</button>
								)}
							</header>
								{/*<div className="alert alert-danger">Konuşma içerisinde kişisel bilgi (telefon numarası, sosyal medya hesabı vb.) paylaşımı yasaktır. Bu tür paylaşımlar sonucu oluşacak sorunlardan GAMESATIS sorumlu değildir.</div>*/}
								{this.state.active_user.user_code !== GlobalVar.GAMESATIS_MESSAGE_USERCODE && !this.activeUserHasSale &&
									this.state.activeUserHasSaleWarningVisible && (
										<div className="messages-area-warning">
											<svg className="icon">
												<use xlinkHref="#gs-icon-user-panel-warning"></use>
											</svg>
											<button
												type="button"
												className="close"
												aria-label="Close"
												onClick={() =>
													this.setState({
														activeUserHasSaleWarningVisible: false,
													})
												}
											>
												<span aria-hidden="true">&times;</span>
											</button>
											<div className="messages-area-warning-body">
												Güvenliğiniz için kullanıcılar ile kişisel bilgilerinizi ve telefon numaranızı{" "}
												<strong><u>
													paylaşmayınız!
													</u>
												</strong>{" "}
												Gamesatış'a ait{" "}
												<strong><u>
													Whatsapp hattı yoktur.
													</u>
												</strong>
												 Size Whatsapp üzerinden GameSatış olarak ulaşan kişilere <strong><u>itibar etmeyiniz.</u></strong>

												 Tüm alım ve satım işlemleri yalnızca{" "}
												<strong><u>
													sitemiz üzerinden
													</u>
												</strong>{" "}
												gerçekleşmektedir. Mesajlaştığınız{" "}
												<strong><u>
													kullanıcı
													</u>
												</strong>{" "}
												ile herhangi bir{" "}
												<strong><u>
													SATIŞ işleminiz yoktur
													</u>
												</strong>
												.
											</div>
										</div>
									)}
								<div
									className="messages-area-container"
									ref={(ref) => (this.MessageAreaRef = ref)}
								>
									<div className="messages-area">
										{this.state.previous_messages_count > 0 && (
											<button
												className="load-before"
												onClick={this.LoadPreviousMessages}
											>
												Önceki Mesajları Yükle
											</button>
										)}
										{this.state.active_messages.map((active_message) => (
											<MessageAreaItem
												message={active_message}
												MarkRead={this.MarkRead}
												key={active_message.id}
											/>
										))}
									</div>
								</div>
								{this.state.active_user.user_code != GlobalVar.GAMESATIS_MESSAGE_USERCODE ? (
									<div className="form-area">
										<textarea
											placeholder="Mesajınızı buraya yazın..."
											ref={(ref) => (this.TextArea = ref)}
											onKeyDown={this.HandleTextareaKeyDown}
											value={this.state.user_message}
											maxLength={this.product_id === undefined ? 1500 : 30}
											onChange={(e) =>
												this.setState({ user_message: e.target.value })
											}
											onPaste={(e) =>
												this.setState({ user_message: e.target.value })
											}
										/>
										<div className="grid-2">
											<button className="btn btn-sm btn-blue" onClick={this.HandleSmsClick}>SMS Gönder</button>
											<button className="btn btn-sm btn-blue" onClick={this.HandleSendClick}>Gönder</button>
										</div>
									</div>
								):(
									<div className="form-area" style={{textAlign: "center"}}>
										<div>
											<div>Bilgilendirme mesajdır yanıt veremezsiniz!</div>
											<div>Size yardımcı olabileceğimiz bir konu var ise Canlı Destek üzerinden bizimle iletişime geçebilirsiniz.</div>
										</div>
									</div>
								)}
								{this.state.new_message_alert > 0 && (
									<button
										className="new-message-alert"
										onClick={this.ScrollToEnd}
									>
										<span>{this.state.new_message_alert}</span>
									</button>
								)}
								{!!this.state.show_sms_box && (
									<SmsMessageBox
										UserCode={this.state.active_user.user_code}
										Hide={() => this.setState({ show_sms_box: false })}
                    message={this.state.user_message}
									/>
								)}
							</div>
						)}
					</div>
					{/*this.state.messageWarningVisible && <MessageWarning Hide={this.CloseMessageWarning} />*/}
				</div>
			);
		}
	}
}

export default MobileRenderer;

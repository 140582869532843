import React from "react";

class PaymentGridListItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			maxHeight: null,
			overflow: 'hidden',
		};
		this.bodyRef = React.createRef();
		this.ToggleItem = this.ToggleItem.bind(this);
		this.UpdateMaxHeight = this.UpdateMaxHeight.bind(this);
	}

	componentDidMount() {
		if (this.props.show) {
			this.ToggleItem();
		}
		else{
			this.setState({ maxHeight: null });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.show && !this.props.show) {
			this.setState({ maxHeight: null, overflow: 'hidden' });
		}
	}

	ToggleItem() {

		if (this.props.waitForResponse) {
			return false;
		}
		let newHeight = null;
		if (!this.state.maxHeight) {
			newHeight = this.bodyRef.current.scrollHeight;
			setTimeout(() => {
				this.setState({overflow: null})
			}, 300)
		}
		else{
			this.setState({overflow: 'hidden'})
		}

		this.setState({ maxHeight: newHeight });
		if(newHeight){
			this.props.ComponentOpen(this.props.id);
		}
	}

	UpdateMaxHeight() {
		let newHeight = this.bodyRef.current.scrollHeight;
		this.setState({ maxHeight: newHeight });
	}

	render() {
		const { maxHeight } = this.state;
		return (
			<div className="payment-grid-list-item">
				<header onClick={this.ToggleItem}>
					{typeof this.props.image === "string" ? (
						<img src={this.props.image} />
					) : (
						this.props.image
					)}
					<div>
						{/*<strong>{this.props.group}</strong> - */}{this.props.name}
						{this.props.slogan && " / "}
						{this.props.slogan}
					</div>
					<button>
						{maxHeight ? (
							<svg className="icon">
								<use xlinkHref="#gs-icon-payment-methods-caret-up"></use>
							</svg>
						) : (
							<svg className="icon">
								<use xlinkHref="#gs-icon-payment-methods-caret-down"></use>
							</svg>
						)}
					</button>
				</header>
				<div
					ref={this.bodyRef}
					className="payment-grid-list-item-body"
					style={{ overflow: this.state.overflow, maxHeight: maxHeight }}
				>
					{this.props.children}
				</div>
			</div>
		);
	}
}
export default PaymentGridListItem;

import React from "react";
import MyCommentsCore from "./MyCommentsCore";
import MyCommentBox from "./MyCommentBox";
import CommentsToStoreBox from "./CommentsToStoreBox";
import Switcher from "../../Common/Switcher";
import Paginate from "../../Common/Paginate";

class DesktopRenderer extends MyCommentsCore{
	render() {
		const { activeTab, comment_count, current_page } = this.state;
		return (
			<div className="my-comments">
				<Switcher
					buttons={[
						{
							id: "user-comments",
							text: "Yorumlarım",
						},
						{
							id: "comments-to-store",
							text: "Mağaza Yorumlarım",
						},
					]}
					onChange={this.ActivateTab}
					active={activeTab}
				/>

				<div className="my-comments-tab">
				{this.state.activeTab === 'comments-to-store' ? 
					this.state.comments.map((comment) => (
						<CommentsToStoreBox
							{...comment}
							ref={(ref) => (comment.ref = ref)}
							GetDetail={this.GetDetail}
							key={comment.id}
							BoxOpen={this.BoxOpen}
							show={this.state.activeBox === comment.id}
						/>
					))
					 : 
					this.state.comments.map((comment) => (
						<MyCommentBox
							{...comment}
							ref={(ref) => (comment.ref = ref)}
							GetDetail={this.GetDetail}
							key={comment.id}
							BoxOpen={this.BoxOpen}
							show={this.state.activeBox === comment.id}
						/>
					))
					}
				</div>
					<Paginate
						onChange={this.ChangePage}
						total_count={comment_count}
						current_page={current_page}
						per_page={15}
					/>
			</div>
		);
	}
}

export default DesktopRenderer;

import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class MyCashOutsCore extends React.Component {
	constructor(props) {
		super(props);

		const { active_tab } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);

		this.state = {
			cash_outs_count: 0,
			current_page: 1,
			my_cash_outs: [],
			activeBox: null,
			activeTab: !active_tab ? '' : active_tab,
		};

		this.GetData = this.GetData.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
		this.CancelMyCashOut = this.CancelMyCashOut.bind(this);

		this.ActivateTab = this.ActivateTab.bind(this);
	}

	componentDidMount() {
		this.GetData(1);
	}

	BoxOpen(activeBox){
		this.setState({
			activeBox
		})
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetData(page){
		PostData("/odeme-taleplerim", {
			page,
			filter: this.state.activeTab,
		})
			.then((response) => {
				// TODO response.set_balance
				let my_cash_outs = [], cash_outs_count = 0, activeBox = null;
				if(response.data.length){
					my_cash_outs = response.data;
					activeBox = my_cash_outs[0].id
					cash_outs_count = my_cash_outs[0].total_count;
				}
				this.setState({
					my_cash_outs,
					cash_outs_count,
					activeBox
				});
			})
			.catch((err) => {
			});
	}

	ActivateTab(activeTab) {
		this.props.ChangeRoute(`/odeme-taleplerim?active_tab=${activeTab}`);
		this.setState({ activeTab, my_cash_outs: [], cash_outs_count: 0, current_page: 1 }, () => this.GetData(1));
	}

	CancelMyCashOut(my_cash_out){
		ShowPopup({
			title: 'Onay Gerekiyor',
			message: 'Ödeme talebinizi iptal etmek istediğinize emin misiniz?<button value="0" class="btn btn-default" data-close>Vazgeç</button><button value="1" class="btn btn-blue" data-close>Evet, İptal Edilsin</button>'
		}).then(button => {
			if(button.value == 1){
				PostData("/mesaj-gonder", {
					mesaj: 'Bekleyen ödeme talebimi iptal etmek istiyorum.',
				}).then(() => {
					ShowPopup({
						title: 'Site Mesajı',
						message: 'Ödeme talebinizi iptal etme isteğiniz alınmıştır.<br>İşleminizle ilgili canlı destek üzerinden dönüş yapılacaktır.'
					});
				});
			}
		})
	}
}


export default MyCashOutsCore;

import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

const statusTexts = {
	approved: "Onaylandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
};
class CurrentAssetsCore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			current_assets_count: 0,
			current_assets_page: 1,
			current_assets: [],
			boxOpen: this.BoxOpen
		};
		this.GetData = this.GetData.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
	}

	componentDidMount() {
		this.GetData(1);
	}

	BoxOpen(activeBox){
		this.setState({
			activeBox
		})
	}

	ChangePage(current_assets_page) {
		this.setState({ current_assets_page });
		this.GetData(current_assets_page);
	}

	GetData(page) {
		PostData("/hareketler", {page})
			.then((response) => {
				// TODO response.set_balance
				if(response.length){
					this.setState({
						current_assets: response,
						current_assets_count: response[0].total_count,
						activeBox: response[0].id,
					});					
				}
			})
			.catch((err) => {


			});
	}
}

export default CurrentAssetsCore;

import React from "react";
import ProfileBoxInput from "../_ProfileBoxInput";
import SettingsBox from "./SettingsBox";
import PopUp from "../../Common/PopUp";
import PostData from "../../../packs_helper/scripts/PostData";
import InputMask from "react-input-mask";
const checkIp = require("check-ip");

class SecuritySettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			edit: null,
			secure_ip: [{ip: '', key: 1}],
		};
		this.TogglePopup = this.TogglePopup.bind(this);
		this.AuthenticationSave = this.AuthenticationSave.bind(this);
		this.ChangePassword = this.ChangePassword.bind(this);
		this.AddSecureIpHandler = this.AddSecureIpHandler.bind(this);
		this.AddSecureIp = this.AddSecureIp.bind(this);
		this.RemoveSecureIp = this.RemoveSecureIp.bind(this);
		this.UpdateSecureIp = this.UpdateSecureIp.bind(this);
		this.SaveSecureIp = this.SaveSecureIp.bind(this);

		this.secureIpKey = 2;

		// setInterval(() => {
		// 	console.log("IPS");
		// 	console.log(this.state.secure_ip);
		// }, 1000);
	}

	componentDidUpdate(prevProps){
		if(prevProps.secure_ip !== this.props.secure_ip && this.props.secure_ip.length > 0){
			this.setState({
				secure_ip: []
			}, () => {
				this.AddSecureIp(this.props.secure_ip);				
			})
			// this.props.secure_ip.forEach(ip => this.AddSecureIp(ip));
		}
	}

	TogglePopup(e) {
		const popup = e.currentTarget.value;

		this.setState({
			popup,
		});
	}

	AuthenticationSave(e) {
		e.preventDefault();
		const formData = new FormData(e.target);

		PostData("/yetkilendirme", formData)
			.then((data) => {
				this.props.UpdateData();
				this.setState({ popup: null });


			})
			.catch((err) => {


			});
	}

	ChangePassword(e) {
		e.preventDefault();
		const formData = new FormData(e.target);

		PostData("/sifre-kaydet", formData)
			.then((data) => {
				this.props.UpdateData();
				this.setState({ popup: null });


			})
			.catch((err) => {


			});
	}

	SaveSecureIp(e) {
		e.preventDefault();
		const formData = new FormData(e.target);
		const secure_ips = formData.getAll('security_ip[]').filter(ip => ip.trim());
		
		// if(!secure_ips.length){
		// 	ShowPopup({
		// 		title: 'Hata',
		// 		message: `Lütfen en az 1 ip adresi girin!`
		// 	});
		// 	return false;
		// }

		for(let i = 0; i < secure_ips.length; i++){
			const cip = checkIp(secure_ips[i]);
			if(!cip.isValid){
				ShowPopup({
					title: 'Hatalı IP',
					message: `Girmiş olduğunuz IP adresi(${secure_ips[i]}) geçerli bir IP adresi değildir. Lütfen kontrol edip tekrar deneyin`
				});
				return false;
			}
			else if(!cip.isPublicIp){
				ShowPopup({
					title: 'Hatalı IP',
					message: `Girmiş olduğunuz IP adresi(${secure_ips[i]}) genel bir IP adresi değildir. Lütfen kontrol edip tekrar deneyin`
				});
				return false;
			}
		}


		PostData("/guvenli-ip-kaydet", formData)
			.then((response) => {
				if(response.state){
					this.props.UpdateData();
					this.setState({ popup: null });
				}
			})
			.catch((err) => {


			});
	}

	AddSecureIpHandler(){
		const empty = this.state.secure_ip.filter(x => !x.ip.trim());
		if(!empty.length && this.state.secure_ip.length < 3){
			this.AddSecureIp(['']);
		}
	}

	AddSecureIp(ips){

		const secure_ip = this.state.secure_ip.filter((x) => x.ip.trim());

		ips.forEach(ip => {
			this.secureIpKey++;
			secure_ip.push({
				ip,
				key: this.secureIpKey
			});			
		});
			// console.log("ADD SECURE IP LAST")
			// console.log(secure_ip)
			this.setState({secure_ip})
		// }
	}

	RemoveSecureIp(key){
		let secure_ip = this.state.secure_ip;
		const index = secure_ip.findIndex(ip => ip.key === key)
		secure_ip.splice(index, 1);
		this.setState({secure_ip}, () => {
			if(!this.state.secure_ip.length){
				this.AddSecureIp([''])
			}
		})
	}

	UpdateSecureIp(ip, key){
		const secure_ip = this.state.secure_ip;
		const index = secure_ip.findIndex(ip => ip.key === key)
		secure_ip[index].ip = ip;
		this.setState({secure_ip})
	}

	render() {

		return (
			<SettingsBox
			icon="secure"
			title="Güvenlik Tercihleri"
			id="security-settings"
			show={this.props.activeBox === "security-settings"}
			BoxOpen={this.props.BoxOpen}
			>
				<div className="profile-settings">
					<div className="settings-input">
						<label>Şifre:</label>
						<div className="settings-input-text">*********</div>
						<button
							className="btn btn-light"
							onClick={this.TogglePopup}
							value="change-password"
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-edit"></use>
							</svg>
						</button>
					</div>

					<div className="settings-input">
						<label>E-Posta Onaylı Giriş:</label>
						<div className={"settings-input-text " + (this.props.mail_authentication ? 'text-green' : 'text-red')}>{this.props.mail_authentication ? 'AÇIK' : 'KAPALI'}</div>
							<button
								className="btn btn-light"
								onClick={this.TogglePopup}
								value="mail-authentication"
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-edit"></use>
								</svg>
							</button>
					</div>

					<div className="settings-input">
						<label>SMS Onaylı Giriş:</label>
						<div className={"settings-input-text " + (this.props.sms_authentication ? 'text-green' : 'text-red')}>{this.props.sms_authentication ? 'AÇIK' : 'KAPALI'}</div>
							<button
								className="btn btn-light"
								onClick={this.TogglePopup}
								value="sms-authentication"
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-edit"></use>
								</svg>
							</button>
					</div>

					<div className="settings-input">
						<label>Güvenli IP Ekleme:</label>
						<div className={"settings-input-text " + (this.props.secure_ip.length ? 'text-green' : 'text-red')}>{this.props.secure_ip.length} Kayıt</div>
						<button
							className="btn btn-light"
							onClick={this.TogglePopup}
							value="change-ips"
						>
							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-edit"></use>
							</svg>
						</button>
					</div>
				</div>

				{this.state.popup === "mail-authentication" && (
					<PopUp
						title="E-Posta Onaylı Giriş"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.AuthenticationSave}
							className="grid-1"
							autoComplete="off"
						>
						<div>Giriş yaparken güvenli olarak işaretlemediğiniz IP adreslerinden yapılan girişler E-Posta ile doğrulansın mı?</div>
						<div className="grid-2">
							<div className="input radio text-red">
								<input type="radio" name="mail_authentication" id="mail_authentication_0" defaultChecked={!this.props.mail_authentication} value="0" />
								<label htmlFor="mail_authentication_0">KAPALI:</label>
							</div>
							<div className="input radio text-green">
								<input type="radio" name="mail_authentication" id="mail_authentication_1" defaultChecked={this.props.mail_authentication} value="1" />
								<label htmlFor="mail_authentication_1">AÇIK:</label>
							</div>
							</div>
							<button className="btn btn-blue">Değiştir</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "sms-authentication" && (
					<PopUp
						title="SMS Onaylı Giriş"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.AuthenticationSave}
							className="grid-1"
							autoComplete="off"
						>
						<div>Giriş yaparken güvenli olarak işaretlemediğiniz IP adreslerinden yapılan girişler SMS ile doğrulansın mı?</div>
						<div className="grid-2">
							<div className="input radio text-red">
								<input type="radio" name="sms_authentication" id="sms_authentication_0" defaultChecked={!this.props.sms_authentication} value="0" />
								<label htmlFor="sms_authentication_0">KAPALI:</label>
							</div>
							<div className="input radio text-green">
								<input type="radio" name="sms_authentication" id="sms_authentication_1" defaultChecked={this.props.sms_authentication} value="1" />
								<label htmlFor="sms_authentication_1">AÇIK:</label>
							</div>
							</div>
							<button className="btn btn-blue">Değiştir</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "change-password" && (
					<PopUp
						title="Parola Değiştir"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.ChangePassword}
							className="grid-1"
							autoComplete="off"
						>
							<div className="input-with-label">
								<label>Yeni Parola:</label>
								<input type="password" name="new_password" />
							</div>
							<div className="input-with-label">
								<label>Yeni Parola:</label>
								<input type="password" name="repeat_password" />
							</div>
							<div className="input-with-label">
								<label>Mevcut Parola:</label>
								<input type="password" name="current_password" />
							</div>

						
							<button className="btn btn-blue">Değiştir</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "change-ips" && (
					<PopUp
						title="Güvenli IP"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.SaveSecureIp}
							className="grid-1"
							autoComplete="off"
						>
							<div className="input-with-label">
								<label>Mevcut Parola:</label>
								<input type="password" name="password" />
							</div>
							{this.state.secure_ip.map((s_ip) => (
								<SecureIpRow
								s_ip={s_ip.ip}
								index={s_ip.key}
								onChange={this.UpdateSecureIp}
								RemoveSecureIp={this.RemoveSecureIp}
								key={s_ip.key}
								/>
							))}

						
						<div className="grid-2">
							<button type="button" className="btn btn-orange" onClick={this.AddSecureIpHandler}>Yeni IP Ekle</button>
							<button className="btn btn-blue">Kaydet</button>
							</div>
						</form>
					</PopUp>
				)}


			</SettingsBox>
		);
	}
}

const SecureIpRow = props => {
	// const [secureIp, SetSecureIp] = React.useState(props.s_ip)


	const OnChange = e => {
		let ip = e.target.value.replace(/[^0-9.]/g, '')


		// SetSecureIp(ip);
		props.onChange(ip, props.index);
	}

	const RemoveSecureIp = () => props.RemoveSecureIp(props.index);

	return (
		<div className="input-with-label">
			<label>IP Adresi:</label>
			<input
			type="text"
			name="security_ip[]"
			placeholder="Örnek: 96.48.177.255"
			value={props.s_ip}
			onChange={OnChange}
			maxLength={15}
			/>
			<button type="button" className="btn btn-red" onClick={RemoveSecureIp}>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-rejected"></use>
				</svg>
			</button>
		</div>

	)
}

export default SecuritySettings;

import React from "react";
import { StatusIcon } from "./MySaleBox";
import SaleDetailButtons from "./SaleDetailButtons";

import ImageWithFallback from "../../Common/ImageWithFallback";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Currency from "../../../packs_helper/scripts/Currency";

const statusTexts = [
	"Beklemede",
	"Tamamlandı",
	"Reddedildi",
	"Teslimatta",
	"Teslim Edildi",
	"İptal Edildi",
];

const SaleDetail = (props) => {
	return (
		<React.Fragment>
			<div className="item-detail sale-detail">
				{props.sale.content.link ? (
					<a href={'/' + props.sale.content.link} target="_blank">
						{props.mobile ? (
							<ImageWithFallback
								className="item-detail-image"
								src={props.sale.content.image && props.sale.content.image.replace('-thumb.', '.')}
							/>
						) : (
						<ImageWithFallback
								className="item-detail-image"
								src={props.sale.content.image}
							/>
						)}
					</a>
				) : (
						props.mobile ? (
							<ImageWithFallback
								className="item-detail-image"
								src={props.sale.content.image && props.sale.content.image.replace('-thumb.', '.')}
							/>
						) : (
						<ImageWithFallback
								className="item-detail-image"
								src={props.sale.content.image}
							/>
						)
				)}
				<div className="item-detail-body">
					<div className="item-detail-body-left">
						<div>
							<label>Tarih:</label>
							<strong>{DateFormat(props.sale.created_at)}</strong>
						</div>
						<div>
							<label>Birim Fiyat:</label>
							<strong>{Currency(props.sale.purchase_cost)} ₺</strong>
						</div>
						<div>
							<label>Adet:</label>
							<strong>{props.sale.quantity}</strong>
						</div>
					</div>
					<div className="item-detail-body-right">
						Tutar:{" "}
						<strong>
							{Currency(props.sale.quantity * props.sale.purchase_cost)} ₺
						</strong>
					</div>
					<div className="item-detail-body-status">
						<StatusIcon status={props.sale.status}>
							{statusTexts[props.sale.status]}
						</StatusIcon>
					</div>
				</div>
				<SaleDetailButtons
					sale={props.sale}
					user={props.user}
					ChangeRoute={props.ChangeRoute}
					ItemDelivered={props.ItemDelivered}
					ItemCancelled={props.ItemCancelled}
				/>
			</div>
			{(props.sale.content.is_steam_item ||
				props.sale.kind == 1 ||
				props.sale.kind == 0 ||
				props.sale.description) && (
				<div className="item-info sale-info">
					{props.sale.content.is_steam_item ? (
						<React.Fragment>
							<strong>
								{
									props.sale.status === 0 ?
										'Teslim edilecek trade URL: '
									:
									props.sale.status === 1 ?
										'Teslim edilen trade URL: '
									:
										'Belirtilen trade URL'
								}

							</strong>
							<a href={props.sale.character_name} target="_blank">
								{props.sale.character_name}
							</a>
						</React.Fragment>
					) : props.sale.kind == 1 ? (
						<React.Fragment>
							<strong>
								{
									props.sale.status === 0 ?
										'Teslim edecek karakter adı: '
									:
									props.sale.status === 1 ?
										'Teslim eden karakter adı: '
									:
										'Belirtilen karakter adı: '
								}
							</strong>
							{props.sale.character_name}
						</React.Fragment>
					) : props.sale.kind == 0 && props.sale.character_name && props.sale.character_name.includes('steamcommunity') && (
						<React.Fragment>
							<strong>
								{
									props.sale.status === 0 ?
										'Teslim edilecek URL: '
									:
									props.sale.status === 1 ?
										'Teslim edilen URL: '
									:
										'Belirtilen URL: '
								}
							</strong>
							<a href={props.sale.character_name} target="_blank">{props.sale.character_name}</a>
						</React.Fragment>
					)}

					{props.sale.description &&
						(props.sale.status === 2 ? (
							<React.Fragment>
								<p>Satış işleminiz iptal edilmiştir!</p>
								<strong>İptal Nedeni: </strong>
								{props.sale.description}
							</React.Fragment>
						) : (
							<div>
								<strong>Açıklama: </strong>
								<span
									dangerouslySetInnerHTML={{ __html: props.sale.description }}
								/>
							</div>
						))}
				</div>
			)}
		</React.Fragment>
	);
};

export default SaleDetail;

import React from "react";
import PostData from "../../packs_helper/scripts/PostData";

const components = {
	"/giris-yap": "sign-in",
	"/kayit-ol": "sign-up",
	"/parolami-unuttum": "password-reset",
	"/parolami-yenile": "password-reset-complete",
	"/uyelik-dogrulama": "confirmation",
};

class SignBoxCore extends React.Component {
	constructor(props) {
		super(props);

		const url = new URL(document.location.href);

		this.pathname = url.pathname;
		let activeTab = "sign-in";
		if (components[this.pathname]) {
			activeTab = components[this.pathname];
		}

		this.state = {
			activeTab: activeTab,
			captchaImage: null,
			email: "",
			password: "",
			captcha_code: "",
			trust_machine: "",
			popup: null,
		};

		this.ActivateTab = this.ActivateTab.bind(this);
		this.UpdateEmail = this.UpdateEmail.bind(this);
		this.RefreshCaptchaCode = this.RefreshCaptchaCode.bind(this);
	}

	componentDidMount() {
		this.RefreshCaptchaCode();
		if(this.props.message){
			ShowPopup({
				title: 'Site Mesajı',
				message: this.props.message
			})
		}
	}

	ActivateTab(activeTab) {
		this.setState({ activeTab });
	}

	UpdateEmail(email){
		this.setState({
			email
		})
	}

	RefreshCaptchaCode() {
		PostData("/refresh_captcha_code").then((response) => {
			this.setState({
				captchaImage: response,
			});
		});
	}
}

export default SignBoxCore;

function GameSelectValidate(validateProps) {
    let resultMessage = '';
    let resultTarget = '';
    let resultState = false;

    if(validateProps.selectedGame && validateProps.selectedGame.id !== undefined){
        resultState = true
    }else{
        resultMessage = 'Devam etmek için lütfen ilan ekleyeceğiniz kategoriyi seçin.';
        resultTarget = 'categoryNameSearch';
    }
    return {
        "state": resultState,
        "message": resultMessage,
        "target": resultTarget
    }
}

export default GameSelectValidate;
function Cookie(cookieString) {
	// this.cookieArray = {};
	
	if(!cookieString){
		cookieString = document.cookie;
	}
	var output = {};
	cookieString.split(/\s*;\s*/).forEach(function(pair) {
		pair = pair.split(/\s*=\s*/);
		var name = decodeURIComponent(pair[0]);
		var value = decodeURIComponent(pair.splice(1).join('='));
		output[name] = value;
	});
	this.cookieArray = output;
}

Cookie.prototype.get = function(name){
	return this.cookieArray[name];
}

Cookie.prototype.set = function(name, value, seconds){
	var expires;
	if (seconds) {
		var date = new Date();
		date.setTime(date.getTime() + (seconds * 1000));
		expires = "; expires=" + date.toGMTString();
	}
	else {
		expires = "";
	}
	document.cookie = name + "=" + value + expires + "; path=/";
}

Cookie.prototype.remove = function(name){
	document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export default Cookie;
import React from "react";
import SellNow from "./SellNow";
import Skin from "./Skin";
import ItemChooseWrapper from "./ItemChooseWrapper";
import DigitalCode from "./DigitalCode";
import PostData from "../../../packs_helper/scripts/PostData";
import Currency from "../../../packs_helper/scripts/Currency";
import Cookie from "../../../packs_helper/scripts/Cookie";


const searchParams = new URL(window.location.href).searchParams;
const selectedGameId = parseInt(searchParams.get("selected_game"));

const cookie = new Cookie();
const types = [
	{
		id: searchParams.get("active_tab") === 'account' ? selectedGameId || 'a' : 'a',
		icon: "account",
		label: "Hesap",
		type: 'accounts',
		component: ItemChooseWrapper,
		needPermission: true,
	},
	{
		id: searchParams.get("active_tab") === 'boost' ? selectedGameId || 'b' : 'b',
		icon: "boost",
		label: "Boost",
		type: 'boost',
		component: ItemChooseWrapper,
		needPermission: true,
	},
	{
		id: 2,
		icon: "skin",
		label: "Skin",
		type: 'skins',
		component: Skin,
		needPermission: true,
	},
	{
		id: 5,
		icon: "code",
		label: "Dijital Kod",
		type: 'digital_codes',
		component: DigitalCode,
		needPermission: true,
	},
	// Fiziksel için:
	// {
	// 	id: searchParams.get("active_tab") === 'physical' ? selectedGameId || 'c' : 'c',
	// 	icon: "tag",
	// 	label: "Bit Pazarı",
	// 	type: 'physical',
	// 	component: ItemChooseWrapper,
	// 	needPermission: true,
	// },
	{
		id: 20127,
		icon: "item",
		label: "Item",
		type: 'items',
		component: ItemChooseWrapper,
		needPermission: true,
	},
	{
		id: 1,
		icon: "sell-us",
		label: "Bize Sat",
		type: 'sell_now',
		component: SellNow,
		needPermission: true,
	},
];

class PostAnAdCore extends React.Component {
	constructor(props) {
		super(props);
		// types[5].needPermission = this.props.user.bsu;
		// const start_component = this.props.user.approve_store !== null ? 0:types.length;
		const start_component = 0;
		const { active_tab } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);
		const activeComponent = types.find(type => type.type == active_tab) || types[start_component];
		this.state = {
			activeComponent,
			popup: !cookie.get('selection_info') ? 'selection_info':null,
		};
		this.SetActiveComponent = this.SetActiveComponent.bind(this);
	}

	componentDidMount() {
		this.UpdateData();
	}

	SelectionWarningClose(){
		cookie.set('selection_info', true)
	}

	UpdateData(){
		PostData("/profilim", {})
			.then((data) => {
				this.setState({data: data});
				if(!this.state.data.not_show_identification_number){
					UpdateUserBalance(Currency(parseFloat(this.state.data.balance)));
				}
			})
			.catch((err) => { });
	};

	SetActiveComponent(id){
		const index = types.findIndex(type => type.id === id);

		this.props.ChangeRoute(`/ilan-ekle?active_tab=${types[index].type}`);
		this.setState({
			activeComponent: types[index]
		})
	}
}

export default PostAnAdCore;
export { types };

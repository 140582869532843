import React from "react";

const OrderDetailListItem = (props) => {
	const GoToMySales = () => {
		if(props.order_detail.me){
			props.ChangeRoute(`/siparislerim?active_tab=0&order=${props.order_detail.id}`);
		}
		else{
			props.ChangeRoute(`/satislarim?id=${props.order_detail.id}`);
		}
	};

	return (
		<React.Fragment>
				<button onClick={GoToMySales} className="order-detail-list-item-id">
					ID: {props.order_detail.id}
				</button>
				<div>
				<a
					href={
						props.order_detail.category_slug +
						"/" +
						props.order_detail.product_slug
					}
					target="_blank"
					className="order-detail-list-item-name"
				>
					{props.order_detail.name}
					{props.order_detail.package_name}
				</a>
				{" "}satın aldı.
				</div>
			<div className="order-detail-list-item-status">
				{props.order_detail.approve_seller_datetime ? (
					<span>
						<span
							className="inprofile-status wait text-green"
							title="Alıcı Onayı Bekleniyor"
						/>
						<span className="order-detail-list-item-status-text">
							Alıcı Onayı Bekleniyor
						</span>
					</span>
				) : (
					<span>
						<span
							className="inprofile-status wait"
							title="Satıcı Onayı Bekleniyor"
						/>
						<span className="order-detail-list-item-status-text">
							Satıcı Onayı Bekleniyor
						</span>
					</span>
				)}
			</div>
		</React.Fragment>
	);
};

export default OrderDetailListItem;
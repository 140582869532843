import React from "react";
import Currency from "../../../packs_helper/scripts/Currency";
import DecimalFieldTag from "../../Common/DecimalFieldTag";
import PriceFieldTag from "../../Common/PriceFieldTag";
import currency from "../../../packs_helper/scripts/Currency";

const PanelSkinBox = (props) => {
  const [status, SetStatus] = React.useState(false);

  const index = props.market_name.lastIndexOf("(");
  let name, packageName;
  if (index > -1) {
    name = props.market_name.substring(0, index);
    packageName = props.market_name.substring(index);
  } else {
    name = props.market_name;
  }

  const suggestedPrice = props.price * props.suggestedPriceRate;

  const [itemObj, SetItemObj] = React.useState({
    key: props.keys[0],
    keys: props.keys,
    quantity: 1,
    description: "",
    cost: +suggestedPrice,
  });

  const [user_cost, SetUserCost] = React.useState(itemObj.cost * itemObj.quantity * (1-props.rate/100));
  // const maxiumumPrice = props.price * props.maximumdPriceRate;

  const ToggleStatus = () => {
    props.SelectionChange(!status, itemObj);
    SetStatus(!status);
  };

  const UpdateCost = (cost) => {
    itemObj.cost = cost;
    props.SelectionChange(status, itemObj);
    SetUserCost(itemObj.cost * itemObj.quantity * (1-props.rate/100));
  };

  const UpdateUserCost = (user_cost) => {
  	itemObj.cost = user_cost / (itemObj.quantity * (1-props.rate/100));
    SetItemObj(itemObj);
    SetUserCost(user_cost)
    props.SelectionChange(status, itemObj);
  };

  const UpdateQuantity = (quantity) => {
    // itemObj.quantity = parseInt(e.currentTarget.value);
    itemObj.quantity = Math.floor(quantity);
    props.SelectionChange(status, itemObj);
    SetUserCost(itemObj.cost * itemObj.quantity * (1-props.rate/100));
  };

  const UpdateDesc = (e) => {
    itemObj.description = e.currentTarget.value;
    props.SelectionChange(status, itemObj);
  };

  const ToggleStatusDivHandler = () => {
  	if(!status){
	    props.SelectionChange(true, itemObj);
	    SetStatus(true);
  	}
  }

  return (
    <div className={"panel-skin-box" + (!status ? " disabled" : "")} onClick={ToggleStatusDivHandler}>
      <div className="panel-skin-box-top">
        {status ? (
          <button
            className="btn btn-xs btn-blue toggle-button"
            onClick={ToggleStatus}
          >
            Çıkar
          </button>
        ) : (
          <button
            className="btn btn-xs btn-light toggle-button"
            onClick={ToggleStatus}
          >
            Ekle
          </button>
        )}
        <h2>{name}</h2>
        <h3>{packageName}</h3>
        <h3>Asset: {props.assetid}</h3>
        <img src={props.image} />
        <small>
          <svg className="icon">
            <use xlinkHref="#gs-icon-user-panel-steam"></use>
          </svg>
          Steam Fiyatı: <strong>{props.price} ₺
          {props.steamRate > 0 && (' (' + currency (props.price / props.steamRate) + ' $)')}
        </strong>
        </small>
        {!props.sellNow && (
          <small>
            <svg className="icon">
              <use xlinkHref="#gs-icon-user-panel-tag"></use>
            </svg>
            Önerilen Satış Fiyatı: <strong>{Currency(suggestedPrice)} ₺</strong>
          </small>
        )}
        <small>
          <svg className="icon">
            <use xlinkHref="#gs-icon-user-panel-gs"></use>
          </svg>
          Sitedeki En Ucuz Fiyat: <strong>{Currency(props.min_cost)} ₺</strong>
        </small>

        {/*<div>{props.type}</div>*/}
      </div>

      {props.sellNow ? (
        <React.Fragment>
          <input
            type="hidden"
            defaultValue={1}
            min={1}
            max={props.quantity}
            onChange={UpdateQuantity}
          />
          <div className="panel-skin-box-info">
            Fiyat Canlı Destek Üzerinden Verilecektir
          </div>
        </React.Fragment>
      ) : (
      <React.Fragment>
        <div className="grid-2 panel-skin-box-grid">
          <PriceFieldTag
		        wrapperClass="panel-skin-box-input"
		        label="Fiyat:"
	          value={itemObj.cost}
	          min={3}
	          onChange={UpdateCost}
          />
          <div className="panel-skin-box-input">
            <label>Adet:</label>
            <DecimalFieldTag
              value={itemObj.quantity}
              min={1}
              max={props.quantity}
              onChange={UpdateQuantity}
            />
          </div>
        </div>
            <PriceFieldTag 
	            wrapperClass="panel-skin-box-input"
	            label="Kazanç"
	            value={user_cost}
	            min={3}
	            onChange={UpdateUserCost}
            />
        </React.Fragment>

      )}
      <textarea
        className="panel-skin-box-desc"
        placeholder="Açıklama:"
        onChange={UpdateDesc}
      ></textarea>
    </div>
  );
};

export default PanelSkinBox;

import React from 'react';


class SellAgreement extends React.Component {
    render() {
        return (
            <div className="wizard-warning-area" dangerouslySetInnerHTML={{__html: this.props.agreement}}>
            </div>
        );
    }
}

export default SellAgreement;
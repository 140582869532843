import React from "react";
import ProfileBoxInput from "../_ProfileBoxInput";
import SettingsBox from "./SettingsBox";
// import userImage from "../../../assets_v3/images/profile-user-image-medium.jpg";
import PopUp from "../../Common/PopUp";
import PostData from "../../../packs_helper/scripts/PostData";
import ImageFileInput from "../../Common/ImageFileInput";
import ImageWithFallback from "../../Common/ImageWithFallback";
import AvatarHandler from "../../Common/AvatarHandler";
import UpdateStoreName from "../Common/UpdateStoreName";


class StoreSettings extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			popup: null
		};
		this.TogglePopup = this.TogglePopup.bind(this);
		this.NotReceiveSMSSave = this.NotReceiveSMSSave.bind(this);
		this.SaveAvatar = this.SaveAvatar.bind(this);
	}

	componentDidMount() {
		// change-store
		const params = new URLSearchParams(window.location.search);
		if (params.has('change_store')){
			this.setState({ popup: 'change-store' });
		}
	}

	TogglePopup(e) {
		const popup = e.currentTarget.value;

		this.setState({
			popup,
		});
	}

	NotReceiveSMSSave(e) {
		e.preventDefault();
		const formData = new FormData(e.target);

		PostData("/bildirim-izinleri", formData)
			.then((data) => {
				this.props.UpdateData();
				this.setState({ popup: null });
			})
			.catch((err) => {

			});
	}

	SaveAvatar(e) {
		e.preventDefault();
		const formData = new FormData(e.target);
		for (let i = 0; i < this.props.uploadedFiles.length; i++) {
			formData.append("uploaded_avatar", this.props.uploadedFiles[i]);
		}
		PostData("/avatar-kaydet", formData)
			.then((data) => {
				this.props.UpdateData();
				this.setState({ popup: null });
			})
			.catch((err) => {

			});
	}

	render() {
		return (
			<SettingsBox
			icon="store"
			title="Mağaza Ayarları"
			id="store-settings"
			show={this.props.activeBox.toString() === "store-settings"}
			BoxOpen={this.props.BoxOpen}
			>
				<div className="store-settings">
					<div className="store-settings-column-left">
						<button
							className="avatar-image"
							onClick={this.TogglePopup}
							value="change-avatar"
						>
							<ImageWithFallback
								src={AvatarHandler(this.props.avatar, this.props.approve_avatar)}
								width="100"
								height="100"
							/>

							<svg className="icon">
								<use xlinkHref="#gs-icon-user-panel-edit"></use>
							</svg>

						</button>
						<div className="settings-input">
							<label>Mağaza Adı:</label>
							<a
								href={(this.props.approve_store && this.props.store_slug) ? `/magaza/${this.props.store_slug}` : null}
								target="_blank"
								className={
									"settings-input-text " +
									(this.props.confirmed ? "text-blue" : "text-red")
								}
							>
								{this.props.store_name && (
									<React.Fragment>
										<svg className="icon">
											<use
												xlinkHref={
													"#gs-icon-user-panel-" +
													(this.props.confirmed ? "approved" : "rejected")
												}
											></use>
										</svg>
										{this.props.store_name}
									</React.Fragment>
								)}
							</a>
							{(!this.props.store_name || this.props.store_name.includes('Store_')) && (
								<button
									className="btn btn-light"
									onClick={this.TogglePopup}
									value="change-store"
								>
									<svg className="icon">
										<use xlinkHref="#gs-icon-user-panel-edit"></use>
									</svg>
								</button>
							)}
						</div>

						<div className="settings-input">
							<label>Mağaza Yorumları:</label>
							<div className="settings-input-text black">
								{this.props.comment_count}
							</div>
						</div>

						<div className="settings-input">
							<label>Toplam İşlem Adedi:</label>
							<div className="settings-input-text black">
								{(this.props.success_sales + this.props.unsuccess_sales).toString()}
							</div>
						</div>

						<div className="settings-input">
							<div className="settings-input-text store-success-grid">
								<div className="settings-input-text">
									<span className="text-jungle">
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-thumb-up"></use>
										</svg>
									</span>
									<span className="black">({this.props.success_sales})</span>
								</div>
								<div className="settings-input-text">
									<span className="text-red">
										<svg className="icon">
											<use xlinkHref="#gs-icon-user-panel-thumb-down"></use>
										</svg>
									</span>
									<span className="black">({this.props.unsuccess_sales})</span>
								</div>
							</div>
						</div>
					</div>
					<div className="store-settings-column-right">
						<div className="settings-input">
							<label>Kullanıcı SMS'leri:</label>
							<div
								className={
									"settings-input-text " +
									(!this.props.not_receive_sms ? "text-green" : "text-red")
								}
							>
								{!this.props.not_receive_sms ? "AÇIK" : "KAPALI"}
							</div>
							<button
								className="btn btn-light"
								onClick={this.TogglePopup}
								value="change-user-sms"
							>
								<svg className="icon">
									<use xlinkHref="#gs-icon-user-panel-edit"></use>
								</svg>
							</button>
						</div>
						<a href="/magaza" className="btn btn-profile" target="_blank">
							Mağaza Kurallarını Okumak İstiyorum
						</a>
						{this.props.confirmed ? (
						<button
							className="btn btn-profile"
							disabled={true}
						>
							Mağaza Doğrulaması Yapıldı
						</button>
						) : (
						<a
							href="/magaza-ve-kimlik-dogrulama"
							className="btn btn-profile"
							target="_blank"
						>
							Mağazamı Onaylatmak İstiyorum
						</a>
						)}
					</div>
				</div>

				{this.state.popup === "change-user-sms" && (
					<PopUp
						title="Kullanıcı SMS'leri"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<form
							action="#"
							onSubmit={this.NotReceiveSMSSave}
							className="grid-1"
							autoComplete="off"
						>
							<div className="grid-2">
								<div className="input radio text-red">
									<input
										type="radio"
										name="not_receive_sms"
										id="not_receive_sms_0"
										defaultChecked={this.props.not_receive_sms}
										value="1"
									/>
									<label htmlFor="not_receive_sms_0">KAPALI:</label>
								</div>
								<div className="input radio text-green">
									<input
										type="radio"
										name="not_receive_sms"
										id="not_receive_sms_1"
										defaultChecked={!this.props.not_receive_sms}
										value="0"
									/>
									<label htmlFor="not_receive_sms_1">AÇIK:</label>
								</div>
							</div>
							<button className="btn btn-blue">Değiştir</button>
						</form>
					</PopUp>
				)}

				{this.state.popup === "change-store" && (
					<PopUp
						title="Mağaza Adı Oluştur"
						Hide={() => this.setState({ popup: null })}
						className="settings-popup"
					>
						<UpdateStoreName
							UpdateData={() => this.props.UpdateData()}
							SetPopUpNull={() => this.setState({popup: null})}
						/>
					</PopUp>
				)}

				{this.state.popup === "change-avatar" && (
					<PopUp
						title="Avatar Seç"
						Hide={() => this.setState({ popup: null })}
						className="avatars-popup"
					>
						<form
							action="#"
							onSubmit={this.SaveAvatar}
							className="grid-1"
							autoComplete="off"
						>
							<div className={this.props.mobile ? "grid-3" : "grid-5"}>
								<div className="upload-avatar-area">
									<div>
										<ImageFileInput
											onChange={this.props.UpdateImage}
											onRemove={this.props.RemoveImage}
											uploadedFiles={this.props.uploadedFiles}
											location={"store-settings"}
										/>
									</div>
									{!this.props.mobile ? (
										<div>
											<div>* Bu özellik sadece aktif paketiniz var ise kullanılabilir.</div>
											<div>* Paket almak ve özelliği aktif etmek için <a href="/magaza-paketleri" target={"_blank"}>tıklayınız</a>.</div>
											<div>* Paket bittikten sonra avatar kalır ancak değiştirilemez.</div>
										</div>
									):(
										<div>
											<div>* Bu özellik sadece aktif paketiniz var ise kullanılabilir.</div>
										</div>
									)}
								</div>
								{Array(15)
								.fill()
								.map((x, index) => (
									<label
										className="avatar-checkbox"
										key={"avatar-" + (index + 1)}
									>
										<input
											type="radio"
											name="avatar_id"
											value={index + 1}
											defaultChecked={index + 1 === this.props.avatar}
										/>
										<ImageWithFallback
											src={AvatarHandler(index + 1, false)}
										/>
									</label>
								))}
							</div>

							<button className="btn btn-blue">Onayla</button>
						</form>
					</PopUp>
				)}
			</SettingsBox>
		);
	}
}

export default StoreSettings;

import React from "react";
// import { createConsumer } from "@rails/actioncable";
import FloatRank from "../../packs_helper/scripts/FloatRank.js";

const rankImages = {
	1: require("../../assets_v3/images/csgo-ranks/1.png"),
	2: require("../../assets_v3/images/csgo-ranks/2.png"),
	3: require("../../assets_v3/images/csgo-ranks/3.png"),
	4: require("../../assets_v3/images/csgo-ranks/4.png"),
	5: require("../../assets_v3/images/csgo-ranks/5.png"),
	6: require("../../assets_v3/images/csgo-ranks/6.png"),
	7: require("../../assets_v3/images/csgo-ranks/7.png"),
	8: require("../../assets_v3/images/csgo-ranks/8.png"),
	9: require("../../assets_v3/images/csgo-ranks/9.png"),
	10: require("../../assets_v3/images/csgo-ranks/10.png"),
	11: require("../../assets_v3/images/csgo-ranks/11.png"),
	12: require("../../assets_v3/images/csgo-ranks/12.png"),
	13: require("../../assets_v3/images/csgo-ranks/13.png"),
	14: require("../../assets_v3/images/csgo-ranks/14.png"),
	15: require("../../assets_v3/images/csgo-ranks/15.png"),
	16: require("../../assets_v3/images/csgo-ranks/16.png"),
	17: require("../../assets_v3/images/csgo-ranks/17.png"),
	18: require("../../assets_v3/images/csgo-ranks/18.png"),
};


// import * as rankImages from "../../assets_v3/images/cs_go_ranks/";
// ActionCable Connection //

// import PostData from "../../packs_helper/scripts/PostData";
// import PropTypes from "prop-types"

class AuctionDetailCore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentDateTime: new Date(),
			auctionEndDateTime: props.auctionEndDateTime,
			maxOffer: 0,
			currentOffer: "",
			offers: [],
			offeredBefore: false,
			price: 0,
		};

		this.description = this.description.bind(this);
		this.OfferValidate = this.OfferValidate.bind(this);
		this.MakeOffer = this.MakeOffer.bind(this);
		this.Received = this.Received.bind(this);

		this.UpdateCurrentOffer = this.UpdateCurrentOffer.bind(this);
		this.serverTimeFix = 0;

		// this.AuctionCompleted = this.AuctionCompleted.bind(this);
		// if (typeof window === "undefined") {
		// 	global.window = {};
		// }
	}

	componentDidMount() {
		// this.serverTimeFix = window.window._stf_ || 0;
		// const consumer = createConsumer();
    const consumer = require('../../packs_helper/scripts/Consumer').default;
		this.connection = consumer.subscriptions.create(
			{ channel: "AuctionChannel", auction_id: this.props.auctionId },
			{
				received: this.Received,
				MakeOffer: function () {
					return this.perform("MakeOffer");
				},
			}
		);

		setInterval(() => {
			this.setState({
				currentDateTime: new Date(),
			});
		}, 1000);

		FloatRank();
	}

	componentDidUpdate() {
		this.serverTimeFix = window.window._stf_ || 0;
		// $('.auction-level').tooltip();
	}

	Received(response) {
		if (!response || !response.type) {
			return false;
		}
		const state = {};
		switch (response.type) {
			case "list":
				state.offers = response.detail.offers.map((offer) => ({
					...offer,
					offer: parseFloat(offer.offer),
				}));
				state.maxOffer = parseFloat(response.detail.max_offer);
				state.auctionEndDateTime = response.detail.end_datetime;
				state.offeredBefore = response.detail.offeredBefore;
				if (state.maxOffer >= this.state.currentOffer) {
					state.currentOffer = "";
					if (this.state.auctionOfferModalVisible) {
						this.setState({ auctionOfferModalVisible: false });
						ShowPopup({
							title: 'Uyarı',
							message: "Yeni teklif verildiği için onaylamak üzere olduğunuz teklif geçerliliğini kaybetti!"
						})
					}
				}
				break;
			case "error":
				ShowPopup({
					title: 'Hata',
					message: response.message
				})
				break;
			case "offerSuccess":
				ShowPopup({
					title: 'Bilgi',
					message: response.message
				})
				break;
			case "refundable_balance_warn":
				this.setState({ refundableBalanceModalVisible: true });
				break;
		}
		this.setState({ ...state });
	}

	OfferValidate() {
		if (!this.props.currentUserId) {
			// Teklif verebilmek için oturum açmanız gerekmektedir.
			ShowPopup({
				title: 'Hata',
				message: 'Açık Artırmaya teklif verebilmek için giriş yapmanız gerekmektedir.<a href="/giris-yap?refer=' +
						window.location.pathname +
						'" class="btn btn-jungle" target="_self" data-remote="true">Üye Girişi</a><button class="btn btn-blue" data-close>Kapat</button>'
			})
		} else if (this.state.currentOffer <= this.state.maxOffer) {
			const acceptableOffer = this.state.maxOffer + 1;
			ShowPopup({
				title: 'Uyarı',
				message: 
					'Verdiğiniz teklif en yüksek tekliften daha düşük.<br>Teklifinizi <strong>' +
						acceptableOffer +
						'₺</strong> olarak güncellenmesini ister misiniz? <button class="btn btn-default" data-value="0" data-close>Vazgeç</button> <button class="btn btn-jungle" data-value="1" data-close>Teklifi Güncelle</button>',
			}).then(button => {
				if (button.dataset.value == 1) {
					this.setState({ currentOffer: this.state.maxOffer + 1 }, () => {
						this.OfferValidate();
					});
				}
			})
		} else {
			if (this.state.offeredBefore) {
				// AuctionOfferModal
				this.setState({ auctionOfferModalVisible: true });
			} else {
				// AuctionJoinModal
				this.setState({ auctionJoinModalVisible: true });
			}
		}
	}

	MakeOffer() {
		this.connection.perform("MakeOffer", { offer: this.state.currentOffer });
		this.setState({
			auctionOfferModalVisible: false,
			auctionJoinModalVisible: false,
		});
	}

	Level(level) {
		if (level < 14) {
			return level;
		} else {
			var tl = 14 + Math.floor((level - 14) / 5);
			return tl > 18 ? 18 : tl;
		}
	}

	RankImage(success_auction_count) {
		return rankImages[this.Level(success_auction_count)];
	}

	description() {
		return { __html: this.props.description };
	}

	UpdateCurrentOffer(currentOffer) {
		this.setState({ currentOffer });
	}
}

export default AuctionDetailCore;

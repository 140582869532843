function PictureSelect(validateProps) {
    let resultMessage = '';
    let resultTarget = '';
    let resultState = false;

    if(validateProps.uploadedFilesLength > 0){
        resultState = true
    }else{
        resultMessage = 'Devam etmek için lütfen en az bir resim ekleyin.';
        resultTarget = 'itemImageSelector';
    }
    return {
        "state": resultState,
        "message": resultMessage,
        "target": resultTarget
    }
}

export default PictureSelect;
import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class CashOutCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			banks: [],
			commissions: [],
			my_banks: [],
			addBank: false,
			currentCommission: 0,
			refundable_balance: 0,
			currentValue: "",
			selectedBank: {
				id: "",
			},
			selectedFormBank: {
				id: "",
			},
			ibanInForm: "",
			activeTab: -1,
			saveBankConfirmationVisible: false
		};
		this.GetData = this.GetData.bind(this);
		this.ChangeBank = this.ChangeBank.bind(this);
		this.ChangeBankInForm = this.ChangeBankInForm.bind(this);
		this.CashOnChange = this.CashOnChange.bind(this);
		this.InputChangeHandler = this.InputChangeHandler.bind(this);
		this.BarcodeChangeHandler = this.BarcodeChangeHandler.bind(this);
		this.PaparaChangeHandler = this.PaparaChangeHandler.bind(this);
		this.RemoveSelectedBank = this.RemoveSelectedBank.bind(this);
		this.SaveBankHandler = this.SaveBankHandler.bind(this);
		this.SaveBank = this.SaveBank.bind(this);

		this.SaveBankConfirmationHandler = this.SaveBankConfirmationHandler.bind(this);
		this.CashOut = this.CashOut.bind(this);

		this.tabs = [
			{
				id: '',
				text: 'Nakite Çevir',
				disabled: false
			},
			{
				id: 'banka-ekle',
				text: 'Banka Ekle',
			},
		]
		this.ActivateTab = this.ActivateTab.bind(this);

		this.IninalId = 21;
		this.PaparaId = 25;
		this.PaparaIbanId = 26;
		this.PaparaCommission = 0.015;
	}

	componentDidMount() {
		this.GetData();
	}

	GetData() {
		PostData("/banka-hesaplarim", {})
			.then((response) => {
				this.setState(prevState => ({
					...response,
					currentCommission: response.commissions[0][2],
					selectedBank: response.my_banks.length ? response.my_banks[0] : {},
				}));
				if(response.my_banks.length === 0){
					this.tabs[0].disabled = true;
					this.setState({
						activeTab: 'banka-ekle',
					})
				}
				else{
					this.tabs[0].disabled = false;
					this.setState({
						activeTab: '',
					})
					if(this.state.selectedBank.bank.id === this.PaparaId || this.state.selectedBank.bank.id === this.PaparaIbanId){
						this.setState({
							currentCommission: 0
						})
					}
				}
			})
			.catch((err) => {


			});
	}

	CashOnChange(currentValue) {
		// const currentValue = Decimal(e.currentTarget.value);
		const { commissions } = this.state;

		this.setState({
			currentValue,
		});

		if(this.state.selectedBank.bank.id === this.PaparaId || this.state.selectedBank.bank.id === this.PaparaIbanId){
			let comval = currentValue * this.PaparaCommission
			if(comval.toString().split('.').length > 1)
				comval = comval.toString().split('.')[0] + '.' + comval.toString().split('.')[1].substr(0,2);
			const currentCommission = parseFloat(comval);
			this.setState({
				currentCommission
			});
			const totalRequest = currentValue + this.state.currentCommission;
			if(this.state.refundable_balance < totalRequest){
				ShowPopup({
					title: "Site Mesajı",
					message:
						'Çekilebilir bakiyenizden daha fazlasını talep edemezsiniz.<button value="0" data-close>Vazgeç</button>'
				});
				this.setState({
					currentValue: 0,
					currentCommission: 0
				})
			}
		}else{
			for (let i = 0; i < commissions.length; i++) {
				if (
					commissions[i][0] <= currentValue &&
					commissions[i][1] >= currentValue
				) {
					this.setState({
						currentCommission: commissions[i][2],
					});
					return;
				}
			}
		}
	}

	ChangeBank(id) {

		const my_banks = this.state.my_banks;
		const selectedBank = my_banks.find((my_bank) => my_bank.id == id);
		this.setState({ selectedBank, currentValue: 0 });
		if(selectedBank.bank.id === this.PaparaId || selectedBank.bank.id === this.PaparaIbanId){
			this.setState({
				currentCommission: 0
			});
		}else{
			const { commissions } = this.state;
			this.setState({
				currentCommission: commissions[0][2]
			});
		}
	}
	ChangeBankInForm(id) {
		if(id == this.IninalId && !this.props.user.confirmed){
			ShowPopup({
				title: "Site Mesajı",
				message:
					'Ininal kartınıza para çekebilmek için öncelikle Mağaza ve Kimlik doğrulaması yapmanız gerekiyor.<button value="0" data-close>Vazgeç</button><button value="1" class="btn btn-blue" data-close>Mağaza ve Kimlik Doğrula</button>',
			}).then((button) => {
				if (button.value == 1) {
					redirect('/magaza-ve-kimlik-dogrulama')
				}
				else{
					this.setState({
						selectedFormBank: {
							id: null,
						}
					}, () => {
						this.setState({
							selectedFormBank: {
								id: "",
							}
						});
					});
				}
			});
			return false;
		}

		const banks = this.state.banks;
		const selectedFormBank = banks.find((bank) => bank.id == id);
		this.setState({ selectedFormBank, ibanInForm: '' });
	}

	RemoveSelectedBank() {
		const id = this.state.selectedBank.id;
		ShowPopup({
			message:
				'Seçmiş olduğunuz banka hesabı silinecektir!<br>Onaylıyor musunuz? <button class="btn btn-default" data-close value="0">Vazgeç</button><button class="btn btn-jungle" data-close value="1">Onayla</button>',
		}).then((button) => {
			if (button.value == 1) {
				PostData("/banka-hesabi-sil", {
					id,
				})
					.then((response) => {
						this.GetData();
					})
					.catch((err) => {


					});
			}
		});
	}

	InputChangeHandler(e) {
		const ibanInForm = e.currentTarget.value.replaceAll(" ", "").toUpperCase();
		if (ibanInForm.length > 26){
			ShowPopup({
				title: 'Hata',
				message: 'IBAN numaranız başında TR ile birlikte toplam 26 haneli olmalıdır.'
			});
			return false
		}
		this.setState({
			ibanInForm,
		});
	}

	BarcodeChangeHandler(e) {
		const value = e.currentTarget.value.replaceAll(/\s/g, '').match(/(\d?)(\d{0,6})(\d{0,6})/)
		value.shift();
		const ibanInForm = value.join(' ').trim();
		this.setState({
			ibanInForm,
		});
	}

	PaparaChangeHandler(e) {
		const value = e.currentTarget.value.replaceAll(/\s/g, '').match(/(\d?)(\d{0,6})(\d{0,6})/)
		value.shift();
		const ibanInForm = value.join('').trim();
		this.setState({
			ibanInForm,
		});
	}

	SaveBankHandler() {
		let iban;
		if(this.state.selectedFormBank.id === this.IninalId){
			iban = this.state.ibanInForm.toString().replace(/\s+/g, '');
			if(iban.length !== 13){
				ShowPopup({
					title: 'Hata',
					message: 'Ininal barkod no 13 rakamdan oluşmaktadır. Eksik giriş yaptınız!'
				});
				return false;
			}
		}else if(this.state.selectedFormBank.id === this.PaparaId){
			iban = this.state.ibanInForm.toString().replace(/\s+/g, '');
			if(iban.length !== 10){
				ShowPopup({
					title: 'Hata',
					message: 'Papara hesap no 10 rakamdan oluşmaktadır. Eksik giriş yaptınız!'
				});
				return false;
			}
			// this.SaveBank();
		}else{
			iban = this.state.ibanInForm.toString();
			if(iban.length !== 26){
				ShowPopup({
					title: 'Hata',
					message: 'IBAN TR dahil 26 karakterden oluşmaktadır. Eksik giriş yaptınız!'
				});
				return false;
			}
		}

		this.setState({
			saveBankConfirmationVisible: true
		})
	}

	SaveBankConfirmationHandler(e){
		this.setState({
			saveBankConfirmationVisible: false
		})
		if(e.target.value == 1){
			this.SaveBank();
		}
		else{
			this.setState({
				saveBankConfirmationVisible: false
			});
		}
	}

	SaveBank() {
		PostData("/banka-hesabi", {
			user_bank_account: {
				bank_id: this.state.selectedFormBank.id,
				iban: this.state.selectedFormBank.id === this.IninalId ? this.state.ibanInForm.toString().replace(/\s+/g, '') : this.state.ibanInForm.toString(),
			},
		})
			.then((response) => {
				if(response.state){
					this.setState({
						activeTab: '',
					});
					this.GetData();
				}
			})
			.catch((err) => {


			});
		}
	CashOut(e) {
		PostData("/nakite-cevir", {
			user_bank_account_id: this.state.selectedBank.id,
			amount: this.state.currentValue,
		})
			.then((response) => {
				if (response.state) {
					this.props.ChangeRoute("/odeme-taleplerim?active_tab=0,2");
				}
			})
			.catch((err) => {


			});
	}

	ActivateTab(activeTab) {
		this.props.ChangeRoute(`/banka-esaplarim?active_tab=${activeTab}`);
		this.setState({ activeTab });
	}

}

export default CashOutCore;

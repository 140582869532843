import React from "react";
import ProfileSettings from "./ProfileSettings";
import SecuritySettings from "./SecuritySettings";
import NotificationSettings from "./NotificationSettings";
import InvoiceSettings from "./InvoiceSettings";
import DateFormatter from "../../Common/DateFormatter";
import PostData from "../../../packs_helper/scripts/PostData";
import Currency from "../../../packs_helper/scripts/Currency";

const Settings = (props) => {
	const [data, SetData] = React.useState({
		identification_number: true,
		gsm_verification: true,
		success_sales: 0,
		unsuccess_sales: 0,
		avatar_id: 0,
		gsm_verification_timeout: 0,
	});

	const [activeBox, SetActiveBox] = React.useState("profile-settings");

	React.useEffect(() => {
		UpdateData();
	}, []);

	const UpdateData = () => {
		PostData("/profilim", {})
			.then((data) => {
				SetData(data);
				props.UpdatePersonalInfoVisibility(data.not_show_identification_number);
				if(data.not_show_identification_number){
					UpdateUserBalance('##,##');
				}
				else{
					UpdateUserBalance(Currency(parseFloat(data.balance)));
				}
			})
			.catch((err) => {

			});
	};

	return (
		<div className="settings">

			<ProfileSettings
				UpdateData={UpdateData}
				name={data.name}
				lastname={data.lastname}
				email={data.email}
				confirmed_at={false}
				birthdate={DateFormatter(data.birthdate)}
				gsm={data.gsm}
				gsm_temp={data.gsm_temp}
				country_code={data.country_code}
				identification_number={data.identification_number}
				identification_number_control={data.identification_number_control}
				gsm_verification={data.gsm_verification}
				gsm_verification_timeout={data.gsm_verification_timeout}
				not_show_identification_number={data.not_show_identification_number}
				activeBox={activeBox}
				BoxOpen={SetActiveBox}
			/>
			<SecuritySettings
				UpdateData={UpdateData}
				mail_authentication={data.mail_authentication}
				sms_authentication={data.sms_authentication}
				secure_ip={data.secure_ip || []}
				activeBox={activeBox}
				BoxOpen={SetActiveBox}
			/>
			<NotificationSettings
				UpdateData={UpdateData}
				sms_send_permission={data.sms_send_permission}
				email_send_permission={data.email_send_permission}
				activeBox={activeBox}
				BoxOpen={SetActiveBox}
			/>
			<InvoiceSettings
				UpdateData={UpdateData}
				name={data.name}
				lastname={data.lastname}
				cafe_name={data.cafe_name}
				tax_number={data.tax_number}
				tax_office={data.tax_office}
				invoice_type={data.invoice_type}
				address={data.address}
				city_id={data.city_id}
				city_name={data.city_name}
				town_id={data.town_id}
				town_name={data.town_name}
				not_show_identification_number={data.not_show_identification_number}
				activeBox={activeBox}
				BoxOpen={SetActiveBox}
			/>
		</div>
	);
};

export default Settings;

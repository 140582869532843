import React from "react";
// import ImageNotFound from "../../assets_v3/images/image-not-found";

const ImageWithFallback = props => {
	const [src, SetSrc] = React.useState(props.src);
	const ImageNotFound = props.fallback || (props.mobile ? 'https://s3.eu-central-1.amazonaws.com/img.gamesatis.com/assets/no-preview/525x420.jpg' : 'https://s3.eu-central-1.amazonaws.com/img.gamesatis.com/assets/no-preview/125x100.jpg');

	React.useEffect(() => {
		SetSrc(props.src);
	}, [props.src]);

	const onError = e => {
		SetSrc(ImageNotFound);
	}

	return (
		<img {...props} src={src || ImageNotFound} onError={onError} className={props.className} mobile={null} />
	)
}

export default ImageWithFallback;

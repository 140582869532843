import React from 'react';
import ImageWithFallback from "../../../../Common/ImageWithFallback";

class GameSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            categorySearchParam: null,
            categoryResultCount: 1,
        }
    }
    changeGame(categoryId, categoryName){
        const goTo = document.getElementsByClassName('wizard-grid')[0].offsetTop;
        window.scroll(0, goTo)
        this.setState({categorySearchParam: categoryName})
        this.props.onChange(categoryId);
        this.gameResultCounter(categoryName)
    }
    gameSearch(e){
        this.setState({categorySearchParam: e });
        this.gameResultCounter(e);
    }
    gameResultCounter(searchValue){
        let categoryResultCount = 0;
        this.props.options.map((category) => (
            category.name.toUpperCase().indexOf(searchValue.toUpperCase()) > -1 &&
                categoryResultCount++
        ))
        this.setState({categoryResultCount})
    }
    render() {
        if (this.props.options.length === 1){
            this.changeGame(this.props.options[0].id, this.props.options[0].name)
        }
        return (
            <div className="wizard-category-step">
                <div className="wizard-category-search">
                    <div className="grid-2x">
                        <input
                            placeholder="Kategori Adı:"
                            type="text"
                            id="categoryNameSearch"
                            ref="categoryNameSearch"
                            value={this.state.categorySearchParam || ''}
                            onChange={(e) => this.gameSearch(e.target.value)}
                            autoFocus
                        />
                        <button className="btn" onClick={() => this.gameSearch("")}>
                            <svg className={
                                this.state.categorySearchParam && this.state.categorySearchParam.length > 0 ?
                                    "icon active"
                                    :"icon passive"
                            }>
                                <use xlinkHref="#gs-icon-user-panel-rejected"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="wizard-category-content">
                    {this.props.options.length > 0 && this.props.options.map((category) => (
                        category.name
                            .toUpperCase()
                            .indexOf(
                                this.state.categorySearchParam == null ? "":this.state.categorySearchParam.toUpperCase()
                            ) > -1 &&
                        (
                            <a
                                className='wizard-category-selection'
                                data-type='accounts'
                                key={category.id}
                                onClick={() => this.changeGame(category.id, category.name)}
                            >
                                <picture className='wizard-category-selection-image'>
                                    <ImageWithFallback
                                        src={category.image['category_image']}
                                        alt={category.name}
                                        loading="lazy"
                                    />
                                </picture>
                                <h2>{category.name}</h2>
                            </a>
                        )
                    ))}
                    {this.state.categoryResultCount == 0 && (
                        this.props.options.map((category) => (
                        category.name.toUpperCase().indexOf("diğer".toUpperCase()) > -1 &&
                        (
                            <a
                                className="wizard-category-selection" data-type="accounts"
                                key={category.id}
                                onClick={() => this.changeGame(category.id, category.name)}
                            >
                                <picture className="wizard-category-selection-image">
                                    <ImageWithFallback
                                        src={category.image['category_image']}
                                        alt={category.name}
                                        loading="lazy"
                                    />
                                    <h2>{category.name}</h2>
                                </picture>
                            </a>
                        )
                    )))}
                </div>
            </div>
        );
    }
}

export default GameSelect;
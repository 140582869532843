import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class SupportTicketsCore extends React.Component {
	constructor(props) {
		super(props);

		const { active_tab, support_id, template } = Object.fromEntries(
			new URL(window.location.href).searchParams
		);
		this.active_tab = active_tab;

		if (template === "verify") {
			this.formDefaultType = "5";
			this.formDefaultTitle = "ID Verification";
			this.formDefaultMessage =
				"My national identity card is attached. Please verify my account.";
		} else if (template === "feedback") {
			this.formDefaultType = "2";
			this.formDefaultTitle = "Geri Bildirim";
			this.formDefaultMessage = "";
		}

		this.state = {
			support_ticket_count: 0,
			current_page: 1,
			support_tickets: [],
			activeTab: "support-tickets",
			uploadedFiles: [],
			password_required: false,
			support_id: this.formDefaultType || 1,
			activeBox: null,
			ticketCategories: [],
		};

		this.GetData = this.GetData.bind(this);
		// this.GetDetail = this.GetDetail.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.ActivateTab = this.ActivateTab.bind(this);
		this.UpdateImage = this.UpdateImage.bind(this);
		this.RemoveImage = this.RemoveImage.bind(this);
		this.TypeChanged = this.TypeChanged.bind(this);
		this.CreateNewTicket = this.CreateNewTicket.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
	}

	componentDidMount() {
		this.GetData(1);
		if(this.active_tab === 'create'){
			this.ActivateTab('create');
		}
	}

	BoxOpen(activeBox) {
		this.setState({
			activeBox,
		});
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetData(page) {
		PostData("/destek-taleplerim", { page })
			.then((response) => {
				if (response.length) {
					this.setState({
						support_ticket_count: response[0].total_count,
						support_tickets: response,
						activeBox: response[0].id,
					});
				}
			})
			.catch((err) => {});
	}
	ActivateTab(activeTab) {
		if (activeTab == "create") {
			if (this.state.ticketCategories.length === 0){
				PostData("/destek-taleplerim", { type: "get_ticket_types" }).then(
					(ticketCategories) => {
						this.setState({ activeTab, ticketCategories });
					}
				);
			}
			else {
				this.setState({ activeTab });
			}
		} else {
			this.setState({ activeTab }, this.GetData);
		}
	}

	UpdateImage(e) {
		const uploadedFiles = this.state.uploadedFiles;
		if (uploadedFiles.length >= 3) {
			ShowPopup({
				message: "En fazla 3 görsel yükleyebilirsiniz!",
				title: "Hata",
			});
			e.target.value = '';
			return false;
		}

		const files = e.currentTarget.files;
		if (files.length) {
			const types = ['image/jpeg', 'image/jpg', 'image/png'];
			if (types.indexOf(files[0].type) === -1) {
				ShowPopup({
					message: "Sadece JPG ve PNG fotoğraflarını yükleyebilirsiniz!",
					title: "Hata",
				});
				e.target.value = '';
				return false;
			}
			uploadedFiles.push(files[0]);
		}
		this.setState({ uploadedFiles }, 
			() => { e.target.value = ''; }
			);
	}

	RemoveImage(fileIndex) {
		const uploadedFiles = this.state.uploadedFiles;
		uploadedFiles.splice(fileIndex, 1);
		this.setState({ uploadedFiles });
	}

	TypeChanged(value) {
		this.setState({
			password_required: value == 7,
			support_id: value,
		});
	}

	CreateNewTicket(e) {
		e.preventDefault();
		this.setState({
			saving: true,
		});
		const formData = new FormData(e.target);

		for (let i = 0; i < this.state.uploadedFiles.length; i++) {
			formData.append("support_ticket[image][]", this.state.uploadedFiles[i]);
		}

		PostData("/destek-talebi-olustur", formData)
			.then((response) => {
				if (response.state) {
					this.GetData(1);
					this.ActivateTab("support-tickets");
				}
				this.setState({
					saving: false,
				});
			})
			.catch((err) => {});
	}
}

export default SupportTicketsCore;
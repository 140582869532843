const Copy = function(e) {

		let data = e.currentTarget.dataset.copy;
		if (data instanceof Array) {
			data = data.join("\n");
		}
		const textarea = document.createElement("TEXTAREA");
		textarea.value = data;
		textarea.style.position = "fixed";
		textarea.style.top = 0;
		textarea.style.left = 0;
		textarea.style.width = 0;
		textarea.style.height = 0;
		textarea.style.zIndex = 9999999999999;
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand("copy");
		document.body.removeChild(textarea);
	}

export default Copy;

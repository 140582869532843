import React from "react";
import HelpAndSupportCore from "./HelpAndSupportCore";
import HelpAndSupportFilterButton from "./HelpAndSupportFilterButton";
import HelpAndSupportListItem from "./HelpAndSupportListItem";

class MobileRenderer extends HelpAndSupportCore {
	constructor(props) {
		super(props);
		this.state.activeCategory = null;
		this.ToggleActiveCategory = this.ToggleActiveCategory.bind(this);
	}

	ToggleActiveCategory(activeCategory) {
		if (this.state.activeCategory === activeCategory) {
			this.setState({
				activeCategory: null,
			});
		} else {
			this.SetActiveCategory(activeCategory);
		}
	}

	render() {
		const { activeCategory } = this.state;

		return (
			<section className="container">
				<div className="payment-grid">
					<div className="payment-grid-filter">
						<header>
							<h1>Yardım & Destek</h1>
							<label>
								Lütfen destek veya yardım almak istediğiniz konuyu seçiniz.
							</label>

							<div className="input-with-icon">
								<div className="icon">
									<svg>
										<use xlinkHref="#gs-icon-layout-search"></use>
									</svg>
								</div>
								<input
									type="search"
									placeholder="Başlıklarda Ara..."
									onInput={this.FilterCategories}
								/>
							</div>
						</header>
						<div className="payment-grid-filter-body">
							{this.state.filteredCategories.map((category) => (
								<React.Fragment key={"category-" + category.id}>
									<HelpAndSupportFilterButton
										onClick={this.ToggleActiveCategory}
										active={activeCategory && activeCategory.id === category.id}
										category={category}
									/>

									<PaymentGridList
										activeCategory={activeCategory}
										show={activeCategory && activeCategory.id === category.id}
									/>
								</React.Fragment>
							))}
							{!this.state.filteredCategories.length && (
								<div className="payment-grid-filter-body-alert">
									Sonuç Bulunamadı!
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		);
	}
}

const PaymentGridList = (props) => {
	const [boxHeight, SetBoxHeight] = React.useState(0);
	const boxRef = React.useRef();

	React.useEffect(() => {
		if (props.show) {

			const newBoxHeight = boxRef.current.scrollHeight;
			SetBoxHeight(newBoxHeight);
		} else {
			SetBoxHeight(0);
		}
	}, [props.show]);

	return (
		<div
			className="payment-grid-list"
			style={{ height: boxHeight }}
			ref={boxRef}
		>
			{props.show && props.activeCategory &&
				props.activeCategory.questions.map((question) => (
					<HelpAndSupportListItem
						key={"question-" + question.id}
						question={question}
					/>
				))}
		</div>
	);
};

export default MobileRenderer;

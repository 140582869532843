import React from "react";
import OrderDetailListItem from "./OrderDetailListItem";

const OrderDetailList = (props) => {
	if (!props.order_details.length) {
		return null;
	}

	return (
		<div className={"order-detail-list " + props.className}>
			{props.order_details.map((order_detail) => (
				<OrderDetailListItem
					order_detail={order_detail}
					ChangeRoute={props.ChangeRoute}
					key={order_detail.id}
				/>
			))}
		</div>
	);
};

export default OrderDetailList;

import React from "react";
import PostData from "../../packs_helper/scripts/PostData";

class PasswordResetComplete extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			password: "",
			password_confirmation: "",
			captcha_code: "",
			contract: "",
		};

		this.PasswordReset = this.PasswordReset.bind(this);
		this.KeyDownHandler = this.KeyDownHandler.bind(this);
		this.SwitchToSignIn = this.SwitchToSignIn.bind(this);
		this.SwitchToConfirmationForm = this.SwitchToConfirmationForm.bind(this);
	}

	PasswordReset(e) {
		e.preventDefault();

		const { password, password_confirmation, captcha_code, contract } =
			this.state;
		const url = new URL(document.location.href);
		const email = url.searchParams.get("email");
		const reset_password_token = url.searchParams.get("reset_password_token");

		const params = {
			password,
			password_confirmation,
			email,
			reset_password_token,
			captcha_code,
			contract,
		};

		this.setState({ waitForResponse: true });
		PostData(this.props.renewPasswordPath, params)
			.then((response) => {


				if (!response.state) {
					this.props.RefreshCaptchaCode();
				} else {
					this.props.ActivateTab("sign-in");
				}
			})
			.catch((err) => {


			});
	}

	KeyDownHandler(e) {
		const keyCode = e.key || e.code;
		if (keyCode === "Enter") {
			this.Login(e);
		}
	}

	SwitchToSignIn() {
		this.props.ActivateTab("sign-in");
	}

	SwitchToConfirmationForm() {
		this.props.ActivateTab("confirmation");
	}

	render() {
		return (
			<React.Fragment>
				<div className="input-with-label">
					<label>Şifre:</label>
					<input
						type="password"
						name="password"
						value={this.state.password}
						onInput={(e) => this.setState({ password: e.target.value })}
						onKeyDown={this.KeyDownHandler}
					/>
				</div>
				<div className="input-with-label">
					<label>
						Şifre <small>(Tekrar)</small>:
					</label>
					<input
						type="password"
						name="password_confirmation"
						value={this.state.password_confirmation}
						onInput={(e) =>
							this.setState({ password_confirmation: e.target.value })
						}
						onKeyDown={this.KeyDownHandler}
					/>
				</div>

				<div className="input-with-captcha">
					<input
						type="text"
						name="captcha_code"
						value={this.state.captcha_code}
						onInput={(e) => this.setState({ captcha_code: e.target.value })}
						onKeyDown={this.KeyDownHandler}
						maxLength={4}
						placeholder="Güvenlik Kodu:"
					/>
					<img src={this.props.captchaImage} />
					<button onClick={this.props.RefreshCaptchaCode}>
						<svg className="icon">
							<use xlinkHref="#gs-icon-sign-box-reload"></use>
						</svg>
					</button>
				</div>

				<button className="btn btn-blue" onClick={this.PasswordReset}>
					Gönder
				</button>
				<button className="btn btn-default" onClick={this.SwitchToSignIn}>
					Giriş Yap
				</button>
				<button
					className="btn btn-default"
					onClick={this.SwitchToConfirmationForm}
				>
					Üyelik Doğrulama
				</button>
			</React.Fragment>
		);
	}
}

export default PasswordResetComplete;

import React from "react";
import CurrentAssetsCore from "./CurrentAssetsCore";
import CurrentAssetsBox from "./CurrentAssetsBox";
import PostData from "../../../packs_helper/scripts/PostData";
import Paginate from "../../Common/Paginate";
// import productDetailImage from "../../../assets_v3/images/product-detail.jpg";

const statusTexts = {
	approved: "Onaylandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
};

class DesktopRenderer extends CurrentAssetsCore{
	render() {
		const { current_assets, current_assets_count, current_assets_page } = this.state;
		return (
			<div className="current-assets-container grid-1">
				{current_assets.map((current_asset) => (
					<CurrentAssetsBox
						id={current_asset.id}
						created_at={current_asset.created_at}
						total={current_asset.total}
						amount={current_asset.amount}
						key={current_asset.id}
						BoxOpen={this.BoxOpen}
						show={this.state.activeBox === current_asset.id}
					>
						<div className="current-asset-detail">
							<strong>#{current_asset.id}</strong>
							- 
							<div>{current_asset.description}</div>
						</div>
					</CurrentAssetsBox>
				))}
				{current_assets_count > 0 && (
					<Paginate
						onChange={this.ChangePage}
						total_count={current_assets_count}
						current_page={current_assets_page}
						per_page={15}
					/>
				)}
			</div>
		);
	}
}
export default DesktopRenderer;
import React from "react";
import PriceFieldTag from "../Common/PriceFieldTag";


const HPriceField = props => {
	const [value, SetValue] = React.useState(props.value || 0);

	return(
		<PriceFieldTag {...props} value={value} onChange={SetValue} />
	)
}

export default HPriceField;
import React from 'react';
import ImageWithFallback from "../../../Common/ImageWithFallback";
import GlobalVar from "../../../GlobalVar";
import Currency from "../../../../packs_helper/scripts/Currency";

class ItemProductBox extends React.Component {
    render() {
        const levels = GlobalVar.STORE_LEVELS;
        const userLevel = this.props.user.level ? this.props.user.level : 0;
        return (
            <a className="product" style={{maxWidth: "16rem", minWidth: "16rem"}}>
                <ImageWithFallback src={this.props.firstImage} className="product-image" />
                <label className="highlight">
                {this.props.user.confirmed ? (
                    <span>{this.props.user.store_name || "Önizleme"}</span>
                ):(
                    <span>{this.props.categoryName || "Önizleme"}</span>
                )}
                </label>
                <h3>{this.props.itemName || "İlan başlığı girilmedi."}</h3>
                    {this.props.user.confirmed ? (
                        <div className="product-store-info">
                            <svg className="icon active" title="Onaylı Mağaza">
                                <use xlinkHref="#gs-icon-product-box-verified"></use>
                            </svg>
                            <img
                                src={GlobalVar.OLD_AWS_URL + '/assets/store/' + levels[userLevel].icon + '-icon.svg'}
                                style={{width: 43, height: 24}}
                            />
                        </div>
                    ):(
                        <div className="product-store-info">
                        </div>
                    )}

                <div className="original-price"></div>
                <div className="selling-price">
                    {Currency(this.props.price)} ₺
                </div>
            </a>
        );
    }
}

export default ItemProductBox;
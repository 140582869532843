import React from "react";

class PaymentMethodsCore extends React.Component {
	constructor(props) {
		super(props);
		const paymentMethods = props.paymentMethods;

		const exchangePermission = paymentMethods.find((paymentMethod) => paymentMethod["name"].includes("Takas"));
		if (exchangePermission) {
			paymentMethods.push({
				id: 1,
				name: "Takas",
				slogan: null,
				group: "exchange",
				form_action: "/promo-key-kullan",
			});
		}

		paymentMethods.push({
			id: 100002,
			name: "GameSatış E-Pin",
			slogan: null,
			group: "epin",
			form_action: "/gs-epin-kullan",
		});
		paymentMethods.push({
			id: 100003,
			name: "GameSatış Promo",
			slogan: null,
			group: "promo",
			form_action: "/promo-key-kullan",
		});

		const defaultGroup = props.mobile ? '' : 'credit_card';
		const [activeGroup, activeTab] = (window.location.hash.substr(1) || defaultGroup).split(',');
		this.state = {
			activeGroup,
			activeTab,
			filteredPaymentMethods: paymentMethods.filter(
				(paymentMethod) => paymentMethod["group"] === activeGroup
			),
		};

		this.quickSelectAmounts = [25, 50, 100, 250, 500, 1000];

		this.ChangeActiveGroup = this.ChangeActiveGroup.bind(this);
	}

	componentDidMount(){
		window.onpopstate = history.onpushstate = () => {
			this.ChangeActiveGroup(window.location.hash.substr(1));
		}
	}

	ChangeActiveGroup(route) {
		const [activeGroup, activeTab] = (route || "credit_card").split(',');

		const filteredPaymentMethods = this.props.paymentMethods.filter(
			(paymentMethod) => paymentMethod["group"] === activeGroup
		);
		this.setState({ activeGroup, activeTab, filteredPaymentMethods });
	}
}

export default PaymentMethodsCore;

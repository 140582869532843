import React from "react";

const StarSelect = props => {
	const [checked, SetChecked] = React.useState(props.defaultChecked)

	const OnChange = (value) => {
		if(props.onChange){
			props.onChange(value);
		}
		SetChecked(value);
	}

	return (
		<div className="star-select-grid">
		{[1,2,3,4,5].map(value => <StarButton name={props.name} value={value} checked={checked == value} OnChange={OnChange} key={props.name + "-" + value} />)}
		</div>
		);
}

const StarButton = props => {
	const OnChange = (e) => {
		if(e.currentTarget.checked){
			props.OnChange(props.value);
		}
	}
	return (
		<React.Fragment>
			<label htmlFor={props.name + "-" + props.value}>
				<svg className="icon">
					<use xlinkHref="#gs-icon-user-panel-star"></use>
				</svg>
			</label>
			<input type="radio" name={props.name} id={props.name + "-" + props.value} checked={props.checked} value={props.value} onChange={OnChange} />
		</React.Fragment>
	);
}
export default StarSelect;
import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class MyCommentsCore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comment_count: 0,
			current_page: 1,
			activeTab: "user-comments",
			comments: [],
			activeBox: null
		};
		this.ActivateTab = this.ActivateTab.bind(this);
		this.GetData = this.GetData.bind(this);
		this.GetDetail = this.GetDetail.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
	}

	componentDidMount() {
		this.GetData(1);
	}

	BoxOpen(activeBox){
		this.setState({
			activeBox
		})
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetData(page) {
		const data = {};
		if (this.state.activeTab === "comments-to-store") {
			data.store = 1;
		}
		data.page = page;
		PostData("/magaza-yorumlari", data)
			.then((response) => {
				if(response.comments.length){
					this.setState({
						comment_count: response.comment_count,
						comments: response.comments.map((comment) => ({
							...comment,
							content: {},
						})),
						activeBox: response.comments[0].id
					});
				}
			})
			.catch((err) => {


			});
	}

	GetDetail(comment_id) {
		PostData("/magaza-yorum-detayi", { comment_id })
			.then((response) => {
				const comments = this.state.comments;
				const index = comments.findIndex(
					(comment) => comment.id === comment_id
				);
				comments[index].content = response;
				this.setState(
					{
						comments,
					},
					() => comments[index].ref.UpdateMaxHeight()
				);
				// comment.is_read = true;
			})
			.catch((err) => {


			});
	}

	ActivateTab(activeTab) {
		this.setState({ activeTab }, this.GetData);
	}

}

export default MyCommentsCore;

import React from 'react';

class AdInfo extends React.Component {
    render() {
        return (
            <div className="grid-1">
                <div className="wizard-input">
                    <label>İlan Başlığı:</label>
                    <input
                        placeholder="Çarpıcı ve açıklayıcı bir başlık girin..."
                        type="text"
                        id="itemName"
                        onChange={this.props.nameOnChange}
                        defaultValue={this.props.itemName}
                        maxLength={50}
                        autoFocus
                    />
                </div>
                <div className="wizard-input">
                    <label>İlan Açıklaması:</label>
                    <small className="text-limit-counter">{this.props.description.length}/1200</small>
                    <textarea
                        id="itemDescription"
                        placeholder="Potansiyel alıcılarınıza neye sahip olacağını tarif edin..."
                        className="grid-4x"
                        onChange={this.props.descOnChange}
                        value={this.props.description}
                        maxLength={1200}
                        rows={8}
                    />
                </div>
            </div>
        );
    }
}

export default AdInfo;
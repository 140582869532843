import React from "react";
import ImageWithFallback from "../Common/ImageWithFallback";
import Currency from "../../packs_helper/scripts/Currency";
import profileButtons from "./_profileButtons";
import AvatarHandler from "../Common/AvatarHandler";

// const profile_ad = require("../../assets_v3/images/profile-ad.jpg");

const UserPanelMenu = (props) => {


	const profileButtonsArray = profileButtons(props.user);
	profileButtonsArray.shift();
	return (
		<div className="user-panel-menu">
			<div className="user-panel-menu-top">
				<div className="user-panel-menu-top-user-info">
					<ImageWithFallback src={AvatarHandler(props.user.avatar, props.user.approve_avatar)} width="60" height="60" />
					<strong className="user-panel-menu-top-user-info-username">
						{props.user.short_name}
					</strong>
					<div className="user-panel-menu-top-user-info-balance">
						{props.not_show_identification_number ?
							'##,##' :
							props.user.balance < 0 ?
								Currency(0) :  Currency(props.user.balance)} ₺
					</div>
					<a href="/cikis-yap" data-method="delete" className="user-panel-menu-top-user-info-exit">
						ÇIKIŞ YAP
					</a>
				</div>

				<Button
					ChangeRoute={props.ChangeRoute}
					name="Settings"
					pathname="/profilim"
					active={props.activeSection === "Settings"}
					label="Bilgilerim"
					icon="edit"
					className="btn-darker"
				/>
			</div>
			{profileButtonsArray.map((button) => (
				<Button
					ChangeRoute={props.ChangeRoute}
					name={button.name}
					pathname={button.pathname}
					active={props.activeSection === button.name}
					alert={button.alert}
					label={button.label}
					icon={button.icon}
					key={button.name}
				/>
			))}
			{props.side_image && props.side_image.info && props.side_image.info.img && (
				<a href={props.side_image.image_link} className="user-panel-menu-bottom" target="_blank">
					<img src={`https://img.gamesatis.com/${props.side_image.info.img}.jpg`} alt={props.side_image.text_1} />
				</a>
			)}
		</div>
	);
};

const Button = (props) => {
	const ChangeRoute = () => {
		props.ChangeRoute(props.pathname);
	};
	return (
		<button
			onClick={ChangeRoute}
			className={
				"btn btn-dark user-panel-menu-button" + (props.className ? (" " + props.className) : '') + (props.alert ? " has-alert" : '') + (props.active ? ' active' : '')
			}
		>
			<svg className="icon">
				<use xlinkHref={"#gs-icon-user-panel-" + props.icon}></use>
			</svg>
			{props.label}
		</button>
	);
};
export {UserPanelMenu as default, Button};

import React from "react";
import ImageWithFallback from "../../Common/ImageWithFallback";
import Currency from "../../../packs_helper/scripts/Currency";

const FavoriteBox = React.forwardRef((props, ref) => {
	const product = props.product;
	const user = product.user;
	const seller_link = "/magaza/" + product.user.user_info.slug;
	const favorite_link = "/favorilere-ekle?product_id=" + product.id;
	const favorite_status_on = "Favoriye Ekle";
	const favorite_status_off = "Favoriden Kaldır";
	const levels = ['YOK', 'Bronze', 'Silver', 'Gold', 'Master'];

	let orginal_price = numberWithDot(Currency(product.cost));
	let price = numberWithDot(Currency(product.discount_cost.replace(".","").replace(",",".")));
	let discount = Math.round(product.discount_rate);
	let product_link = product.category.slug + "/" + product.slug;

	if(product.steam_item){
		product_link = product.category.slug + "/" + product.steam_item.slug + "?id=" + product.id;
		orginal_price = numberWithDot(Currency(product.steam_item.cost));
		discount = Math.round(100 - Number(price.replace(".","").replace(",",".")) / Number(orginal_price.replace(".","").replace(",",".")) * 100);
	}

	function PackageNameRender(package_name){
		let package_name_result = package_name
		if(package_name !== null){
			if(package_name.substr(0,3) === "!!!"){
				package_name_result = package_name.substr(3);	
			}
		}
		return package_name_result;
	}

	function PackageClassRender(package_name){
		let package_class = "package-name empty";
		if(package_name !== null){
			if(package_name.substr(0,3) === "!!!"){
				package_class = "package-name highlight"
			}else{
				package_class = "package-name package"
			}
		}
		return package_class;
	}

	function ProductActiveControl(){
		let resultClass = "product-row";
		if(!product['for_sale?']){
			resultClass = "product-row passive_product";
		}
		return resultClass;
	}

	function numberWithDot(x) {
	    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}

	function LowerLevel(level){
		if(level !== undefined)
			level = level.substr(0,1).toLowerCase() + level.substr(1);
		else
			level = "none";
		return level;
	}

	const OnClick = () => {
		props.onPress(props.id);
	};

	const ShowHistoryToggle = () => {
		props.showHistoryToggle(props.id, props.showHistoryModal);
	}

	const EscapeThumb = (imageLink) => {
		return imageLink.replace("-thumb", "").replace("/125fx100f", "");
	}

	return (
		<div id={"product_"+props.id} className={ProductActiveControl()}>
			<div className="product-row-image-area">
				<a href={product_link} target="_blank">
					<ImageWithFallback
						className="product-row-image"
						src={props.mobile ? EscapeThumb(product.image):product.image}
					/>
				</a>
			</div>
			<div className="product-row-detail-area">
				<div className="product-row-detail-area-info">
					<div className="product-row-detail-area-info-name">
						<a href={product_link} target="_blank">{product.name}</a>
					</div>
					<div className={PackageClassRender(product.package_name)}>
						<a href={product_link} target="_blank">{PackageNameRender(product.package_name)}</a>
					</div>
					<div className="product-row-seller">
						{product.user.user_info.slug ? (
							<a 
								href={seller_link} 
								target="_blank" 
								data-toggle="tooltip" 
								title={product.user.user_info.store_name + " Mağazasını İncele"}>
								{product.user.user_info.store_name}
							</a>	
						):
						(
							<a>{product.user.user_info.store_name}</a>
						)}
						{product.user.user_info.confirmed !== null && (
							<svg className="icon">
								<title>Onaylı Mağaza</title>
								<use xlinkHref="#gs-icon-product-box-verified"></use>
							</svg>
						)}

						{levels[user.user_info.level] !== undefined ? 
							(
								<img 
									src={"https://images.gamesatis.com/assets/store/"+LowerLevel(levels[user.user_info.level])+"-icon.svg"} 
									title={levels[user.user_info.level]+" Mağaza"}
									width='36' 
									height='24' 
								/>
							)
							:
							("")
						}
					</div>
				</div>
				<div className="product-row-detail-area-price">
					<div className={orginal_price !== price?"original-price":"original-price passive"}>
						{orginal_price} ₺
						<label>%{discount}</label>
					</div>
					<div className="selling-price">
						{price} ₺
					</div>
				</div>
			</div>
			<div className="product-row-buttons">
				<div className="product-row-buttons-multiple">
					<a href={product_link} target="_blank" className="btn btn-sm btn-default">İlana Git</a>
					<a href={favorite_link} onClick={OnClick} className="btn btn-sm btn-default" data-remote="true" data-method="post" rel="nofollow" data-toggle='tooltip'  title="Favoriden Kaldır">
						<svg className="icon full">
							<title>Favoriden Kaldır</title>
							<use xlinkHref="#gs-icon-user-panel-heart-full"></use>
						</svg>
					</a>
				</div>
				{product.price_history ? (
					<a 
						className="btn btn-sm btn-default" 
						onClick={ShowHistoryToggle}
						data-toggle='tooltip' 
						title="Fiyat Geçmişi">
							<svg className="icon history">
								<title>Fiyat Geçmişi</title>
								<use xlinkHref="#gs-icon-user-panel-history"></use>
							</svg>
							Fiyat Geçmişi
					</a>
				):(
					<a 
						className="btn btn-sm btn-default" 
						data-toggle='tooltip' 
						disabled
						title="Fiyat Geçmişi Yok">
							<svg className="icon history">
								<title>Fiyat Geçmişi</title>
								<use xlinkHref="#gs-icon-user-panel-history"></use>
							</svg>
							Fiyat Geçmişi
					</a>
				)}
				
			</div>
		</div>

	);
});


export default FavoriteBox;
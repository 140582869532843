import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class MyPaymentsCore extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			payments_count: 0,
			current_page: 1,
			my_payments: [],
			activeBox: null
		};
		this.GetData = this.GetData.bind(this);
		this.ChangePage = this.ChangePage.bind(this);
		this.BoxOpen = this.BoxOpen.bind(this);
	}

	componentDidMount() {
		this.GetData(1);
	}

	BoxOpen(activeBox){
		this.setState({
			activeBox
		})
	}

	ChangePage(current_page) {
		this.setState({ current_page });
		this.GetData(current_page);
	}

	GetData(page) {
		PostData("/odemelerim", {page})
			.then((response) => {
				if(response.data.length){
					// TODO response.set_balance
					this.setState({
						my_payments: response.data,
						payments_count: response.total_count,
						activeBox: response.data[0].id,
					});
				}
			})
			.catch((err) => {


			});
	}
}

export default MyPaymentsCore;

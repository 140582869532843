import React from "react";

const PaymentGridFilterButton = (props) => {
	const ButtonClickHandler = () => {
		props.onClick(props.group);
	};

	return (
		<button
			className={"btn btn-dark" + (props.active ? " active" : "")}
			onClick={ButtonClickHandler}
		>
			<svg className="texture">
				<use xlinkHref={"#gs-icon-payment-methods-" + (props.icon)}></use>
			</svg>
			<svg className="icon">
				<use xlinkHref={"#gs-icon-payment-methods-" + (props.icon)}></use>
			</svg>
			{props.label}
		</button>
	);
};

export default PaymentGridFilterButton;
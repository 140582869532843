// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();

import Switcher from "../../packs_helper/scripts/Switcher";
import CountDown from "../../packs_helper/scripts/CountDown";
import consumer from '../../packs_helper/scripts/Consumer';

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);




let loginFormInjected = false;

function ReInsertScript(script) {
  const sc = document.createElement("script");
  if (script.type) {
    sc.type = script.type;
  }
  if (script.src) {
    sc.src = script.src;
  }
  if (script.onload) {
    sc.onload = script.onload;
  }
  if (script.onerror) {
    sc.onerror = script.onerror;
  }
  if (script.innerHTML != "") {
    sc.innerHTML = script.innerHTML;
  }
  script.parentNode.appendChild(sc);
  script.remove();
}

function ReInsertLinkTag(linkTag) {
  const lt = document.createElement("link");
  if (linkTag.rel) {
    lt.rel = linkTag.rel;
  }
  if (linkTag.media) {
    lt.media = linkTag.media;
  }
  if (linkTag.href) {
    lt.href = linkTag.href;
  }
  linkTag.parentNode.appendChild(lt);
  linkTag.remove();
}

const ajaxRoutes = [
  "/giris-yap",
  "/kayit-ol",
  "/sign_in",
  "/skin-incele",
  "/share",
  "/sepete-gonder",
  "/nasil-yuklenir",
  "/yayinci-basvuru-kriterleri",
  "/product-image-popup",
  "/sepete-ekle",
  "/kampanyaya-katil",
];

window.redirect = function (href) {
  return new Promise((resolve, reject) => {
    const url = new URL(href, location);
    if (ajaxRoutes.includes(url.pathname)) {
      Rails.ajax({
        url: href,
        type: "GET",
        success: (...args) => {
          Rails.fire(document, "ajax:success", args);
          resolve();
        },
      });
    } else {
      window.location.href = href;
      resolve();
    }
  });
};

// window.addEventListener('beforeunload', event => {
//  event.stopPropagation();
//  event.stopImmediatePropagation();
//  event.preventDefault();
//  console.log("beforeunload")
//  console.log(event);
//  document.body.dataset.waitForResponse="true";
//  return false;
// });

document.addEventListener("ajax:beforeSend", (event) => {


  document.body.dataset.waitForResponse = "true";
  // const [xhr, status] = event.detail;
});

document.addEventListener("ajax:error", (event) => {
  ShowPopup({
    title: "Hata",
    message: "İsteğiniz gerçekleştirilirken bir hata oluştu!",
  });
  document.body.dataset.waitForResponse = null;
  // const [xhr, status] = event.detail;
});
document.addEventListener("ajax:success", (event) => {
  event.preventDefault();


  document.body.dataset.waitForResponse = null;
  const [response, status, xhr] = event.detail;
  const path = new URL(xhr.responseURL).pathname;

  if (response.message) {
    ShowPopup({
      message: response.message,
      redirect: response.redirect_to,
      rawHtml: response.rawHtml,
      autoHide: ajaxRoutes.includes(path) ? 999999 : 3000,
    });
  } else if (response.redirect_to) {
    window.location.href = response.redirect_to;
  } else if (ajaxRoutes.includes(path)) {
    ShowPopup({ message: xhr.response, rawHtml: true });
  }
});

let gsPopupCount = 0;
window.ShowPopup = function (options) {
  const defaultOptions = {
    message: "",
    title: null,
    redirect: null,
    autoSize: false,
    rawHtml: false,
    autoHide: null,
  };
  options = Object.assign({}, defaultOptions, options);
  if (!options.message) {
    return false;
  }
  const random =
    "p" + (Math.random() * 0xffffffffffff * 100000000000).toString(16);

  return new Promise((resolve, reject) => {
    const gsPopup = document.createElement("DIV");
    gsPopup.id = random;
    gsPopup.className = "gs-popup";
    gsPopup.tabIndex = 0;

    const gsPopupWrapper = document.createElement("DIV");
    gsPopupWrapper.className = "gs-popup-wrapper";
    gsPopup.append(gsPopupWrapper);

    const gsPopupBody = document.createElement("DIV");
    gsPopupBody.className = "gs-popup-body";
    if (options.message instanceof HTMLElement) {
      gsPopupBody.appendChild(options.message);
    } else {
      gsPopupBody.innerHTML = options.message;
    }

    let gsPopupCloses = [...gsPopupBody.querySelectorAll("[data-close]")];
    let gsPopupClosIndex = 0;

    if (gsPopupBody.children.length) {
      const title = gsPopupBody.children[0].dataset.popupTitle;
      if (title) {
        options.title = title;
      }

	    if (gsPopupBody.children[0].dataset.closeable) {
	    	const popupClose = document.createElement("button");
	      popupClose.className = "btn gs-popup-close";
	      popupClose.dataset.close = "";
	      gsPopupWrapper.append(popupClose);
	      gsPopupCloses.unshift(popupClose);
	      gsPopupClosIndex++;
	    }

    }


    if (!gsPopupCloses.length) {
      const popupClose = document.createElement("button");
      popupClose.className = "btn gs-popup-close";
      popupClose.dataset.close = "";
      gsPopupWrapper.append(popupClose);
	    gsPopupCloses.unshift(popupClose);
      gsPopupClosIndex++;
    }

    if (!options.rawHtml) {
      const buttons = gsPopupBody.querySelectorAll("a:not(.no-button), button");
      if (buttons.length > 0) {
        const buttonsContainer = document.createElement("DIV");
        buttonsContainer.className = "gs-popup-buttons-container";
        [...buttons].forEach((button) => {
            buttonsContainer.appendChild(button);
        });
        gsPopupBody.appendChild(buttonsContainer);
      }
      else{
        const buttonsContainer = document.createElement("DIV");
        buttonsContainer.className = "gs-popup-buttons-container";

	      const popupClose = document.createElement("button");
	      popupClose.innerText = "Kapat";
	      popupClose.dataset.close = "";

        buttonsContainer.appendChild(popupClose);
        gsPopupBody.appendChild(buttonsContainer);

		    gsPopupCloses.unshift(popupClose);
	      gsPopupClosIndex++;
      }
      if (!options.title) {
        options.title = "Site Mesajı";
      }
    }


    gsPopupCloses.forEach((gsPopupClose) => {
      gsPopupClose.addEventListener("click", function () {
        gsPopup.remove();
        if (--gsPopupCount === 0) {
          document.body.classList.remove("has-popup");
        }
        if (options.redirect) {
          redirect(options.redirect);
        }
        resolve(gsPopupClose);
      });
    });

    if (options.title) {
      const gsPopupHeader = document.createElement("DIV");
      gsPopupHeader.className = "gs-popup-header";
      gsPopupHeader.innerHTML = options.title;
      gsPopupWrapper.append(gsPopupHeader);
    }

    gsPopupWrapper.append(gsPopupBody);

    const scripts = gsPopupBody.querySelectorAll("script");
    scripts.forEach(function (script) {
      ReInsertScript(script);
    });

    // const styles = gsPopupBody.querySelectorAll("link");
    // scripts.forEach(function (linkTag) {
    //  ReInsertLinkTag(linkTag);
    // });

    //  if(options.autoSize){
    //    var siid = setInterval(() => {
    //      // const rate = gsPopupWrapper.clientWidth / gsPopupWrapper.clientHeight;
    //      const rate = gsPopupWrapper.clientHeight / gsPopupWrapper.clientWidth;
    //      console.log(rate);
    //      return false;
    //      if(rate < 5){
    //        console.log("cleaned");
    //        clearInterval(siid);
    //        return false;
    //      }
    //      gsPopupWrapper.style.width = (gsPopupWrapper.clientWidth * 0.95) + "px";
    //      console.log(gsPopupWrapper.style.width);
    //    }, 1);
    //  }
    //  else{
    // // document.body.append(gsPopup)
    //  }
    if (gsPopupCount === 0) {
      document.body.classList.add("has-popup");
    }

    document.addEventListener("keyup", (e) => {
      const keyCode = e.keyCode || e.which;
      if (keyCode === 27) {

        gsPopupCloses[0].click();
      }
    });

    gsPopupCount++;
    if (options.as_html) {
      return gsPopup.outerHTML;
    } else {
      document.body.append(gsPopup);
      // gsPopup.blur();
      gsPopup.focus();
    }

    if (options.autoSize) {
      // const rate = gsPopupWrapper.clientHeight / gsPopupWrapper.clientWidth;
      gsPopupWrapper.style.width =
        Math.max(gsPopupWrapper.clientHeight * 3, 500) + "px";
    }

    if (gsPopupCloses.length) {
      if (options.autoHide && gsPopupCloses.length) {
        setTimeout(() => {
          gsPopupCloses[0].click();
        }, options.autoHide);
      }

      gsPopup.addEventListener("click", function (e) {
        if (e.target === this) {
          gsPopupCloses[0].click();
        }
      });
    }

    //  gsPopup.innerHTML = `
    // <div class="gs-popup-wrapper">
    // <button class="gs-popup-close" data-close></button>
    // ${options.title ? `<div class="gs-popup-header">${options.title}</div>` : ''}
    // <div class="gs-popup-body">
    // ${options.message}
    // </div>
    // </div>
    // `;
  });
};

window.addToCartModalFormAgreementControl = function (e) {


};

const countdownElements = document.querySelectorAll("[data-countdown]");
countdownElements.forEach(CountDown);

// window.addEventListener('load', function(){
const categoryButtons = document.querySelectorAll(".category-buttons");
categoryButtons.forEach(function (categoryButton) {
  const categoryButtonsNavigation =
    categoryButton.querySelectorAll(".navigations");
  const categoryButtonsNav = categoryButton.querySelector("nav");
  categoryButtonsNavigation.forEach(function (el) {
    if (categoryButtonsNav.scrollWidth > categoryButtonsNav.clientWidth) {
      categoryButton.classList.add("wide");
    }
    el.addEventListener("click", function (event) {
      event.preventDefault();
      if (el.classList.contains("next")) {
        categoryButtonsNav.scrollLeft += categoryButtonsNav.clientWidth * 0.7;
      }
      if (el.classList.contains("prev")) {
        categoryButtonsNav.scrollLeft -= categoryButtonsNav.clientWidth * 0.7;
      }
    });
  });
});
// });

const topNavigation = document.querySelector(".top-navigation");
const menuButtons = document.querySelectorAll(".menu-toggler");
menuButtons.forEach(function (button) {
  button.addEventListener(
    "click",
    function (event) {
      event.preventDefault();
      if (topNavigation.classList.contains("open")) {
        topNavigation.classList.remove("open");
      } else {
        topNavigation.classList.add("open");
      }
    },
    false
  );
});

const ranks = document.querySelectorAll(".rank-bar-container-fill");
ranks.forEach(function (div) {
  div.style.width = 100 - div.dataset.rank + "%";
});

const reviewToggler = document.getElementById("review-toggler");
if (reviewToggler) {
  const reviewForm = document.getElementById("review-form");
  reviewToggler.addEventListener("click", function (e) {
    reviewForm.classList.toggle("show");
  });
}

let notificationsContainer;
let notificationsAlert;
consumer.subscriptions.create(
  { channel: "StoreMessagesChannel" },
  {
    connected: function () {
    	notificationsAlert = document.createElement('AUDIO');
    	notificationsAlert.src = '/assets/store-message-alert.mp3';
    },
    disconnected: function () {

    },
    received: function (e) {


      if (
        e.response_from === "get_next_messages" &&
        !e.messages[0].me &&
        window.location.pathname != "/mesajlarim"
      ) {
				notificationsAlert.currentTime = 0;
				notificationsAlert.play();
        if (!notificationsContainer) {
          notificationsContainer = document.createElement("DIV");
          notificationsContainer.classList.add("site-notifications");
          document.body.appendChild(notificationsContainer);
          // document
          //   .querySelector(".site-header")
          //   .appendChild(notificationsContainer);
        }

        const notificationDiv = document.createElement("DIV");

        const notificationClose = document.createElement("BUTTON");
        notificationClose.addEventListener(
          "click",
          () => {
            notificationsContainer.removeChild(notificationDiv);
          },
          { once: true }
        );

        notificationDiv.appendChild(notificationClose);

        const notificationHeader = document.createElement("HEADER");
        const notificationTitle = document.createElement("h2");
        notificationTitle.innerText = "Yeni Mesaj:";

        const notificationUser = document.createElement("small");
        notificationUser.innerText = e.messages[0].user_name;
        notificationTitle.appendChild(notificationUser);

        notificationDiv.appendChild(notificationTitle);

        const notificationMessage = document.createElement("A");
        notificationMessage.innerText = e.messages[0].message;
        notificationMessage.href = `/mesajlarim?active_tab=${e.messages[0].user_code}`;
        notificationMessage.target = "_blank";
        notificationMessage.addEventListener(
          "click",
          () => notificationClose.click(),
          { once: true }
        );

        notificationDiv.appendChild(notificationMessage);

        // document.body.appendChild(notificationDiv);
        notificationsContainer.appendChild(notificationDiv);

        setTimeout(() => {
          // notificationsContainer.removeChild(notificationDiv);
          notificationClose.click();
        }, 10000);
      }
    },
  }
);

const userBalance = document.getElementById("user-balance");
if (userBalance) {
  window.UpdateUserBalance = function (balance) {
    userBalance.innerText = balance + " ₺";
  };
}


document.addEventListener("DOMContentLoaded", function() {
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
});
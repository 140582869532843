import React, { Component, Fragment } from "react";
// import PropTypes from "prop-types";
import Switcher from "../Common/Switcher";
import DecimalFieldTag from "../Common/DecimalFieldTag";
import PostData from "../../packs_helper/scripts/PostData";

class DonateUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "donate",
			price: ""
		};

		this.ActivateTab = this.ActivateTab.bind(this);
		this.PriceOnChange = this.PriceOnChange.bind(this);
		this.SetPrice = this.SetPrice.bind(this);
		this.DonateSave = this.DonateSave.bind(this);
	}

	ActivateTab(activeTab) {
		this.setState({ activeTab });
	}

	PriceOnChange(price){

		this.setState({price})
	}

	SetPrice(e){
		const price = e.currentTarget.dataset.price;

		this.setState({price})
	}

	DonateSave(e){
		e.preventDefault();
		ShowPopup({
			title: 'Onay Gerekiyor',
			message: `${this.props.nickname} kullanıcısına bağış yapmak istediğinize emin misiniz?<button value="0" class="btn btn-default" data-close>Vazgeç</button><button value="1" class="btn btn-jungle" data-close>Onayla</button>`
		}).then(button => {
			if(button.value == 1){
				const formData = new FormData(e.target);
				formData.append('user', this.props.nickname);
// {
// 					user:
// 					sender: 
// 					amount: this.state.price,
// 					message: 
// 				}

				PostData(this.props.save_path, formData)
				.then(response => {
					if(response.state){
						e.target.reset();
						this.setState({price: ''})
					}
				})

			}
		})
	}

	render() {
		const { activeTab } = this.state;
		return (
			<Fragment>
				<div className="donate-area-user-top">
					<div className="donate-area-user-image">
						<img src={this.props.imageSrc} />
					</div>
					<div className="donate-area-user-info">
						<h1>{this.props.nickname}</h1>
						<label> </label>
						<div className="networks">

						{this.props.socials.map(social => (
							<a href={social[1]} target="_blank" key={social[0]}>
								<svg className="icon" key={social[0]}>
									<use xlinkHref={"#gs-icon-donate-box-" + social[0]}></use>
								</svg>
							</a>
						))}
						</div>
					</div>
				</div>

				{false && (
				<Switcher
					buttons={[
						{
							id: "donate",
							text: "Destekle",
						},
						{
							id: "chat",
							text: "Sohbet",
						},
					]}
					onChange={this.ActivateTab}
					active={activeTab}
				/>
				)}

				<div className="donate-area-user-bottom">
					<form action={this.props.save_path} method="post" onSubmit={this.DonateSave}
						className={
							"donate-area-user-donate" +
							(activeTab === "donate" ? " show" : "")
						}
					>
						<input placeholder="Adınız:" type="text" name="sender" maxLength={50} />

						<div className="input-with-label">
							<label>
								Tutar:
							</label>
							<DecimalFieldTag
							name="amount"
							className="price-input"
							autoComplete="off"
							value={this.state.price}
							onChange={this.PriceOnChange}
							/>
							<span>₺</span>
						</div>

						<div className="donate-area-user-grid-buttons">
							<button type="button" onClick={this.SetPrice} data-price="1">1TL</button>
							<button type="button" onClick={this.SetPrice} data-price="2">2TL</button>
							<button type="button" onClick={this.SetPrice} data-price="3">3TL</button>
							<button type="button" onClick={this.SetPrice} data-price="5">5TL</button>
							<button type="button" onClick={this.SetPrice} data-price="10">10TL</button>
							<button type="button" onClick={this.SetPrice} data-price="20">20TL</button>
						</div>

						<textarea name="message" placeholder="Mesajınız" maxLength={150}></textarea>

						<button className="btn btn-blue">
							<svg className="icon">
								<use xlinkHref="#gs-icon-donate-box-heart"></use>
							</svg>
							<span>Destekle</span>
						</button>
					</form>
					<div
						className={
							"donate-area-user-chat" + (activeTab === "chat" ? " show" : "")
						}
					>
						<iframe
							id="twitch-chat-embed"
							src="https://www.twitch.tv/embed/twitchdev/chat?parent=localhost&darkpopout"
							height="100%"
							width="100%"
						></iframe>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default DonateUser;

function ImagePath(model, field, id, style, name) {
	if(!name){
		return null;
	}
	const parts = id
		.toString()
		.padStart(9, 0)
		.match(/\d{1,3}/g)
		.join("/");
	return `https://s3.eu-central-1.amazonaws.com/images.gamesatis.com/${model}/${field}/${parts}/${style}/${name}`;
}
export default ImagePath;
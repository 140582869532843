import React from 'react';
import cyperRingImage from "../../../../../assets_v3/images/knight-online-cypher-ring.jpg";
import ImageWithFallback from "../../../../Common/ImageWithFallback";

class PictureShow extends React.Component {

    render() {
        return (
            <div className="wizard-grid-show-image">
                <div id="itemImageSelector">
                    {this.props.cyperRing ? (
                        <img src={cyperRingImage} />
                    ) : (
                        <ImageWithFallback src={this.props.purchaseImage} />
                    )}
                </div>
            </div>
        );
    }
}

export default PictureShow;
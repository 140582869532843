function Parameterize(str) {
		return str
			.replace("ı", "i")
			.replace("ş", "s")
			.replace(/[\s\'\"\.\(\)]/g, "-")
			.replace(/-+/g, "-")
			.replace(/-+$/, "")
			.toLowerCase();
	}

export default Parameterize;
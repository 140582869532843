function AdInfoValidate(validateProps) {
    let resultMessage = "";
    let resultTarget = "";
    let resultState = false;

    const itemNameLength = 8;
    const descriptionLength = 10;

    const itemNameMessage = "İlan Başlığı alanına en az " + itemNameLength + " karakterlik bilgi girmelisiniz.";
    const itemNameTarget = "itemName";

    const descriptionMessage = "Açıklama alanına en az " + descriptionLength + " karakterlik bilgi girmelsiniz.";
    const descriptionTarget = "itemDescription";

    if (
            (validateProps.itemName && validateProps.itemName.trim().length >= itemNameLength) &&
            validateProps.itemDescription.trim().length >= descriptionLength
        )
            resultState = true;

    if(resultState === false){
        if(!validateProps.itemName || validateProps.itemName.trim().length <= itemNameLength){
            resultMessage = itemNameMessage;
            resultTarget = itemNameTarget
        }else if(validateProps.itemDescription.trim().length <= descriptionLength){
            resultMessage = descriptionMessage;
            resultTarget = descriptionTarget;
        }
    }

    return {
        "state": resultState,
        "message": resultMessage,
        "target": resultTarget
    };
}

export default AdInfoValidate;
import React from "react";
import MyPaymentsCore from "./MyPaymentsCore";
import MyPaymentBox, { StatusIcon } from "./MyPaymentBox";
import Currency from "../../../packs_helper/scripts/Currency";
import DateFormat from "../../../packs_helper/scripts/DateFormat";
import Paginate from "../../Common/Paginate";
// import productDetailImage from "../../../assets_v3/images/product-detail.jpg";

const statusTexts = {
	approved: "Onaylandı",
	pending: "Beklemede",
	rejected: "Reddedildi",
};

class MobileRenderer extends MyPaymentsCore{
	render() {
		const { my_payments, payments_count, current_page } = this.state;
		return (
			<div className="my-orders-container grid-1 my-payments">
				{my_payments.map((my_payment) => (
					<MyPaymentBox
						id={my_payment.id}
						name={my_payment.payment.name}
						status={my_payment.status}
						transfer_method={my_payment.payment.group}
						key={my_payment.id}
						BoxOpen={this.BoxOpen}
						show={this.state.activeBox === my_payment.id}
					>
						<div className="item-detail">
							<div className="item-detail-body">
								<div className="item-detail-body-left">
									<div>
										<label>Tarih:</label>
										<strong>{DateFormat(my_payment.created_at)}</strong>
									</div>
								</div>
								<div className="item-detail-body-center">
									{my_payment.admin_description}
								</div>
								<div className="item-detail-body-right">
									Tutar:{" "}
									<strong>
										{Currency(my_payment.amount)} ₺
									</strong>
								</div>
								<div className="item-detail-body-status">
									<StatusIcon status={my_payment.status}>
										{statusTexts[my_payment.status]}
									</StatusIcon>
									{my_payment.papara_log && my_payment.papara_log.papara_payment_id && my_payment.status === "pending" && (
										<a href={"https://www.papara.com/checkout/" + my_payment.papara_log.papara_payment_id} target="_blank" className="btn btn-blue">
											Ödeme Sayfası
										</a>
									)}
								</div>
							</div>
						</div>
					</MyPaymentBox>
				))}
				<Paginate
					onChange={this.ChangePage}
					total_count={payments_count}
					current_page={current_page}
					per_page={15}
				/>
			</div>
		);
	}
}

export default MobileRenderer;
import React from "react";
import GlobalVar from "../../../GlobalVar";
// import deliveryHours from "../../../../packs_helper/scripts/deliveryHours";
import GameSelect from "./WizardSteps/GameSelect";
import AdInfo from "./WizardSteps/AdInfo";
import PictureSelect from "./WizardSteps/PictureSelect";
import PropertiesSelect from "./WizardSteps/PropertiesSelect";
import PriceAmount from "./WizardSteps/PriceAmount";
import Confirmation from "./WizardSteps/Confirmation";
import AdType from "./WizardSteps/AdType";
import PictureShow from "./WizardSteps/PictureShow";
import GameSelectValidate from "./WizardValidates/GameSelectValidate";
import AdInfoValidate from "./WizardValidates/AdInfoValidate";
import Warning from "./WizardSteps/Warning";
import PictureSelectValidate from "./WizardValidates/PictureSelectValidate";
import PropertiesSelectValidate from "./WizardValidates/PropertiesSelectValidate";
import PriceAmountValidate from "./WizardValidates/PriceAmountValidate";
import ConfirmationValidate from "./WizardValidates/ConfirmationValidate";
import PostData from "../../../../packs_helper/scripts/PostData";
import cyperRingImage from "../../../../assets_v3/images/knight-online-cypher-ring.jpg";
import AdTypeValidate from "./WizardValidates/AdTypeValidate";
import SellAgreement from "./WizardSteps/SellAgreement";

class ItemCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedGame: null,
			categories: [],
			rate: 0,
			price: 0,
			user_price: 0,
			quantity: 1,
			maxQuantity: 1,
			agreementChecked: false,
			agreementArticle: '',
			sendDisabled: false,

			properties: [],
			items: [],
			selectedProperties: {},
			description: '',

			uploadedFiles: [],
			videoLink: '',
			remaining_message: "",

			adSell: true,
			showBalance: null,
			wizardMode: true,
			currentStep: 0,
		};


		this.ChangeGame = this.ChangeGame.bind(this);
		this.GetGames = this.GetGames.bind(this);
		this.GetPackage = this.GetPackage.bind(this);
		
		this.UpdateImage = this.UpdateImage.bind(this);
		this.RemoveImage = this.RemoveImage.bind(this);

		this.GetProperties = this.GetProperties.bind(this);
		this.UpdateProperty = this.UpdateProperty.bind(this);
		this.SaveProduct = this.SaveProduct.bind(this);

		this.DeliveryTimeChange = this.DeliveryTimeChange.bind(this);

		this.ChangeDescription = this.ChangeDescription.bind(this);

		this.UpdatePrice = this.UpdatePrice.bind(this);
		this.UpdateUserPrice = this.UpdateUserPrice.bind(this);
		this.UpdateQuantity = this.UpdateQuantity.bind(this);
		this.UpdateName = this.UpdateName.bind(this);
		this.UpdateVideoLink = this.UpdateVideoLink.bind(this);
		this.UpdateShowBalance = this.UpdateShowBalance.bind(this);

		this.GetPackageMessage = this.GetPackageMessage.bind(this);

		this.SellPurchaseHandler = this.SellPurchaseHandler.bind(this);

		this.WizardStep = this.WizardStep.bind(this);
	}

	componentDidMount() {
		this.GetGames();
		this.GetPackage();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.type !== this.props.type) {
			this.GetGames();
			this.ClearForm();
		}
		if (this.props.categoryId > 0 && prevProps.categoryId !== this.props.categoryId) {
			this.GetProperties(this.props.categoryId);
		}
		this.state.wizardMode &&
			this.WizardValidation(this.GetWizardSteps(), this.state.currentStep, false);
	}

	GetWizardSteps(){
		const isRing = (this.state.selectedGame && this.state.selectedGame.id === 20325)
		const firstImage = this.state.adSell || this.state.adSell === null ?
							!isRing ?
								this.state.uploadedFiles[0] ?
									URL.createObjectURL(this.state.uploadedFiles[0])
									: null
								: cyperRingImage
							: this.state.selectedGame.image.purchase_image || null;
		let wizardSteps = [
			{
				stepComponent: GameSelect,
				validateComponent: () => GameSelectValidate({
					selectedGame: this.state.selectedGame
				}),
				stepTitle: "Bir Kategori Seçin",
				stepProps: {
					options: this.state.categories,
					onChange: this.ChangeGame,
					selected: this.state.selectedGame ? this.state.selectedGame.name : ""
				},
			},
			{
				stepComponent: AdInfo,
				validateComponent: () => AdInfoValidate({
					itemName: this.state.itemName,
					itemDescription: this.state.description
				}),
				stepTitle: "İlanınızı Tanımlayın",
				stepProps: {
					itemName: this.state.itemName,
					nameOnChange: this.UpdateName,
					description: this.state.description,
					descOnChange: this.ChangeDescription
				}
			},
			{
				stepComponent: PriceAmount,
				stepTitle: "Fiyat ve Adet Belirleyin",
				validateComponent: () => PriceAmountValidate({
					priceValue:this.state.price,
					adSell:this.state.adSell,
					showBalance:this.state.showBalance,
					type:this.props.type,
					minPrice:this.state.selectedGame.min_ad_cost
				}),
				stepProps: {
					priceValue:this.state.price,
					PriceChange:this.UpdatePrice,
					userPriceValue:this.state.user_price,
					UserPriceChange:this.UpdateUserPrice,
					quantityValue:this.state.quantity,
					quantityMin:1,
					quantityMax:["accounts", "boost"].includes(this.props.type) ? this.state.maxQuantity : ["physical"].includes(this.props.type) ? 999 : 1,
					QuantityChange:this.UpdateQuantity,
					rate:this.state.rate,
					showBalance: this.state.showBalance,
					UpdateShowBalance: this.UpdateShowBalance,
					adSell: this.state.adSell,
					isMobile: this.props.mobile,
					userConfirmed: this.props.user.confirmed,
					type: this.props.type
				}
			},
			{
				stepComponent: Confirmation,
				stepTitle: "İlanınızı Onaylayın",
				validateComponent: () => ConfirmationValidate({
					agreementChecked: this.state.agreementChecked,
					deliveryTime: this.state.deliveryTime,
					adSell: this.state.adSell,
					type: this.props.type
				}),
				stepProps: {
					deliveryTimes:GlobalVar.DELIVERY_TIMES,
					deliveryTimeSelected: this.state.deliveryTime,
					DeliveryTimeChange: this.DeliveryTimeChange,
					remainingMessage:this.state.remaining_message,
					adSell:this.state.adSell,
					getPackageMessage:this.GetPackageMessage,
					saveProduct:this.SaveProduct,
					sendDisabled:this.state.sendDisabled,
					agreementCheck: (e) => this.setState({ agreementChecked: e.currentTarget.checked }),
					firstImage: firstImage,
					itemName: this.state.itemName,
					price: this.state.price,
					user: this.props.user,
					categoryName: this.state.selectedGame ? this.state.selectedGame.name:'Kategori Seçin',
					type: this.props.type
				}
			},
		];

		if(["accounts", "boost"].includes(this.props.type)){
			const adTypeWizardStep = {
				stepComponent: AdType,
				stepTitle: "İlan Tipini Seçin",
				validateComponent: () => AdTypeValidate({
					adSell: this.state.adSell,
				}),
				stepProps: {
					adSell: this.state.adSell,
					SellPurchaseHandler: this.SellPurchaseHandler,
				}
			};

			// wizardSteps.splice(1,0, adTypeWizardStep);

		}else if(this.state.adSell === null){
			this.setState({ adSell: true });
		}


		if((!this.state.adSell && this.state.adSell !== null) || isRing){
			const pictureSelectionWizardStep = {
				stepComponent: PictureShow,
				stepTitle: "Hazır Resim Kullanılır",
				stepProps: {
					cyperRing:isRing,
					purchaseImage: this.state.selectedGame.image.purchase_image
				}
			};
			wizardSteps.splice(3,0, pictureSelectionWizardStep);
		}else{
			const pictureSelectionWizardStep = {
				stepComponent: PictureSelect,
				stepTitle: "Resim Ekleyin",
				validateComponent: () => PictureSelectValidate({
					uploadedFilesLength: this.state.uploadedFiles.length,
				}),
				stepProps: {
					onChange:this.UpdateImage,
					onRemove:this.RemoveImage,
					uploadedFiles:this.state.uploadedFiles,
					max:3,
					videoLink:this.state.videoLink,
					UpdateVideoLink:this.UpdateVideoLink,
					cyperRing:isRing,
				}
			};

			const warningWizardStep = {
				stepComponent: Warning,
				stepTitle: "Uyarı",
				stepProps: {
					type: this.props.type
				}
			};

			if (this.state.selectedGame && this.state.selectedGame.sell_agreement !== ""){
				const sellAgreement = {
					stepComponent: SellAgreement,
					stepTitle: "Kategori Satış Koşulları",
					stepProps: {
						type: this.props.type,
						agreement: this.state.selectedGame.sell_agreement
					}
				}
				wizardSteps.splice(1,0, sellAgreement);
				this.props.type !== 'physical' && wizardSteps.splice(2, 0, warningWizardStep);
			}else{
				this.props.type !== 'physical' && wizardSteps.splice(1, 0, warningWizardStep);
			}

			wizardSteps.splice(3,0, pictureSelectionWizardStep);
		}

		const propertiesWizardStep = {
			stepComponent: PropertiesSelect,
			validateComponent: () => PropertiesSelectValidate({
				properties:this.state.properties,
				selectedProperties:this.state.selectedProperties
			}),
			stepTitle: "Özellikleri Belirleyin",
			stepProps: {
				properties:this.state.properties,
				selectedProperty:this.state.selectedProperties,
				UpdateProperty:this.UpdateProperty,
				isMobile: this.props.mobile,
			}
		};
		if(this.state.properties.length > 0 && this.state.adSell)
			wizardSteps.splice(5,0, propertiesWizardStep);

		return wizardSteps;
	}

	WizardStep(direction, AllSteps, CurrentStep, step = 0){
		const withAlert = direction !== 'back'
		const validatedStep = this.WizardValidation(AllSteps, CurrentStep, withAlert);
		if(direction === "back" && !CurrentStep - 1 < 0){
			const previousStep = CurrentStep-1;
			if(previousStep <= validatedStep)
				this.setState({currentStep: previousStep})
		}else if(direction === "next" && CurrentStep < (AllSteps.length - 1)){
			const nextStep = CurrentStep + 1;
			if(nextStep <= validatedStep)
				this.setState({currentStep: nextStep})
		}else if(direction === "jump"){
			if(step > -1 && step < AllSteps.length){
				if(step <= validatedStep)
					this.setState({currentStep: step})
			}
		}
	}

	WizardValidation(AllSteps, CurrentStep, withAlert = true){
		let validatedStep = 0;
		this.ClearWizardError();
		for (let i = 0; i < CurrentStep + 1; i++) {
			if(AllSteps[i] && typeof AllSteps[i].validateComponent !== "undefined"){
				const ComponentResult = AllSteps[i].validateComponent();
				if(ComponentResult.state){
					validatedStep++
				}else{
					if(CurrentStep === i){
						const wizardError = document.getElementById('wizard-error');
						wizardError.classList.remove('wizard-item-form-success');
						wizardError.classList.add('wizard-item-form-alert');
						wizardError.innerHTML = ComponentResult.message;
						if(withAlert){
							ShowPopup({
								message: ComponentResult.message,
								title: "Zorunlu Alanları Doldurmalısınız!"
							});
						}
						if(ComponentResult.target !== ''){
							const errorElement = document.getElementById(ComponentResult.target);
							if(errorElement){
								errorElement.classList.add("wizard-error");
								withAlert && errorElement.focus();
							}
						}
					}
					break;
				}
			}else{
				validatedStep++;
			}
		}
		return validatedStep;
	}

	ClearWizardError(){
		const previousErrorElement = document.getElementsByClassName("wizard-error");
		previousErrorElement.length > 0 &&
			previousErrorElement[0].classList.remove("wizard-error");
		const wizardError = document.getElementById('wizard-error');
		wizardError.classList.remove('wizard-item-form-alert');
		wizardError.classList.add('wizard-item-form-success');
		if(this.state.currentStep === this.GetWizardSteps().length - 1){
			wizardError.innerHTML = 'Tüm doğrulamalar tamamlandı. İlanınızı yayına alabilirsiniz!';
		}else{
			wizardError.innerHTML = 'Bu adımdaki tüm doğrulamalar tamamlandı, sonraki adıma geçebilirsiniz.';
		}
	}

	ClearForm(){
		this.setState({
			selectedGame: null,
			price: 0,
			user_price: 0,
			quantity: 1,
			maxQuantity: 1,
			agreementChecked: false,
			sendDisabled: false,
			properties: [],
			items: [],
			selectedProperties: {},
			description: '',
			itemName: '',
			deliveryTime: '',
			uploadedFiles: [],
			adSell: true,
			showBalance: null,
			currentStep: 0,
		});
	}

	GetGames() {
		PostData("/ilan-oyunlari", {
			type: this.props.type,
		}).then((response) => {
			let setCategories = [];
			response.categories.forEach((category)=>{
				let newCategory;
				if(category.info && category.info.purchase_image){
					newCategory = {
						"id": category.id,
						"name": category.name,
						"preferences": null,
						"min_ad_cost": category.min_ad_cost && category.min_ad_cost > 0 ? category.min_ad_cost : response.cost_limit,
						"sell_agreement": category.buy_sell_description === null ? "":category.buy_sell_description,
						"image": {
							"category_image": GlobalVar.AWS_URL + "/" + category.info.img + ".jpg",
							"purchase_image": GlobalVar.AWS_URL + "/" + category.info.purchase_image + ".jpg"
						}
					}
				}else {
					newCategory = {
						"id": category.id,
						"name": category.name,
						"preferences": null,
						"min_ad_cost": category.min_ad_cost && category.min_ad_cost > 0 ? category.min_ad_cost : response.cost_limit,
						"sell_agreement": category.buy_sell_description === null ? "":category.buy_sell_description,
						"image": {
							"category_image": GlobalVar.AWS_URL + "/" + category.info.img + ".jpg",
							"purchase_image": null
						}
					}
				}
				setCategories.push(newCategory)
			})

			this.setState({
				categories: setCategories,
				rate: response.rate,
				selectedGame: this.props.categoryId > 0 && setCategories.find(
					(category) => category.id == this.props.categoryId
				),
			});
			if(this.state.selectedGame){
				this.setState({currentStep: 1});
				this.props.categoryId > 0 && this.GetProperties(this.props.categoryId);
			}
			// this.GetKoItemProperties(response.categories[0].id);
		});
	}

	GetProperties(category_id) {
		PostData("/urun-ozellikleri", {
			category_id,
		}).then((response) => {
			const selectedProperties = {};
			response.forEach(function (property, a, b) {
				selectedProperties[property.id] = null;
			});
			this.setState({
				properties: response,
				selectedProperties,
			});
		});
	}

	GetArticle() {
		return {__html: this.state.article}
	}

	GetPackageMessage() {
		if(this.state.adSell)
			return {__html: this.state.remaining_message}
		else
			return {__html: "En fazla 5 adet alım ilanı ekleyebilirsiniz."}
	}

	GetPackage(){
		let remaining_message = false;
		let ad_cost = 2.5;
		PostData("/magaza-paketlerim", {}).then((response) => {
			if(response.exist_store_packages.length > 0) {
				response.exist_store_packages[0].info.package_content.map((product) => {
					if (product.product_id === GlobalVar.PACKAGE_PRODUCTS.AD_PRODUCT_ID && product.remaining > 0) {
						remaining_message = "Kalan ücretsiz " +
							product.remaining +
							" adet ilan ekleme hakkınızdan 1 adet düşecektir.";
						return;
					}
					if (product.product_id === GlobalVar.PACKAGE_PRODUCTS.AD_COST_PRODUCT_ID) {
						ad_cost = product.remaining;
					}

					if(product.product_id === GlobalVar.PACKAGE_PRODUCTS.MULTI_STOCK_PRODUCT_ID) {
						this.setState({ maxQuantity:  product.remaining});
					}
				});
				if(!remaining_message){
					remaining_message = "Ücretsiz ilan ekleme " +
						"hakkınız kalmadığı için bakiyenizden " +
						ad_cost + " ₺ düşecektir."
				}
			}else{
				remaining_message = false
			}
			this.setState({ remaining_message });
		});

	}

	SellPurchaseHandler(which){
		let process = false;
		switch (which){
			case "sell":
				if(!this.state.adSell)
					this.state.adSell === null ?
						this.setState({ adSell: true, currentStep: this.state.currentStep + 1 })
						:process = true;
				break;
			case "purchase":
				if(this.state.adSell || this.state.adSell === null)
					this.state.adSell === null ?
						this.setState({ adSell: false, currentStep: this.state.currentStep + 1 })
						:process = true;
				break;
		}
		process && this.setState({ adSell: !this.state.adSell });
		this.setState({ currentStep: this.state.currentStep + 1 });
	}

	SaveProduct(e, confirm) {
		this.setState({ sendDisabled: true });
		const AllSteps = this.GetWizardSteps()
		const stepLength = AllSteps.length
		const validatedStep = this.WizardValidation(AllSteps, stepLength, true)
		if(validatedStep < stepLength){
			this.setState({ sendDisabled: false });
			return false;
		}
		const formData = new FormData();
		let postUrl = "";
		if(this.state.adSell){
			postUrl = "/item-ilani-ver";
			formData.append(
				'product[delivery_time]',
				this.state.deliveryTime
			)
			for (let i = 0; i < this.state.uploadedFiles.length; i++) {
				formData.append('product[image][]', this.state.uploadedFiles[i]);
			}

			const selectedProperties = Object.entries(this.state.selectedProperties);
			for (let i = 0; i < selectedProperties.length; i++) {
				formData.append(
					'product[properties][' + selectedProperties[i][0] + ']', selectedProperties[i][1]
				);
			}
			this.state.videoLink !== '' && formData.append('product[video_link]', this.state.videoLink)
		}else{
			postUrl = "/item-alim-ilani-ver";
			formData.append('product[show_balance]', this.state.showBalance);
		}
		formData.append('product[category_id]', this.state.selectedGame.id);
		formData.append('product[description]', this.state.description);
		formData.append('product[cost]', this.state.price);
		formData.append('product[quantity]', this.state.quantity);
		formData.append('product[name]', this.state.itemName);
		if(confirm){
			formData.append('confirm_fee', 1);
			formData.append("pre_provision", "false");
		}else{
			formData.append("pre_provision", "true");
		}
		PostData(postUrl, formData)
		.then((response) => {
			if (response.state) {
				this.props.ChangeRoute("/ilanlarim");
			}
			else if(response.ad_message && response.waiting_response){
				ShowPopup({
					title: 'Onay Gerekiyor',
					message: response.ad_message +
						'<button class="btn btn-default" value="0" data-close>Vazgeç</button>' +
						'<button class="btn btn-jungle" value="1" data-close>Onayla</button>'
				}).then(button => {
					if(button.value == 1){
						this.SaveProduct(e, true);
					}else{
						this.setState({ sendDisabled: false });
					}
				})
			}
			else{
				this.setState({ sendDisabled: false });
			}
		})
		.catch((err) => { this.setState({ sendDisabled: false }); });
	}

	ChangeGame(selectedGameId) {
		this.props.onChange(selectedGameId);
		const selectedGame = this.state.categories.find(
				(category) => category.id === selectedGameId
			);
		this.setState({
			selectedGame,
			currentStep: 1
		});
	}

	DeliveryTimeChange(deliveryTime){
		this.setState({deliveryTime});
	}

	UpdateProperty(property_id, id) {
		const selectedProperties = this.state.selectedProperties;
		selectedProperties[property_id] = id;
		this.setState({ selectedProperties });
	}

	UpdatePrice(price) {
		let user_price = 0
		const original_user_price = price * (1 - this.state.rate / 100);
		if(price >= GlobalVar.LIMIT_CHANGE_RATE){
			user_price = price * (1 - GlobalVar.RATE_AFTER_LIMIT / 100);
			if (original_user_price > user_price)
				user_price = original_user_price
		}else{
			user_price = original_user_price
		}
		this.setState({ price, user_price });
	}

	UpdateUserPrice(user_price) {
		let price = 0;
		const original_user_price = user_price / (1 - this.state.rate / 100);
		if(user_price >= (GlobalVar.LIMIT_CHANGE_RATE * (1 - GlobalVar.RATE_AFTER_LIMIT / 100))){
			price = user_price / (1 - GlobalVar.RATE_AFTER_LIMIT / 100);
			if (original_user_price < price)
				price = original_user_price
		}else{
			price = original_user_price
		}
		this.setState({ price, user_price });
	}

	UpdateQuantity(quantity) {
		if(quantity > this.state.maxQuantity){
			let message = "Bu kategori için en fazla " + this.state.maxQuantity + " adet stok ekleyebilirsiniz."
			this.setState({ quantity: this.state.maxQuantity });
			if(this.state.maxQuantity === 1)
				message = 'Sadece stoksuz ilan oluşturabilirsiniz. Daha fazlası için uygun bir paket aktif etmelisiniz.' +
					'<button class="btn btn-default" value="0" data-close>Stoksuz Sat</button>' +
					'<button class="btn btn-jungle" value="1" data-close>Paketlere Gözat</button>';
				this.UpdateQuantity(1);
				ShowPopup({
					title: 'Stok Limiti!',
					message: message
				}).then(button => {
					if(button.value == 1){
						window.open('/magaza-paketleri', '_blank');
					}
				});
		}else{
			this.setState({ quantity });
		}
	}

	UpdateName(e) {
		const itemName = e.currentTarget.value;
		this.setState({ itemName });
	}

	UpdateVideoLink(e){
		const videoLink = e.currentTarget.value;
		this.setState({ videoLink })
	}

	UpdateShowBalance(showBalance){
		this.setState({ showBalance })
	}

	ChangeDescription(e) {
		const description = e.currentTarget.value;
		this.setState({ description });
	}

	UpdateImage(e) {
		const uploadedFiles = this.state.uploadedFiles;
		if (uploadedFiles.length >= 4) {
			ShowPopup({
				message: "En fazla 3 görsel yükleyebilirsiniz!",
				title: "Hata",
			});
			return false;
		}
		const files = e.currentTarget.files;
		if(files.length){
			if(((files[0].size / 1024) / 1024).toFixed(2) < 6) {
				uploadedFiles.push(files[0]);
				this.setState({ uploadedFiles });
			}else {
				ShowPopup({
					message: '<div>Yüklemeye çalıştığınız dosyaların toplam boyutu en fazla 6MB olabilir.</div> ' +
						'<div>Lütfen dosyalarınızı küçülterek yeniden deneyin.</div> ' +
						'<div>Benzer bir online araç kullanarak yeniden boyutlandırabilirsiniz.</div>' +
						'<a href="https://www.resizenow.com/tr" target="_blank">https://www.resizenow.com/tr</a>'
				})
			}
		}
	}

	RemoveImage(fileIndex){
		const uploadedFiles = this.state.uploadedFiles;
		uploadedFiles.splice(fileIndex, 1);
		this.setState({uploadedFiles});
	}

}

export default ItemCore;

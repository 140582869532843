import React from "react";
import PostData from "../../../../packs_helper/scripts/PostData";
import KoItemDefaultImage from "../../../../assets_v3/images/ko-item-default";
import Parameterize from "../../../../packs_helper/scripts/Parameterize";
import GlobalVar from "../../../GlobalVar";

class KoItemCore extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedGame: null,
			categories: [],
			agreementChecked: false,
			sendDisabled: false,
			rate: 0,
			price: 0,
			user_price: 0,

			items: [],
			servers: [],
			types: [],
			grades: [],
			selectedServer: null,
			selectedItem: null,
			selectedType: null,
			selectedGrade: null,
			itemName: "",
			deliveryTime: '',
			imagePath: KoItemDefaultImage,
			description: '',
		};

		this.ChangeGame = this.ChangeGame.bind(this);
		this.GetGames = this.GetGames.bind(this);
		this.GetKoItemProperties = this.GetKoItemProperties.bind(this);
		this.ChangeServer = this.ChangeServer.bind(this);
		this.ChangeItem = this.ChangeItem.bind(this);
		this.ChangeType = this.ChangeType.bind(this);
		this.ChangeGrade = this.ChangeGrade.bind(this);
		this.UpdateItemName = this.UpdateItemName.bind(this);
		this.GetTypes = this.GetTypes.bind(this);
		this.GetGrades = this.GetGrades.bind(this);
		this.UpdatePrice = this.UpdatePrice.bind(this);
		this.UpdateUserPrice = this.UpdateUserPrice.bind(this);

		this.DeliveryTimeChange = this.DeliveryTimeChange.bind(this);
		this.ChangeDescription = this.ChangeDescription.bind(this);
		this.SaveProduct = this.SaveProduct.bind(this);
	}

	componentDidMount() {
		this.GetGames();
	}

	GetGames() {

		PostData("/ilan-oyunlari", {
			type: "items",
		}).then((response) => {
			let setCategories = [];
			response.categories.forEach((category)=>{
				let newCategory;
				if(category.info && category.info.purchase_image){
					newCategory = {
						"id": category.id,
						"name": category.name,
						"preferences": null,
						"image": {
							"category_image": GlobalVar.AWS_URL + "/" + category.info.img + ".jpg",
							"purchase_image": GlobalVar.AWS_URL + "/" + category.info.purchase_image + ".jpg"
						}
					}
				}else {
					newCategory = {
						"id": category.id,
						"name": category.name,
						"preferences": null,
						"image": {
							"category_image": GlobalVar.AWS_URL + "/" + category.info.img + ".jpg",
							"purchase_image": null
						}
					}
				}
				setCategories.push(newCategory)
			})
			this.setState({
				categories: setCategories,
				rate: response.rate,
				selectedGame: this.props.categoryId > 0 && setCategories.find(
					(category) => category.id == this.props.categoryId
				),
			});
			this.GetKoItemProperties(this.props.categoryId)
		});
	}

	GetKoItemProperties(category_id) {
		PostData("/ko-item-ozellikleri", {
			category_id,
		}).then((response) => {
			const { servers, items } = response;
			this.setState(
				{
					servers,
					items,
				}
			);
		});
	}

	ChangeGame(selectedGameId) {
		this.props.onChange(selectedGameId);
		// const categories = this.state.categories;
		// const selectedGame = categories.find((game) => game.id == selectedGameId);
		// if (selectedGame) {
		// 	this.setState({ selectedGame });
		// }
	}

	ChangeServer(selectedServerId) {
		const servers = this.state.servers;
		const selectedServer = servers.find(
			(server) => server.id == selectedServerId
		);
		if (selectedServer) {
			this.setState({ selectedServer });
		}
	}

	ChangeItem(selectedItemId) {
		const items = this.state.items;
		const selectedItem = items.find((item) => item.id == selectedItemId);
		if (selectedItem) {
			this.setState({ selectedItem }, () => {
				this.GetTypes(selectedItem);
			});
		}
	}

	ChangeType(selectedTypeId) {
		const types = this.state.types;
		const selectedType = types.find((type) => type.id == selectedTypeId);
		if (selectedType) {
			this.setState({ selectedType }, () => {
				this.GetGrades(selectedType);
			});
		}
	}

	ChangeGrade(selectedGradeId) {
		const grades = this.state.grades;
		const selectedGrade = grades.find((grade) => grade.id == selectedGradeId);
		if (selectedGrade) {
			this.setState({ selectedGrade }, () => {
				this.UpdateItemName();
			});
		}
	}

	UpdatePrice(price) {
		let user_price = 0
		const original_user_price = price * (1 - this.state.rate / 100);
		if(price >= GlobalVar.LIMIT_CHANGE_RATE){
			user_price = price * (1 - GlobalVar.RATE_AFTER_LIMIT / 100);
			if (original_user_price > user_price)
				user_price = original_user_price
		}else{
			user_price = original_user_price
		}
		this.setState({ price, user_price });
	}

	UpdateUserPrice(user_price) {
		let price = 0;
		const original_user_price = user_price / (1 - this.state.rate / 100);
		if(user_price >= (GlobalVar.LIMIT_CHANGE_RATE * (1 - GlobalVar.RATE_AFTER_LIMIT / 100))){
			price = user_price / (1 - GlobalVar.RATE_AFTER_LIMIT / 100);
			if (original_user_price < price)
				price = original_user_price
		}else{
			price = original_user_price
		}
		this.setState({ price, user_price });
	}

	DeliveryTimeChange(deliveryTime){
		this.setState({ deliveryTime });
	}

	ChangeDescription(e) {
		const description = e.currentTarget.value;
		this.setState({ description });
	}

	SaveProduct() {
		this.setState({ sendDisabled: true });

		if (!this.state.agreementChecked) {
			ShowPopup({ message: "Lütfen sözleşmeyi onaylayınız!", title: "Hata" });
			this.setState({ sendDisabled: false });
			return false;
		}

		if(!this.state.deliveryTime){
			ShowPopup({ message: "Teslimat saati girmeniz gerekmektedir!", title: "Hata" });
			this.setState({ sendDisabled: false });
			return false;
		}


		PostData("/item-ilani-ver", {
		product: {
			delivery_time: this.state.deliveryTime,
			category_id: this.state.selectedGame.id,
			image: this.state.imagePath,
			type: this.state.selectedType.name,
			description: this.state.description,
			cost: this.state.price,
			name: this.state.itemName,
		},
		ko_item_server: this.state.selectedServer.id,
		ko_item_grades: this.state.selectedGrade.id,
		ko_item_types: this.state.selectedType.id,
		}).then((response) => {
			if (response.state) {
				this.props.ChangeRoute("/ilanlarim", "0");
			}
			this.setState({ sendDisabled: false });
		}).catch(err => {
			this.setState({ sendDisabled: false });
		});
	}

	
	GetTypes(selectedItem) {
		PostData("/get-ko-item-types", { id: selectedItem.id }).then((response) => {
			const types = response.map((type) => ({ id: type[0], name: type[1] }));
			this.setState(
				{
					types,
				},
				() => {
					this.ChangeType(types[0].id);
					this.GetGrades(types[0].id);
				}
			);
		});
	}

	GetGrades(selectedType) {
		PostData("/get-ko-item-grades", {
			type: selectedType.id,
			id: this.state.selectedItem.id,
		}).then((response) => {
			const grades = response.map((grade) => ({
				id: grade[0],
				name: grade[1],
			}));
			this.setState(
				{
					grades,
				},
				() => {
					if (grades.length) {
						this.ChangeGrade(grades[0].id);
						this.UpdateItemName();
					}
				}
			);
		});
	}

	UpdateItemName() {
		const { selectedItem, selectedType, selectedGrade } = this.state;
		if (selectedItem) {
			if (selectedGrade) {
			const parameterizedPath = Parameterize(
				selectedItem.name + "-" + selectedType.name + "-" + selectedGrade.name
			);

			const imagePath =
				"https://www.gamesatis.com/koitems/" +
				parameterizedPath.substr(0, 1) +
				"/" +
				parameterizedPath +
				".png";


				const selectedGradeValue = parseInt(selectedGrade.name);
				if (selectedGradeValue != selectedGrade.name) {
					this.setState({
						itemName: selectedItem.name + " (" + selectedGrade.name + ")",
						imagePath,
					});
				} else {
					this.setState({
						itemName:
							selectedItem.name +
							(selectedGradeValue > 0 ? " +" + selectedGrade.name : ""),
						imagePath,
					});
				}
			}
		}
	}
}

export default KoItemCore;

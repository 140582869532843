import React from "react";

const HelpAndSupportFilterButton = (props) => {
	const ButtonClickHandler = () => {
		props.onClick(props.category);
	};

	return (
		<button
			className={"btn btn-dark" + (props.active ? " active" : "")}
			onClick={ButtonClickHandler}
		>
		<img src={props.category.icon} />
			{props.category.name}
		</button>
	);
};

export default HelpAndSupportFilterButton;
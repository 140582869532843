import React from "react";

class CountryListSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			search: "",
			selectedItem: !!props.value
				? this.props.items.find((item) => item.alpha_2 === props.value)
				: null,
		};
		this.Select = this.Select.bind(this);
		this.InputBlur = this.InputBlur.bind(this);
		this.Focus = this.Focus.bind(this);
		this.SearchChangeHandler = this.SearchChangeHandler.bind(this);
		// this.filteredItems = this.filteredItems.bind(this);
	}

	Select(item) {
		this.setState({
			selectedItem: item,
			focus: false,
			search: "",
		});
		this.props.onChange(item.alpha_2);
	}

	InputBlur() {
		if (!this.state.search) {
			setTimeout(() => {
				this.setState({
					focus: false,
					search: "",
				});
			}, 300);
		}
	}

	Focus() {
		this.setState({ focus: true }, () => this.input.focus());
	}

	SearchChangeHandler(e) {
		this.setState({
			search: e.target.value.toLocaleLowerCase(),
		});
	}

	get filteredItems() {
		return this.props.items.filter(
			(item) => item.keywords.indexOf(this.state.search) > -1
		);
	}

	render() {
		return (
			<div className={"searchable-select " + (this.props.className || "")}>
				{!this.state.focus ? (
					<button type="button" onClick={this.Focus}>
						{this.state.selectedItem ? (
							<React.Fragment>
								<img
									src={this.state.selectedItem.image}
									alt={this.state.selectedItem.alpha_2}
								/>
								
								{this.props.onlyFlag !== "1" ? this.state.selectedItem.country_code:("")}
							</React.Fragment>
						) : (
							this.props.prompt
						)}
						<svg className="icon">
							<use xlinkHref={this.props.icon}></use>
						</svg>
					</button>
				) : (
					<div>
						<input
							type="text"
							ref={(ref) => (this.input = ref)}
							onBlur={this.InputBlur}
							onChange={this.SearchChangeHandler}
						/>
						<ul>
							{this.filteredItems.length > 0 ? (
								this.filteredItems.map((item, index) => (
									<CountryListSelectItem
										item={item}
										onClick={this.Select}
										key={item.alpha_2}
									/>
								))
							) : (
								<li>Sonuç bulunamadı!</li>
							)}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

const CountryListSelectItem = (props) => {
	const ClickHandler = () => {
		props.onClick(props.item);
	};
	return (
		<li onClick={ClickHandler}>
			<img src={props.item.image} alt={props.alpha_2} />
			{" " + props.item.name + " "}(+{props.item.country_code})
		</li>
	);
};

export default CountryListSelect;
import React from 'react';


class Warning extends React.Component {
    render() {
        return (
            <div style={{textAlign: "center"}}>
                    <div className="wizard-warning-area">
                        <div style={{fontSize: '1.5rem'}}><u>DOLANDIRICILARA DİKKAT EDİN!</u></div>
                        <div style={{fontSize: '1rem'}}>Satışınız olduğunda sizi <span><u>sadece SMS</u></span> ile bilgilendireceğiz. </div>
                        <div style={{fontSize: '1rem'}}>Gönderilen SMS'in sonunda <span><u>B021</u> veya <u>B038</u></span> yazacaktır.</div>
                        <div style={{fontSize: '1rem'}}>Tüm işlemler <span><u>www.gamesatis.com</u></span> üzerinden gerçekleşecektir.</div>
                        <div style={{fontSize: '1rem'}}><span>Satış İşlemlerim</span> sayfası üzerinden alıcınıza ulaşabilirsiniz.</div>
                        <div style={{fontSize: '1rem'}}><span>Mail, Whatsapp, Discord veya farklı yollar ile teslimat yapmayın!</span></div>
                    </div>
            </div>
        );
    }
}

export default Warning;
import React from "react";

const Stars = (props) => {
	const value = Math.round(props.value);

	return (
		<div className={"stars-grid " + (props.className || '')} onClick={props.onClick && props.onClick}>
			{[1, 2, 3, 4, 5].map((value) => (
				<svg className={"icon" + (value <= props.value ? ' active' : '')} key={value}>
					<use xlinkHref={props.xlinkHref} />
				</svg>
			))}
		</div>
	);
};

export default Stars;
function Switcher(switcher, callback){
	const buttons = switcher.querySelectorAll('button');
	buttons.forEach(function(button){
		button.addEventListener('click', function(e){
			buttons.forEach(function(b){
				b.classList.remove('active');
			});
			button.classList.add('active');
			callback(button);
		});
	});
}

export default Switcher;
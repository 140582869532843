import React from "react";
import PostData from "../../../packs_helper/scripts/PostData";

class UpdateStoreName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store_name: ''
        };
        this.SaveStore = this.SaveStore.bind(this);
    }

    componentDidMount(){

    }

    SaveStore() {
        const formData = { store_name: this.state.store_name};
        const _self = this;
        PostData("/magaza-kaydet", formData)
            .then((data) => {
                if (data.state){
                    this.props.UpdateData();
                    this.props.SetPopUpNull();
                }
            })
            .catch((err) => {

            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="grid-1">
                    <div className="input-with-label">
                        <label>Mağaza Adı:</label>
                        <input type="text" name="store_name" onChange={(e) => this.setState({store_name: e.target.value})} />
                    </div>

                    <button className="btn btn-blue" onClick={() => this.SaveStore()}>Kaydet</button>
                </div>
            </React.Fragment>
        );
    }
}

export default UpdateStoreName;
